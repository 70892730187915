import { Router, NavigationEnd } from '@angular/router';
import { filter, map, delay } from 'rxjs/operators';
import { SentryService } from './sentry.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./sentry.service";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(_router, _sentryService) {
        this._router = _router;
        this._sentryService = _sentryService;
    }
    GoogleAnalyticsService.prototype.startTracking = function () {
        if (environment.keys.analytics.isEnabled) {
            var success = this.appendTrackingCode();
            if (success) {
                this.subscribeToRouteChanges();
            }
        }
    };
    GoogleAnalyticsService.prototype.appendTrackingCode = function () {
        try {
            var script = document.createElement('script');
            script.innerHTML = "\n                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n        \n                ga('create', '" + environment.keys.analytics.key + "', 'auto');\n            ";
            document.head.appendChild(script);
            return true;
        }
        catch (ex) {
            this._sentryService.captureException(new Error('Error appending google analytics'));
            return false;
        }
    };
    GoogleAnalyticsService.prototype.subscribeToRouteChanges = function () {
        this._router.events
            .pipe(filter(function (e) { return e instanceof NavigationEnd; }), map(function (e) {
            return { url: e.urlAfterRedirects };
        }), delay(0))
            .subscribe(function (x) {
            ga('set', 'page', x.url);
            ga('send', 'pageview');
        });
    };
    GoogleAnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SentryService)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
