import { Component } from '@angular/core';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';
import { HotjarService } from './shared/services/hotjar.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent {
    constructor(private _googleAnalyticsService: GoogleAnalyticsService, private _hotjarService: HotjarService) {
        this._googleAnalyticsService.startTracking();
        this._hotjarService.startTracking();
    }
}
