/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-segment.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "./overview-segment-chart.component";
import * as i4 from "./scroll.service";
var styles_OverviewChartSegmentComponent = [i0.styles];
var RenderType_OverviewChartSegmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewChartSegmentComponent, data: {} });
export { RenderType_OverviewChartSegmentComponent as RenderType_OverviewChartSegmentComponent };
export function View_OverviewChartSegmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "b-overview__segment-inner"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickMenuItem(_co.fragment) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "b-overview__segment-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "b-overview__segment-pie-chart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(6, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.chart.dataSets; var currVal_2 = _co.item.chart.labels; var currVal_3 = _co.settings.options; var currVal_4 = _co.settings.type; var currVal_5 = _co.settings.colors; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.headline; _ck(_v, 3, 0, currVal_0); }); }
export function View_OverviewChartSegmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-overview-segment-chart", ""]], null, null, null, View_OverviewChartSegmentComponent_0, RenderType_OverviewChartSegmentComponent)), i1.ɵdid(1, 49152, null, 0, i3.OverviewChartSegmentComponent, [i4.ScrollService], null, null)], null, null); }
var OverviewChartSegmentComponentNgFactory = i1.ɵccf("[app-overview-segment-chart]", i3.OverviewChartSegmentComponent, View_OverviewChartSegmentComponent_Host_0, { fragment: "fragment", item: "item", settings: "settings" }, {}, []);
export { OverviewChartSegmentComponentNgFactory as OverviewChartSegmentComponentNgFactory };
