
import { MarkerType } from './marker-type';
import { MarkerFacilityType } from './marker-facility-type';
import { MarkerPrimarySchoolType } from './marker-primary-school-type';
import { MarkerHighSchoolType } from './marker-high-school-type';
import { MarkerDaycareType } from './marker-daycare-type';
import { MarkerRealEstateType } from './marker-realestate-type';
import { MarkerSchoolDistrictType } from './marker-school-district-type';

export class MarkerTypeContainer {
    markerType: MarkerType;
    markerSubType: number | null;

    constructor(markerType: MarkerType, markerSubType: number = null) {
        this.markerType = markerType;
        this.markerSubType = markerSubType;
    }

    public get markerTypePropertyName(): string {
        let name = this.markerTypeName;
        if (name != null)
            return this.getLoweredName(name);
        else
            return null;
    }

    public get markerSubTypePropertyName(): string {
        let name = this.markerSubTypeName;
        if (name != null)
            return this.getLoweredName(name);
        else
            return null;
    }

    public get markerSubTypePropertyNames(): string[] {
        switch (this.markerType) {
            case MarkerType.Facility:
                return Object
                    .keys(MarkerFacilityType)
                    .filter(k => typeof MarkerFacilityType[k as any] === "number" && MarkerFacilityType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            case MarkerType.PrimarySchool:
                return Object
                    .keys(MarkerPrimarySchoolType)
                    .filter(k => typeof MarkerPrimarySchoolType[k as any] === "number" && MarkerPrimarySchoolType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            case MarkerType.HighSchool:
                return Object
                    .keys(MarkerHighSchoolType)
                    .filter(k => typeof MarkerHighSchoolType[k as any] === "number" && MarkerHighSchoolType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            case MarkerType.Daycare:
                return Object
                    .keys(MarkerDaycareType)
                    .filter(k => typeof MarkerDaycareType[k as any] === "number" && MarkerDaycareType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            case MarkerType.RealEstate:
                return Object
                    .keys(MarkerRealEstateType)
                    .filter(k => typeof MarkerRealEstateType[k as any] === "number" && MarkerRealEstateType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            case MarkerType.SchoolDistrict:
                return Object
                    .keys(MarkerSchoolDistrictType)
                    .filter(k => typeof MarkerSchoolDistrictType[k as any] === "number" && MarkerSchoolDistrictType[k as any] != "0")
                    .map(x => this.getLoweredName(x));
            default:
                return null;
        }
    }

    public get markerSubTypeValues(): number[] {
        switch (this.markerType) {
            case MarkerType.Facility:
                return Object
                    .keys(MarkerFacilityType)
                    .filter(k => typeof MarkerFacilityType[k as any] === "number" && MarkerFacilityType[k as any] != "0")
                    .map(k => +MarkerFacilityType[k as any]);
            case MarkerType.PrimarySchool:
                return Object
                    .keys(MarkerPrimarySchoolType)
                    .filter(k => typeof MarkerPrimarySchoolType[k as any] === "number" && MarkerPrimarySchoolType[k as any] != "0")
                    .map(k => +MarkerPrimarySchoolType[k as any]);
            case MarkerType.HighSchool:
                return Object
                    .keys(MarkerHighSchoolType)
                    .filter(k => typeof MarkerHighSchoolType[k as any] === "number" && MarkerHighSchoolType[k as any] != "0")
                    .map(k => +MarkerHighSchoolType[k as any]);
            case MarkerType.Daycare:
                return Object
                    .keys(MarkerDaycareType)
                    .filter(k => typeof MarkerDaycareType[k as any] === "number" && MarkerDaycareType[k as any] != "0")
                    .map(k => +MarkerDaycareType[k as any]);
            case MarkerType.RealEstate:
                return Object
                    .keys(MarkerRealEstateType)
                    .filter(k => typeof MarkerRealEstateType[k as any] === "number" && MarkerRealEstateType[k as any] != "0")
                    .map(k => +MarkerRealEstateType[k as any]);
            case MarkerType.SchoolDistrict:
                return Object
                    .keys(MarkerSchoolDistrictType)
                    .filter(k => typeof MarkerSchoolDistrictType[k as any] === "number" && MarkerSchoolDistrictType[k as any] != "0")
                    .map(k => +MarkerSchoolDistrictType[k as any]);
            default:
                return null;
        }
    }

    public get hasSubType(): boolean {
        return this.markerSubType != null;
    }

    public get isMarkerWithPossibleSubType(): boolean {
        return this.markerType == MarkerType.Facility || this.markerType == MarkerType.HighSchool || this.markerType == MarkerType.PrimarySchool || this.markerType == MarkerType.Daycare || this.markerType == MarkerType.RealEstate || this.markerType == MarkerType.SchoolDistrict;
    }

    public matches(otherMarkerTypeContainer: MarkerTypeContainer): boolean {
        return this.markerType == otherMarkerTypeContainer.markerType && this.markerSubType == otherMarkerTypeContainer.markerSubType;
    }

    public get isDaycareType(): boolean {
        return this.markerType == MarkerType.Daycare;
    }
    public get isPrivateDaycareType(): boolean {
        return this.markerType == MarkerType.PrivateDaycare;
    }
    public get isPrimarySchoolType(): boolean {
        return this.markerType == MarkerType.PrimarySchool;
    }
    public get isHighSchoolType(): boolean {
        return this.markerType == MarkerType.HighSchool;
    }
    public get isFacilityType(): boolean {
        return this.markerType == MarkerType.Facility;
    }
    public get isSportsUnionType(): boolean {
        return this.markerType == MarkerType.SportsUnion;
    }
    public get isPublicTransportationType(): boolean {
        return this.markerType == MarkerType.PublicTransportation;
    }
    public get isSupermarketType(): boolean {
        return this.markerType == MarkerType.Supermarket;
    }
    public get isNoiseType(): boolean {
        return this.markerType == MarkerType.Noise;
    }
    public get isTraficCountType(): boolean {
        return this.markerType == MarkerType.TraficCount;
    }
    public get isDoctorType(): boolean {
        return this.markerType == MarkerType.Doctor;
    }
    public get isRealEstateType(): boolean {
        return this.markerType == MarkerType.RealEstate;
    }
    public get isSchoolDistrictType(): boolean {
        return this.markerType == MarkerType.SchoolDistrict;
    }
    public get isResidentsForRentType(): boolean {
        return this.markerType == MarkerType.ResidentsForRent;
    }



    private get markerTypeName(): string {
        return MarkerType[this.markerType]
    }

    private get markerSubTypeName(): string {
        switch (this.markerType) {
            case MarkerType.Facility:
                return MarkerFacilityType[this.markerSubType]
            case MarkerType.PrimarySchool:
                return MarkerPrimarySchoolType[this.markerSubType]
            case MarkerType.HighSchool:
                return MarkerHighSchoolType[this.markerSubType]
            case MarkerType.Daycare:
                return MarkerDaycareType[this.markerSubType]
            case MarkerType.RealEstate:
                return MarkerRealEstateType[this.markerSubType]
            case MarkerType.SchoolDistrict:
                return MarkerSchoolDistrictType[this.markerSubType]
            default:
                return null;
        }
    }

    private getLoweredName(name: string): string {
        return name.charAt(0).toLowerCase() + name.slice(1);
    }

    // Members are the members of the more specific subtypes. We cast to one of those types, when needing to use them specifically: 
    // - MarkerFacilityType
    // - MarkerHighSchoolType
    // - MarkerPrimarySchoolType
}