import * as tslib_1 from "tslib";
import { BaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';
var Parish = /** @class */ (function (_super) {
    tslib_1.__extends(Parish, _super);
    function Parish() {
        var _this = _super.call(this) || this;
        _this.areaLevel = AreaLevel.Parish;
        _this.countryId = 208;
        return _this;
    }
    Parish.prototype.init = function (obj) {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
        this.municipalityId = obj.municipalityId;
        this.parishId = obj.parishId;
    };
    Object.defineProperty(Parish.prototype, "fullName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    return Parish;
}(BaseArea));
export { Parish };
