import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IAreaEventData } from './area-event-data';
import { AreaLevel } from '../shared/types/areaLevel.enum';

@Component({
    selector: 'app-breadcrumb-mobile',
    templateUrl: './breadcrumb-mobile.component.html',
    styleUrls: ['./breadcrumb-mobile.component.scss']
})
export class BreadcrumbMobileComponent {
    @Input() areaLevel: any;
    @Input() path: any;

    @Output() onClickArea = new EventEmitter<IAreaEventData>();

    constructor() { }

    clickArea(areaLevel: AreaLevel, regionId?: number, municipalityId?: number, parishId?: number) {
        this.onClickArea.emit({
            areaLevel: areaLevel,
            regionId: regionId,
            municipalityId: municipalityId,
            parishId: parishId,
        });
    }
}
