<div class="b-left-menu">
    <div class="ui vertical fluid accordion following menu">

        <div class="item {{item.cssClass}}" *ngFor="let item of items">
            <a class="title active" (click)="clickMenuItem(item, true)">
                <i class="{{item.icon}} icon"></i>&nbsp;
                <b>{{item.title}}</b>
            </a>
            <div class="content menu {{item.fragment}} {{item.cssClass}}" [ngClass]="{'hidden': true == item.hideSubItems}">
                <a class="item" (click)="clickMenuItem(subitem)" *ngFor="let subitem of item.items" [ngClass]="{'hidden' : subitem.hidden}">{{subitem.title}}</a>
            </div>
        </div>
    </div>
</div>