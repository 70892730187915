<div class="b-menu-container-desktop" [ngClass]="{'responsive' : settings.position == 1, 'fixed' : settings.position == 3, 'overlayed' : settings.isOverlayed}">
    <div class="ui top inverted blue container menu desktop" [ngClass]="{'responsive' : settings.position == 1, 'fullwidth' : settings.position == 2, 'fixed' : settings.position == 3, 'overlayed' : settings.isOverlayed}">
            <app-feedback></app-feedback>
            <div class="ui container">

            <a class="header item" routerLink="/">
                Ditomraade.dk
                <span class="b-beta">beta</span>
            </a>

            <a class="item" [ngClass]="{'active' : item.active}" *ngFor="let item of items.left" [routerLink]="item.link">
                <div class="content">
                    {{item?.title}}
                </div>
            </a>

            <div class="ui category search item" *ngIf="settings.isSearchBoxEnabled">
                <div class="ui icon input">
                    <app-address-search-box (onSelected)="onSelect($event)" style="width:300px" [showIcon]="true"></app-address-search-box>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="b-menu-container-phone">
    <div class="ui top inverted blue fixed menu phone">
        <div class="ui container">
            <app-feedback></app-feedback>
                
            <a class="item" id="menu-sidebar-toggle">
                <i class="bars icon" *ngIf="!sidebar.isActive"></i>
                <i class="close icon" *ngIf="sidebar.isActive"></i>
            </a>

            <div class="menu right secondary">
                <a class="header item" routerLink="/">
                    Ditomraade.dk
                    <span class="b-beta">beta</span>
                </a>
            </div>
        </div>
    </div>
</div>