import { IAreaContainerGeometries, AreaContainerGeometries } from './area-container-geometries';
import { IRegion, Region } from './region';
import { MultiPolygonGeometry } from '../shared/types/geometry/multiPolygon';
import { Feature } from '../shared/types/geometry/feature';
import { PointGeometry } from '../shared/types/geometry/point';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Bounds } from '../shared/types/geometry/bounds';
import { IBaseAreaContainerItem } from './base-area-container-item';

export class AreaContainerRegionItem implements IAreaContainerRegionItem, IBaseAreaContainerItem {
    private _isGeometryAdded: boolean = false;

    core: IRegion;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    constructor() {
        this.geometry = new AreaContainerGeometries();
    }

    addCore(region: any): void {
        let core = new Region();
        core.init(region);
        this.core = core;
    }

    addGeometry(region: any): void {
        // Area
        let geometry = new MultiPolygonGeometry(region.geometry.coordinates);
        let properties = { regionId: region.regionId, areaLevel: AreaLevel.Region /*, bbox: new Bounds(region.bounds).bbox*/ };
        let feature = new Feature(geometry, properties, region.regionId);
        this.geometry.areaFeature = feature;

        // Center
        let g = new PointGeometry([region.visualCenter.lng, region.visualCenter.lat]);
        let p = { regionId: region.regionId, name: region.name };
        let f = new Feature(g, p);
        this.geometry.centerFeature = f;

        // Visual center
        this.geometry.visualCenter = region.visualCenter;

        // Bounds
        let b = new Bounds(region.bounds);
        this.geometry.bounds = b;

        // Change state
        this._isGeometryAdded = true;
    }

    hasGeometry(): boolean {
        return this._isGeometryAdded;
    }

    hasFactsheet(): boolean {
        return false;
    }
}

export interface IAreaContainerRegionItem {
    core: IRegion;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    addCore(country: any): void;
    addGeometry(country: any): void;

    hasGeometry(): boolean;
    hasFactsheet(): boolean;
}