import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { MunicipalityComponent } from './municipality/municipality.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MenuPosition } from './shared/types/menuPosition.enum';
// import { SitemapComponent } from './sitemap/sitemap.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DataSourcesComponent } from './data-sources/data-sources.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { BetaComponent } from './beta/beta.component';
import { RoadmapComponent } from './roadmap/roadmap.component';
var ɵ0 = { layoutSettings: { position: MenuPosition.Responsive, isOverlayed: true } }, ɵ1 = { layoutSettings: { position: MenuPosition.Fixed } }, ɵ2 = { layoutSettings: { position: MenuPosition.Fixed } }, ɵ3 = { layoutSettings: { position: MenuPosition.Fixed } }, ɵ4 = { layoutSettings: { position: MenuPosition.Fixed } }, ɵ5 = { layoutSettings: { position: MenuPosition.Fixed } }, ɵ6 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true }, ɵ7 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true }, ɵ8 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true }, ɵ9 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true }, ɵ10 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true }, ɵ11 = { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled: true }, reuse: true };
var routes = [
    { path: '', component: HomeComponent, data: ɵ0 },
    { path: 'kommune', component: MunicipalityComponent, data: ɵ1 },
    { path: 'kommune/:municipality', component: MunicipalityComponent, data: ɵ2 },
    { path: 'kommune/:municipality/:parish', component: MunicipalityComponent, data: ɵ3 },
    { path: 'kommune/:municipality/:parish/:zip/:road', component: MunicipalityComponent, data: ɵ4 },
    { path: 'kommune/:municipality/:parish/:zip/:road/:level', component: MunicipalityComponent, data: ɵ5 },
    { path: 'kort', component: MapComponent, data: ɵ6 },
    { path: 'kort/:region', component: MapComponent, data: ɵ7 },
    { path: 'kort/:region/:municipality', component: MapComponent, data: ɵ8 },
    { path: 'kort/:region/:municipality/:parish', component: MapComponent, data: ɵ9 },
    { path: 'kort/:region/:municipality/:parish/:zip/:road', component: MapComponent, data: ɵ10 },
    { path: 'kort/:region/:municipality/:parish/:zip/:road/:level', component: MapComponent, data: ɵ11 },
    { path: 'om-ditomraade', component: AboutComponent },
    { path: 'kontakt', component: ContactComponent },
    { path: 'almindelige-vilkaar', component: TermsAndConditionsComponent },
    // { path: 'sitemap', component: SitemapComponent },
    { path: 'cookiepolitik', component: CookiesComponent },
    { path: 'datakilder', component: DataSourcesComponent },
    { path: 'privatlivspolitik', component: PrivacyTermsComponent },
    { path: 'beta', component: BetaComponent },
    { path: 'roadmap', component: RoadmapComponent },
    // Handle all other routes
    { path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
