import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MarkerService } from '../marker.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MarkerItem } from '../marker-item';
import { MarkerTypeContainer } from '../marker-type-container';

declare var $: any;

@Component({
    selector: 'app-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['./comparison.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ComparisonComponent implements OnInit {
    private _unsubscribe: Subject<void> = new Subject<void>();
    private _jqModal: any = null;
    private _modalId: string = '#b-comparison-modal';

    isVisible: boolean = false;
    title: string = null;
    markerTypeContainer: MarkerTypeContainer = null;
    items: any[] = [];
    dateUpdated: any = null;

    constructor(private _markerService: MarkerService) { }

    ngOnInit(): void {
        this._markerService.onToggleComparisonVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(x => {
                this.openCompare(x.markerItem);
            });
    }

    ngAfterViewInit(): void {
        this._jqModal = $(this._modalId)
            .modal('setting', 'transition', 'scale')
            .modal('setting', 'duration', 250);
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();

        // Marking all existing modals to be destryed (it does not seem to work to just destroy the modal from the .ui.modals container here)
        // If not removed properly, the modal will get duplicated each time this component is initialized.
        $(this._modalId)
            .addClass('destroyed');
    }

    openCompare(markerItem: MarkerItem) {
        this.title = 'Sammenligning af ' + markerItem.headline;
        this.items = markerItem.comparedItems;
        this.dateUpdated = markerItem.dataUpdated;
        this.markerTypeContainer = markerItem.markerTypeContainer;
        this.isVisible = true;

        this._jqModal
            .modal('show');

        // Removing modals marked as "destroyed" that are created after "show" is called 
        $(this._modalId + '.destroyed')
            .remove();
    }

    clickRemoveFromCompare(itemId: number) {
        this._markerService.toggleCompare(itemId, this.markerTypeContainer);
    }
}
