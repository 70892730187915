<div class="b-container" *ngIf="item">
    <div *ngIf="isCompareEnabled" class="ui toggle checkbox right floated" [ngClass]="{'checked' : item.isOnCompareList, 'with-address' : hasAddress}" (click)="clickToggleCompare()">
        <input type="checkbox" [checked]="item.isCompared">
        <label></label>
    </div>
    <div class="b-body" (click)="clickToggleSelected()" [ngClass]="{'active': item.isSelected, 'faded': false == !!item.isSelected && markerItem.selectedItems.length > 0}">
        <p class="b-default-text">
            <span>{{item.name}}</span>
        </p>
        <p *ngIf="hasAddress" class="b-small-text">
            <span class="text small">{{item.distanceToAddress | number: '1.2-2'}} km</span>
        </p>
    </div>
</div>