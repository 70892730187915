
      <div *ngIf="item" class="ui grid b-mobile" style="margin-top:0;">
          <div class="ten wide column">
              <span>{{item.headline}}</span>
              <span *ngIf="item.description" class="b-description">{{item.description}}</span>
          </div>
          <div class="six wide right aligned column">
              <span *ngIf="item.statistics && item.statistics.municipality">{{item.statistics.municipality.present.value | number: '1.0'}} {{item.statistics.municipality.present.unit}}</span>
              <span *ngIf="!(item.statistics && item.statistics.municipality)">-</span>
          </div>
      </div>
  