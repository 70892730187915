export enum DataSourceType {
    None = 0,

    DanmarksStatistik = 1,
    NoegletalDk = 2,
    FinansDanmark = 3,
    StyrelsenForItOgLaering = 4,
    BoerneOgUndervisningsMinisteriet = 5,
    Facilitetsdatabasen = 6,
    Dgi = 7,
    Rejseplanen = 8,
    SundhedsDataStyrelsen = 9,
    KommunernesLandsforening = 10,
    Vejdirektoratet = 11
}