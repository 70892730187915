/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./left-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./left-menu.component";
import * as i4 from "./scroll.service";
import * as i5 from "./menu-items.service";
var styles_LeftMenuComponent = [i0.styles];
var RenderType_LeftMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeftMenuComponent, data: {} });
export { RenderType_LeftMenuComponent as RenderType_LeftMenuComponent };
function View_LeftMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickMenuItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_2); }); }
function View_LeftMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "title active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickMenuItem(_v.context.$implicit, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "hidden": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_3 = i1.ɵinlineInterpolate(2, "content menu ", _v.context.$implicit.fragment, " ", _v.context.$implicit.cssClass, ""); var currVal_4 = _ck(_v, 9, 0, (true == _v.context.$implicit.hideSubItems)); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.items; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "item ", _v.context.$implicit.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, " icon"); _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_LeftMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "b-left-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "ui vertical fluid accordion following menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftMenuComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_LeftMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-left-menu", [], null, null, null, View_LeftMenuComponent_0, RenderType_LeftMenuComponent)), i1.ɵdid(1, 4243456, null, 0, i3.LeftMenuComponent, [i4.ScrollService, i5.MenuItemsService], null, null)], null, null); }
var LeftMenuComponentNgFactory = i1.ɵccf("app-left-menu", i3.LeftMenuComponent, View_LeftMenuComponent_Host_0, {}, {}, []);
export { LeftMenuComponentNgFactory as LeftMenuComponentNgFactory };
