import { MapService } from './map.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./map.service";
var CategoriesService = /** @class */ (function () {
    function CategoriesService(_mapService) {
        var _this = this;
        this._mapService = _mapService;
        this.onLoad = new BehaviorSubject({ isLoading: false, isLoaded: false });
        this._mapService
            .onFitBoundsStart
            .subscribe(function (x) {
            _this.onLoad.next({
                isLoading: true,
                isLoaded: false
            });
        });
        this._mapService
            .onToggleLoadMarkers
            .subscribe(function (x) {
            _this.onLoad.next({
                isLoading: x.isLoading,
                isLoaded: x.isLoaded
            });
        });
    }
    CategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.ɵɵinject(i1.MapService)); }, token: CategoriesService, providedIn: "root" });
    return CategoriesService;
}());
export { CategoriesService };
