import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    settings: any = {}
    sidebar: any = {}
    items: any = {}

    constructor(private _layoutService: LayoutService, private _router: Router) {
    }
    
    ngOnInit(): void {
        this._layoutService.init();
        this.settings = this._layoutService.settings;
        this.sidebar = this._layoutService.sidebar;
        this.items.left = this._layoutService.menu.items.left.slice(1);
        this.items.right = this._layoutService.menu.items.right;
    }

    ngAfterViewInit() {
        this._layoutService.menuLoaded();
    }

    onSelect(selected: any) {
        var route = 'kort';
        if (selected.zipParam == null && selected.roadParam == null)
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam]);
        else if (selected.levelParam == null)
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam, selected.zipParam, selected.roadParam]);
        else
            this._router.navigate([route, selected.regionParam, selected.municipalityParam, selected.parishParam, selected.zipParam, selected.roadParam, selected.levelParam]);
    }
}
