import { Component, Output, EventEmitter, OnDestroy, ViewEncapsulation, ComponentFactoryResolver, Injector, OnInit } from '@angular/core';
import { Subject, Observable, forkJoin, empty, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as mapboxgl from 'mapbox-gl';
import { MapService } from './map.service';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { IFeatureCollection, FeatureCollection } from '../shared/types/geometry/featureCollection';
import { IBounds, Bounds } from '../shared/types/geometry/bounds';
import { IZoom } from '../shared/types/zoom/zoom';
import { RouteDataService } from 'src/app/map/route-data.service';
import { IRouteData } from 'src/app/map/route-data';
import { IAreaEventData } from 'src/app/map/area-event-data';
import { ICurrentAreaEventData } from 'src/app/map/current-area-event-data';
import { ZoomService } from 'src/app/map/zoom.service';
import { MarkerService } from 'src/app/map/marker.service';
import { MarkerType } from 'src/app/map/marker-type';
import { IImageDataContainer, ImageDataContainer } from './image-data-container';
import { MarkerItem } from './marker-item';
import { IAddress } from './address';
import { AddressLevel } from '../shared/types/addressLevel.enum';
import { PointGeometry } from '../shared/types/geometry/point';
import { Feature, IFeature } from '../shared/types/geometry/feature';
import { UtilityService } from '../shared/services/utility.service';
import { DeviceType } from '../shared/types/device-type.enum';
import { Coordinate } from '../shared/types/geometry/coordinate';
import { IAreaContainerCountryItem } from './area-container-country-item';
import { IAreaContainerRegionItem } from './area-container-region-item';
import { IAreaContainerMunicipalityItem } from './area-container-municipality-item';
import { IAreaContainerParishItem } from './area-container-parish-item';
import { IBaseAreaContainerItem } from './base-area-container-item';
import { MarkerTypeContainer } from './marker-type-container';
import { LayerType } from './layer-type';

declare var $: any;

@Component({
    selector: 'app-map-box',
    templateUrl: './map-box.component.html',
    styleUrls: ['./map-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MapBoxComponent implements OnInit, OnDestroy {
    @Output() onMapLoaded: EventEmitter<ICurrentAreaEventData> = new EventEmitter<ICurrentAreaEventData>();
    @Output() onLayerClick: EventEmitter<IAreaEventData> = new EventEmitter<IAreaEventData>();

    private _unsubscribe: Subject<void> = new Subject<void>();
    private _cancelLoadMarkerData: Subject<void> = new Subject<void>();

    private _map: mapboxgl.Map = null;
    private _eventsRegistered: any[] = [];
    // private _popup: mapboxgl.Popup = null;
    // private _popupComponent: ComponentRef<PopupDesktopComponent> = null;
    private _hoveredStateId: { [key: string]: number } = {};
    private _imageDataContainers: { [key: string]: IImageDataContainer } = {};
    private _mapSettings: any = {
        animationDuration: 800, // 1000,
        layerColor: {
            region: '#009455',
            municipality: '#2185d0',
            parish: '#d03221',
        },
        isInitialLoad: true
    }

    current = {
        activeLayer: {
            region: {
                countryId: null
            },
            municipality: {
                regionId: null
            },
            parish: {
                municipalityId: null
            }
        },
        area: null as IBaseAreaContainerItem,
        bounds: null as IBounds,
        address: null as IAddress,
        path: {
            country: null as IAreaContainerCountryItem,
            region: null as IAreaContainerRegionItem,
            municipality: null as IAreaContainerMunicipalityItem,
            parish: null as IAreaContainerParishItem,
        },
        isFitBoundsAnimating: false,
        isAreaLoaded: false
    }

    constructor(private _utilityService: UtilityService, private _mapService: MapService, private _zoomService: ZoomService, private _markerService: MarkerService, private _routeDataService: RouteDataService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.initializeMap();
    }

    // // This is a lifecycle method of an Angular component which gets invoked whenever for
    // // our component change detection is triggered
    // ngDoCheck() {
    //     if (this._popupComponent && false == this._popupComponent.changeDetectorRef['destroyed'])
    //         this._popupComponent.changeDetectorRef.detectChanges();
    // }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();

        this._cancelLoadMarkerData.next();
        this._cancelLoadMarkerData.complete();
    }

    private initializeMap() {
        // // Locate the user
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(position => {
        //         var lat = position.coords.latitude;
        //         var lng = position.coords.longitude;
        //     });
        // }

        this._mapService
            .loadInitialAreaData()
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.buildMap();
            });
    }

    private getVisibleBounds(isIncludePadding: boolean = true): IBounds {
        let bounds = this._map.getBounds();

        let padding = { top: 0, left: 0, right: 0, bottom: 0 }
        if (isIncludePadding)
            padding = this.getFitBoundsPadding();

        let projectSw = this._map.project(bounds.getSouthWest());
        let projectNe = this._map.project(bounds.getNorthEast());
        var newProjectSw = new mapboxgl.Point(padding.left, projectSw.y);
        var newProjectNe = new mapboxgl.Point(projectNe.x, padding.top);

        let unprojectSw = this._map.unproject(newProjectSw);
        let unprojectNe = this._map.unproject(newProjectNe);

        return new Bounds([[unprojectSw.lng, unprojectSw.lat], [unprojectNe.lng, unprojectNe.lat]]);
    }

    private buildMap() {
        this._map = new mapboxgl.Map({
            container: 'map',
            style: 'assets/mapbox/style.json',
            center: [11.6, 55.6],
            zoom: 6,
            attributionControl: false
        });

        // Adding 2 sets of attrib controls. One for desktop and oe for mobile. @media queries are handling the respective visibility.
        this._map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'bottom-left');
        this._map.addControl(new mapboxgl.AttributionControl({ compact: true }), 'top-left');

        this._map.on('moveend', x => {
            let bounds = this.getVisibleBounds();
            this.current.bounds = bounds;
            this._mapService.changeBounds(bounds);

            // let bboxLayerId = 'bbox_layer';
            // if (false == this.hasLayer(bboxLayerId)) {
            //     this._map.addLayer({
            //         'id': bboxLayerId,
            //         'type': 'line',
            //         'source': {
            //             'type': 'geojson',
            //             'data': {
            //                 'type': 'Feature',
            //                 'properties': {},
            //                 'geometry': {
            //                     'type': 'LineString',
            //                     'coordinates': [
            //                         [bounds[0][0], bounds[0][1]],
            //                         [bounds[1][0], bounds[1][1]]
            //                     ]
            //                 }
            //             }
            //         },
            //         'layout': {
            //             'line-join': 'round',
            //             'line-cap': 'round'
            //         },
            //         'paint': {
            //             'line-color': '#888',
            //             'line-width': 8
            //         }
            //     });
            // }
        });

        this._map.on('load', (_event) => {
            let bounds = this.getVisibleBounds();
            this.current.bounds = bounds;
            this._mapService.changeBounds(bounds);

            // Load category icon images
            this.loadImages()
                .subscribe(() => {
                    // Common
                    this.registerIdleEventListener(this.fitBoundsFromLayerClickFinished);

                    this._routeDataService.onRouteChange
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe((x: IRouteData) => {
                            this.fitBoundsFromRouteChange(x);
                        });

                    this._zoomService.onZoomChange
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe(() => {
                            this.fitBounds(this.current.area.geometry.bounds.bbox);
                        });

                    this._markerService.onToggleMarkerVisibility
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe((x: any) => {
                            this.toggleCategoryLayer(x.markerTypeContainer);
                        });

                    this._markerService.onToggleSelect
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe((x) => {
                            if (x == null || this.current.area.core.areaLevel < 30)
                                return;

                            switch (x.markerItem.layerTypeId) {
                                case LayerType.Marker:
                                    this.toggleMarkerItemSelected(x.markerItem, x.item);

                                    break;
                                case LayerType.Area:
                                    this.toggleAreaItemSelected(x.markerItem, x.item);

                                    break;
                                default:
                                    throw new Error(`LayerType with value ${x.markerItem.layerTypeId} is not supported.`);
                            }
                        });

                    this._mapService.onMobileMenuHeightChanged
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe((x: any) => {
                            if (this.current.area && this.current.area.geometry && this.current.area.geometry.bounds && this.current.area.geometry.bounds.bbox) {
                                this.fitBounds(this.current.area.geometry.bounds.bbox);
                            }
                        });

                    this._mapService.onResetBounds
                        .pipe(takeUntil(this._unsubscribe))
                        .subscribe(() => {
                            if (this.current.area && this.current.area.geometry && this.current.area.geometry.bounds && this.current.area.geometry.bounds.bbox) {
                                this.fitBounds(this.current.area.geometry.bounds.bbox);
                            }
                        });

                    this.onMapLoaded.emit(this.getCurrentAreaEventData(this));
                });
        });
    }

    private enableCountryArea(countryId: number): Observable<void> {
        return new Observable((observer) => {
            this.removeMunicipalityData();
            this.removeParishData();

            let obsRegion: Observable<void> = this.returnImmediatelyObservable();

            // Load regions
            if (this.current.activeLayer.region.countryId != countryId) {
                this.removeRegionData();
                obsRegion = this.addRegionData();
            }

            forkJoin(obsRegion)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                })
        });
    }

    private addRegionData(): Observable<void> {
        return new Observable((observer) => {
            this._mapService.loadRegionAreaGeometries()
                .subscribe(() => {
                    let geometries = this._mapService.getRegionAreas();
                    let areaFeatures = geometries
                        .map(x => x.areaFeature);
                    let centerFeatures = geometries
                        .map(x => x.centerFeature);

                    let areaFeatureCollection = this.getFeatureCollection(areaFeatures);
                    let centerFeatureCollection = this.getFeatureCollection(centerFeatures);

                    // Add region
                    this.addOrReplaceSource('regionAreas', areaFeatureCollection, false);
                    this.addOrReplaceSource('regionCenters', centerFeatureCollection, false);
                    this.addRegionLayers();
                    this.registerClickEventListener('region-fills', this.layerClick, 2);
                    this.registerMouseMoveEventListener('region-fills', 'regionAreas', this.highlightFromLayerMouseMove);
                    this.registerMouseLeaveEventListener('region-fills', 'regionAreas', this.unhighlightFromLayerMouseLeave);

                    this.current.activeLayer.region.countryId = 208;
                    observer.next();
                    observer.complete();
                });
        });
    }

    private removeRegionData() {
        this.unregisterClickEventListener('region-fills');
        this.unregisterMouseMoveEventListener('region-fills');
        this.unregisterMouseLeaveEventListener('region-fills');

        this.removeLayer('region-fills');
        this.removeLayer('region-borders');
        this.removeLayer('region-names');
        this.removeSource('regionAreas');
        this.removeSource('regionCenters');

        this.current.activeLayer.region.countryId = null;
    }

    private enableRegionArea(countryId: number, regionId: number): Observable<void> {
        return new Observable((observer) => {
            this.removeParishData();

            let obsRegion: Observable<void> = this.returnImmediatelyObservable();
            let obsMunicipality: Observable<void> = this.returnImmediatelyObservable();

            // Load regions
            if (this.current.activeLayer.region.countryId != countryId) {
                obsRegion = this.addRegionData();
            }

            // Load municipalities
            if (this.current.activeLayer.municipality.regionId != regionId) {
                this.removeMunicipalityData();
                obsMunicipality = this.addMunicipalityData(regionId)
            }

            forkJoin(obsRegion, obsMunicipality)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                })
        });
    }

    private addMunicipalityData(regionId: number): Observable<void> {
        return new Observable((observer) => {
            this._mapService.loadMunicipalityAreaGeometries(regionId)
                .subscribe(() => {
                    let geometries = this._mapService.getMunicipalityAreas(regionId);
                    let areaFeatures = geometries
                        .map(x => x.areaFeature);
                    let centerFeatures = geometries
                        .map(x => x.centerFeature);

                    let areaFeatureCollection = this.getFeatureCollection(areaFeatures);
                    let centerFeatureCollection = this.getFeatureCollection(centerFeatures);

                    // Add municipality
                    this.addOrReplaceSource('municipalityAreas', areaFeatureCollection, false);
                    this.addOrReplaceSource('municipalityCenters', centerFeatureCollection, false);
                    this.addMunicipalityLayers();
                    this.registerClickEventListener('municipality-fills', this.layerClick, 2);
                    this.registerMouseMoveEventListener('municipality-fills', 'municipalityAreas', this.highlightFromLayerMouseMove);
                    this.registerMouseLeaveEventListener('municipality-fills', 'municipalityAreas', this.unhighlightFromLayerMouseLeave);

                    this.current.activeLayer.municipality.regionId = regionId;
                    observer.next();
                    observer.complete();
                });
        });
    }

    private removeMunicipalityData() {
        this.unregisterClickEventListener('municipality-fills');
        this.unregisterMouseMoveEventListener('municipality-fills');
        this.unregisterMouseLeaveEventListener('municipality-fills');

        this.removeLayer('municipality-fills');
        this.removeLayer('municipality-borders');
        this.removeLayer('municipality-names');
        this.removeSource('municipalityAreas');
        this.removeSource('municipalityCenters');

        this.current.activeLayer.municipality.regionId = null;
    }


    private enableMunicipalityArea(countryId: number, regionId: number, municipalityId: number): Observable<void> {
        return new Observable((observer) => {
            let obsRegion: Observable<void> = this.returnImmediatelyObservable();
            let obsMunicipality: Observable<void> = this.returnImmediatelyObservable();
            let obsParish: Observable<void> = this.returnImmediatelyObservable();

            // Load regions
            if (this.current.activeLayer.region.countryId != countryId) {
                obsRegion = this.addRegionData();
            }

            // Load municipalities
            if (this.current.activeLayer.municipality.regionId != regionId) {
                this.removeMunicipalityData();
                obsMunicipality = this.addMunicipalityData(regionId)
            }

            // Load parishes
            if (this.current.activeLayer.parish.municipalityId != municipalityId) {
                this.removeParishData();
                obsParish = this.addParishData(municipalityId)
            }

            forkJoin(obsRegion, obsMunicipality, obsParish)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                })
        });
    }

    private addParishData(municipalityId: number): Observable<void> {
        return new Observable((observer) => {
            this._mapService.loadParishAreaGeometries(municipalityId)
                .subscribe(() => {
                    // var t0 = performance.now();

                    let geometries = this._mapService.getParishAreas(municipalityId);
                    let areaFeatures = geometries
                        .map(x => x.areaFeature);
                    let centerFeatures = geometries
                        .map(x => x.centerFeature);

                    let areaFeatureCollection = this.getFeatureCollection(areaFeatures);
                    let centerFeatureCollection = this.getFeatureCollection(centerFeatures);

                    // Add parish
                    this.addOrReplaceSource('parishAreas', areaFeatureCollection, false);
                    this.addOrReplaceSource('parishCenters', centerFeatureCollection, false);
                    this.addParishLayers();
                    this.registerClickEventListener('parish-fills', this.layerClick, 2);
                    this.registerMouseMoveEventListener('parish-fills', 'parishAreas', this.highlightFromLayerMouseMove);
                    this.registerMouseLeaveEventListener('parish-fills', 'parishAreas', this.unhighlightFromLayerMouseLeave);

                    // var t1 = performance.now();
                    // console.log("Call to addParishData took " + (t1 - t0) + " milliseconds.")

                    this.current.activeLayer.parish.municipalityId = municipalityId;
                    observer.next();
                    observer.complete();
                });
        });
    }

    private removeParishData() {
        this.unregisterClickEventListener('parish-fills');
        this.unregisterMouseMoveEventListener('parish-fills');
        this.unregisterMouseLeaveEventListener('parish-fills');

        this.removeLayer('parish-fills');
        this.removeLayer('parish-borders');
        this.removeLayer('parish-names');
        this.removeSource('parishAreas');
        this.removeSource('parishCenters');

        this.current.activeLayer.parish.municipalityId = null;
    }

    private enableParishArea(countryId: number, regionId: number, municipalityId: number): Observable<void> {
        return new Observable((observer) => {
            let obsRegion: Observable<void> = this.returnImmediatelyObservable();
            let obsMunicipality: Observable<void> = this.returnImmediatelyObservable();
            let obsParish: Observable<void> = this.returnImmediatelyObservable();

            // Load regions
            if (this.current.activeLayer.region.countryId != countryId) {
                obsRegion = this.addRegionData();
            }

            // Load municipalities
            if (this.current.activeLayer.municipality.regionId != regionId) {
                this.removeMunicipalityData();
                obsMunicipality = this.addMunicipalityData(regionId)
            }

            // Load parishes
            if (this.current.activeLayer.parish.municipalityId != municipalityId) {
                this.removeParishData();
                obsParish = this.addParishData(municipalityId)
            }

            forkJoin(obsRegion, obsMunicipality, obsParish)
                .subscribe(() => {
                    observer.next();
                    observer.complete();
                })
        });
    }

    private getFeatureCollection(features: IFeature[]): IFeatureCollection {
        var featureCollection = new FeatureCollection();
        for (const feature of features) {
            featureCollection.addFeature(feature);
        }
        return featureCollection;
    }

    private fitBounds(bbox: [[number, number], [number, number]]) {
        // Ajusting bbox coordinates to (custom) zoom level
        let bounds = new Bounds(bbox);
        let zoom = this.getZoomForCurrentArea();
        let bboxWithZoom = bounds.getBboxWithZoom(zoom);
        let padding = this.getFitBoundsPadding();

        let animationDuration: number = this.getAnimationDuration();
        this._mapSettings.isInitialLoad = false;

        // Fitting bounds with modified bbox values
        this._map.fitBounds(bboxWithZoom, { duration: animationDuration, padding: padding });
    }

    private getFitBoundsPadding(): { top: number, bottom: number, left: number, right: number } {
        let deviceType = this._utilityService.getClientDeviceType();
        let padding = { top: 0, bottom: 0, left: 0, right: 0 };

        if (deviceType == DeviceType.Mobile) {
            let heightContainer = $('#b-mobile-container').outerHeight();
            if (heightContainer) {
                // The map height is in "vh" which is relative to the max height of the viewable area of the browser. (on iPhone it is the viewable area when address bar and navigation bar is hidden)
                let heightMax = $('#b-100vh').height();
                let heightViewport = $(window).outerHeight();

                let bottom = heightContainer - 65 + (heightMax - heightViewport) + 5;
                let top = 5;

                let heightMap = heightViewport - 45 - 65;  // We need to take window height, as it resizes on mobile depending on manufacturer features (hiding address bar and navigation bar on iPhone, etc.)
                let maxPadding = heightMap - 100;   // Height should never be less than 100px

                // If padding is more than the actual height of the map, we default to a fixed padding
                var totalTopAndBottom = top + bottom;
                if (totalTopAndBottom > maxPadding) {
                    padding = { top: 0, bottom: maxPadding, left: 5, right: 5 };
                }
                else {
                    padding = { top: top, bottom: bottom, left: 5, right: 5 };
                }
            }
        }
        else {
            let deviceDimensions = this._utilityService.getClientDeviceDimensions();
            if (deviceDimensions.width <= 1024) {
                padding = { top: 90, bottom: 0, left: 270, right: 270 };
            }
            else {
                padding = { top: 50, bottom: 0, left: 350, right: 350 };
            }
        }

        return padding;

    }

    private fitBoundsFromSelectedMarker(coordinates: any): void {
        // let mapBounds = this._map.getBounds();
        // let bboxFromMap : [[number, number], [number, number]] = [[mapBounds["_sw"]["lng"], mapBounds["_sw"]["lat"]], [mapBounds["_ne"]["lng"], mapBounds["_ne"]["lat"]]];

        if (this.isCoordinateOutsideBbox(this.current.area.geometry.bounds.bbox, coordinates)) {
            let bboxWithCoordinate = this.getExtendedBbox(this.current.area.geometry.bounds.bbox, coordinates);
            this.fitBounds(bboxWithCoordinate);
        }
    }

    private getAnimationDuration() {
        let animationDuration: number = this._mapSettings.animationDuration;
        if (this.current.area.core.areaLevel > AreaLevel.Country && this._mapSettings.isInitialLoad) {
            animationDuration = 0;
        }
        return animationDuration;
    }

    private getZoomForCurrentArea(): IZoom {
        return this._zoomService.getZoomForArea(this.current.area.core.areaLevel);
    }


    // To cope for the situation where multiple click callbacks is set to be executed at the same time, we schedule these events and choose which one to execute.
    // This happens when multiple layers are located on top of each other (like a parish area and a marker icon).
    private _scheduledClickCallbacks: any[] = [];
    private _isExecutingClickCallback: boolean = false;

    private executeScheduledClickCallbacks(self: MapBoxComponent) {
        // We only wish to execute the scheduled click callbacks once
        if (false === self._isExecutingClickCallback) {
            self._isExecutingClickCallback = true;

            setTimeout(() => {
                // If we only have one scheduled callback we just execute it
                if (self._scheduledClickCallbacks.length == 1) {
                    let evt = self._scheduledClickCallbacks[0];
                    evt.callback(evt.features, evt.self);
                }
                // If we have multiple callbacks, we choose which one toi execute based on a priority defined by the event type.
                else if (self._scheduledClickCallbacks.length > 1) {
                    let evts = self._scheduledClickCallbacks
                        .sort((a: any, b: any) => {
                            return a.priority - b.priority;
                        });

                    let evt = evts[0];
                    evt.callback(evt.features, evt.self);
                }

                // Clean up
                self._scheduledClickCallbacks.splice(0, self._scheduledClickCallbacks.length)
                self._isExecutingClickCallback = false;
            }, 1);
        }
    }

    private registerClickEventListener(layer: string, callback: (e: any, self: MapBoxComponent) => any, priority: number) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'click' && x.layer == layer);
        if (index == -1) {
            let callbackWrapper = (a: any) => {
                // Add callback to the array of callbacks to be executed "very soon"
                let newFeatures = {
                    ...a.features,
                    lngLat: a.lngLat
                };

                this._scheduledClickCallbacks.push({
                    priority: priority,
                    layer: layer,
                    callback: callback,
                    features: newFeatures,
                    evt: a,
                    self: this
                });

                // Perform execution of all registered callbacks in 20 milliseconds
                setTimeout(() => { this.executeScheduledClickCallbacks(this); }, 20);
            };
            this._eventsRegistered.push({ event: 'click', layer: layer, callback: callbackWrapper });
            this._map.on('click', layer, callbackWrapper);
        }
    }
    private registerMouseMoveEventListener(layer: string, source: string, callback: (e: any, source: string, self: MapBoxComponent) => any) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'mousemove' && x.layer == layer);
        if (index == -1) {
            let callbackWrapper = (a: any) => callback(a, source, this);
            this._eventsRegistered.push({ event: 'mousemove', layer: layer, callback: callbackWrapper });
            this._map.on('mousemove', layer, callbackWrapper);
        }
    }
    private registerMouseLeaveEventListener(layer: string, source: string, callback: (e: any, source: string, self: MapBoxComponent) => any) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'mouseleave' && x.layer == layer);
        if (index == -1) {
            let callbackWrapper = (a: any) => callback(a, source, this);
            this._eventsRegistered.push({ event: 'mouseleave', layer: layer, callback: callbackWrapper });
            this._map.on('mouseleave', layer, callbackWrapper);
        }
    }
    private registerIdleEventListener(callback: (e: any, self: MapBoxComponent) => any) {
        let callbackWrapper = (a: any) => callback(a, this);
        this._eventsRegistered.push({ event: 'idle', layer: null, callback: callbackWrapper });
        this._map.on('idle', callbackWrapper);
    }

    private unregisterClickEventListener(layer: string) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'click' && x.layer == layer);
        if (index > -1) {
            let callbackWrapper = this._eventsRegistered[index].callback;
            this._eventsRegistered.splice(index, 1);
            this._map.off('click', layer, callbackWrapper);
        }
    }
    private unregisterMouseMoveEventListener(layer: string) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'mousemove' && x.layer == layer);
        if (index > -1) {
            let callbackWrapper = this._eventsRegistered[index].callback;
            this._eventsRegistered.splice(index, 1);
            this._map.off('mousemove', layer, callbackWrapper);
        }
    }
    private unregisterMouseLeaveEventListener(layer: string) {
        let index = this._eventsRegistered.findIndex(x => x.event == 'mouseleave' && x.layer == layer);
        if (index > -1) {
            let callbackWrapper = this._eventsRegistered[index].callback;
            this._eventsRegistered.splice(index, 1);
            this._map.off('mouseleave', layer, callbackWrapper);
        }
    }

    private layerClick(features: any, self: MapBoxComponent): void {
        let properties = features[0].properties;
        let areaLevel = properties.areaLevel as AreaLevel;
        let regionId = +properties.regionId;
        let municipalityId = +properties.municipalityId;
        let parishId = +properties.parishId;

        self.onLayerClick.emit({
            areaLevel: areaLevel,
            regionId: regionId,
            municipalityId: municipalityId,
            parishId: parishId,
        });
    }

    private layerClusteringCircleClick(features: any, self: MapBoxComponent) {
        let lngLat = features.lngLat;
        let zoom = self._map.getZoom() + 1;

        self._map.flyTo({
            center: lngLat,
            zoom: zoom,
            speed: 1,
        });
    }

    private markerClick(features: any, self: MapBoxComponent): void {
        let properties = features[0].properties;

        // When using properties from the features array, it is important to convert values afterwards, as everything are stringified in the mapbox features collection
        let markerType = properties.markerType;
        if (isNaN(properties.markerType)) {
            markerType = null;
        }

        let markerSubType = properties.markerSubType;
        if (isNaN(properties.markerSubType)) {
            markerSubType = null;
        }

        let markerTypeContainer = new MarkerTypeContainer(markerType, markerSubType)
        self._markerService.toggleSelected(properties.id, markerTypeContainer)
    }

    // private areaClick(features: any, self: MapBoxComponent): void {
    //     let properties = features[0].properties;

    //     console.log('areaClick', properties);

    //     // let areaLevel = properties.areaLevel as AreaLevel;
    //     // let regionId = +properties.regionId;
    //     // let municipalityId = +properties.municipalityId;
    //     // let parishId = +properties.parishId;

    //     // self.onLayerClick.emit({
    //     //     areaLevel: areaLevel,
    //     //     regionId: regionId,
    //     //     municipalityId: municipalityId,
    //     //     parishId: parishId,
    //     // });
    // }

    private loadMarkerData(currentAreaEventData: ICurrentAreaEventData): void {
        let area = currentAreaEventData.area;
        if (area.core.areaLevel >= AreaLevel.Municipality) {
            this._mapService
                .loadMapData()
                .pipe(takeUntil(this._cancelLoadMarkerData))
                .subscribe((x: any) => {
                    // Adding marker sub types data
                    this._markerService.addSubCategories(x.facilityTypes, MarkerType.Facility);
                    this._markerService.addSubCategories(x.highSchoolTypes, MarkerType.HighSchool);
                    this._markerService.addSubCategories(x.primarySchoolTypes, MarkerType.PrimarySchool);
                    this._markerService.addSubCategories(x.daycareInstitutionTypes, MarkerType.Daycare);
                    this._markerService.addSubCategories(x.realEstateItemTypes, MarkerType.RealEstate);
                    this._markerService.addSubCategories(x.educationLevelTypes, MarkerType.SchoolDistrict);

                    // Adding data types
                    this._markerService.addDataSourceTypes(x.dataSourceTypes);

                    // Initializing/updating map data
                    this.initializeCategoryLayers();

                    this._mapService.onFitBoundsEnd.next(currentAreaEventData);
                });
        }
        else {
            this._mapService.onFitBoundsEnd.next(currentAreaEventData);
        }
    }

    private cancelLoadMarkerData() {
        this._cancelLoadMarkerData.next();
        this._cancelLoadMarkerData.complete();

        // Reinitialize cancel
        this._cancelLoadMarkerData = new Subject<void>();
    }

    private removeCategoryLayers(): void {
        this.removeCategoryLayer(MarkerType.PrivateDaycare);
        this.removeCategoryLayer(MarkerType.PublicTransportation);
        this.removeCategoryLayer(MarkerType.Supermarket);
        this.removeCategoryLayer(MarkerType.Noise);
        this.removeCategoryLayer(MarkerType.TraficCount);
        this.removeCategoryLayer(MarkerType.Doctor);
        this.removeCategoryLayer(MarkerType.ResidentsForRent);
        this.removeCategoryLayer(MarkerType.SportsUnion);

        this.removeCategoryLayerWithSubCategories(MarkerType.Facility);
        this.removeCategoryLayerWithSubCategories(MarkerType.PrimarySchool);
        this.removeCategoryLayerWithSubCategories(MarkerType.HighSchool);
        this.removeCategoryLayerWithSubCategories(MarkerType.Daycare);
        this.removeCategoryLayerWithSubCategories(MarkerType.RealEstate);
        this.removeCategoryLayerWithSubCategories(MarkerType.SchoolDistrict);
    }

    private removeCategoryLayerWithSubCategories(markerType: MarkerType): void {
        let markerTypeContainer = new MarkerTypeContainer(markerType, null)
        for (const markerSubType of markerTypeContainer.markerSubTypeValues) {
            this.removeCategoryLayer(markerType, markerSubType);
        }
    }

    private initializeCategoryLayers(): void {
        this.initializeCategoryLayer(MarkerType.PrivateDaycare);
        this.initializeCategoryLayer(MarkerType.PublicTransportation);
        this.initializeCategoryLayer(MarkerType.Supermarket);
        this.initializeCategoryLayer(MarkerType.Noise);
        this.initializeCategoryLayer(MarkerType.TraficCount);
        this.initializeCategoryLayer(MarkerType.Doctor);
        this.initializeCategoryLayer(MarkerType.ResidentsForRent);
        this.initializeCategoryLayer(MarkerType.SportsUnion);

        this.initializeCategoryLayerWithSubCategories(MarkerType.Facility);
        this.initializeCategoryLayerWithSubCategories(MarkerType.PrimarySchool);
        this.initializeCategoryLayerWithSubCategories(MarkerType.HighSchool);
        this.initializeCategoryLayerWithSubCategories(MarkerType.Daycare);
        this.initializeCategoryLayerWithSubCategories(MarkerType.RealEstate);
        this.initializeCategoryLayerWithSubCategories(MarkerType.SchoolDistrict);
    }

    private initializeCategoryLayerWithSubCategories(markerType: MarkerType): void {
        let markerTypeContainer = new MarkerTypeContainer(markerType, null)
        let prop = markerTypeContainer.markerTypePropertyName;

        if (this._markerService.categories[prop].base.showMarkers) {
            for (const markerSubType of markerTypeContainer.markerSubTypeValues) {
                this.initializeCategoryLayer(markerType, markerSubType);
            }
        }
    }

    private initializeCategoryLayer(markerType: MarkerType, markerSubType: number = null): void {
        let markerTypeContainer = new MarkerTypeContainer(markerType, markerSubType)
        if (this._markerService.isMarkerVisible(markerTypeContainer)) {
            this.addCategoryLayer(markerTypeContainer);
        }
    }

    private toggleCategoryLayer(markerTypeContainer: MarkerTypeContainer) {
        if (this._markerService.isMarkerVisible(markerTypeContainer)) {
            this.addCategoryLayer(markerTypeContainer);
        }
        else {
            this.removeCategoryLayer(markerTypeContainer.markerType, markerTypeContainer.markerSubType);
        }
    }

    private addCategoryLayer(markerTypeContainer: MarkerTypeContainer) {
        let currentAreaEventData = this.getCurrentAreaEventData(this);

        // Getting coordinate and municipalityId
        let areaData = this.getCoordinateAndBoundsAndMunicipalityIdFromAreaEventData(currentAreaEventData.area);
        let municipalityId = areaData.municipalityId;
        let coordinate = areaData.coordinate;
        let bounds = this.current.bounds;
        //let bounds = areaData.bounds;

        if (markerTypeContainer.isMarkerWithPossibleSubType && false == markerTypeContainer.hasSubType) {
            this.addCategoryLayerToMap(markerTypeContainer);
        }
        else {
            this._mapService
                .loadMarkerData(markerTypeContainer, coordinate, bounds, municipalityId, currentAreaEventData.markerSkipCount, currentAreaEventData.markerTakeCount)
                .subscribe((x: any) => {
                    this.addMarkerItems(x, markerTypeContainer);
                    this.addCategoryLayerToMap(markerTypeContainer);
                });
        }
    }

    private getCoordinateAndBoundsAndMunicipalityIdFromAreaEventData(area: IBaseAreaContainerItem): { municipalityId: number, coordinate: Coordinate, bounds: IBounds } {
        let municipalityId: number;
        let coordinate: Coordinate;
        let bounds: IBounds;
        if (area.core.areaLevel == AreaLevel.Municipality) {
            let municipality = <IAreaContainerMunicipalityItem>area;
            municipalityId = municipality.core.municipalityId;
            coordinate = municipality.geometry.visualCenter;
            bounds = municipality.geometry.bounds;
        }
        else if (area.core.areaLevel == AreaLevel.Parish) {
            let parish = <IAreaContainerParishItem>area;
            municipalityId = parish.core.municipalityId;
            coordinate = parish.geometry.visualCenter;
            bounds = parish.geometry.bounds;

            // Getting coordinate from address, if address is selected. Else visualCenter of the selected area is used.
            if (this.current.address != null && this.current.address.coordinate != null)
                coordinate = this.current.address.coordinate;
        }
        else {
            throw new Error("Categories are only served on Municipality and Parish levels.");
        }

        return {
            coordinate: coordinate,
            municipalityId: municipalityId,
            bounds: bounds
        }
    }

    private addCategoryLayerToMap(markerTypeContainer: MarkerTypeContainer) {
        let prop = markerTypeContainer.markerTypePropertyName

        if (markerTypeContainer.isMarkerWithPossibleSubType) {
            if (markerTypeContainer.hasSubType) {
                // Adding markers for single sub category
                let subProp = markerTypeContainer.markerSubTypePropertyName;

                let markerItem: MarkerItem = this._markerService.categories[prop][subProp];
                let marker: any = this._mapService.marker[prop][subProp];
                var layerTypeId: LayerType = markerItem.layerTypeId;
                this.addMarkerDataForLayerType(markerItem, marker, layerTypeId);
            }
            else {
                // Checking all subcategories to see if any markers needs to be added
                const subTypePropertyNames = markerTypeContainer.markerSubTypePropertyNames;
                for (const subProp of subTypePropertyNames) {
                    let markerItem: MarkerItem = this._markerService.categories[prop][subProp];
                    if (markerItem.showMarkers) {
                        let marker: any = this._mapService.marker[prop][subProp];
                        var layerTypeId: LayerType = markerItem.layerTypeId;
                        this.addMarkerDataForLayerType(markerItem, marker, layerTypeId);
                    }
                }
            }
        }
        else {
            // Adding markers for single sub category
            let markerItem: MarkerItem = this._markerService.categories[prop];
            let marker: any = this._mapService.marker[prop];
            var layerTypeId: LayerType = markerItem.layerTypeId;
            this.addMarkerDataForLayerType(markerItem, marker, layerTypeId);
        }
    }


    private addMarkerDataForLayerType(markerItem: MarkerItem, marker: any, layerType: LayerType) {
        switch (layerType) {
            case LayerType.Marker:
                let featureCollection: IFeatureCollection = marker.featureCollection;
                this.addMarkerData(markerItem, featureCollection);

                break;
            case LayerType.Area:
                let areaFeatureCollection: IFeatureCollection = marker.featureCollection;
                let centerFeatureCollection: IFeatureCollection = marker.center;

                this.addAreaData(markerItem, areaFeatureCollection, centerFeatureCollection);

                break;
            default:
                throw new Error(`LayerType with value ${layerType} is not supported.`);
        }
    }





    private removeCategoryLayer(markerType: MarkerType, markerSubType: number = null) {
        let markerTypeContainer = new MarkerTypeContainer(markerType, markerSubType);
        let prop = markerTypeContainer.markerTypePropertyName

        if (markerTypeContainer.isMarkerWithPossibleSubType) {
            if (markerTypeContainer.hasSubType) {
                // Removing markers for single sub category
                let subProp = markerTypeContainer.markerSubTypePropertyName;

                let markerItem: MarkerItem = this._markerService.categories[prop][subProp];
                var layerTypeId: LayerType = markerItem.layerTypeId;
                this.removeMarkerDataForLayerType(markerItem.layerInfo, markerTypeContainer, layerTypeId);
            }
            else {
                // Checking all subcategories to see if any markers needs to be removed
                const subTypePropertyNames = markerTypeContainer.markerSubTypePropertyNames;
                for (const subProp of subTypePropertyNames) {
                    let markerItem: MarkerItem = this._markerService.categories[prop][subProp];
                    var layerTypeId: LayerType = markerItem.layerTypeId;
                    if (markerItem.showMarkers)
                        this.removeMarkerDataForLayerType(markerItem.layerInfo, markerItem.markerTypeContainer, layerTypeId);
                }
            }
        }
        else {
            let markerItem: MarkerItem = this._markerService.categories[prop];
            var layerTypeId: LayerType = markerItem.layerTypeId;
            this.removeMarkerDataForLayerType(markerItem.layerInfo, markerTypeContainer, layerTypeId);
        }
    }

    private removeMarkerDataForLayerType(layerInfo: any, markerTypeContainer: MarkerTypeContainer, layerType: LayerType) {
        switch (layerType) {
            case LayerType.Marker:
                this.removeMarkerData(layerInfo, markerTypeContainer);

                break;
            case LayerType.Area:
                this.removeAreaData(layerInfo);

                break;
            default:
                throw new Error(`LayerType with value ${layerType} is not supported.`);
        }
    }



    private addAreaData(markerItem: MarkerItem, areaFeatureCollection: IFeatureCollection, centerFeatureCollection: IFeatureCollection) {
        let layerInfo: any = markerItem.layerInfo;

        this.addOrReplaceSource(layerInfo.dataSourceId, areaFeatureCollection, layerInfo.hasClustering);
        this.addOrReplaceSource(layerInfo.centerSourceId, centerFeatureCollection, layerInfo.hasClustering);

        this.addAreaLayer(layerInfo.layerMarkersId, layerInfo.dataSourceId, layerInfo.centerSourceId, layerInfo.fillColor);

        let layerIdFills = `${layerInfo.layerMarkersId}-fills`;
        this.registerClickEventListener(layerIdFills, this.markerClick, 2);
        this.registerMouseMoveEventListener(layerIdFills, layerInfo.dataSourceId, this.highlightFromLayerMouseMove);
        this.registerMouseLeaveEventListener(layerIdFills, layerInfo.dataSourceId, this.unhighlightFromLayerMouseLeave);

        // Disable parish fills and names
        this._map.setFilter('parish-fills', ["==", 'municipalityId', ""]);
        this._map.setFilter('parish-borders', ["==", 'municipalityId', ""]);
        this._map.setFilter('parish-names', ["==", 'municipalityId', ""]);

        // Fitting bounds to municipality
        let padding = this.getFitBoundsPadding();
        let animationDuration: number = this.getAnimationDuration();
        this._map.fitBounds(this.current.path.municipality.geometry.bounds.bbox, { duration: animationDuration, padding: padding });
    }


    // private removeParishLayers() {
    //     this.unregisterClickEventListener('parish-fills');
    //     this.unregisterMouseMoveEventListener('parish-fills');
    //     this.unregisterMouseLeaveEventListener('parish-fills');

    //     this.removeLayer('parish-fills');
    //     this.removeLayer('parish-borders');
    //     this.removeLayer('parish-names');
    //     this.removeSource('parishAreas');
    //     this.removeSource('parishCenters');
    // }


    private removeAreaData(layerInfo: any) {
        let layerIdFills = `${layerInfo.layerMarkersId}-fills`;
        let layerIdBorders = `${layerInfo.layerMarkersId}-borders`;
        let layerIdNames = `${layerInfo.layerMarkersId}-names`;

        this.unregisterClickEventListener(layerIdFills);
        this.unregisterMouseMoveEventListener(layerIdFills);
        this.unregisterMouseLeaveEventListener(layerIdFills);

        // this.removeMarkerHighlightByMarkerItem(markerTypeContainer);
        this.removeLayer(layerIdFills);
        this.removeLayer(layerIdBorders);
        this.removeLayer(layerIdNames);
        this.removeSource(layerInfo.dataSourceId);
        this.removeSource(layerInfo.centerSourceId);

        if (this.current.isAreaLoaded) {
            this._map.setFilter('parish-borders', null);
            this.setAreaFilters(this, this.current.area.core)
        }
    }

    // private addAreaLayer(layerId: string, areaSourceId: string, centerSourceId: string, fillColor: string, filter: string[] = undefined) {
    //     console.log('addAreaLayer', layerId, areaSourceId, centerSourceId, fillColor);

    //     this._map.addLayer({
    //         id: `${layerId}-fills`,
    //         type: "fill",
    //         source: areaSourceId,
    //         paint: {
    //             'fill-opacity': this.getFillOpacityOnState(),
    //             'fill-color': fillColor,
    //         }
    //     });
    //     this._map.addLayer({
    //         id: `${layerId}-borders`,
    //         type: "line",
    //         source: areaSourceId,
    //         paint: {
    //             'line-color': fillColor,
    //             'line-width': 2,
    //             'line-dasharray': [2, 1]
    //         }
    //     });
    //     this._map.addLayer({
    //         id: `${layerId}-names`,
    //         type: "symbol",
    //         source: centerSourceId,
    //         layout: {
    //             "text-field": ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }] as any,
    //             "text-font": ['Open Sans Semibold'],
    //             "text-offset": [0, 0.6],
    //             "text-anchor": 'center'
    //         },
    //         paint: {}
    //     });

    //     if (filter != undefined) {
    //         this._map.setFilter(`${layerId}-fills`, filter);
    //         this._map.setFilter(`${layerId}-names`, filter);
    //     }
    // }






















    private addMarkerData(markerItem: MarkerItem, featureCollection: IFeatureCollection) {
        let layerInfo: any = markerItem.layerInfo;
        let iconUrl: string = markerItem.iconUrlEnabled;

        this.addImage(layerInfo.imageId, iconUrl);
        this.addOrReplaceSource(layerInfo.dataSourceId, featureCollection, layerInfo.hasClustering);

        if (layerInfo.hasClustering) {
            // Actual markers
            this.addCircleLayer(layerInfo.layerClusteringCircleId, layerInfo.dataSourceId, ['has', 'point_count']);
            this.addTextLayer(layerInfo.layerClusteringCountId, layerInfo.dataSourceId, '{point_count_abbreviated}', ['has', 'point_count'], [0, 0]);
            this.addMarkerLayer(layerInfo.layerMarkersId, layerInfo.dataSourceId, layerInfo.imageId, ['!', ['has', 'point_count']]);
            this.registerClickEventListener(layerInfo.layerClusteringCircleId, this.layerClusteringCircleClick, 1);
            this.registerMouseMoveEventListener(layerInfo.layerClusteringCircleId, layerInfo.dataSourceId, this.highlightFromMarkerMouseMove);
            this.registerMouseLeaveEventListener(layerInfo.layerClusteringCircleId, layerInfo.dataSourceId, this.unhighlightFromMarkerMouseLeave);
        }
        else {
            this.addMarkerLayer(layerInfo.layerMarkersId, layerInfo.dataSourceId, layerInfo.imageId);
        }

        this.replaceMarkerHighlight(markerItem);

        this.registerClickEventListener(layerInfo.layerMarkersId, this.markerClick, 1);
        this.registerMouseMoveEventListener(layerInfo.layerMarkersId, layerInfo.dataSourceId, this.highlightFromMarkerMouseMove);
        this.registerMouseLeaveEventListener(layerInfo.layerMarkersId, layerInfo.dataSourceId, this.unhighlightFromMarkerMouseLeave);
    }

    private removeMarkerData(layerInfo: any, markerTypeContainer: MarkerTypeContainer) {
        this.unregisterClickEventListener(layerInfo.layerMarkersId);
        this.unregisterMouseMoveEventListener(layerInfo.layerMarkersId);
        this.unregisterMouseLeaveEventListener(layerInfo.layerMarkersId);

        this.removeMarkerHighlightByMarkerItem(markerTypeContainer);
        this.removeLayer(layerInfo.layerMarkersId);
        if (layerInfo.hasClustering) {
            this.unregisterClickEventListener(layerInfo.layerClusteringCircleId);
            this.unregisterMouseMoveEventListener(layerInfo.layerClusteringCircleId);
            this.unregisterMouseLeaveEventListener(layerInfo.layerClusteringCircleId);
            this.removeLayer(layerInfo.layerClusteringCircleId);
            this.removeLayer(layerInfo.layerClusteringCountId);
        }
        this.removeSource(layerInfo.dataSourceId);
        this.removeImage(layerInfo.imageId);
    }

    private addMarkerItems(entity: any, markerTypeContainer: MarkerTypeContainer) {
        if (entity.message && entity.message.type == 0) {
            return;
        }

        this._markerService.setDataUpdated(entity.updated, markerTypeContainer);
        this._markerService.addItems(entity.items, markerTypeContainer);
    }

    private fitBoundsFromRouteChange(routeData: IRouteData) {
        this.current.isAreaLoaded = false;
        this.current.isFitBoundsAnimating = true;

        // Always cancel any loading (if active) when moving to next area
        this.cancelLoadMarkerData();
        this.setCurrent(this, routeData.areaLevel, routeData.regionId, routeData.municipalityId, routeData.parishId, routeData.address);

        let obs: Observable<void> = this.returnImmediatelyObservable();
        if (this.current.area.core.areaLevel == AreaLevel.Country) {
            obs = this.enableCountryArea(this.current.path.country.core.countryId);
        }
        else if (this.current.area.core.areaLevel == AreaLevel.Region) {
            obs = this.enableRegionArea(this.current.path.region.core.countryId, this.current.path.region.core.regionId);
        }
        else if (this.current.area.core.areaLevel == AreaLevel.Municipality) {
            obs = this.enableMunicipalityArea(this.current.path.municipality.core.countryId, this.current.path.municipality.core.regionId, this.current.path.municipality.core.municipalityId);
        }
        else if (this.current.area.core.areaLevel == AreaLevel.Parish) {
            obs = this.enableParishArea(this.current.path.parish.core.countryId, this.current.path.parish.core.regionId, this.current.path.parish.core.municipalityId);
        }

        // Subscribing to the assigned observable
        obs
            .subscribe(() => {
                this.setAreaFilters(this, this.current.area.core);
                this.setAddressLayer(this, this.current.address);
                this.fitBounds(this.current.area.geometry.bounds.bbox);

                this.current.isAreaLoaded = true;
                this._mapService.onFitBoundsStart.emit(this.getCurrentAreaEventData(this));
            });
    }

    private setCurrent(self: MapBoxComponent, areaLevel: AreaLevel, regionId: number, municipalityId: number, parishId: number, address: IAddress) {
        self.current.address = address;
        if (areaLevel == AreaLevel.Country) {
            let area = self._mapService.getCountryAreaContainer();
            self.current.area = area;
            self.current.path.country = area;
            self.current.path.region = null;
            self.current.path.municipality = null;
            self.current.path.parish = null;
            self.removeCategoryLayers();
        }
        else if (areaLevel == AreaLevel.Region) {
            let area = self._mapService.getRegionAreaContainer(regionId);
            self.current.area = area;
            self.current.path.country = self._mapService.getCountryAreaContainer();
            self.current.path.region = area;
            self.current.path.municipality = null;
            self.current.path.parish = null;
            self.removeCategoryLayers();
        }
        else if (areaLevel == AreaLevel.Municipality) {
            let area = self._mapService.getMunicipalityAreaContainer(municipalityId);
            self.current.area = area;
            self.current.path.country = self._mapService.getCountryAreaContainer();
            self.current.path.region = self._mapService.getRegionAreaContainer(area.core.regionId);
            self.current.path.municipality = area;
            self.current.path.parish = null;
            self.removeCategoryLayers();
        }
        else if (areaLevel == AreaLevel.Parish) {
            let area = self._mapService.getParishAreaContainer(parishId);
            self.current.area = area;
            self.current.path.country = self._mapService.getCountryAreaContainer();
            self.current.path.region = self._mapService.getRegionAreaContainer(area.core.regionId);
            self.current.path.municipality = self._mapService.getMunicipalityAreaContainer(area.core.municipalityId);
            self.current.path.parish = area;
        }
        else {
            throw new Error('No zoom info found for area ' + AreaLevel[areaLevel]);
        }

    }

    private fitBoundsFromLayerClickFinished(e: any, self: MapBoxComponent) {
        // Only do something if ilde-state was caused as a result of a finished fitBounds animation
        if (self.current.isFitBoundsAnimating) {
            self.current.isFitBoundsAnimating = false;

            let currentAreaEventData = self.getCurrentAreaEventData(self);
            self.loadMarkerData(currentAreaEventData);
            self.loadMissingAreaData(self);
        }
    }

    private loadMissingAreaData(self: MapBoxComponent) {
        this.loadMissingMunicipalities(self)
            .subscribe(() => {
                this.loadMissingParishes(self)
                    .subscribe(() => {
                    })
            })
    }

    private loadMissingMunicipalities(self: MapBoxComponent): Observable<void> {
        return new Observable((observer) => {
            let regionIds = self._mapService.getRegionIds();
            let observablesArray: Observable<void>[] = [];
            for (const regionId of regionIds) {
                let hasGeometries = self._mapService.hasMunicipalityGeometriesByRegionId(regionId);
                if (false == hasGeometries) {
                    var obs = self._mapService.loadMunicipalityAreaGeometries(regionId)
                    observablesArray.push(obs);
                }
            }

            if (observablesArray.length > 0) {
                forkJoin(observablesArray)
                    .subscribe(() => {
                        observer.next();
                        observer.complete();
                    })
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    }
    private loadMissingParishes(self: MapBoxComponent): Observable<void> {
        return new Observable((observer) => {
            let regionIds = self._mapService.getRegionIds();
            let observablesArray: Observable<void>[] = [];
            for (const regionId of regionIds) {
                let hasGeometries = self._mapService.hasParishGeometriesByRegionId(regionId);
                if (false == hasGeometries) {
                    var obs = self._mapService.loadParishAreaGeometriesByRegionId(regionId)
                    observablesArray.push(obs);
                }
            }

            if (observablesArray.length > 0) {
                forkJoin(observablesArray)
                    .subscribe(() => {
                        observer.next();
                        observer.complete();
                    })
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    }

    private getCurrentAreaEventData(self: MapBoxComponent): ICurrentAreaEventData {
        let deviceType = self._utilityService.getClientDeviceType();
        var areaLevel = self.current.area.core.areaLevel;
        var country = self.current.path.country;
        var region = self.current.path.region;
        var municipality = self.current.path.municipality;
        var parish = self.current.path.parish;
        let markerTakeCount = (deviceType == DeviceType.Mobile ? 5 : 10);

        let output = {
            area: null as IBaseAreaContainerItem,
            path: {
                country: null,
                region: null,
                municipality: null,
                parish: null,
            },
            markerSkipCount: 0,
            markerTakeCount: markerTakeCount
        };

        if (areaLevel >= AreaLevel.Country) {
            // let areaObj = new Country();
            // areaObj.init(country);

            output.area = country;
            output.path.country = country;
        }

        if (areaLevel >= AreaLevel.Region) {
            // let areaObj = new Region();
            // areaObj.init(region);

            output.area = region;
            output.path.region = region;
        }

        if (areaLevel >= AreaLevel.Municipality) {
            // let areaObj = new Municipality();
            // areaObj.init(municipality);

            output.area = municipality;
            output.path.municipality = municipality;
        }

        if (areaLevel >= AreaLevel.Parish) {
            // let areaObj = new Parish();
            // areaObj.init(parish);

            output.area = parish;
            output.path.parish = parish;
        }

        return output;
    }

    private highlightFromLayerMouseMove(e: any, source: string, self: MapBoxComponent) {
        if (self._hoveredStateId[source]) {
            self._map.setFeatureState({ source: source, id: self._hoveredStateId[source] }, { opacity: 1 });
        }
        self._hoveredStateId[source] = e.features[0].id;
        self._map.setFeatureState({ source: source, id: self._hoveredStateId[source] }, { opacity: 2 });
        self._map.getCanvas().style.cursor = 'pointer';
    }

    private unhighlightFromLayerMouseLeave(e: any, source: string, self: MapBoxComponent) {
        if (self._hoveredStateId[source]) {
            self._map.setFeatureState({ source: source, id: self._hoveredStateId[source] }, { opacity: 1 });
        }
        self._hoveredStateId[source] = null;
        self._map.getCanvas().style.cursor = '';
    }

    private highlightFromMarkerMouseMove(e: any, source: string, self: MapBoxComponent) {
        self._map.getCanvas().style.cursor = 'pointer';
    }

    private unhighlightFromMarkerMouseLeave(e: any, source: string, self: MapBoxComponent) {
        self._map.getCanvas().style.cursor = '';
    }

    private hasLayer(name: string): boolean {
        return undefined != this._map.getLayer(name);
    }

    private removeLayer(name: string): void {
        if (this.hasLayer(name))
            this._map.removeLayer(name);
    }

    private loadImage(name: string, url: string): Observable<void> {
        return new Observable((observer) => {
            this._map.loadImage(url, (error: { status: number } | null, image: ImageData) => {
                this._imageDataContainers[name] = new ImageDataContainer(name, url, image);

                observer.next();
                observer.complete();
            })
        })
    }

    private addImage(name: string, url: string): void {
        if (false == this._map.hasImage(name)) {
            let imageDataContainer = this._imageDataContainers[name];
            this._map.addImage(name, imageDataContainer.image);
        }
    }

    private removeImage(name: string): void {
        if (this._map.hasImage(name))
            this._map.removeImage(name);
    }

    private addOrReplaceSource(name: string, featureCollection: IFeatureCollection, hasClustering: boolean) {
        if (false == this.hasSource(name)) {
            this.addSource(name, featureCollection, hasClustering);
        }
        else {
            this.setSourceData(name, featureCollection);
        }
    }

    private addSource(name: string, featureCollection: IFeatureCollection, hasClustering: boolean) {
        if (hasClustering) {
            this._map.addSource(name, {
                type: 'geojson',
                data: featureCollection as GeoJSON.FeatureCollection<GeoJSON.GeometryObject>,
                cluster: hasClustering,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
            });
        }
        else {
            this._map.addSource(name, {
                type: 'geojson',
                data: featureCollection as GeoJSON.FeatureCollection<GeoJSON.GeometryObject>
            });
        }
    }

    private setSourceData(name: string, featureCollection: IFeatureCollection) {
        let source = <mapboxgl.GeoJSONSource>this._map.getSource(name);
        let data = featureCollection as GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
        source.setData(data);
    }

    private hasSource(name: string): boolean {
        return undefined != this._map.getSource(name);
    }

    private removeSource(name: string): void {
        if (this.hasSource(name))
            this._map.removeSource(name);
    }

    private toggleMarkerItemSelected(markerItem: MarkerItem, item: any): void {
        if (item.isSelected) {
            this.removeMarkerHighlight();
            this.addMarkerHighlight(markerItem);
            this.fitBoundsFromSelectedMarker(item.geometry.coordinates);
        }
        else {
            this.removeMarkerHighlight();
        }
    }

    private toggleAreaItemSelected(markerItem: MarkerItem, item: any): void {
        if (item.isSelected) {
            console.log('toggleAreaItemSelected isSelected');

            // this.removeMarkerHighlight();
            // this.addMarkerHighlight(markerItem);
            // this.fitBoundsFromSelectedMarker(item.geometry.coordinates);
        }
        else {
            console.log('toggleAreaItemSelected not selected');
            // this.removeMarkerHighlight();
        }
    }

    private addMarkerHighlight(markerItem: MarkerItem) {
        // Only enable popup for non-mobile design
        let deviceType = this._utilityService.getClientDeviceType();
        if (deviceType != DeviceType.Mobile) {
            let featureCollection = this._markerService.getSelectedMarkerItemAsFeatureCollection(); // markerItem.markerType, markerItem.markerSubType
            if (featureCollection.hasFeatures()) {
                let layerInfo = this._markerService.selectedImageProperties;

                // Add/update highlight layer
                this.addImage(layerInfo.imageId, layerInfo.iconUrl);
                this.addOrReplaceSource(layerInfo.dataSourceId, featureCollection, false);
                this.addMarkerLayer(layerInfo.layerMarkersId, layerInfo.dataSourceId, layerInfo.imageId, null, [0, 12]);

                // Move original marker layer in front of the highlight layer
                this._map.moveLayer(layerInfo.layerMarkersId, markerItem.layerInfo.layerMarkersId);
            }
        }
    }

    private replaceMarkerHighlight(markerItem: MarkerItem) {
        let item = markerItem.getSelectedItem();
        if (item != null) {
            this.removeMarkerHighlight();
            this.addMarkerHighlight(markerItem);
        }
    }

    private removeMarkerHighlightByMarkerItem(markerTypeContainer: MarkerTypeContainer) {
        let item = this._markerService.getSelectedMarkerItemByType(markerTypeContainer);
        if (item != null) {
            this.removeMarkerHighlight();
        }
    }

    private removeMarkerHighlight() {
        let layerInfo = this._markerService.selectedImageProperties;
        this.removeLayer(layerInfo.layerMarkersId);
        this.removeSource(layerInfo.dataSourceId);
    }

    private addMarkerLayer(name: string, dataSource: string, imageId: string, filter: Array<any> = null, iconOffset: number[] = [0, 0]) {
        if (false == this.hasLayer(name)) {
            this._map.addLayer({
                id: name,
                type: "symbol",
                source: dataSource,
                layout: {
                    'symbol-z-order': 'source',
                    'icon-image': imageId,
                    'icon-allow-overlap': true,
                    'icon-anchor': 'bottom',
                    'icon-offset': iconOffset
                },
                paint: {}
            });

            // Set cluster filter on original layer
            if (filter != null) {
                this._map.setFilter(name, filter);
            }
        }
    }

    private addTextLayer(name: string, dataSource: string, textField: any = ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }], filter: Array<any> = null, textOffset: number[] = [0, 0.6]) {
        if (false == this.hasLayer(name)) {
            this._map.addLayer({
                id: name,
                type: "symbol",
                source: dataSource,
                layout: {
                    "text-field": textField,
                    "text-font": ['Open Sans Semibold'],
                    "text-offset": textOffset,
                    "text-anchor": 'center'
                },
                paint: {}
            });

            // Set cluster filter on original layer
            if (filter != null) {
                this._map.setFilter(name, filter);
            }
        }
    }

    private addCircleLayer(name: string, dataSource: string, filter: Array<any> = null) {
        if (false == this.hasLayer(name)) {
            // Add custering circle layer
            this._map.addLayer({
                id: name,
                type: 'circle',
                source: dataSource,
                paint: {
                    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                    // with three steps to implement three types of circles:
                    //   * Blue, 20px circles when point count is less than 100
                    //   * Yellow, 30px circles when point count is between 100 and 750
                    //   * Pink, 40px circles when point count is greater than or equal to 750
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#3875d7',
                        100,
                        '#f1f075',
                        750,
                        '#f28cb1'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        40
                    ]
                }
            });

            // Set cluster filter on original layer
            if (filter != null) {
                this._map.setFilter(name, filter);
            }
        }
    }

    private addAreaLayer(layerId: string, areaSourceId: string, centerSourceId: string, fillColor: string, filter?: string[]) {
        let layerIdFills = `${layerId}-fills`;
        let layerIdBorders = `${layerId}-borders`;
        let layerIdNames = `${layerId}-names`;

        if (false == this.hasLayer(layerIdFills)) {
            this._map.addLayer({
                id: layerIdFills,
                type: "fill",
                source: areaSourceId,
                paint: {
                    'fill-opacity': this.getFillOpacityOnState(),
                    'fill-color': fillColor,
                }
            });
        }

        if (false == this.hasLayer(layerIdBorders)) {
            this._map.addLayer({
                id: layerIdBorders,
                type: "line",
                source: areaSourceId,
                paint: {
                    'line-color': fillColor,
                    'line-width': 2,
                    'line-dasharray': [2, 1]
                }
            });
        }

        if (false == this.hasLayer(layerIdNames)) {
            this._map.addLayer({
                id: layerIdNames,
                type: "symbol",
                source: centerSourceId,
                layout: {
                    "text-field": ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }] as any,
                    "text-font": ['Open Sans Semibold'],
                    "text-offset": [0, 0.6],
                    "text-anchor": 'center'
                },
                paint: {}
            });
        }

        // Setting optional filters
        if (filter != undefined) {
            this._map.setFilter(layerIdFills, filter);
            this._map.setFilter(layerIdNames, filter);
        }
    }



    private addRegionLayers() {
        let layerId: string = 'region';
        let areaSourceId: string = 'regionAreas';
        let centerSourceId: string = 'regionCenters';
        let fillColor: string = this._mapSettings.layerColor.region;

        this.addAreaLayer(layerId, areaSourceId, centerSourceId, fillColor);

        // this._map.addLayer({
        //     id: "region-fills",
        //     type: "fill",
        //     source: "regionAreas",
        //     paint: {
        //         'fill-opacity': this.getFillOpacityOnState(),
        //         'fill-color': this._mapSettings.layerColor.region,
        //     }
        // });
        // this._map.addLayer({
        //     id: "region-borders",
        //     type: "line",
        //     source: "regionAreas",
        //     paint: {
        //         'line-color': this._mapSettings.layerColor.region,
        //         'line-width': 2,
        //         'line-dasharray': [2, 1]
        //     }
        // });
        // this._map.addLayer({
        //     id: "region-names",
        //     type: "symbol",
        //     source: "regionCenters",
        //     layout: {
        //         "text-field": ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }] as any,
        //         "text-font": ['Open Sans Semibold'],
        //         "text-offset": [0, 0.6],
        //         "text-anchor": 'center'
        //     },
        //     paint: {}
        // });
    }

    private addMunicipalityLayers() {
        let layerId: string = 'municipality';
        let areaSourceId: string = 'municipalityAreas';
        let centerSourceId: string = 'municipalityCenters';
        let fillColor: string = this._mapSettings.layerColor.municipality;
        let filter: string[] = ["==", "regionId", ""];

        this.addAreaLayer(layerId, areaSourceId, centerSourceId, fillColor, filter);

        // this._map.addLayer({
        //     id: "municipality-fills",
        //     type: "fill",
        //     source: "municipalityAreas",
        //     paint: {
        //         'fill-opacity': this.getFillOpacityOnState(),
        //         'fill-color': this._mapSettings.layerColor.municipality,
        //     },
        //     // filter: ["==", "regionId", ""]
        // });
        // this._map.addLayer({
        //     id: "municipality-borders",
        //     type: "line",
        //     source: "municipalityAreas",
        //     paint: {
        //         'line-color': this._mapSettings.layerColor.municipality,
        //         'line-width': 2,
        //         'line-dasharray': [2, 1]
        //     }
        // });
        // this._map.addLayer({
        //     id: "municipality-names",
        //     type: "symbol",
        //     source: "municipalityCenters",
        //     layout: {
        //         "text-field": ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }] as any,
        //         "text-font": ['Open Sans Semibold'],
        //         "text-offset": [0, 0.6],
        //         "text-anchor": 'center'
        //     },
        //     paint: {},
        //     // filter: ["==", "regionId", ""]
        // });
    }

    private addParishLayers() {
        let layerId: string = 'parish';
        let areaSourceId: string = 'parishAreas';
        let centerSourceId: string = 'parishCenters';
        let fillColor: string = this._mapSettings.layerColor.parish;
        let filter: string[] = ["==", "municipalityId", ""];

        this.addAreaLayer(layerId, areaSourceId, centerSourceId, fillColor, filter);


        // this._map.addLayer({
        //     id: "parish-fills",
        //     type: "fill",
        //     source: "parishAreas",
        //     paint: {
        //         'fill-opacity': this.getFillOpacityOnState(),
        //         'fill-color': this._mapSettings.layerColor.parish,
        //     },
        //     // filter: ["==", "municipalityId", ""]
        // });
        // this._map.addLayer({
        //     id: "parish-borders",
        //     type: "line",
        //     source: "parishAreas",
        //     paint: {
        //         'line-color': this._mapSettings.layerColor.parish,
        //         'line-width': 2,
        //         'line-dasharray': [2, 1]
        //     }
        // });
        // this._map.addLayer({
        //     id: "parish-names",
        //     type: "symbol",
        //     source: "parishCenters",
        //     layout: {
        //         "text-field": ['format', ['upcase', ['get', 'name']], { 'font-scale': .8 }] as any,
        //         "text-font": ['Open Sans Semibold'],
        //         "text-offset": [0, 0.6],
        //         "text-anchor": 'center'
        //     },
        //     paint: {},
        //     // filter: ["==", "municipalityId", ""]
        // });
    }

    private setAreaFilters(self: MapBoxComponent, core: any): any {
        if (core.areaLevel == AreaLevel.Country) {
            // Show all regions
            self._map.setFilter('region-fills', null);
            self._map.setFilter('region-names', null);
            self._map.setPaintProperty('region-fills', 'fill-opacity', self.getFillOpacityOnState())
        }
        else if (core.areaLevel == AreaLevel.Region) {
            // Show all regions outside selected region
            self._map.setFilter('region-fills', ["!=", 'regionId', core.regionId]);
            self._map.setFilter('region-names', ["!=", 'regionId', core.regionId]);
            self._map.setPaintProperty('region-fills', 'fill-opacity', self.getFillOpacityOffState())

            // Show all municipalities within selected region
            self._map.setFilter('municipality-fills', ["==", 'regionId', core.regionId]);
            self._map.setFilter('municipality-names', ["==", 'regionId', core.regionId]);
            self._map.setPaintProperty('municipality-fills', 'fill-opacity', self.getFillOpacityOnState())
        }
        else if (core.areaLevel == AreaLevel.Municipality) {
            // Show all regions outside selected region
            self._map.setFilter('region-fills', ["!=", 'regionId', core.regionId]);
            self._map.setFilter('region-names', ["!=", 'regionId', core.regionId]);
            self._map.setPaintProperty('region-fills', 'fill-opacity', self.getFillOpacityOffState())

            // Show all municipalities within selected region, but outside selected municipality
            self._map.setFilter('municipality-fills', ["all", ["==", 'regionId', core.regionId], ["!=", 'municipalityId', core.municipalityId]]);
            self._map.setFilter('municipality-names', ["==", 'regionId', core.regionId]);
            self._map.setPaintProperty('municipality-fills', 'fill-opacity', self.getFillOpacityOffState())

            // Show all parishes within selected municipality
            self._map.setFilter('parish-fills', ["==", 'municipalityId', core.municipalityId]);
            self._map.setFilter('parish-names', ["==", 'municipalityId', core.municipalityId]);
            self._map.setPaintProperty('parish-fills', 'fill-opacity', self.getFillOpacityOnState())
        }
        else if (core.areaLevel == AreaLevel.Parish) {
            // Show all regions outside selected region
            self._map.setFilter('region-fills', ["!=", 'regionId', core.regionId]);
            self._map.setFilter('region-names', ["!=", 'regionId', core.regionId]);
            self._map.setPaintProperty('region-fills', 'fill-opacity', self.getFillOpacityOffState())

            // Show all municipalities within selected region, but outside selected municipality
            self._map.setFilter('municipality-fills', ["all", ["==", 'regionId', core.regionId], ["!=", 'municipalityId', core.municipalityId]]);
            self._map.setFilter('municipality-names', ["==", 'regionId', core.regionId]);
            self._map.setPaintProperty('municipality-fills', 'fill-opacity', self.getFillOpacityOffState())

            // Show all parishes within selected municipality, but outside selected parish
            self._map.setFilter('parish-fills', ["all", ["==", 'municipalityId', core.municipalityId], ["!=", 'parishId', core.parishId]]);
            self._map.setFilter('parish-names', ["all", ["==", 'municipalityId', core.municipalityId], ["!=", 'parishId', core.parishId]]);
            self._map.setPaintProperty('parish-fills', 'fill-opacity', self.getFillOpacityIntermediateState())
        }
    }

    private setAddressLayer(self: MapBoxComponent, address: any): any {
        let layerInfo: any = this._markerService.categories.home.layerInfo;
        if (address.addressLevel != AddressLevel.None) {
            let iconUrl: string = this._markerService.categories.home.iconUrlEnabled;

            // Visual center
            let featureCollection = new FeatureCollection();
            let g = new PointGeometry([address.coordinate.lng, address.coordinate.lat]);
            let p = {};
            let f = new Feature(g, p);
            featureCollection.addFeature(f);

            self.addImage(layerInfo.imageId, iconUrl);
            self.addOrReplaceSource(layerInfo.dataSourceId, featureCollection, layerInfo.hasClustering);
            self.addMarkerLayer(layerInfo.layerMarkersId, layerInfo.dataSourceId, layerInfo.imageId);
        }
        else {
            this.removeLayer(layerInfo.layerMarkersId);
            this.removeSource(layerInfo.dataSourceId);
            this.removeImage(layerInfo.imageId);
        }
    }

    private getFillOpacityOnState(): any {
        return ["case",
            ["==", ["feature-state", "opacity"], 1],
            0.2,
            ["==", ["feature-state", "opacity"], 2],
            0.5,
            0.2
        ];
    }
    private getFillOpacityIntermediateState(): any {
        return ["case",
            ["==", ["feature-state", "opacity"], 1],
            0.1,
            ["==", ["feature-state", "opacity"], 2],
            0.2,
            0.1
        ];
    }
    private getFillOpacityOffState(): any {
        return ["case",
            ["==", ["feature-state", "opacity"], 1],
            0,
            ["==", ["feature-state", "opacity"], 2],
            0.2,
            0
        ];
    }

    private loadImages(): Observable<void> {
        return new Observable((observer) => {
            // Load category icon images
            let obs1 = this.loadImage(this._markerService.categories.home.layerInfo.imageId, this._markerService.categories.home.iconUrlEnabled)
            let obs2_a = this.loadImage(this._markerService.categories.primarySchool.government.layerInfo.imageId, this._markerService.categories.primarySchool.government.iconUrlEnabled)
            let obs2_b = this.loadImage(this._markerService.categories.primarySchool.private.layerInfo.imageId, this._markerService.categories.primarySchool.private.iconUrlEnabled)
            let obs2_c = this.loadImage(this._markerService.categories.primarySchool.boarding.layerInfo.imageId, this._markerService.categories.primarySchool.boarding.iconUrlEnabled)
            let obs2a = this.loadImage(this._markerService.categories.highSchool.government.layerInfo.imageId, this._markerService.categories.highSchool.government.iconUrlEnabled)
            let obs2b = this.loadImage(this._markerService.categories.highSchool.private.layerInfo.imageId, this._markerService.categories.highSchool.private.iconUrlEnabled)
            let obs2c = this.loadImage(this._markerService.categories.highSchool.studentPreparation.layerInfo.imageId, this._markerService.categories.highSchool.studentPreparation.iconUrlEnabled)
            // let obs3 = this.loadImage(this._markerService.categories.kindergarden.layerInfo.imageId, this._markerService.categories.kindergarden.iconUrlEnabled)
            // let obs3a = this.loadImage(this._markerService.categories.nursery.layerInfo.imageId, this._markerService.categories.nursery.iconUrlEnabled)
            let obs3b = this.loadImage(this._markerService.categories.privateDaycare.layerInfo.imageId, this._markerService.categories.privateDaycare.iconUrlEnabled)
            let obs3c = this.loadImage(this._markerService.categories.publicTransportation.layerInfo.imageId, this._markerService.categories.publicTransportation.iconUrlEnabled)
            let obs3d = this.loadImage(this._markerService.categories.supermarket.layerInfo.imageId, this._markerService.categories.supermarket.iconUrlEnabled)
            let obs3_1 = this.loadImage(this._markerService.categories.noise.layerInfo.imageId, this._markerService.categories.noise.iconUrlEnabled)
            let obs3_11 = this.loadImage(this._markerService.categories.traficCount.layerInfo.imageId, this._markerService.categories.traficCount.iconUrlEnabled)
            let obs3_2 = this.loadImage(this._markerService.categories.doctor.layerInfo.imageId, this._markerService.categories.doctor.iconUrlEnabled)
            let obsRealEstate_1 = this.loadImage(this._markerService.categories.realEstate.cooperativeDwelling.layerInfo.imageId, this._markerService.categories.realEstate.cooperativeDwelling.iconUrlEnabled)
            let obsRealEstate_2 = this.loadImage(this._markerService.categories.realEstate.ownerOccupiedFlat.layerInfo.imageId, this._markerService.categories.realEstate.ownerOccupiedFlat.iconUrlEnabled)
            let obsRealEstate_3 = this.loadImage(this._markerService.categories.realEstate.recreationalProperty.layerInfo.imageId, this._markerService.categories.realEstate.recreationalProperty.iconUrlEnabled)
            let obsRealEstate_4 = this.loadImage(this._markerService.categories.realEstate.recreationalPlot.layerInfo.imageId, this._markerService.categories.realEstate.recreationalPlot.iconUrlEnabled)
            let obsRealEstate_5 = this.loadImage(this._markerService.categories.realEstate.allYearRoundPlot.layerInfo.imageId, this._markerService.categories.realEstate.allYearRoundPlot.iconUrlEnabled)
            let obsRealEstate_6 = this.loadImage(this._markerService.categories.realEstate.allotment.layerInfo.imageId, this._markerService.categories.realEstate.allotment.iconUrlEnabled)
            let obsRealEstate_7 = this.loadImage(this._markerService.categories.realEstate.ruralProperty.layerInfo.imageId, this._markerService.categories.realEstate.ruralProperty.iconUrlEnabled)
            let obsRealEstate_8 = this.loadImage(this._markerService.categories.realEstate.pleasureProperty.layerInfo.imageId, this._markerService.categories.realEstate.pleasureProperty.iconUrlEnabled)
            let obsRealEstate_9 = this.loadImage(this._markerService.categories.realEstate.townHouse.layerInfo.imageId, this._markerService.categories.realEstate.townHouse.iconUrlEnabled)
            let obsRealEstate_10 = this.loadImage(this._markerService.categories.realEstate.brickHouse.layerInfo.imageId, this._markerService.categories.realEstate.brickHouse.iconUrlEnabled)
            let obsRealEstate_11 = this.loadImage(this._markerService.categories.realEstate.brickHouseApartment.layerInfo.imageId, this._markerService.categories.realEstate.brickHouseApartment.iconUrlEnabled)
            let obs3_5 = this.loadImage(this._markerService.categories.residentsForRent.layerInfo.imageId, this._markerService.categories.residentsForRent.iconUrlEnabled)
            let obs3_6 = this.loadImage(this._markerService.categories.sportsUnion.layerInfo.imageId, this._markerService.categories.sportsUnion.iconUrlEnabled)
            let obs4 = this.loadImage(this._markerService.categories.facility.badminton.layerInfo.imageId, this._markerService.categories.facility.badminton.iconUrlEnabled)
            let obs5 = this.loadImage(this._markerService.categories.facility.curling.layerInfo.imageId, this._markerService.categories.facility.curling.iconUrlEnabled)
            let obs6 = this.loadImage(this._markerService.categories.facility.fitness.layerInfo.imageId, this._markerService.categories.facility.fitness.iconUrlEnabled)
            let obs7 = this.loadImage(this._markerService.categories.facility.golf.layerInfo.imageId, this._markerService.categories.facility.golf.iconUrlEnabled)
            let obs8 = this.loadImage(this._markerService.categories.facility.horseriding.layerInfo.imageId, this._markerService.categories.facility.horseriding.iconUrlEnabled)
            let obs9 = this.loadImage(this._markerService.categories.facility.icehockey.layerInfo.imageId, this._markerService.categories.facility.icehockey.iconUrlEnabled)
            let obs10 = this.loadImage(this._markerService.categories.facility.olympicsite.layerInfo.imageId, this._markerService.categories.facility.olympicsite.iconUrlEnabled)
            let obs11 = this.loadImage(this._markerService.categories.facility.shootingrange.layerInfo.imageId, this._markerService.categories.facility.shootingrange.iconUrlEnabled)
            let obs12 = this.loadImage(this._markerService.categories.facility.soccer.layerInfo.imageId, this._markerService.categories.facility.soccer.iconUrlEnabled)
            let obs13 = this.loadImage(this._markerService.categories.facility.stadiumLarge.layerInfo.imageId, this._markerService.categories.facility.stadiumLarge.iconUrlEnabled)
            let obs14 = this.loadImage(this._markerService.categories.facility.stadiumMedium.layerInfo.imageId, this._markerService.categories.facility.stadiumMedium.iconUrlEnabled)
            let obs15 = this.loadImage(this._markerService.categories.facility.stadiumSmall.layerInfo.imageId, this._markerService.categories.facility.stadiumSmall.iconUrlEnabled)
            let obs16 = this.loadImage(this._markerService.categories.facility.swimming.layerInfo.imageId, this._markerService.categories.facility.swimming.iconUrlEnabled)
            let obs17 = this.loadImage(this._markerService.categories.facility.tennisIndoor.layerInfo.imageId, this._markerService.categories.facility.tennisIndoor.iconUrlEnabled)
            let obs18 = this.loadImage(this._markerService.categories.facility.tennisOutdoor.layerInfo.imageId, this._markerService.categories.facility.tennisOutdoor.iconUrlEnabled)
            let obs19a = this.loadImage(this._markerService.categories.daycare.age0to2.layerInfo.imageId, this._markerService.categories.daycare.age0to2.iconUrlEnabled)
            let obs19b = this.loadImage(this._markerService.categories.daycare.age3to5.layerInfo.imageId, this._markerService.categories.daycare.age3to5.iconUrlEnabled)
            let obs19c = this.loadImage(this._markerService.categories.daycare.age0to5.layerInfo.imageId, this._markerService.categories.daycare.age0to5.iconUrlEnabled)
            let obs20 = this.loadImage(this._markerService.selectedImageProperties.imageId, this._markerService.selectedImageProperties.iconUrl);

            forkJoin(
                obs1,
                obs2_a,
                obs2_b,
                obs2_c,
                obs2a,
                obs2b,
                obs2c,
                // obs3,
                // obs3a,
                obs3b,
                obs3c,
                obs3d,
                obs3_1,
                obs3_11,
                obs3_2,
                obsRealEstate_1,
                obsRealEstate_2,
                obsRealEstate_3,
                obsRealEstate_4,
                obsRealEstate_5,
                obsRealEstate_6,
                obsRealEstate_7,
                obsRealEstate_8,
                obsRealEstate_9,
                obsRealEstate_10,
                obsRealEstate_11,
                obs3_5,
                obs3_6,
                obs4,
                obs5,
                obs6,
                obs7,
                obs8,
                obs9,
                obs10,
                obs11,
                obs12,
                obs13,
                obs14,
                obs15,
                obs16,
                obs17,
                obs18,
                obs19a,
                obs19b,
                obs19c,
                obs20
            ).subscribe(() => {
                observer.next();
                observer.complete();
            });
        });
    }

    private getExtendedBbox(bbox: [[number, number], [number, number]], coordinate: [number, number] = null): [[number, number], [number, number]] {
        if (coordinate == null)
            return bbox;

        let coordinates: [number, number][] = [];
        coordinates.push(bbox[0]);
        coordinates.push(bbox[1]);
        coordinates.push(coordinate);

        return this.getBbox(coordinates);
    }

    private getBbox(coordinates: [number, number][]): [[number, number], [number, number]] {
        let x0 = Infinity, y0 = Infinity, x1 = -Infinity, y1 = -Infinity;

        for (const p of coordinates) {
            if (p[0] < x0) x0 = p[0];
            if (p[0] > x1) x1 = p[0];
            if (p[1] < y0) y0 = p[1];
            if (p[1] > y1) y1 = p[1];
        }

        return [[x0, y0], [x1, y1]];
    }

    private isCoordinateOutsideBbox(bbox: [[number, number], [number, number]], p: [number, number]): boolean {
        let x0 = bbox[0][0], y0 = bbox[0][1], x1 = bbox[1][0], y1 = bbox[1][1];

        if (p[0] < x0) return true;
        if (p[0] > x1) return true;
        if (p[1] < y0) return true;
        if (p[1] > y1) return true;

        return false;
    }

    private returnImmediatelyObservable(): Observable<void> {
        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }
}
