<div class="ui two column equal height stackable grid container">

    <div class="row">
        <div class="sixteen wide column b-header">
            <p id="{{id}}">{{headline}}</p>
        </div>
    </div>
    <div class="row" *ngIf="!table || !chart">
        <div class="sixteen wide column">

            <div class="ui segment">
                <h3 class="b-missing-data">
                    Manglende data
                </h3>
            </div>

        </div>
    </div>

    <div class="row" *ngIf="table && chart">
        <div class="six wide column b-table-wapper">

            <div class="ui vertical steps">
                <div class="completed step b-table-header-container">
                    <div class="content">
                        <div class="description">
                            <table class="ui selectable table b-table {{cellWidthCss}}">
                                <thead>
                                    <tr>
                                        <th>{{table.name}}</th>
                                        <th class="right aligned" *ngFor="let header of table.header">{{header}}</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="completed step b-table-body-container" *ngFor="let item of table.items" (click)="onClick(item.type)" [ngClass]="{'active' : isActive(category, item) }">
                    <div class="content">
                        <div class="description">
                            <table class="b-table {{cellWidthCss}}">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="title" *ngIf="isActive(category, item)">{{item.type.description}}</div>
                                            <div class="description" *ngIf="false == isActive(category, item)">{{item.type.description}}</div>
                                        </td>
                                        <td class="right aligned">
                                            <div class="title" *ngIf="isActive(category, item)">
                                                <app-table-cell [item]="item.municipality"></app-table-cell>
                                            </div>
                                            <div class="description" *ngIf="false == isActive(category, item)">
                                                <app-table-cell [item]="item.municipality"></app-table-cell>
                                            </div>
                                        </td>
                                        <td class="right aligned" *ngIf="item.country">
                                            <div class="title" *ngIf="isActive(category, item)">
                                                <app-table-cell [item]="item.country"></app-table-cell>
                                            </div>
                                            <div class="description" *ngIf="false == isActive(category, item)">
                                                <app-table-cell [item]="item.country"></app-table-cell>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-description" *ngIf="table.description">
                {{table.description}}
            </div>
        </div>
        <div class="ten wide column b-chart-wrapper">

            <div class="ui segment">
                <div class="ui top attached grey progress" id="{{progressId}}" style="visibility:hidden;">
                    <div class="bar"></div>
                </div>

                <p *ngIf="chart.description">{{chart.description}}</p>
                <div *ngIf="chartSettings.options">
                    <canvas baseChart [datasets]="chart.dataSets" [labels]="chart.labels" [colors]="chartSettings.colors" [chartType]="chartSettings.type" [options]="chartSettings.options"></canvas>
                </div>

                <div class="b-source-description">
                    <span class="text small">Kilde: {{dataSourceDescription}}</span>
                </div>
            </div>

        </div>
    </div>
</div>