/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie-consent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cookie-consent.component";
import * as i4 from "../../services/local-storage.service";
var styles_CookieConsentComponent = [i0.styles];
var RenderType_CookieConsentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookieConsentComponent, data: {} });
export { RenderType_CookieConsentComponent as RenderType_CookieConsentComponent };
function View_CookieConsentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "b-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "ui floating message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "b-text-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ved at benytte ditomraade.dk accepterer du vores brug af cookies. L\u00E6s mere "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "/cookiepolitik"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["her"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "b-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "ui small blue button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.acceptCookies() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, null); }
export function View_CookieConsentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookieConsentComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (false == _co.isCookiesAccepted); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CookieConsentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie-consent", [], null, null, null, View_CookieConsentComponent_0, RenderType_CookieConsentComponent)), i1.ɵdid(1, 114688, null, 0, i3.CookieConsentComponent, [i4.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookieConsentComponentNgFactory = i1.ɵccf("app-cookie-consent", i3.CookieConsentComponent, View_CookieConsentComponent_Host_0, {}, {}, []);
export { CookieConsentComponentNgFactory as CookieConsentComponentNgFactory };
