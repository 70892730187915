<tr>
    <td>
        Kommune
    </td>
    <td *ngFor="let item of items">
        {{item.municipalityName}}
    </td>
</tr>
<tr *ngIf="hasAddress">
    <td>
        Afstand
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.distanceToAddress">{{item.distanceToAddress | number: '1.2-2'}} km</span>
        <span *ngIf="!item.distanceToAddress">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Fraværsprocent</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.absense">({{dateUpdated.absense}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.absense">{{item.latestKeyFigures.absense.value | number}} {{item.latestKeyFigures.absense.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.absense">-</span>
    </td>
</tr>


<tr>
    <td class="b-label-container">
        <span class="b-label">Karaktérgennemsnit</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.grade">({{dateUpdated.grade}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.grade">{{item.latestKeyFigures.grade.value | number}} {{item.latestKeyFigures.grade.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.grade">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Gymnasial ansøgningsrate</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.highSchoolApplicationRate">({{dateUpdated.highSchoolApplicationRate}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.highSchoolApplicationRate">{{item.latestKeyFigures.highSchoolApplicationRate.value | number}} {{item.latestKeyFigures.highSchoolApplicationRate.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.highSchoolApplicationRate">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Inklusionsgrad</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.inclusionRate">({{dateUpdated.inclusionRate}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.inclusionRate">{{item.latestKeyFigures.inclusionRate.value | number}} {{item.latestKeyFigures.inclusionRate.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.inclusionRate">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Socioøkonomisk reference <sup>1)</sup></span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.socialEconomicReference">({{dateUpdated.socialEconomicReference}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.socialEconomicReference">{{item.latestKeyFigures.socialEconomicReference.value | number}} {{item.latestKeyFigures.socialEconomicReference.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.socialEconomicReference">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Andel lærerpersonale</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.staffDistribution">({{dateUpdated.staffDistribution}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.staffDistribution">{{item.latestKeyFigures.staffDistribution.value | number}} {{item.latestKeyFigures.staffDistribution.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.staffDistribution">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Elever i alt</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.studentCountPerClass">({{dateUpdated.studentCountPerClass}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.studentCountPerClass">{{item.latestKeyFigures.studentCountPerClass.value | number}} {{item.latestKeyFigures.studentCountPerClass.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.studentCountPerClass">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Elever med dansk oprindelse</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.studentCountPerOrigin">({{dateUpdated.studentCountPerOrigin}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.studentCountPerOrigin">{{item.latestKeyFigures.studentCountPerOrigin.value | number}} {{item.latestKeyFigures.studentCountPerOrigin.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.studentCountPerOrigin">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Antal elever pr. lærerårsværk</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.studentsPerTeacherManHour">({{dateUpdated.studentsPerTeacherManHour}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.studentsPerTeacherManHour">{{item.latestKeyFigures.studentsPerTeacherManHour.value | number}} {{item.latestKeyFigures.studentsPerTeacherManHour.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.studentsPerTeacherManHour">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Gennemsnitsalder på lærere</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.teacherAverageAge">({{dateUpdated.teacherAverageAge}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.teacherAverageAge">{{item.latestKeyFigures.teacherAverageAge.value | number}} {{item.latestKeyFigures.teacherAverageAge.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.teacherAverageAge">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Ressourcer brugt på undervisning</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.teaching">({{dateUpdated.teaching}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.teaching">{{item.latestKeyFigures.teaching.value | number}} {{item.latestKeyFigures.teaching.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.teaching">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Trivselsindikator (1-5)</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.wellbeing">({{dateUpdated.wellbeing}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.wellbeing">{{item.latestKeyFigures.wellbeing.value | number}} {{item.latestKeyFigures.wellbeing.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.wellbeing">-</span>
    </td>
</tr>
<tr>
    <td class="b-label-container">
        <span class="b-label">Undervisningstimer pr. år pr. klassetrin</span>
        <span class="b-date-updated text small" *ngIf="dateUpdated && dateUpdated.yearlyLessonHoursPerLevel">({{dateUpdated.yearlyLessonHoursPerLevel}})</span>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigures.yearlyLessonHoursPerLevel">{{item.latestKeyFigures.yearlyLessonHoursPerLevel.value | number:'1.0-0'}} {{item.latestKeyFigures.yearlyLessonHoursPerLevel.unit}}</span>
        <span *ngIf="!item.latestKeyFigures.yearlyLessonHoursPerLevel">-</span>
    </td>
</tr>
<tr>
    <td [attr.colspan]="items.length + 1">
        <p>
            <span>
                1) Den socioøkonomiske reference for grundskolekarakterer er et statistisk beregnet udtryk, som viser,
                <br />
                hvordan elever på landsplan med samme socioøkonomiske baggrundsforhold som skolens elever har klaret afgangsprøverne.
            </span>

            <br />

            <span style="display: inline-block; padding-top: 10px;">
                "Socioøkonomisk" refererer til elevernes sociale og økonomiske baggrund, mens reference fortæller,
                <br />
                at tallet kan bruges som et sammenligningsgrundlag for skolens faktisk opnåede karakterer.
            </span>

            <br />

            <span style="display: inline-block; padding-top: 10px;">
                <em>Kilde: <a href="https://www.uvm.dk/statistik/grundskolen/karakterer-og-test/sociooekonomisk-reference-for-grundskolekarakterer" target="_blank">Børne- og Undervisningsministeriet</a></em>
            </span>
        </p>
    </td>
</tr>