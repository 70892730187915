import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComparisonComponent } from './comparison.component';
import { ComparisonPrimarySchoolComponent } from './primarySchool/comparison-primary-school.component';
import { ComparisonHighSchoolComponent } from './highSchool/comparison-high-school.component';

@NgModule({
    declarations: [
        ComparisonComponent, 
        ComparisonPrimarySchoolComponent, 
        ComparisonHighSchoolComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        ComparisonComponent
    ]
})
export class ComparisonModule { }
