<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <!-- Loader -->
        <div class="ui active  dimmer" *ngIf="false == page.isLoaded && page.isLoading">
            <div class="ui big text loader">Henter data...</div>
        </div>

        <div class="ui vertical stripe segment" *ngIf="showMunicipalityList">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Kommuner</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column">
                        <div class="ui stackable grid">
                            <div class="four wide column">
                                <div *ngFor="let item of municipalities | slice:0:25">
                                    <a [routerLink]="['/kommune', item.urlParameter]">{{item.name}}</a>
                                </div>
                            </div>
                            <div class="four wide column">
                                <div *ngFor="let item of municipalities | slice:25:50">
                                    <a [routerLink]="['/kommune', item.urlParameter]">{{item.name}}</a>
                                </div>
                            </div>
                            <div class="four wide column">
                                <div *ngFor="let item of municipalities | slice:50:75">
                                    <a [routerLink]="['/kommune', item.urlParameter]">{{item.name}}</a>
                                </div>
                            </div>
                            <div class="four wide column">
                                <div *ngFor="let item of municipalities | slice:75">
                                    <a [routerLink]="['/kommune', item.urlParameter]">{{item.name}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <!-- Top menu -->
            <app-top-menu></app-top-menu>

            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>{{municipality.fullName}}</h1>
                        <app-area-label [text]="municipality.fullName"></app-area-label>
                    </div>
                </div>















                <div class="row" *ngIf="true">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'summary-overview'" [headline]="'Overblik'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop" style="background-color: rgba(33, 133, 208, 0.03)">
                            <div class="ui horizontal segments">

                                <!-- Left menu -->
                                <app-left-menu></app-left-menu>

                                <div app-overview-summary-desktop class="ui blue segment" style="border-left: none;" [fragment]="'economy-tax'" [item]="data.summary.economyTax" [imageName]="'tax2.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui blue segment" [fragment]="'economy-tax'" [item]="data.summary.economyPropertyTax" [imageName]="'propertytax.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui blue segment" [fragment]="'economy-tax'" [item]="data.summary.economyChurchTax" [imageName]="'church.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui blue segment" [fragment]="'safety-crime-reporting'" [item]="data.summary.safetyCrimeBurglary" [imageName]="'theft.svg'">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <div app-overview-summary-desktop class="ui segment" [fragment]="'daycare-fare'" [item]="data.summary.daycareFareNursery" [imageName]="'nursery.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui segment" [fragment]="'daycare-fare'" [item]="data.summary.daycareFareKindergarden" [imageName]="'kindergarden.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui segment" [fragment]="'population-relocation'" [item]="data.summary.populationRelocation" [imageName]="'relocation.svg'">
                                </div>

                                <div app-overview-summary-desktop class="ui segment" [fragment]="'population-age'" [item]="data.summary.populationAge" [imageName]="'elderly.svg'">
                                </div>

                            </div>
                        </div>

                        <div class="ui segments attached b-overview-mobile">
                            <div class="ui horizontal segments">

                                <div app-overview-summary-mobile class="ui blue segment" [fragment]="'economy-tax'" [item]="data.summary.economyTax" [imageName]="'tax2.svg'">
                                </div>

                                <div app-overview-summary-mobile class="ui blue segment" [fragment]="'economy-tax'" [item]="data.summary.economyPropertyTax" [imageName]="'propertytax.svg'">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'economy-tax'" [item]="data.summary.economyChurchTax" [imageName]="'church.svg'">
                                </div>

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'population-relocation'" [item]="data.summary.populationRelocation" [imageName]="'relocation.svg'">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'daycare-fare'" [item]="data.summary.daycareFareNursery" [imageName]="'nursery.svg'">
                                </div>

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'daycare-fare'" [item]="data.summary.daycareFareKindergarden" [imageName]="'kindergarden.svg'">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'safety-crime-reporting'" [item]="data.summary.safetyCrimeBurglary" [imageName]="'theft.svg'">
                                </div>

                                <div app-overview-summary-mobile class="ui segment" [fragment]="'population-age'" [item]="data.summary.populationAge" [imageName]="'elderly.svg'">
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
















                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'population-overview'" [headline]="'Befolkning'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">

                                <!-- Left menu -->
                                <!-- <app-left-menu></app-left-menu> -->

                                <!-- ORIGIN -->
                                <div app-overview-segment-number class="ui blue segment" style="border-left: none;" [fragment]="'population-origin'" [item]="data.population.overview.origin">
                                </div>

                                <!-- EDUCATION -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'population-education'" [item]="data.population.overview.education">
                                </div>

                                <!-- FAMILY COMPOSITION -->
                                <div app-overview-segment-chart class="ui blue segment" [fragment]="'population-familycomposition'" [item]="data.population.overview.familyComposition"
                                    [settings]="settings.population.overview.chart">
                                </div>

                                <!-- MARITAL STATUS -->
                                <div app-overview-segment-chart class="ui blue segment" [fragment]="'population-civilStatus'" [item]="data.population.overview.civilStatus"
                                    [settings]="settings.population.overview.chart">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <!-- GROWTH -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'population-growth'" [item]="data.population.overview.growth">
                                </div>

                                <!-- IMMIGRATION -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'population-immigration'" [item]="data.population.overview.immigration">
                                </div>

                                <!-- AGE -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'population-age'" [item]="data.population.overview.age">
                                </div>

                                <!-- WEALTH -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'population-wealth'" [item]="data.population.overview.wealth">
                                </div>

                                <!-- UNEMPLOYMENT -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'population-unemployment'" [item]="data.population.overview.unemployment">
                                </div>

                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">
                            <!-- ORIGIN -->
                            <div app-overview-segment-mobile [fragment]="'population-origin'" [item]="data.population.overview.origin">
                            </div>

                            <!-- EDUCATION -->
                            <div app-overview-segment-mobile [fragment]="'population-education'" [item]="data.population.overview.education">
                            </div>

                            <!-- GROWTH -->
                            <div app-overview-segment-mobile [fragment]="'population-growth'" [item]="data.population.overview.growth">
                            </div>

                            <!-- IMMIGRATION -->
                            <div app-overview-segment-mobile [fragment]="'population-immigration'" [item]="data.population.overview.immigration">
                            </div>

                            <!-- AGE -->
                            <div app-overview-segment-mobile [fragment]="'population-age'" [item]="data.population.overview.age">
                            </div>

                            <!-- WEALTH -->
                            <div app-overview-segment-mobile [fragment]="'population-wealth'" [item]="data.population.overview.wealth">
                            </div>

                            <!-- UNEMPLOYMENT -->
                            <div app-overview-segment-mobile [fragment]="'population-unemployment'" [item]="data.population.overview.unemployment">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- ORIGIN -->
                        <app-sub-section [id]="'population-origin'" [table]="data.population.origin.table" [chart]="data.population.origin.chart" [chartSettings]="settings.population.origin.chart"
                            [category]="data.population.origin.originType" [headline]="data.population.origin.headline" [dataSourceDescription]="data.population.origin.dataSourceDescription"
                            (onCategoryClick)="clickOriginType($event)">
                        </app-sub-section>

                        <!-- EDUCATION -->
                        <app-sub-section [id]="'population-education'" [table]="data.population.education.table" [chart]="data.population.education.chart"
                            [chartSettings]="settings.population.education.chart" [category]="data.population.education.educationType" [headline]="data.population.education.headline"
                            [dataSourceDescription]="data.population.education.dataSourceDescription" (onCategoryClick)="clickEducationType($event)">
                        </app-sub-section>

                        <!-- FAMILY COMPOSITION -->
                        <app-sub-section [id]="'population-familycomposition'" [table]="data.population.familyComposition.table" [chart]="data.population.familyComposition.chart"
                            [chartSettings]="settings.population.familyComposition.chart" [headline]="data.population.familyComposition.headline"
                            [dataSourceDescription]="data.population.familyComposition.dataSourceDescription" [category]="data.population.familyComposition.familyCompositionType"
                            (onCategoryClick)="clickFamilyCompositionType($event)">
                        </app-sub-section>

                        <!-- MARITAL STATUS -->
                        <app-sub-section [id]="'population-civilStatus'" [table]="data.population.civilStatus.table" [chart]="data.population.civilStatus.chart"
                            [chartSettings]="settings.population.civilStatus.chart" [category]="data.population.civilStatus.civilStatusType" [headline]="data.population.civilStatus.headline"
                            [dataSourceDescription]="data.population.civilStatus.dataSourceDescription" (onCategoryClick)="clickCivilStatusType($event)">
                        </app-sub-section>

                        <!-- GROWTH -->
                        <app-sub-section [id]="'population-growth'" [table]="data.population.growth.table" [chart]="data.population.growth.chart" [chartSettings]="settings.population.growth.chart"
                            [category]="data.population.growth.growthType" [headline]="data.population.growth.headline" [dataSourceDescription]="data.population.growth.dataSourceDescription"
                            (onCategoryClick)="clickGrowthType($event)">
                        </app-sub-section>

                        <!-- IMMIGRATION -->
                        <app-sub-section [id]="'population-immigration'" [table]="data.population.immigration.table" [chart]="data.population.immigration.chart"
                            [chartSettings]="settings.population.immigration.chart" [category]="data.population.immigration.immigrationType" [headline]="data.population.immigration.headline"
                            [dataSourceDescription]="data.population.immigration.dataSourceDescription" (onCategoryClick)="clickImmigrationType($event)">
                        </app-sub-section>

                        <!-- AGE -->
                        <app-sub-section [id]="'population-age'" [table]="data.population.age.table" [chart]="data.population.age.chart" [chartSettings]="settings.population.age.chart"
                            [category]="data.population.age.ageType" [headline]="data.population.age.headline" [dataSourceDescription]="data.population.age.dataSourceDescription"
                            (onCategoryClick)="clickAgeType($event)">
                        </app-sub-section>

                        <!-- WEALTH -->
                        <app-sub-section [id]="'population-wealth'" [table]="data.population.wealth.table" [chart]="data.population.wealth.chart" [chartSettings]="settings.population.wealth.chart"
                            [tableSettings]="settings.population.wealth.table" [headline]="data.population.wealth.headline" [dataSourceDescription]="data.population.wealth.dataSourceDescription">
                        </app-sub-section>

                        <!-- UNEMPLOYMENT -->
                        <app-sub-section [id]="'population-unemployment'" [table]="data.population.unemployment.table" [chart]="data.population.unemployment.chart"
                            [chartSettings]="settings.population.unemployment.chart" [headline]="data.population.unemployment.headline"
                            [dataSourceDescription]="data.population.unemployment.dataSourceDescription">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>


        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'economy-overview'" [headline]="'Økonomi'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">
                                <!-- INCOME -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'economy-income'" [item]="data.economy.overview.income">
                                </div>

                                <!-- TAXES -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'economy-income'" [item]="data.economy.overview.taxLevel">
                                </div>
                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">
                            <!-- INCOME -->
                            <div app-overview-segment-mobile [fragment]="'economy-income'" [item]="data.economy.overview.income">
                            </div>

                            <!-- TAXES -->
                            <div app-overview-segment-mobile [fragment]="'economy-tax'" [item]="data.economy.overview.taxLevel">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- INCOME -->
                        <app-sub-section [id]="'economy-income'" [table]="data.economy.income.table" [chart]="data.economy.income.chart" [chartSettings]="settings.economy.income.chart"
                            [tableSettings]="settings.economy.income.table" [category]="data.economy.income.incomeType" [headline]="data.economy.income.headline"
                            [dataSourceDescription]="data.economy.income.dataSourceDescription" (onCategoryClick)="clickIncomeType($event)">
                        </app-sub-section>

                        <!-- TAXES -->
                        <app-sub-section [id]="'economy-tax'" [table]="data.economy.tax.table" [chart]="data.economy.tax.chart" [chartSettings]="settings.economy.tax.chart"
                            [tableSettings]="settings.economy.tax.table" [category]="data.economy.tax.taxType" [headline]="data.economy.tax.headline"
                            [dataSourceDescription]="data.economy.tax.dataSourceDescription" (onCategoryClick)="clickTaxType($event)">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>



        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'health-overview'" [headline]="'Sundhed'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">
                                <!-- LIFETIME -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'health-lifetime'" [item]="data.health.overview.lifetime">
                                </div>
                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">
                            <!-- LIFETIME -->
                            <div app-overview-segment-mobile [fragment]="'health-lifetime'" [item]="data.health.overview.lifetime">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- LIFETIME -->
                        <app-sub-section [id]="'health-lifetime'" [table]="data.health.lifetime.table" [chart]="data.health.lifetime.chart" [chartSettings]="settings.health.lifetime.chart"
                            [headline]="data.health.lifetime.headline" [dataSourceDescription]="data.health.lifetime.dataSourceDescription">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>




        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'residential-overview'" [headline]="'Bolig'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">
                                <!-- HOUSING -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'residential-housings'" [item]="data.residential.overview.ownedHousings">
                                </div>

                                <!-- SIZE -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'residential-size'" [item]="data.residential.overview.size">
                                </div>

                                <!-- CONSTRUCTION YEAR -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'residential-construction-year'" [item]="data.residential.overview.constructionYear">
                                </div>

                                <!-- PRICE -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'residential-price'" [item]="data.residential.overview.price">
                                </div>

                                <!-- SALES PERIOD -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'residential-sales-period'" [item]="data.residential.overview.salesPeriod">
                                </div>
                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">
                            <!-- HOUSING -->
                            <div app-overview-segment-mobile [fragment]="'residential-housings'" [item]="data.residential.overview.ownedHousings">
                            </div>

                            <!-- SIZE -->
                            <div app-overview-segment-mobile [fragment]="'residential-size'" [item]="data.residential.overview.size">
                            </div>

                            <!-- CONSTRUCTION YEAR -->
                            <div app-overview-segment-mobile [fragment]="'residential-construction-year'" [item]="data.residential.overview.constructionYear">
                            </div>

                            <!-- PRICE -->
                            <div app-overview-segment-mobile [fragment]="'residential-price'" [item]="data.residential.overview.price">
                            </div>

                            <!-- SALES PERIOD -->
                            <div app-overview-segment-mobile [fragment]="'residential-sales-period'" [item]="data.residential.overview.salesPeriod">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- HOUSING -->
                        <app-sub-section [id]="'residential-housings'" [table]="data.residential.housings.table" [chart]="data.residential.housings.chart"
                            [chartSettings]="settings.residential.housings.chart" [category]="data.residential.housings.housingType" [headline]="data.residential.housings.headline"
                            [dataSourceDescription]="data.residential.housings.dataSourceDescription" (onCategoryClick)="clickHousingType($event)">
                        </app-sub-section>

                        <!-- SIZE -->
                        <app-sub-section [id]="'residential-size'" [table]="data.residential.size.table" [chart]="data.residential.size.chart" [chartSettings]="settings.residential.size.chart"
                            [category]="data.residential.size.sizeType" [headline]="data.residential.size.headline" [dataSourceDescription]="data.residential.size.dataSourceDescription"
                            (onCategoryClick)="clickSizeType($event)">
                        </app-sub-section>

                        <!-- CONSTRUCTION YEAR -->
                        <app-sub-section [id]="'residential-construction-year'" [table]="data.residential.constructionYear.table" [chart]="data.residential.constructionYear.chart"
                            [chartSettings]="settings.residential.constructionYear.chart" [category]="data.residential.constructionYear.yearType"
                            [headline]="data.residential.constructionYear.headline" [dataSourceDescription]="data.residential.constructionYear.dataSourceDescription"
                            (onCategoryClick)="clickConstructionYearType($event)">
                        </app-sub-section>

                        <!-- PRICE -->
                        <app-sub-section [id]="'residential-price'" [table]="data.residential.price.table" [chart]="data.residential.price.chart" [chartSettings]="settings.residential.price.chart"
                            [category]="data.residential.price.propertyType" [headline]="data.residential.price.headline" [dataSourceDescription]="data.residential.price.dataSourceDescription"
                            (onCategoryClick)="clickPricePropertyType($event)">
                        </app-sub-section>

                        <!-- SALES PERIOD -->
                        <app-sub-section [id]="'residential-sales-period'" [table]="data.residential.salesPeriod.table" [chart]="data.residential.salesPeriod.chart"
                            [chartSettings]="settings.residential.salesPeriod.chart" [category]="data.residential.salesPeriod.propertyType" [headline]="data.residential.salesPeriod.headline"
                            [dataSourceDescription]="data.residential.salesPeriod.dataSourceDescription" (onCategoryClick)="clickSalesPeriodPropertyType($event)">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>


        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'safety-overview'" [headline]="'Tryghed'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">

                                <!-- CRIME REPORTING -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'safety-crime-reporting'" [item]="data.safety.overview.crimeReporting">
                                </div>

                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">

                            <!-- CRIME REPORTING -->
                            <div app-overview-segment-mobile [fragment]="'safety-crime-reporting'" [item]="data.safety.overview.crimeReporting">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- CRIME REPORTING -->
                        <app-sub-section [id]="'safety-crime-reporting'" [table]="data.safety.crimeReporting.table" [chart]="data.safety.crimeReporting.chart"
                            [chartSettings]="settings.safety.crimeReporting.chart" [category]="data.safety.crimeReporting.violationType" [headline]="data.safety.crimeReporting.headline"
                            [dataSourceDescription]="data.safety.crimeReporting.dataSourceDescription" (onCategoryClick)="clickViolationType($event)">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>

        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'freetime-overview'" [headline]="'Fritid'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">

                                <!-- EXPENSES -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'freetime-expenses'" [item]="data.freetime.overview.culture">
                                </div>

                                <div app-overview-segment-number class="ui blue segment" [fragment]="'freetime-expenses'" [item]="data.freetime.overview.sport">
                                </div>

                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">

                            <!-- EXPENSES -->
                            <div app-overview-segment-mobile [fragment]="'freetime-expenses'" [item]="data.freetime.overview.culture">
                            </div>

                            <div app-overview-segment-mobile [fragment]="'freetime-expenses'" [item]="data.freetime.overview.sport">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- EXPENSES -->
                        <app-sub-section [id]="'freetime-expenses'" [table]="data.freetime.expenses.table" [chart]="data.freetime.expenses.chart" [chartSettings]="settings.freetime.expenses.chart"
                            [category]="data.freetime.expenses.expenseType" [headline]="data.freetime.expenses.headline" [dataSourceDescription]="data.freetime.expenses.dataSourceDescription"
                            (onCategoryClick)="clickExpenseType($event)">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>

        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'school-overview'" [headline]="'Skole'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">

                                <!-- PRIVATE SCHOOL STUDENTS PER 100 STUDENTS -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'school-private-school-students'" [item]="data.education.overview.privateSchoolStudentsPer100Students">
                                </div>

                                <!-- STUDENTS COUNT -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'school-student-count'" [item]="data.education.overview.studentCountAverage">
                                </div>

                                <!-- TEACHING EXPENSES -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'school-teaching-expenses'" [item]="data.education.overview.teachingExpenses">
                                </div>

                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">

                            <!-- PRIVATE SCHOOL STUDENTS PER 100 STUDENTS -->
                            <div app-overview-segment-mobile [fragment]="'school-private-school-students'" [item]="data.education.overview.privateSchoolStudentsPer100Students">
                            </div>

                            <!-- STUDENTS COUNT -->
                            <div app-overview-segment-mobile [fragment]="'school-student-count'" [item]="data.education.overview.studentCountAverage">
                            </div>

                            <!-- TEACHING EXPENSES -->
                            <div app-overview-segment-mobile [fragment]="'school-teaching-expenses'" [item]="data.education.overview.teachingExpenses">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- PRIVATE SCHOOL STUDENTS PER 100 STUDENTS -->
                        <app-sub-section [id]="'school-private-school-students'" [table]="data.education.privateSchoolStudentsPer100Students.table"
                            [chart]="data.education.privateSchoolStudentsPer100Students.chart" [chartSettings]="settings.education.privateSchoolStudentsPer100Students.chart"
                            [tableSettings]="settings.education.privateSchoolStudentsPer100Students.table" [headline]="data.education.privateSchoolStudentsPer100Students.headline"
                            [dataSourceDescription]="data.education.privateSchoolStudentsPer100Students.dataSourceDescription">
                        </app-sub-section>

                        <!-- STUDENTS COUNT -->
                        <app-sub-section [id]="'school-student-count'" [table]="data.education.studentCountAverage.table" [chart]="data.education.studentCountAverage.chart"
                            [chartSettings]="settings.education.studentCountAverage.chart" [tableSettings]="settings.education.studentCountAverage.table"
                            [headline]="data.education.studentCountAverage.headline" [dataSourceDescription]="data.education.studentCountAverage.dataSourceDescription">
                        </app-sub-section>

                        <!-- TEACHING EXPENSES -->
                        <app-sub-section [id]="'school-teaching-expenses'" [table]="data.education.teachingExpenses.table" [chart]="data.education.teachingExpenses.chart"
                            [chartSettings]="settings.education.teachingExpenses.chart" [tableSettings]="settings.education.teachingExpenses.table"
                            [headline]="data.education.teachingExpenses.headline" [dataSourceDescription]="data.education.teachingExpenses.dataSourceDescription">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>




        <div class="ui vertical stripe segment" *ngIf="page.isLoaded && false == page.isLoading && false == showMunicipalityList">
            <div class="ui middle aligned stackable grid container">

                <div class="row">
                    <div class="sixteen wide column">
                        <div app-overview-headline class="ui blue label" [id]="'daycare-overview'" [headline]="'Børnepasning'">
                        </div>

                        <div class="ui segments attached overview b-overview-desktop">
                            <div class="ui horizontal segments">

                                <!-- AVAILABILITY, 0-2 YEAR -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityNursery">
                                </div>

                                <!-- AVAILABILITY, 3-5 YEAR -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityKindergarden">
                                </div>

                                <!-- AVAILABILITY, 6-13 YEAR -->
                                <div app-overview-segment-number class="ui blue segment" [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityAfterSchool">
                                </div>

                            </div>
                            <div class="ui horizontal segments">

                                <!-- FARES, 0-2 YEAR -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareNursery">
                                </div>

                                <!-- FARES, 3-5 YEAR -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareKindergarden">
                                </div>

                                <!-- FARES, 6-9 YEAR -->
                                <div app-overview-segment-number class="ui segment" [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareAfterSchool">
                                </div>

                            </div>
                        </div>

                        <div class="ui blue segment b-overview-mobile">

                            <!-- AVAILABILITY, 0-2 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityNursery">
                            </div>

                            <!-- AVAILABILITY, 3-5 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityKindergarden">
                            </div>

                            <!-- AVAILABILITY, 6-13 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-availability'" [item]="data.daycare.overview.availabilityAfterSchool">
                            </div>

                            <!-- FARES, 0-2 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareNursery">
                            </div>

                            <!-- FARES, 3-5 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareKindergarden">
                            </div>

                            <!-- FARES, 6-9 YEAR -->
                            <div app-overview-segment-mobile [fragment]="'daycare-fares'" [item]="data.daycare.overview.fareAfterSchool">
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column b-segment-column">

                        <!-- AVAILABILITY -->
                        <app-sub-section [id]="'daycare-availability'" [table]="data.daycare.availability.table" [chart]="data.daycare.availability.chart"
                            [chartSettings]="settings.daycare.availability.chart" [category]="data.daycare.availability.availabilityType" [headline]="data.daycare.availability.headline"
                            [dataSourceDescription]="data.daycare.availability.dataSourceDescription" (onCategoryClick)="clickAvailabilityType($event)">
                        </app-sub-section>

                        <!-- FARES -->
                        <app-sub-section [id]="'daycare-fares'" [table]="data.daycare.fares.table" [chart]="data.daycare.fares.chart" [chartSettings]="settings.daycare.fares.chart"
                            [category]="data.daycare.fares.fareType" [headline]="data.daycare.fares.headline" [dataSourceDescription]="data.daycare.fares.dataSourceDescription"
                            (onCategoryClick)="clickFareType($event)">
                        </app-sub-section>

                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>