
export interface IZoomLevel {
    min: number;
    max: number;
    current: number;
    zeroBased(): IZoomLevel;
}

export class ZoomLevel implements IZoomLevel {
    public min: number;
    public max: number;
    public current: number;

    constructor(min: number, max: number, current: number = 0) {
        this.min = min;
        this.max = max;
        this.current = current;
    }

    zeroBased(): IZoomLevel {
        // I.e. converting all values from a range (-4,2) to a range (0,6).
        return new ZoomLevel(0, this.max - this.min, this.current - this.min);
    }
}