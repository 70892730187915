import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseMarkerItem } from './base-marker-item';
import { UtilityService } from '../shared/services/utility.service';
import { MarkerService } from './marker.service';
import { takeUntil } from 'rxjs/operators';
import { MarkerTypeContainer } from './marker-type-container';

@Component({
    selector: '[app-categories-checkbox]',
    templateUrl: './categories-checkbox.component.html',
    styleUrls: ['./categories-checkbox.component.scss']
})
export class CategoriesCheckboxComponent implements OnInit {
    @Input() baseMarkerItem: BaseMarkerItem;

    private _unsubscribe: Subject<void> = new Subject<void>();

    elementId: string;
    elementIdJq: string;

    constructor(private _utilityService: UtilityService, private _markerService: MarkerService) {
        this.elementId = 'chk_' + this._utilityService.getRandomString();
        this.elementIdJq = '.' + this.elementId;
    }

    ngOnInit() {
        if (this.baseMarkerItem.showMarkers) {
            this.enableCheckbox();
        }

        this._markerService.onToggleMarkerVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (this.baseMarkerItem.markerTypeContainer.markerType == x.markerTypeContainer.markerType && false == x.markerTypeContainer.hasSubType && this.baseMarkerItem.showMarkers) {
                    this.enableCheckbox();
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    private enableCheckbox() {
        var self = this;

        // Enabling dropdown
        setTimeout(() => {
            $(this.elementIdJq)
                .checkbox({
                    onChecked: function () {
                        let markerSubType = $(this).data('item-id');
                        self.onSelectionAdd(markerSubType);
                    },
                    onUnchecked : function () {
                        let markerSubType = $(this).data('item-id');
                        self.onSelectionRemove(markerSubType);
                    },
                })

            for (const dropdownItem of this.baseMarkerItem.dropdownItems) {
                if (dropdownItem.selected) {
                    let markerTypeContainer = new MarkerTypeContainer(this.baseMarkerItem.markerTypeContainer.markerType, Number(dropdownItem.value));
                    this._markerService.toggleMarkerVisibility(markerTypeContainer, true);

                    var chkbox = $(".b-checkbox-container .ui.form").find(`input[data-item-id='${dropdownItem.value}']`);
                    chkbox.prop('checked', true);
                }
            }
        });
    }
    
    private onSelectionAdd(markerSubType: any) {
        let markerTypeContainer = new MarkerTypeContainer(this.baseMarkerItem.markerTypeContainer.markerType, Number(markerSubType));
        this._markerService.toggleMarkerVisibility(markerTypeContainer, true);
    }

    private onSelectionRemove(markerSubType: any) {
        let markerTypeContainer = new MarkerTypeContainer(this.baseMarkerItem.markerTypeContainer.markerType, Number(markerSubType));
        this._markerService.toggleMarkerVisibility(markerTypeContainer, false);
    }
}
