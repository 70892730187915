/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./top-menu.component";
import * as i4 from "./scroll.service";
import * as i5 from "./menu-items.service";
import * as i6 from "../shared/services/utility.service";
var styles_TopMenuComponent = [i0.styles];
var RenderType_TopMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopMenuComponent, data: {} });
export { RenderType_TopMenuComponent as RenderType_TopMenuComponent };
function View_TopMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickMenuItem(_v.context.$implicit, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(2, "item ", _v.context.$implicit.cssClass, " ", _v.context.$implicit.fragment, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, " icon"); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_2); }); }
export function View_TopMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui compact inverted blue menu"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "fluid nine item": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopMenuComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ui compact inverted blue menu"; var currVal_1 = _ck(_v, 3, 0, _co.isMobileSite); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.items; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_TopMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-menu", [], null, null, null, View_TopMenuComponent_0, RenderType_TopMenuComponent)), i1.ɵdid(1, 49152, null, 0, i3.TopMenuComponent, [i4.ScrollService, i5.MenuItemsService, i6.UtilityService], null, null)], null, null); }
var TopMenuComponentNgFactory = i1.ɵccf("app-top-menu", i3.TopMenuComponent, View_TopMenuComponent_Host_0, {}, {}, []);
export { TopMenuComponentNgFactory as TopMenuComponentNgFactory };
