import { DataSourceType } from './data-source-type';

export class DataSourceTypeContainer {
    id: DataSourceType;
    description: string;

    constructor(item : any) {
        this.id = item.id;
        this.description = item.description;
    }

    // constructor(dataSourceType: DataSourceType, dataSourceDescription: string) {
    //     this.dataSourceType = dataSourceType;
    //     this.dataSourceDescription = dataSourceDescription;
    // }
}