<div class="b-site-feedback-button-container" [hidden]="feedbackStep != 0">
    <button id="{{feedbackButtonId}}" class="basic circular ui icon button" (click)="clickShow()" data-duration="100">
        <i class="yellow smile icon"></i>&nbsp;&nbsp;Feedback
    </button>
</div>
<div id="{{feedbackContainerId}}" class="transition hidden b-site-feedback-container">

    <div class="ui card" [hidden]="feedbackStep == 0">
        <div class="b-close-button-container" [hidden]="feedbackStep == 3">
            <button (click)="clickClose()" class="ui circular blue icon big button">
                <i class="icon close"></i>
            </button>
        </div>

        <div class="content" [hidden]="feedbackStep == 3">
            <!-- <div class="header">Giv os det antal stjerner vi fortjener</div> -->
            <!-- <div class="header">Hvor mange stjerner fortjener vi?</div> -->
            <div class="header">Hvordan vil du vurdere din oplevelse af siden?</div>
            <div class="description">

                <div id="{{feedbackRatingId}}" class="ui massive star rating" data-max-rating="5"></div>

                <div class="b-textarea-container" [hidden]="feedbackStep != 2 && false == isRated">
                    <div class="ui form">
                        <div class="field">
                            <!-- <label>Short Text</label> -->
                            <textarea rows="4" placeholder="Fortæl os om din oplevelse..." [(ngModel)]="feedback.comment"></textarea>
                        </div>
                    </div>

                    <div class="b-send-button-container">
                        <button class="ui primary large button" (click)="clickSend()">
                            Send
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="content b-finish-container" [hidden]="feedbackStep != 3">
            <div class="description">
                <div class="b-finish-button-container" (click)="clickClose()">
                    <button class="ui primary large button">
                        Luk
                    </button>
                </div>
            </div>
            <div class="header">Tak for din feedback!</div>
        </div>
    </div>

</div>