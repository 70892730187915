<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui inverted vertical masthead segment">
            <div class="b-overlay"></div>
            <div class="ui grid container">
                <div class="row">
                    <div class="sixteen wide column">

                        <div class="b-header-container">
                            <h1 class="ui inverted header">
                                Ditomraade.dk
                            </h1>
                            <h2>
                                Kortlægning af dit nærområde
                                ud fra sociale, økonomiske, demografiske,
                                miljø- og uddannelsesmæssige statistikker
                            </h2>
                        </div>

                        <div class="ui raised segment">
                            <app-address-search-box (onSelected)="onSelect($event)" [size]="'massive'" [showIcon]="true"></app-address-search-box>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ui vertical stripe segment">
            <div class="ui stackable two column divided center aligned grid container">
                <div class="row">
                    <div class="column">
                        <h2 class="ui center aligned icon header">
                            <i class="circular map marker alternate icon"></i>
                            Nærområde
                        </h2>
                        <p>
                            Få et enkelt og overskueligt overblik over dit nuværende eller kommende nærområde mht. skoler, børnepasning, sportsfaciliteter, offentlig transport, støj, forurening og meget andet.
                        </p>
                        <a class="ui huge button" routerLink="/kort">Se kort her</a>
                    </div>
                    <div class="column">
                        <h2 class="ui center aligned icon header">
                            <i class="circular users icon"></i>
                            Kommune
                        </h2>
                        <p>
                            Gå på opdagelse i din nuværende eller kommende kommune ud fra sociale, økonomiske, demografiske, miljø- og uddannelsesmæssige statistikker.
                        </p>
                        <a class="ui huge button" routerLink="/kommune">Se kommuner her</a>
                    </div>
                </div>
            </div>
        </div>







        <!-- 


        <div class="ui vertical stripe segment">
            <div class="ui stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <div class="ui segments attached overview">
                            <div class="ui horizontal segments">

                                <p>Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                                    som har været brugt i bogtrykkerkunsten siden 1500-tallet.</p>
                                <p>Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.</p>
                            </div>
                            <div class="ui horizontal segments">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
 -->


        <!-- 
        
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="eight wide column">
                        <h3 class="ui header">
                            Hvad ved du omkring dit nabolag?
                        </h3>
                        <p>
                            Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                            som har været brugt i bogtrykkerkunsten siden 1500-tallet.
                            <br>
                            <br> Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.
                            <br>
                            <br> Fyldteksten kan genereres automatisk i flere moderne dtp-programmer. Selv om det umiddelbart ser ud
                            som vrøvletekst, har det vist sig, at den faktisk har rod i gamle latinske tekster.
                            <br>
                            <br> Richard McClintock, der var professor i latin ved Hampden-Sydney College i Virginia har opdaget, at
                            ordene kan relateres til en tekst skrevet af Cicero i år 45 f.Kr..
                        </p>
                    </div>
                    <div class="six wide right floated column">
                        <img class="ui large bordered rounded image" src="/assets/no-image.png">
                    </div>
                </div>
                <div class="row">
                    <div class="center aligned column">
                        <a class="ui huge button" routerLink="/adresse/1084/101/9190/2500-Valby/Vigerslev-Allé-23/st-tv">Se detaljer her</a>
                        <a class="ui huge button" routerLink="/kort/1084/101/9190">Se kort her</a>
                    </div>
                </div>
            </div>
        </div>


 -->





        <!-- 
        <div class="ui segment">
            <div class="ui container">
                <div class="row">
                    <div class="column">

                        <div class="ui card">
                            <div class="content">
                                <div class="header">Hvad ved du omkring dit nabolag?</div>
                                <div class="description">
                                    <p>Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                                        som har været brugt i bogtrykkerkunsten siden 1500-tallet.</p>
                                    <p>Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div> -->

        <!-- 


        <div class="ui vertical stripe segment">
            <div class="ui three column grid container">
                <div class="row">
                    <div class="column">

                        <div class="ui card">
                            <div class="content">
                                <div class="header">Hvad ved du omkring dit nabolag?</div>
                                <div class="description">
                                    <p>Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                                        som har været brugt i bogtrykkerkunsten siden 1500-tallet.</p>
                                    <p>Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="column">

                        <div class="ui card">
                            <div class="content">
                                <div class="header">Hvad ved du omkring dit nabolag?</div>
                                <div class="description">
                                    <p>Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                                        som har været brugt i bogtrykkerkunsten siden 1500-tallet.</p>
                                    <p>Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column">

                        <div class="ui card">
                            <div class="content">
                                <div class="header">Hvad ved du omkring dit nabolag?</div>
                                <div class="description">
                                    <p>Et eksempel på Lorem ipsum brugt ved et foreslået design af en hjemmeside Lorem ipsum, også kendt som mumletekst, er en fyldtekst,
                                        som har været brugt i bogtrykkerkunsten siden 1500-tallet.</p>
                                    <p>Teksten bruges som fyldtekst, for at ordene ikke skal forstyrre det grafiske udtryk under selve layout-processen.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div> -->





        <!-- <div class="row">
                    <div class="center aligned column">
                        <a class="ui huge button" routerLink="/adresse/1084/101/9190/2500-Valby/Vigerslev-Allé-23/st-tv">Se detaljer her</a>
                        <a class="ui huge button" routerLink="/kort/1084/101/9190">Se kort her</a>
                    </div>
                </div> -->


        <!-- Footer -->
        <div class="appfooter">
            <app-footer></app-footer>
        </div>
    </div>