import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MarkerService } from './marker.service';
import { MarkerItem } from './marker-item';
import { TopRightContentBoxDesktopService } from './top-right-content-box-desktop.service';

@Component({
    selector: 'app-top-right-content-box-desktop',
    templateUrl: './top-right-content-box-desktop.component.html',
    styleUrls: ['./top-right-content-box-desktop.component.scss']
})
export class TopRightContentBoxDesktopComponent implements OnInit {
    private _unsubscribe: Subject<void> = new Subject<void>();

    private _timer: any;
    private _elementLastHeight: number = 0;
    private _windowLastHeight: number = 0;

    // Factsheet
    @Input() area: any;
    @Input() factsheet: any;
    @Input() isLoading: boolean;
    @Input() isCompared: boolean;
    @Input() isComparable: boolean;

    // @Input() isMarkerSelected: boolean = false;
    showSelectedMarker: boolean = false;
    selectedMarker: { item: any, markerItem: MarkerItem } = null;

    isCollapsed: boolean = false;
    iconCollapseSide: string = 'right';
    iconCollapseBottom: string = 'up';

    constructor(private _markerService: MarkerService, private _topRightContentBoxDesktopService: TopRightContentBoxDesktopService) { }

    ngOnInit() {
        this._markerService.onToggleSelect
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (x == null)
                    return;

                let item = x.markerItem.getSelectedItem();
                if (item != null) {
                    this.showSelectedMarker = true;
                    this.selectedMarker = x;
                }
                else {
                    this.showSelectedMarker = false;
                    this.selectedMarker = null;
                }
            })

        this._markerService.onToggleMarkerVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (this.selectedMarker && this.selectedMarker.markerItem) {
                    let markerTypeContainer = this.selectedMarker.markerItem.markerTypeContainer;
                    if (markerTypeContainer.isMarkerWithPossibleSubType) {
                        let prop = markerTypeContainer.markerTypePropertyName;
                        let showFacilities = this._markerService.categories[prop].base.showMarkers;
                        if (showFacilities) {
                            this.showSelectedMarker = this._markerService.getMarkerVisibility(markerTypeContainer);
                        }
                        else {
                            this.showSelectedMarker = false;
                        }
                    }
                    else {
                        this.showSelectedMarker = this._markerService.getMarkerVisibility(markerTypeContainer);
                    }
                }
            });

        this.checkForDesktopRightContentBoxHeightChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();

        clearTimeout(this._timer);
    }

    clickCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed) {

            this.iconCollapseSide = 'left';
            this.iconCollapseBottom = 'down';
        }
        else {
            this.iconCollapseSide = 'right';
            this.iconCollapseBottom = 'up';
        }
    }

    private checkForDesktopRightContentBoxHeightChanges() {
        let container = $("#b-top-right-content-box-desktop");
        let containerChanged = container && container.outerHeight() != undefined && container.outerHeight() != this._elementLastHeight;
        let windowChanged = window && $(window).outerHeight() != undefined && $(window).outerHeight() != this._windowLastHeight;
        if (containerChanged || windowChanged) {
            this._elementLastHeight = container.outerHeight();
            this._windowLastHeight = $(window).outerHeight();
            this._topRightContentBoxDesktopService.emitOnBoxHeightChanged({ elementHeight: this._elementLastHeight, windowHeight: this._windowLastHeight });
        }

        this._timer = setTimeout(() => {
            this.checkForDesktopRightContentBoxHeightChanges();
        }, 100);
    }
}
