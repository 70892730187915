/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./categories-list-item-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./categories-list-item-mobile.component";
import * as i4 from "./marker.service";
import * as i5 from "./map.service";
import * as i6 from "./route-data.service";
var styles_CategoriesListItemMobileComponent = [i0.styles];
var RenderType_CategoriesListItemMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoriesListItemMobileComponent, data: {} });
export { RenderType_CategoriesListItemMobileComponent as RenderType_CategoriesListItemMobileComponent };
function View_CategoriesListItemMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " km"])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.item.distanceToAddress, "1.2-2")); _ck(_v, 1, 0, currVal_0); }); }
function View_CategoriesListItemMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "b-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickToggleSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "fluid ui blue button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesListItemMobileComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasAddress; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.name; _ck(_v, 5, 0, currVal_1); }); }
export function View_CategoriesListItemMobileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesListItemMobileComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CategoriesListItemMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-categories-list-item-mobile", ""]], null, null, null, View_CategoriesListItemMobileComponent_0, RenderType_CategoriesListItemMobileComponent)), i1.ɵdid(1, 245760, null, 0, i3.CategoriesListItemMobileComponent, [i4.MarkerService, i5.MapService, i6.RouteDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoriesListItemMobileComponentNgFactory = i1.ɵccf("[app-categories-list-item-mobile]", i3.CategoriesListItemMobileComponent, View_CategoriesListItemMobileComponent_Host_0, { item: "item", markerItem: "markerItem" }, {}, []);
export { CategoriesListItemMobileComponentNgFactory as CategoriesListItemMobileComponentNgFactory };
