import { MenuPosition } from '../types/menuPosition.enum';
import { Router, ActivationStart, GuardsCheckEnd } from '@angular/router';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var LayoutService = /** @class */ (function () {
    function LayoutService(_router) {
        this._router = _router;
        this._isInitialized = false;
        this._defaultSettings = {
            position: MenuPosition.Responsive,
            isOverlayed: false,
            isFooterEnabled: true,
            isSearchBoxEnabled: false
        };
        this.settings = {
            position: this._defaultSettings.position,
            isOverlayed: this._defaultSettings.isOverlayed,
            isFooterEnabled: this._defaultSettings.isFooterEnabled,
            isSearchBoxEnabled: this._defaultSettings.isSearchBoxEnabled,
            isSidebarActive: this._defaultSettings.isSidebarActive,
        };
        this.sidebar = {
            isActive: false,
        };
        this.menu = {
            onLoaded: new ReplaySubject(1),
            // currentUrl: [],
            items: {
                left: [
                    {
                        "title": "Forside",
                        "link": "/",
                        active: false
                    },
                    {
                        title: "Kort",
                        link: "/kort",
                        active: false
                    },
                    {
                        title: "Kommune",
                        link: "/kommune",
                        active: false
                    },
                    {
                        title: "Roadmap",
                        link: "/roadmap",
                        active: false
                    },
                    {
                        title: "Om DitOmraade",
                        link: "/om-ditomraade",
                        active: false
                    }
                ],
                right: [
                // {
                //     title: "Konto",
                //     link: "/log-ind"
                // }
                ]
            }
        };
    }
    LayoutService.prototype.init = function () {
        var _this = this;
        if (false == this._isInitialized) {
            var data_1 = null;
            var url_1 = null;
            // https://stackoverflow.com/questions/41465867/reset-service-variable-on-route-change-in-angular2
            this._router.events.subscribe(function (event) {
                // Fetch data here
                if (event instanceof ActivationStart) {
                    // this.menu.currentUrl = this.getCurrentUrl(event.snapshot.url);
                    url_1 = _this.getCurrentUrl(event.snapshot.url);
                    data_1 = event.snapshot.data;
                }
                // Set settings here
                if (event instanceof GuardsCheckEnd) {
                    if (event.shouldActivate) {
                        _this.setActiveUrl(url_1);
                        _this.resetSettings(data_1.layoutSettings);
                    }
                }
            });
            this._isInitialized = true;
        }
    };
    LayoutService.prototype.setSidebarActive = function (isActive) {
        this.sidebar.isActive = isActive;
    };
    LayoutService.prototype.menuLoaded = function () {
        this.menu.onLoaded.next();
    };
    LayoutService.prototype.getCurrentUrl = function (urlSegments) {
        return urlSegments.map(function (x) { return x.path; });
    };
    LayoutService.prototype.setActiveUrl = function (url) {
        var basePath = (url.length > 0 ? url[0] : '');
        for (var _i = 0, _a = this.menu.items.left; _i < _a.length; _i++) {
            var item = _a[_i];
            if (item.link.substring(1).toLowerCase() == basePath) {
                item.active = true;
            }
            else {
                item.active = false;
            }
        }
    };
    LayoutService.prototype.resetSettings = function (settings) {
        if (settings && settings.hasOwnProperty('position')) {
            this.settings.position = settings.position;
        }
        else {
            this.settings.position = this._defaultSettings.position;
        }
        if (settings && settings.hasOwnProperty('isOverlayed')) {
            this.settings.isOverlayed = settings.isOverlayed;
        }
        else {
            this.settings.isOverlayed = this._defaultSettings.isOverlayed;
        }
        if (settings && settings.hasOwnProperty('isFooterEnabled')) {
            this.settings.isFooterEnabled = settings.isFooterEnabled;
        }
        else {
            this.settings.isFooterEnabled = this._defaultSettings.isFooterEnabled;
        }
        if (settings && settings.hasOwnProperty('isSearchBoxEnabled')) {
            this.settings.isSearchBoxEnabled = settings.isSearchBoxEnabled;
        }
        else {
            this.settings.isSearchBoxEnabled = this._defaultSettings.isSearchBoxEnabled;
        }
    };
    LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(i0.ɵɵinject(i1.Router)); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
