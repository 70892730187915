import * as tslib_1 from "tslib";
import { BaseArea } from 'src/app/map/base-area';
import { AreaLevel } from 'src/app/shared/types/areaLevel.enum';
var Municipality = /** @class */ (function (_super) {
    tslib_1.__extends(Municipality, _super);
    function Municipality() {
        var _this = _super.call(this) || this;
        _this.areaLevel = AreaLevel.Municipality;
        _this.countryId = 208;
        return _this;
    }
    Municipality.prototype.init = function (obj) {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
        this.municipalityId = obj.municipalityId;
    };
    Object.defineProperty(Municipality.prototype, "fullName", {
        get: function () {
            var municipalityNamesEndingWithS = [101, 400];
            if (municipalityNamesEndingWithS.includes(this.municipalityId)) {
                return this.name + 's kommune';
            }
            else {
                return this.name + ' kommune';
            }
        },
        enumerable: true,
        configurable: true
    });
    return Municipality;
}(BaseArea));
export { Municipality };
