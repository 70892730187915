import { MarkerType } from './marker-type';
import { MarkerFacilityType } from './marker-facility-type';
import { MarkerPrimarySchoolType } from './marker-primary-school-type';
import { MarkerHighSchoolType } from './marker-high-school-type';
import { MarkerDaycareType } from './marker-daycare-type';
import { MarkerRealEstateType } from './marker-realestate-type';
import { MarkerSchoolDistrictType } from './marker-school-district-type';
var MarkerTypeContainer = /** @class */ (function () {
    function MarkerTypeContainer(markerType, markerSubType) {
        if (markerSubType === void 0) { markerSubType = null; }
        this.markerType = markerType;
        this.markerSubType = markerSubType;
    }
    Object.defineProperty(MarkerTypeContainer.prototype, "markerTypePropertyName", {
        get: function () {
            var name = this.markerTypeName;
            if (name != null)
                return this.getLoweredName(name);
            else
                return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "markerSubTypePropertyName", {
        get: function () {
            var name = this.markerSubTypeName;
            if (name != null)
                return this.getLoweredName(name);
            else
                return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "markerSubTypePropertyNames", {
        get: function () {
            var _this = this;
            switch (this.markerType) {
                case MarkerType.Facility:
                    return Object
                        .keys(MarkerFacilityType)
                        .filter(function (k) { return typeof MarkerFacilityType[k] === "number" && MarkerFacilityType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                case MarkerType.PrimarySchool:
                    return Object
                        .keys(MarkerPrimarySchoolType)
                        .filter(function (k) { return typeof MarkerPrimarySchoolType[k] === "number" && MarkerPrimarySchoolType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                case MarkerType.HighSchool:
                    return Object
                        .keys(MarkerHighSchoolType)
                        .filter(function (k) { return typeof MarkerHighSchoolType[k] === "number" && MarkerHighSchoolType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                case MarkerType.Daycare:
                    return Object
                        .keys(MarkerDaycareType)
                        .filter(function (k) { return typeof MarkerDaycareType[k] === "number" && MarkerDaycareType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                case MarkerType.RealEstate:
                    return Object
                        .keys(MarkerRealEstateType)
                        .filter(function (k) { return typeof MarkerRealEstateType[k] === "number" && MarkerRealEstateType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                case MarkerType.SchoolDistrict:
                    return Object
                        .keys(MarkerSchoolDistrictType)
                        .filter(function (k) { return typeof MarkerSchoolDistrictType[k] === "number" && MarkerSchoolDistrictType[k] != "0"; })
                        .map(function (x) { return _this.getLoweredName(x); });
                default:
                    return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "markerSubTypeValues", {
        get: function () {
            switch (this.markerType) {
                case MarkerType.Facility:
                    return Object
                        .keys(MarkerFacilityType)
                        .filter(function (k) { return typeof MarkerFacilityType[k] === "number" && MarkerFacilityType[k] != "0"; })
                        .map(function (k) { return +MarkerFacilityType[k]; });
                case MarkerType.PrimarySchool:
                    return Object
                        .keys(MarkerPrimarySchoolType)
                        .filter(function (k) { return typeof MarkerPrimarySchoolType[k] === "number" && MarkerPrimarySchoolType[k] != "0"; })
                        .map(function (k) { return +MarkerPrimarySchoolType[k]; });
                case MarkerType.HighSchool:
                    return Object
                        .keys(MarkerHighSchoolType)
                        .filter(function (k) { return typeof MarkerHighSchoolType[k] === "number" && MarkerHighSchoolType[k] != "0"; })
                        .map(function (k) { return +MarkerHighSchoolType[k]; });
                case MarkerType.Daycare:
                    return Object
                        .keys(MarkerDaycareType)
                        .filter(function (k) { return typeof MarkerDaycareType[k] === "number" && MarkerDaycareType[k] != "0"; })
                        .map(function (k) { return +MarkerDaycareType[k]; });
                case MarkerType.RealEstate:
                    return Object
                        .keys(MarkerRealEstateType)
                        .filter(function (k) { return typeof MarkerRealEstateType[k] === "number" && MarkerRealEstateType[k] != "0"; })
                        .map(function (k) { return +MarkerRealEstateType[k]; });
                case MarkerType.SchoolDistrict:
                    return Object
                        .keys(MarkerSchoolDistrictType)
                        .filter(function (k) { return typeof MarkerSchoolDistrictType[k] === "number" && MarkerSchoolDistrictType[k] != "0"; })
                        .map(function (k) { return +MarkerSchoolDistrictType[k]; });
                default:
                    return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "hasSubType", {
        get: function () {
            return this.markerSubType != null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isMarkerWithPossibleSubType", {
        get: function () {
            return this.markerType == MarkerType.Facility || this.markerType == MarkerType.HighSchool || this.markerType == MarkerType.PrimarySchool || this.markerType == MarkerType.Daycare || this.markerType == MarkerType.RealEstate || this.markerType == MarkerType.SchoolDistrict;
        },
        enumerable: true,
        configurable: true
    });
    MarkerTypeContainer.prototype.matches = function (otherMarkerTypeContainer) {
        return this.markerType == otherMarkerTypeContainer.markerType && this.markerSubType == otherMarkerTypeContainer.markerSubType;
    };
    Object.defineProperty(MarkerTypeContainer.prototype, "isDaycareType", {
        get: function () {
            return this.markerType == MarkerType.Daycare;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isPrivateDaycareType", {
        get: function () {
            return this.markerType == MarkerType.PrivateDaycare;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isPrimarySchoolType", {
        get: function () {
            return this.markerType == MarkerType.PrimarySchool;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isHighSchoolType", {
        get: function () {
            return this.markerType == MarkerType.HighSchool;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isFacilityType", {
        get: function () {
            return this.markerType == MarkerType.Facility;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isSportsUnionType", {
        get: function () {
            return this.markerType == MarkerType.SportsUnion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isPublicTransportationType", {
        get: function () {
            return this.markerType == MarkerType.PublicTransportation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isSupermarketType", {
        get: function () {
            return this.markerType == MarkerType.Supermarket;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isNoiseType", {
        get: function () {
            return this.markerType == MarkerType.Noise;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isTraficCountType", {
        get: function () {
            return this.markerType == MarkerType.TraficCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isDoctorType", {
        get: function () {
            return this.markerType == MarkerType.Doctor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isRealEstateType", {
        get: function () {
            return this.markerType == MarkerType.RealEstate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isSchoolDistrictType", {
        get: function () {
            return this.markerType == MarkerType.SchoolDistrict;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "isResidentsForRentType", {
        get: function () {
            return this.markerType == MarkerType.ResidentsForRent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "markerTypeName", {
        get: function () {
            return MarkerType[this.markerType];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkerTypeContainer.prototype, "markerSubTypeName", {
        get: function () {
            switch (this.markerType) {
                case MarkerType.Facility:
                    return MarkerFacilityType[this.markerSubType];
                case MarkerType.PrimarySchool:
                    return MarkerPrimarySchoolType[this.markerSubType];
                case MarkerType.HighSchool:
                    return MarkerHighSchoolType[this.markerSubType];
                case MarkerType.Daycare:
                    return MarkerDaycareType[this.markerSubType];
                case MarkerType.RealEstate:
                    return MarkerRealEstateType[this.markerSubType];
                case MarkerType.SchoolDistrict:
                    return MarkerSchoolDistrictType[this.markerSubType];
                default:
                    return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    MarkerTypeContainer.prototype.getLoweredName = function (name) {
        return name.charAt(0).toLowerCase() + name.slice(1);
    };
    return MarkerTypeContainer;
}());
export { MarkerTypeContainer };
