export interface IPointGeometry {
    type: string;
    coordinates: number[];
}

export class PointGeometry implements IPointGeometry {
    type: string = "Point";
    coordinates: number[];

    constructor(coordinates: number[]) {
        this.coordinates = coordinates;
    }
}