<div class="b-container" *ngIf="markerItem && markerItem.showMarkers">
    <div class="b-header">
        <div class="ui right floated" *ngIf="markerItem.isCompareEnabled">
            <p><strong>Sammenlign</strong></p>
        </div>
        <div class="b-headline">
            <img *ngIf="markerItem.iconUrlEnabled" [src]="markerItem.iconUrlEnabled" style="height: 30px;display: inline-block;" />
            <p style="display: inline-block;padding-left: 5px;"><strong>{{markerItem.headline}}</strong></p>
        </div>
    </div>

    <div style="padding-top: 10px;" *ngIf="isLoading">
        <div class="ui active centered inline medium text loader">
            <div>
                Henter data for {{markerItem.headline}}...
            </div>
        </div>
    </div>

    <div *ngIf="false == isLoading && isLoaded">
        <div app-categories-list-item-desktop *ngFor="let item of markerItem.items" [markerItem]="markerItem" [item]="item" [isCompareEnabled]="markerItem.isCompareEnabled"></div>
    </div>

    <div *ngIf="markerItem.markerTypeContainer.isNoiseType || markerItem.markerTypeContainer.isResidentsForRentType" style="text-align: center;margin-top: 20px; margin-bottom: 10px;">
        <p style="color: rgba(0,0,0,.87);"><strong>Manglende data... Data er på vej.</strong></p>
    </div>

    <div *ngIf="false == markerItem.markerTypeContainer.isNoiseType && false == markerItem.markerTypeContainer.isResidentsForRentType && (!markerItem.items || markerItem.items.length == 0) && false == isLoading && isLoaded" style="text-align: center;margin-top: 20px; margin-bottom: 10px;">
        <p style="color: rgba(0,0,0,.87);">Ingen data i den valgte kategori for dette område</p>
    </div>

    <div class="b-body" *ngIf="markerItem.isComparable">
        <button class="compact ui button align right" (click)="clickOpenCompare()">
            Åbn sammenligning af {{markerItem.headline}} ({{markerItem.comparedItems.length}})
        </button>
    </div>
</div>