import { Injectable, Output, EventEmitter } from '@angular/core';
import { Zoom, IZoom } from '../shared/types/zoom/zoom';
import { ZoomLevel } from '../shared/types/zoom/zoomLevel';
import { AreaLevel } from '../shared/types/areaLevel.enum';

@Injectable({
    providedIn: 'root'
})
export class ZoomService {
    @Output() onZoomChange = new EventEmitter<IZoom>();

    private _settings: any = {
        country: {
            zoom: new Zoom(new ZoomLevel(-2, 2, -1), 1.1),
        },
        region: {
            zoom: new Zoom(new ZoomLevel(-4, 4), 1.1),
        },
        municipality: {
            zoom: new Zoom(new ZoomLevel(-4, 4), 1.1),
        },
        parish: {
            zoom: new Zoom(new ZoomLevel(-4, 2, -1), 1.3),
        },
    }

    constructor() { }

    emitZoomIn(areaLevel: AreaLevel) {
        var zoom = this.getZoomForArea(areaLevel);
        if (zoom.zoomIn()) {
            this.onZoomChange.emit(zoom);
        }
    }

    emitZoomOut(areaLevel: AreaLevel) {
        var zoom = this.getZoomForArea(areaLevel);
        if (zoom.zoomOut()) {
            this.onZoomChange.emit(zoom);
        }
    }

    getZoomForArea(areaLevel: AreaLevel): IZoom {
        if (areaLevel == AreaLevel.Country) {
            return this._settings.country.zoom;
        }
        else if (areaLevel == AreaLevel.Region) {
            return this._settings.region.zoom;
        }
        else if (areaLevel == AreaLevel.Municipality) {
            return this._settings.municipality.zoom;
        }
        else if (areaLevel == AreaLevel.Parish) {
            return this._settings.parish.zoom;
        }

        throw new Error('No zoom info found for area ' + AreaLevel[areaLevel]);
    }
}
