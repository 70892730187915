<div class="ui modal b-map-view-modal-compare" id="b-comparison-modal">
    <i class="close icon"></i>
    <div class="header b-header">
        {{title}}
    </div>
    <div class="image content">
        <div class="description">

            <table class="ui very basic celled table">
                <thead>
                    <tr>
                        <th class="collapsing"></th>
                        <th *ngFor="let item of items">
                            <div class="ui centered grid">
                                <div class="center aligned column">
                                    <button class="ui icon mini button" (click)="clickRemoveFromCompare(item.id)">
                                        <i class="trash alternate outline icon"></i>
                                        Fjern
                                    </button>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th></th>
                        <th *ngFor="let item of items">
                            <span>{{item.name}}</span>
                        </th>
                    </tr>
                </thead>

                <tbody app-comparison-primary-school [items]="items" [dateUpdated]="dateUpdated" *ngIf="markerTypeContainer && markerTypeContainer.markerType == 30">
                </tbody>

                <tbody app-comparison-high-school [items]="items" [dateUpdated]="dateUpdated" *ngIf="markerTypeContainer && markerTypeContainer.markerType == 32">
                </tbody>
                
            </table>
        </div>
    </div>
</div>