import { BaseArea, IBaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';

export class Parish extends BaseArea implements IParish, IBaseArea {
    areaLevel: AreaLevel = AreaLevel.Parish;
    countryId: number = 208;
    regionId: number;
    municipalityId: number;
    parishId: number;

    constructor() {
        super();
    }

    init(obj: IBaseParish): void {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
        this.municipalityId = obj.municipalityId;
        this.parishId = obj.parishId;
    }

    get fullName() {
        return this.name;
    }
}

export interface IParish {
    countryId: number;
    regionId: number;
    municipalityId: number;
    parishId: number;

    // IBaseArea
    areaLevel: AreaLevel;
    name: string;
    urlParameter: string;
    fullName: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}

export interface IBaseParish {
    name: string;
    countryId: number;
    regionId: number;
    municipalityId: number;
    parishId: number;
}