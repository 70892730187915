import { PointGeometry } from './point';
import * as polylabel from '@mapbox/polylabel';

export interface IMultiPolygonGeometry {
    type: string;
    coordinates: number[][][][];
}

export class MultiPolygonGeometry implements IMultiPolygonGeometry {
    type: string = "MultiPolygon";
    coordinates: number[][][][];
    visualCenter: PointGeometry = null;

    constructor(coordinates: number[][][][], isCalculateVisualCenter: boolean = false) {
        this.coordinates = coordinates;

        // Calculating visual center
        if (isCalculateVisualCenter) {
            this.visualCenter = this.getVisualCenter(coordinates);
        }
    }

    private getVisualCenter(coordinates: number[][][][]): PointGeometry {
        if (coordinates.length > 1) {
            // Find largest polygon
            let largestDiagonalLength: number = 0;
            let largestPolygonIndex: number;
            let i = 0;
            for (const polygon of coordinates) {
                let bboxCoords: [number, number][] = [];
                for (const ring of polygon) {
                    for (const coord of ring) {
                        bboxCoords.push([coord[0], coord[1]]);
                    }
                }
                let bbox = this.getBbox(bboxCoords);
                let diagonalLength = this.getDiagonalLength(bbox);
                if (diagonalLength > largestDiagonalLength) {
                    largestDiagonalLength = diagonalLength;
                    largestPolygonIndex = i;
                }
                i++;
            }
            let coords = <number[][][]>coordinates[largestPolygonIndex];
            return new PointGeometry(polylabel(coords));
        }
        else {
            // Take first (and only) polygon
            let coords = <number[][][]>coordinates[0];
            return new PointGeometry(polylabel(coords));
        }
    }

    private getDiagonalLength(bbox: [[number, number], [number, number]]): number {
        let a = bbox[1][0] - bbox[0][0];
        let b = bbox[1][1] - bbox[0][1];

        return Math.sqrt(a * a + b * b);
    }

    private getBbox(coordinates: [number, number][]): [[number, number], [number, number]] {
        let x0 = Infinity, y0 = Infinity, x1 = -Infinity, y1 = -Infinity;

        for (const p of coordinates) {
            if (p[0] < x0) x0 = p[0];
            if (p[0] > x1) x1 = p[0];
            if (p[1] < y0) y0 = p[1];
            if (p[1] > y1) y1 = p[1];
        }

        return [[x0, y0], [x1, y1]];
    }
}