import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MarkerService } from './marker.service';
import { MarkerItem } from './marker-item';
import { RouteDataService } from './route-data.service';
import { MapService } from './map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: '[app-categories-list-item-desktop]',
    templateUrl: './categories-list-item-desktop.component.html',
    styleUrls: ['./categories-list-item-desktop.component.scss']
})
export class CategoriesListItemDesktopComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();
    
    @Input() markerItem: MarkerItem;
    @Input() item: any;
    @Input() isCompareEnabled: boolean;

    hasAddress: boolean = false;

    constructor(private _markerService: MarkerService, private _mapService: MapService, private _routeDataService: RouteDataService) {
    }

    ngOnInit(): void {
        this._mapService
            .onToggleLoadMarkers
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                this.hasAddress = this._routeDataService.getCurrentRouteData().hasAddress;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }



    clickToggleCompare() {
        this._markerService.toggleCompare(this.item.id, this.markerItem.markerTypeContainer);
    }

    clickToggleSelected() {
        this._markerService.toggleSelected(this.item.id, this.markerItem.markerTypeContainer);
    }
}