import { MarkerType } from './marker-type';
import { MarkerTypeContainer } from './marker-type-container';

export class BaseMarkerItem {
    private readonly _baseIconUrl: string = '../../assets/images/';

    private _iconUrlEnabled: string;
    private _iconUrlDisabled: string;

    markerTypeContainer: MarkerTypeContainer;

    headline: string;
    dropdownHeader: string;

    iconUrl: string;
    showMarkers: boolean;

    subCategories: any[];
    dropdownItems: any[];
    isFirstTimeShowingCategory: boolean = true;
    isShowAllSubCategoriesEnabled: boolean = true;

    get headlineLowered(): string {
        return this.getLoweredName(this.headline);
    }

    constructor(markerType: MarkerType, headline: string, dropdownHeader: string, iconName: string, isShowAllSubCategoriesEnabled: boolean) {
        this.markerTypeContainer = new MarkerTypeContainer(markerType, null);
        this.headline = headline;
        this.dropdownHeader = dropdownHeader;
        this._iconUrlEnabled = this._baseIconUrl + iconName + '.png';
        this._iconUrlDisabled = this._baseIconUrl + 'disabled/' + iconName + '.png';
        this.isShowAllSubCategoriesEnabled = isShowAllSubCategoriesEnabled;

        this.iconUrl = this._iconUrlDisabled;
        this.showMarkers = false;

        this.subCategories = null;
        this.dropdownItems = [];
    }

    public toggleMarkerVisibility(isVisible: boolean): void {
        // If "isVisible" is not explicitly set, then set it to the opposite value of "showMarkers"
        if (isVisible == null)
            isVisible = !this.showMarkers;

        this.showMarkers = isVisible;
        if (isVisible)
            this.iconUrl = this._iconUrlEnabled;
        else
            this.iconUrl = this._iconUrlDisabled;
    }

    public addSubCategories(subCategories: any[]): void {
        if (this.subCategories == null) {
            // Only take subcategories that are actually enabled
            let availableSubCategories = this.markerTypeContainer.markerSubTypeValues;
            let subCategoriesFiltered = subCategories
                .filter(x => availableSubCategories.includes(x.id))

            // Adding facility types
            this.subCategories = subCategoriesFiltered;

            // Adding select elements
            for (let i = 0; i < subCategoriesFiltered.length; i++) {
                const element = subCategoriesFiltered[i];
                this.dropdownItems.push({
                    name: element.name,
                    value: element.id
                });
            }
        }
    }

    public hasSubCategories(): boolean {
        return this.subCategories != null && this.subCategories.length > 0;
    }

    public toggleDropdownSelected(markerSubType: number, isSelected: boolean) {
        for (let i = 0; i < this.dropdownItems.length; i++) {
            const item = this.dropdownItems[i];
            if (item.value == markerSubType) {
                item.selected = isSelected;
                break;
            }
        }
    }


    private getLoweredName(name: string): string {
        return name.charAt(0).toLowerCase() + name.slice(1);
    }
}