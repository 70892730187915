export enum MarkerPrimarySchoolType {
    None = 0,

    // BoardingWithSpecialOffer = 1010,        // Efterskoler med samlet særligt tilbud
    Boarding = 1011,                        // Efterskoler
    Government = 1012,                      // Folkeskoler
    Private = 1013,                         // Friskoler og private grundskoler
    // After = 1014,                           // Kommunale ungdomsskoler og ungdomskostskoler
    // Special = 1015,                         // Specialskoler for børn
    // Treatment = 1016,                       // Dagbehandlingstilbud og behandlingshjem
    // GovernmentInternational = 1017,         // Kommunale internationale skoler
    // PrivateInternational = 1018,            // Frie og private internationale skoler
    // ForeignStudents = 1019                  // Særlige skoletilbud til udenlandske børn
}