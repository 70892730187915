<div class="b-container" *ngIf="factsheet && area && (area.areaLevel == 30 || area.areaLevel == 40)">
    <div class="ui cards">
        <div class="card">
            <!-- Loader -->
            <div class="ui active inverted dimmer" *ngIf="isLoading">
                <div class="ui medium text loader">Henter data for {{area.fullName}}...</div>
            </div>
            <div class="content">
                <div class="header">
                    <div>
                        {{area.fullName}}
                    </div>
                </div>
                <div class="description">
                    <div>
                        <div class="right floated" *ngIf="isCompared && isComparable">
                            <a class="ui label b-compare-button" (click)="clickToggleComparisonVisibility()"
                                [ngClass]="{'active' : isCompared == true, 'municipality' : area.areaLevel == 30, 'parish' : area.areaLevel == 40 }">
                                <i class="external alternate icon"></i><span>Åbn</span>
                            </a>
                        </div>
                        <div style="margin-top: 10px; margin-bottom: 10px;" *ngIf="false">
                            <a class="ui label b-compare-button" (click)="clickToggleCompare()"
                                [ngClass]="{'active' : isCompared == true, 'municipality' : area.areaLevel == 30, 'parish' : area.areaLevel == 40 }">
                                <i class="exchange icon"></i>
                                <span *ngIf="!isCompared">Tiljøj til sammenligning</span>
                                <span *ngIf="isCompared">Fjern fra sammenligning</span>
                            </a>
                        </div>
                        <table>
                            <tr *ngIf="factsheet.income">
                                <td>Indkomst før skat (husstand)</td>
                                <td>{{factsheet.income.value | number: '1.0'}} {{factsheet.income.unit}}</td>
                            </tr>
                            <tr *ngIf="area.areaLevel == 40">
                                <td>Personer med kort videregående uddannelse eller højere</td>
                                <td>{{factsheet.education.value | number: '1.1'}} {{factsheet.education.unit}}</td>
                            </tr>
                            <tr>
                                <td>Nettotilflytning</td>
                                <td>{{factsheet.relocation.value | number: '1.1'}} {{factsheet.relocation.unit}}</td>
                            </tr>
                            <tr>
                                <td>Personer med dansk oprindelse</td>
                                <td>{{factsheet.origin.value | number: '1.1'}} {{factsheet.origin.unit}}</td>
                            </tr>
                            <tr>
                                <td>Befolkningens gennemsnitlige alder</td>
                                <td>{{factsheet.averageAge.value | number: '1.1'}} {{factsheet.averageAge.unit}}</td>
                            </tr>
                            <!-- <tr>
                                    <td>Andel boliger bygget efter år 2000</td>
                                    <td>{{factsheet.newResidentials.value | number: '1.0'}}
                                        {{factsheet.newResidentials.unit}}</td>
                                </tr>
                                <tr>
                                    <td>Boliger til salg</td>
                                    <td>{{factsheet.realestateForSale.value}}
                                        {{factsheet.realestateForSale.unit}}</td>
                                </tr>
                                <tr>
                                    <td>Boligernes gennemsnitlige liggetid</td>
                                    <td>{{factsheet.salesPeriod.value | number: '1.0'}}
                                        {{factsheet.salesPeriod.unit}}</td>
                                </tr>
                                <tr>
                                    <td>Beboernes typiske beskæftigelse</td>
                                    <td>{{factsheet.job.value}} {{factsheet.job.unit}}</td>
                                </tr> -->
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>