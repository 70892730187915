import { EventEmitter } from '@angular/core';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { Observable } from 'rxjs/internal/Observable';
import { AreaContainer } from './area-container';
import { MarkerType } from './marker-type';
import { MarkerContainer } from './marker-container';
import { MarkerDetailsContainer } from './marker-details-container';
import { forkJoin, BehaviorSubject, Subject } from 'rxjs';
import { MarkerTypeContainer } from './marker-type-container';
import { UtilityService } from '../shared/services/utility.service';
import { DeviceType } from '../shared/types/device-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/dataaccess.service";
import * as i2 from "../shared/services/utility.service";
var MapService = /** @class */ (function () {
    function MapService(_dataAccessService, _utilityService) {
        this._dataAccessService = _dataAccessService;
        this._utilityService = _utilityService;
        this._areaContainer = new AreaContainer();
        this._markerContainer = new MarkerContainer();
        this._markerDetailsContainer = new MarkerDetailsContainer();
        this.onFitBoundsStart = new EventEmitter();
        this.onFitBoundsEnd = new BehaviorSubject(null);
        this.onMobileMenuHeightChanged = new EventEmitter();
        this.onResetBounds = new Subject();
        this.onBoundsChangedSubject = new Subject();
        this.onBoundsChanged = this.onBoundsChangedSubject.asObservable();
        this.onAreaDataReady = new EventEmitter();
        this.onToggleLoadMarkers = new BehaviorSubject({
            markerTypeContainer: new MarkerTypeContainer(MarkerType.None, null),
            isLoading: false,
            isLoaded: true,
        });
        this.marker = this._markerContainer.marker;
        this._isAreaDataReady = false;
    }
    Object.defineProperty(MapService.prototype, "isAreaDataReady", {
        get: function () {
            return this._isAreaDataReady;
        },
        enumerable: true,
        configurable: true
    });
    MapService.prototype.changeBounds = function (bounds) {
        this.onBoundsChangedSubject.next(bounds);
    };
    MapService.prototype.loadInitialAreaData = function () {
        var _this = this;
        // Load initial data
        return new Observable(function (observer) {
            if (false == _this._isAreaDataReady) {
                var countryAreaGeometries = _this.getCountryAreaGeometries();
                var regionAreaGeometries = _this._dataAccessService.getMapAreaGeometries(AreaLevel.Country);
                var areaIdsData = _this.getMapAreaIdsData();
                forkJoin(countryAreaGeometries, regionAreaGeometries, areaIdsData)
                    .subscribe(function (results) {
                    var country = results[0];
                    var region = results[1];
                    var areaIds = results[2];
                    // Core 
                    _this._areaContainer.addCountry(country);
                    _this._areaContainer.addRegions(areaIds.regions);
                    _this._areaContainer.addMunicipalities(areaIds.municipalities);
                    _this._areaContainer.addParishes(areaIds.parishes);
                    // Geometries
                    _this._areaContainer.addCountryGeometry(country);
                    _this._areaContainer.addRegionGeometries(region.map.areaGeometries.regions);
                    _this._isAreaDataReady = true;
                    _this.onAreaDataReady.next();
                    _this.onAreaDataReady.complete();
                    observer.next();
                    observer.complete();
                });
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    };
    MapService.prototype.getMapAreaIdsData = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getMapAreaIds()
                .subscribe(function (x) {
                observer.next(x.map.areaIds);
                observer.complete();
            });
        });
    };
    MapService.prototype.getCountryAreaGeometries = function () {
        return new Observable(function (observer) {
            var country = {
                areaLevel: AreaLevel.Country,
                bounds: [[8.0443998933, 54.538384098], [15.2050413988, 57.7875799259]],
                id: 1,
                countryId: 208,
                name: 'Danmark'
            };
            observer.next(country);
            observer.complete();
        });
    };
    MapService.prototype.loadRegionAreaGeometries = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasRegionGeometries()) {
                _this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Country)
                    .subscribe(function (x) {
                    _this._areaContainer.addRegionGeometries(x.map.areaGeometries.regions);
                    observer.next();
                    observer.complete();
                });
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    };
    MapService.prototype.loadMunicipalityAreaGeometries = function (regionId) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasMunicipalityGeometriesByRegionId(regionId)) {
                _this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Region, regionId)
                    .subscribe(function (x) {
                    _this._areaContainer.addMunicipalityGeometries(x.map.areaGeometries.municipalities);
                    observer.next();
                    observer.complete();
                });
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    };
    MapService.prototype.loadParishAreaGeometries = function (municipalityId) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasParishGeometriesByMunicipalityId(municipalityId)) {
                _this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Municipality, null, municipalityId)
                    .subscribe(function (x) {
                    _this._areaContainer.addParishGeometries(x.map.areaGeometries.parishes);
                    observer.next();
                    observer.complete();
                });
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    };
    MapService.prototype.loadParishAreaGeometriesByRegionId = function (regionId) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasParishGeometriesByRegionId(regionId)) {
                _this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Municipality, regionId, null)
                    .subscribe(function (x) {
                    _this._areaContainer.addParishGeometries(x.map.areaGeometries.parishes);
                    observer.next();
                    observer.complete();
                });
            }
            else {
                observer.next();
                observer.complete();
            }
        });
    };
    MapService.prototype.loadMunicipalityFactsheet = function (municipalityId) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasMunicipalityFactsheet(municipalityId)) {
                _this._dataAccessService
                    .getMapFactsheetMunicipality(municipalityId)
                    .subscribe(function (x) {
                    var factsheet = _this._areaContainer.addMunicipalityFactsheet(municipalityId, x.map.municipality.factsheet.data);
                    observer.next(factsheet);
                    observer.complete();
                });
            }
            else {
                var municipality = _this._areaContainer.getMunicipality(municipalityId);
                observer.next(municipality.factsheet);
                observer.complete();
            }
        });
    };
    MapService.prototype.loadParishFactsheet = function (parishId) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == _this._areaContainer.hasParishFactsheet(parishId)) {
                _this._dataAccessService
                    .getMapFactsheetParish(parishId)
                    .subscribe(function (x) {
                    var factsheet = _this._areaContainer.addParishFactsheet(parishId, x.map.parish.factsheet.data);
                    observer.next(factsheet);
                    observer.complete();
                });
            }
            else {
                var parish = _this._areaContainer.getParish(parishId);
                observer.next(parish.factsheet);
                observer.complete();
            }
        });
    };
    MapService.prototype.loadMapData = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getMapData()
                .subscribe(function (x) {
                observer.next(x.map);
                observer.complete();
            });
        });
    };
    MapService.prototype.loadMarkerData = function (markerTypeContainer, coordinate, bounds, municipalityId, markerSkipCount, markerTakeCount) {
        var _this = this;
        var prop = markerTypeContainer.markerTypePropertyName;
        return new Observable(function (observer) {
            if (false == _this._markerContainer.hasData(coordinate, markerTypeContainer)) {
                _this.onToggleLoadMarkers.next({
                    markerTypeContainer: markerTypeContainer,
                    isLoading: true,
                    isLoaded: false,
                });
                _this.requestMarkerData(markerTypeContainer, coordinate, bounds, municipalityId, markerSkipCount, markerTakeCount)
                    .subscribe(function (x) {
                    var data;
                    if (markerTypeContainer.isRealEstateType) {
                        data = x.map.markers;
                    }
                    else {
                        data = x.map[prop];
                    }
                    _this._markerContainer.addData(data, coordinate, markerTypeContainer);
                    _this._markerContainer.updateMarkerItems(data.items, markerTypeContainer);
                    _this.onToggleLoadMarkers.next({
                        markerTypeContainer: markerTypeContainer,
                        isLoading: false,
                        isLoaded: true,
                    });
                    observer.next(data);
                    observer.complete();
                });
            }
            else {
                var data = _this._markerContainer.getData(coordinate, markerTypeContainer);
                _this._markerContainer.updateMarkerItems(data.items, markerTypeContainer);
                _this.onToggleLoadMarkers.next({
                    markerTypeContainer: markerTypeContainer,
                    isLoading: false,
                    isLoaded: true,
                });
                observer.next(data);
                observer.complete();
            }
        });
    };
    MapService.prototype.requestMarkerData = function (markerTypeContainer, coordinate, bounds, municipalityId, markerSkipCount, markerTakeCount) {
        var _this = this;
        return new Observable(function (observer) {
            var deviceType = _this._utilityService.getClientDeviceType();
            var obs;
            switch (markerTypeContainer.markerType) {
                case MarkerType.Nursery:
                    obs = _this._dataAccessService
                        .getNurseryMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Kindergarden:
                    obs = _this._dataAccessService
                        .getKindergaardenMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.PrimarySchool:
                    obs = _this._dataAccessService
                        .getPrimarySchoolMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.HighSchool:
                    obs = _this._dataAccessService
                        .getHighSchoolMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.PrivateDaycare:
                    // Specific default for private daycare on desktop
                    var markerTakeCountPrivateDaycare = (deviceType == DeviceType.Mobile ? 5 : 100);
                    obs = _this._dataAccessService
                        .getPrivateDaycareMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCountPrivateDaycare);
                    break;
                case MarkerType.PublicTransportation:
                    obs = _this._dataAccessService
                        .getPublicTransportationMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Supermarket:
                    obs = _this._dataAccessService
                        .getSupermarketMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Noise:
                    obs = _this._dataAccessService
                        .getNoiseMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Doctor:
                    obs = _this._dataAccessService
                        .getDoctorMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.RealEstate:
                    var markerTakeCountRealEstate = (deviceType == DeviceType.Mobile ? 5 : null);
                    obs = _this._dataAccessService
                        .getRealEstateMapMarkers(coordinate.lng, coordinate.lat, bounds.bbox, markerTypeContainer.markerSubType, markerSkipCount, markerTakeCountRealEstate);
                    break;
                case MarkerType.ResidentsForRent:
                    obs = _this._dataAccessService
                        .getResidentsForRentMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Facility:
                    obs = _this._dataAccessService
                        .getFacilityMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.SportsUnion:
                    obs = _this._dataAccessService
                        .getSportsUnionMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Daycare:
                    obs = _this._dataAccessService
                        .getDaycareMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.SchoolDistrict:
                    obs = _this._dataAccessService
                        .getSchoolDistrictData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.TraficCount:
                    var markerTakeCountTraficCount = (deviceType == DeviceType.Mobile ? 5 : null);
                    obs = _this._dataAccessService
                        .getTraficCountData(coordinate.lng, coordinate.lat, bounds.bbox, municipalityId, markerSkipCount, markerTakeCountTraficCount);
                    break;
                default:
                    break;
            }
            obs.subscribe(function (x) {
                observer.next(x);
                observer.complete();
            });
        });
    };
    MapService.prototype.hasDetailsData = function (markerTypeContainer, markerId) {
        return this._markerDetailsContainer.hasData(markerTypeContainer, markerId);
    };
    MapService.prototype.loadDetailsData = function (markerTypeContainer, markerId) {
        var _this = this;
        var prop = markerTypeContainer.markerTypePropertyName;
        return new Observable(function (observer) {
            if (false == _this._markerDetailsContainer.hasData(markerTypeContainer, markerId)) {
                // this.onToggleLoadMarkers.next({
                //     markerTypeContainer: markerTypeContainer,
                //     isLoading: true,
                //     isLoaded: false,
                // });
                _this.requestDetailsData(markerTypeContainer, markerId)
                    .subscribe(function (x) {
                    var data = x.map[prop];
                    _this._markerDetailsContainer.addData(markerTypeContainer, markerId, data);
                    // this.onToggleLoadMarkers.next({
                    //     markerTypeContainer: markerTypeContainer,
                    //     isLoading: false,
                    //     isLoaded: true,
                    // });
                    observer.next(data);
                    observer.complete();
                });
            }
            else {
                var data = _this._markerDetailsContainer.getData(markerTypeContainer, markerId);
                // this.onToggleLoadMarkers.next({
                //     markerTypeContainer: markerTypeContainer,
                //     isLoading: false,
                //     isLoaded: true,
                // });
                observer.next(data);
                observer.complete();
            }
        });
    };
    MapService.prototype.requestDetailsData = function (markerTypeContainer, markerId) {
        var _this = this;
        return new Observable(function (observer) {
            var obs;
            switch (markerTypeContainer.markerType) {
                case MarkerType.RealEstate:
                    obs = _this._dataAccessService
                        .getRealEstateMapDetails(markerId);
                    break;
                default:
                    break;
            }
            obs.subscribe(function (x) {
                observer.next(x);
                observer.complete();
            });
        });
    };
    MapService.prototype.getRegionIds = function () {
        return this._areaContainer.getRegions()
            .map(function (x) { return x.core.regionId; });
    };
    MapService.prototype.getMunicipalityIds = function (regionId) {
        return this._areaContainer.getMunicipalities(regionId)
            .map(function (x) { return x.core.municipalityId; });
    };
    MapService.prototype.getRegionAreas = function () {
        return this._areaContainer.getRegions()
            .map(function (x) { return x.geometry; });
    };
    MapService.prototype.getMunicipalityAreas = function (regionId) {
        return this._areaContainer.getMunicipalities(regionId)
            .map(function (x) { return x.geometry; });
    };
    MapService.prototype.getParishAreas = function (municipalityId) {
        return this._areaContainer.getParishes(municipalityId)
            .map(function (x) { return x.geometry; });
    };
    MapService.prototype.getCountryAreaContainer = function () {
        return this._areaContainer.getCountry();
    };
    MapService.prototype.getRegionAreaContainer = function (id) {
        return this._areaContainer.getRegion(id);
    };
    MapService.prototype.getMunicipalityAreaContainer = function (id) {
        return this._areaContainer.getMunicipality(id);
    };
    MapService.prototype.getParishAreaContainer = function (id) {
        return this._areaContainer.getParish(id);
    };
    MapService.prototype.getCountryAreaContainerByUrlParameter = function () {
        return this._areaContainer.getCountry();
    };
    MapService.prototype.getRegionAreaContainerByUrlParameter = function (regionUrlParameter) {
        return this._areaContainer.getRegionByUrlParameter(regionUrlParameter);
    };
    MapService.prototype.getMunicipalityAreaContainerByUrlParameter = function (regionUrlParameter, municipalityUrlParameter) {
        return this._areaContainer.getMunicipalityByUrlParameter(regionUrlParameter, municipalityUrlParameter);
    };
    MapService.prototype.getParishAreaContainerByUrlParameter = function (regionUrlParameter, municipalityUrlParameter, parishUrlParameter) {
        return this._areaContainer.getParishByUrlParameter(regionUrlParameter, municipalityUrlParameter, parishUrlParameter);
    };
    MapService.prototype.hasMunicipalityGeometriesByRegionId = function (regionId) {
        return this._areaContainer.hasMunicipalityGeometriesByRegionId(regionId);
    };
    MapService.prototype.hasParishGeometriesByRegionId = function (regionId) {
        return this._areaContainer.hasParishGeometriesByRegionId(regionId);
    };
    MapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapService_Factory() { return new MapService(i0.ɵɵinject(i1.DataAccessService), i0.ɵɵinject(i2.UtilityService)); }, token: MapService, providedIn: "root" });
    return MapService;
}());
export { MapService };
