import { Component, OnInit, Input } from '@angular/core';
import { IBaseArea } from './base-area';
import { MarkerService } from './marker.service';
import { MarkerType } from './marker-type';
import { MarkerTypeContainer } from './marker-type-container';

declare var $: any;

@Component({
    selector: 'app-categories-desktop',
    templateUrl: './categories-desktop.component.html',
    styleUrls: ['./categories-desktop.component.scss']
})
export class CategoriesDesktopComponent implements OnInit {
    @Input() area: IBaseArea;
    @Input() isLoading: boolean;

    categories: any;
    visibleCategories: any[] = [];

    isCollapsed: boolean = false;
    iconCollapseSide: string = 'left';
    iconCollapseBottom: string = 'up';

    constructor(private _markerService: MarkerService) {
    }

    ngOnInit(): void {
        this.categories = this._markerService.categories;
        this.visibleCategories = this._markerService.visibleCategories;
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (changes.area) {
    //         if (changes.area.currentValue.areaLevel == AreaLevel.Parish) {
    //             his.enableFacilitiesDropdownIfShown();
    //         }
    //     }
    // }

    clickCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed) {
            this.iconCollapseSide = 'right';
            this.iconCollapseBottom = 'down';
        }
        else {
            // this.enableFacilitiesDropdownIfShown();
            this.iconCollapseSide = 'left';
            this.iconCollapseBottom = 'up';
        }
    }

    clickIcon(markerType: MarkerType): void {
        let markerTypeContainer = new MarkerTypeContainer(markerType);
        var isMarkerShowAllSubCategoriesEnabled = this._markerService.getMarkerShowAllSubCategoriesEnabled(markerTypeContainer);
        this._markerService.toggleMarkerVisibility(markerTypeContainer, null, isMarkerShowAllSubCategoriesEnabled);
    }
}
