export class ImageDataContainer implements IImageDataContainer {
    name: string;
    url: string;
    image: ImageData;

    constructor(name: string, url: string, image: ImageData) {
        this.name = name;
        this.url = url;
        this.image = image;
    }
}

export interface IImageDataContainer {
    name: string;
    url: string;
    image: ImageData;
}
