import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MarkerService } from './marker.service';
import { MarkerItem } from './marker-item';
import { RouteDataService } from './route-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapService } from './map.service';

@Component({
    selector: '[app-categories-list-item-mobile]',
    templateUrl: './categories-list-item-mobile.component.html',
    styleUrls: ['./categories-list-item-mobile.component.scss']
})
export class CategoriesListItemMobileComponent implements OnInit, OnDestroy  {
    private _unsubscribe: Subject<void> = new Subject<void>();
    
    @Input() item: any;
    @Input() markerItem: MarkerItem;

    hasAddress: boolean = false;

    constructor(private _markerService: MarkerService, private _mapService: MapService, private _routeDataService: RouteDataService) {
    }

    ngOnInit(): void {
        this._mapService
            .onToggleLoadMarkers
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                this.hasAddress = this._routeDataService.getCurrentRouteData().hasAddress;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }



    // clickToggleCompare() {
    //     this._markerService.toggleCompare(this.item.id, this.markerType, this.markerSubType);
    // }

    clickToggleSelected() {
        this._markerService.toggleSelected(this.item.id, this.markerItem.markerTypeContainer);
    }
}
