import * as i0 from "@angular/core";
var ScrollService = /** @class */ (function () {
    function ScrollService() {
    }
    ScrollService.prototype.scrollToId = function (id, offset) {
        if (offset === void 0) { offset = 80; }
        var element = document.getElementById(id);
        var bodyRect = document.body.getBoundingClientRect().top;
        var elementRect = element.getBoundingClientRect().top;
        var elementPosition = elementRect - bodyRect;
        var offsetPosition = elementPosition - offset;
        $('html, body').stop().animate({ scrollTop: offsetPosition }, 300);
    };
    ScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScrollService_Factory() { return new ScrollService(); }, token: ScrollService, providedIn: "root" });
    return ScrollService;
}());
export { ScrollService };
