import { IZoom } from '../zoom/zoom';

export interface IBounds {
    bbox: [[number, number], [number, number]];
}

export class Bounds implements IBounds {
    bbox: [[number, number], [number, number]];

    constructor(bbox: [[number, number], [number, number]]) {
        this.bbox = bbox;
    }
    
    public getBboxWithZoom(zoom: IZoom): [[number, number], [number, number]] {
        let diffX = this.bbox[1][0] - this.bbox[0][0];
        let diffY = this.bbox[1][1] - this.bbox[0][1];

        let factor = Math.pow(zoom.factor, -zoom.level.current);
        return [
            [
                this.bbox[0][0] + diffX * (1 - factor),
                this.bbox[0][1] + diffY * (1 - factor),
            ],
            [
                this.bbox[1][0] - diffX * (1 - factor),
                this.bbox[1][1] - diffY * (1 - factor),
            ]
        ];
    }
}
