import { MarkerType } from './marker-type';
import { LayerType } from './layer-type';
import { MarkerTypeContainer } from './marker-type-container';
import { DataSourceType } from './data-source-type';
import { DataSourceTypeContainer } from './data-source-type-container';

export class MarkerItem {
    private readonly _baseIconUrl: string = '../../assets/images/';

    private _iconUrlEnabled: string;
    private _iconUrlDisabled: string;
    private _itemsAll: any[];           // Keeps track of items added at all time (to keep choises for isCompared across what is visible)

    markerTypeContainer: MarkerTypeContainer;

    id: string;
    layerTypeId: LayerType;
    iconUrl: string;
    iconUrlEnabled: string;
    showMarkers: boolean;
    isComparable: boolean;
    items: any[];
    selectedItems: any[];
    comparedItems: any[];
    comparedGroups: any[];
    isCompareEnabled: boolean;
    headline: string;
    dataSourceType: DataSourceType = DataSourceType.None;
    dataSourceTypeContainer: DataSourceTypeContainer;
    dataUpdated: any;

    layerInfo: any = {
        layerInfo: null,
        dataSourceId: null,
        layerComparisonId: null,
        layerMarkersId: null,

        centerSourceId: null,
        fillColor: null,
        hasClustering: false,
        layerClusteringId: null
    };

    constructor(markerType: MarkerType, markerSubType: number, dataSourceType: DataSourceType, isCompareEnabled: boolean, headline: string, iconName: string, iconSubFolder: string = null, hasClustering: boolean = false, layerTypeId: LayerType = LayerType.Marker, areaFillColor: string = null) {
        // this.markerType = markerType;
        // this.markerSubType = markerSubType;
        this.markerTypeContainer = new MarkerTypeContainer(markerType, markerSubType);
        this.dataSourceType = dataSourceType;
        this.id = 'markerItem_' + markerType.toString() + (!markerSubType ? '' : "_" + markerSubType.toString());
        this._iconUrlEnabled = this._baseIconUrl + (iconSubFolder != null ? iconSubFolder + '/' : '') + iconName + '.png';
        this._iconUrlDisabled = this._baseIconUrl + (iconSubFolder != null ? iconSubFolder + '/' : '') + 'disabled/' + iconName + '.png';

        this.layerTypeId = layerTypeId;
        this.iconUrl = this._iconUrlDisabled;
        this.iconUrlEnabled = this._iconUrlEnabled.substring(5);    // Lidt høker
        this.showMarkers = false;
        this.isComparable = false;
        this._itemsAll = [];
        this.items = [];
        this.selectedItems = [];
        this.comparedItems = [];
        this.comparedGroups = [];
        this.isCompareEnabled = isCompareEnabled;
        this.headline = headline;

        this.layerInfo = {
            imageId: 'img_' + this.id,
            dataSourceId: 'data_' + this.id,
            layerComparisonId: 'layerComparison_' + this.id,
            layerMarkersId: 'layerMarkers_' + this.id,
            centerSourceId: 'layerCenters_' + this.id,
            fillColor: areaFillColor,
            hasClustering: hasClustering,
            layerClusteringCircleId: 'layerClusteringCircle_' + this.id,
            layerClusteringCountId: 'layerClusteringCount_' + this.id
        }
    }

    public setDataSourceTypeContainer(dataSourceTypeContainer: DataSourceTypeContainer) {
        this.dataSourceTypeContainer = dataSourceTypeContainer;
    }

    public setDataUpdated(dataUpdated: any) {
        this.dataUpdated = dataUpdated;
    }

    public addItemIfNotExists(item: any) {
        // Adding item to the overall master collection where items are only added
        this.addItemIfNotExistsToCollection(item, this._itemsAll);

        // Finding either the item just added or an item added before with same id
        var itemExisting = this.findItemById(item.id, this._itemsAll);

        // Updating distance to address on item (important if item already existed)
        itemExisting.distanceToAddress = item.distanceToAddress;

        // Adding item from  _itemsAll collection
        this.addItemIfNotExistsToCollection(itemExisting, this.items);
    }

    public clearItems() {
        this.items.splice(0, this.items.length);
    }

    public toggleMarkerVisibility(isVisible: boolean): void {
        // If "isVisible" is not explicitly set, then set it to the opposite value of "showMarkers"
        if (isVisible == null)
            isVisible = !this.showMarkers;

        this.showMarkers = isVisible;
        if (isVisible)
            this.iconUrl = this._iconUrlEnabled;
        else
            this.iconUrl = this._iconUrlDisabled;
    }

    public toggleSelected(itemId: number): any {
        var item = this.findItemById(itemId, this.items);   // Find item in items list and add to selected list
        if (item != null) {
            item.isSelected = !item.isSelected;
            if (item.isSelected)
                this.addItemIfNotExistsToCollection(item, this.selectedItems);
            else
                this.removeItemFromCollection(item.id, this.selectedItems);

            return item;
        }

        return null;
    }

    public hasSelectedItem(): boolean {
        return this.selectedItems.length == 1;
    }

    public getSelectedItem(): any {
        if (this.selectedItems.length > 1)
            throw new Error('Number og selected items (' + this.selectedItems.length + ') for markerType ' + this.markerTypeContainer.markerType + ' is greater than 1');
        else if (this.selectedItems.length == 0)
            return null;
        else
            return this.selectedItems[0];
    }

    public clearSelected(): void {
        for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            item.isSelected = false;
        }

        this.selectedItems.splice(0, this.selectedItems.length);
    }

    public clearSelectedExcept(itemId: number): void {
        for (let i = 0; i < this.selectedItems.length; i++) {
            const item = this.selectedItems[i];
            if (item.id != itemId)
                item.isSelected = false;
        }

        this.selectedItems.splice(0, this.selectedItems.length);
    }

    public toggleCompare(itemId: number): boolean {
        var item = this.findItemById(itemId, this._itemsAll);   // Find item in _itemsAll list and add to compared list
        if (item != null) {
            item.isCompared = !item.isCompared;
            if (item.isCompared) {
                this.addItemIfNotExistsToCollection(item, this.comparedItems);
                this.addItemIfNotExistsToGroup(item, this.comparedGroups);
            }
            else {
                this.removeItemFromCollection(item.id, this.comparedItems);
                this.removeItemFromGroup(item, this.comparedGroups);
            }
        }

        // Settings "isComparable" as a side effect
        this.isComparable = this.comparedItems.length > 1;
        return item.isCompared;
    }

    public clearCompare(): void {
        for (let i = 0; i < this._itemsAll.length; i++) {
            const item = this._itemsAll[i];
            item.isCompared = false;
        }

        this.comparedItems.splice(0, this.comparedItems.length);
        this.comparedGroups.splice(0, this.comparedGroups.length);

        // Settings "isComparable" as a side effect
        this.isComparable = false;
    }

    public isItemCompared(itemId: number): boolean {
        return this.isItemInCollection({ id: itemId }, this.comparedItems);
    }




    private addItemIfNotExistsToGroup(item: any, groups: any[]) {
        // Add item to existing group
        var groupExists = false;
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            if (group.municipalityId == item.municipalityId) {
                groupExists = true;
                this.addItemIfNotExistsToCollection(item, group.items);
            }
        }

        // Create new group (if no existing group could be found)
        if (!groupExists) {
            groups.push({
                municipalityId: item.municipalityId,
                municipalityName: item.municipalityName,
                items: [item]
            });
        }
    }

    private removeItemFromGroup(item: any, groups: any[]) {
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            if (group.municipalityId == item.municipalityId) {
                this.removeItemFromCollection(item.id, group.items);

                // Remove group, if group is empty
                if (group.items.length == 0) {
                    groups.splice(i, 1);
                }

                break;
            }
        }
    }

    private findItemById(id: number, items: any[]): any {
        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            if (element.id == id)
                return element;
        }

        return null;
    }

    private isItemInCollection(item: any, items: any[]) {
        return this.findItemById(item.id, items) != null;
    }

    // Adding an item to a collection if it not already exists
    private addItemIfNotExistsToCollection(item: any, items: any[]) {
        if (this.isItemInCollection(item, items) == false)
            items.push(item);
    }

    // Removes an item from a collection
    private removeItemFromCollection(itemId: number, items: any[]) {
        for (var i = items.length - 1; i >= 0; i--) {
            if (items[i].id === itemId) {
                items.splice(i, 1);
                break;
            }
        }
    }
}