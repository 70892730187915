import { Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges, OnDestroy } from '@angular/core';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { ZoomService } from './zoom.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapService } from './map.service';
import { TopRightContentBoxDesktopService } from './top-right-content-box-desktop.service';

@Component({
    selector: 'app-zoom-controls',
    templateUrl: './zoom-controls.component.html',
    styleUrls: ['./zoom-controls.component.scss']
})
export class ZoomControlsComponent implements OnDestroy, OnChanges {
    private _unsubscribe: Subject<void> = new Subject<void>();
    private _jqZoomProgress: any = null;

    @Input() areaLevel: AreaLevel;

    constructor(private _zoomService: ZoomService, private _topRightContentBoxDesktopService: TopRightContentBoxDesktopService) { }

    ngAfterViewInit() {
        this._jqZoomProgress = $('app-zoom-controls .ui.progress')
            .progress({
                label: 'ratio'
            });

        this._zoomService.onZoomChange
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(() => {
                this.setZoom();
            });

        this._topRightContentBoxDesktopService.onBoxHeightChanged
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: { elementHeight: number, windowHeight: number }) => {
                let heightDifference = x.windowHeight - x.elementHeight;
                let container = $("#b-zoom-controls-container");
                let className = 'pushed';
                if (heightDifference < 180) {
                    container.addClass(className);
                }
                else {
                    container.removeClass(className);
                }
            });

        this.setZoom();
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.areaLevel) {
            this.setZoom();
        }
    }

    clickZoomIn(): void {
        this._jqZoomProgress.progress('increment');
        this._zoomService.emitZoomIn(this.areaLevel);
    }

    clickZoomOut(): void {
        this._jqZoomProgress.progress('decrement');
        this._zoomService.emitZoomOut(this.areaLevel);
    }

    private setZoom() {
        if (this._jqZoomProgress) {
            let zoom = this._zoomService.getZoomForArea(this.areaLevel);
            let level = zoom.level.zeroBased();
            this._jqZoomProgress.progress('set total', level.max);
            this._jqZoomProgress.progress('set progress', level.current);
        }
    }
}
