<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Persondatapolitik for ditomraade.dk</h1>

                        <p>Denne privatlivspolitik forklarer, hvordan Boligder ApS ("vi" eller "os") behandler dine personoplysninger.</p>

                        <h3>Dataansvarlig</h3>
                        <p>Den juridiske enhed, som er ansvarlig for behandlingen af dine personoplysninger er:</p>
                        <em>
                            Boligder ApS<br />
                            Lindehjørnet 14<br />
                            2605 Brøndby<br />
                            CVR: 39699524<br />
                            E-mail: <a href="mailto:info@boligder.dk">info@boligder.dk</a>
                        </em>

                        <h3>Brug af personoplysninger</h3>
                        <p>
                            Vi indhenter kun persondata i de tilfælde, hvor dette skulle være relevant for os, og vi vil kun indhente persondata, hvis det er relevant for din aktivitet hos os.
                            Ved indsamling, behandling og anvendelse af dine persondata overholder vi altid alle relevante lovbestemmelser.
                            <br />
                            <br />
                            Vi vil kun opbevare dine persondata, så længe vi enten er pålagt en juridisk forpligtelse hertil, eller så længe det er relevant for den hensigt, hvormed de blev indsamlet.
                        </p>


                        <h3>Oplysninger, vi indsamler</h3>
                        <p>
                            Hvis du ønsker at oprette en bruger på ditomraade.dk, har vi brug for at indsamle visse persondata for at kunne gennemføre brugeroprettelsen. Vi indhenter i denne forbindelse oplysninger som f.eks. navn, e-mailadresse,
                            postadresse, telefonnummer og eventuelle personlige ønsker for datafiltrering.
                            <br />
                            <br />
                            Vi indsamler og behandler dine persondata, når du foretager dig følgende:
                        </p>
                        <ul>
                            <li>
                                Besøger og bruger vores hjemmeside
                            </li>
                            <li>
                                Oprette en konto
                            </li>
                            <li>
                                Tilmelder dig vores nyhedsbrev
                            </li>
                            <li>
                                Sender os spørgsmål eller feedback
                            </li>
                        </ul>


                        <h3>Behandlingsgrundlag og formål</h3>
                        <p>
                            Dine almindelige kontaktoplysninger som navn og e-mailadresse indhenter vi for at kunne levere nyhedsbrev og notifikationer, som der abonneres på.
                            <br />
                            <br />
                            Hvis du under registreringsprocessen eller på vores hjemmeside har givet os dit samtykke hertil, bruger vi din e-mailadresse til udsendelse af et nyhedsbrev. Du kan til
                            enhver tid til- og afmelde dig nyhedsbrevet. Alle nyhedsbreve indeholder også et link til afmelding. Hvis behandling af persondata er baseret på dit samtykke, har du til enhver tid ret til at tilbagekalde samtykket.
                        </p>


                        <h3>Andre modtagere af personoplysninger</h3>
                        <p>
                            Vi kan sælge dine persondata til tredjemand. Vi har vores hjemmeside hos Hetzner Online GmbH ("databehandleren"), som fungerer som vores databehandler. Alle persondata, som du oplyser på vores hjemmeside, vil blive
                            opbevaret i databehandlerens datacentre.
                        </p>


                        <h3>Dine rettigheder</h3>
                        <p>
                            Som den registrerede har du en række rettigheder, som vi til enhver tid skal sikre opfyldelse af. Du har ret til at anmode os om følgende:
                        </p>
                        <ul>
                            <li>
                                At få adgang til og få rettet/ændret dine persondata
                            </li>
                            <li>
                                At få slettet dine persondata
                            </li>
                        </ul>

                        <p>
                            Du har derudover ret til at protestere over behandlingen af dine personlige data, og du har ret til at indgive en klage til en databeskyttelsesmyndighed.
                            <br />
                            <br />
                            Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen af dine personoplysninger, kan du også sende os en anmodning
                            herom til vores e-mailadresse <a href="mailto:info@boligder.dk">info@boligder.dk</a>.
                        </p>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>