import { Injectable } from '@angular/core';

import { SentryService } from './sentry.service';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HotjarService {
    constructor(private _sentryService: SentryService) { }

    startTracking() {
        if (environment.keys.hotjar.isEnabled) {
            this.appendTrackingCode();
        }
    }

    private appendTrackingCode() {
        try {
            const script = document.createElement('script');
            script.innerHTML = `
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:` + environment.keys.hotjar.key + `,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;
            document.head.appendChild(script);

        } catch (ex) {
            this._sentryService.captureException(new Error('Error appending hotjar'));
        }
    }
}
