import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
    private _cookieAcceptName: string = 'cookies_accepted';
    private _cookieRenewDateName: string = 'cookies_accepted_date';
    private _cookieAcceptValue: string = 'yes';

    isCookiesAccepted: boolean = true;

    constructor(private _localStorageService: LocalStorageService) {
    }

    ngOnInit() {
        this.evaluateIsCookiesAccepted();
    }

    acceptCookies() {
        var currentDate = this.getCurrentDate();

        this._localStorageService.setItem(this._cookieAcceptName, this._cookieAcceptValue);
        this._localStorageService.setItem(this._cookieRenewDateName, currentDate.formatted);
        this.evaluateIsCookiesAccepted();
    }

    private evaluateIsCookiesAccepted(): void {
        let consent = this._localStorageService.getItem(this._cookieAcceptName);
        let consentDate = this.getConsentDate();
        var currentDate = this.getCurrentDate();
        var diff = Math.abs(currentDate.date.getTime() - consentDate.date.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        if (consent == this._cookieAcceptValue && diffDays < 365)
            this.isCookiesAccepted = true;
        else
            this.isCookiesAccepted = false;
    }

    private getConsentDate(): { day: number, month: number, year: number, formatted: string, date: Date } {
        let consentDate = this._localStorageService.getItem(this._cookieRenewDateName);
        if (consentDate == null) {
            consentDate = '1970-1-1';
        }

        var dateParts = consentDate.split('-');

        return {
            day: +dateParts[2],
            month: +dateParts[1],
            year: +dateParts[0],
            formatted: consentDate,
            date: this.getDate(+dateParts[2], +dateParts[1], +dateParts[0])
        }
    }

    private getCurrentDate(): { day: number, month: number, year: number, formatted: string, date: Date } {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        return {
            day: dd,
            month: mm,
            year: yyyy,
            formatted: yyyy + '-' + mm + '-' + dd,
            date: today
        }
    }

    private getDate(day: number, month: number, year: number): Date {
        return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
    }
}
