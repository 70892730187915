<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Ditomraade.dk – beta-version</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column">
                        <h3>Hvad betyder beta?</h3>
                        <p>
                            Ditomraade.dk er stadig i beta-version. Det betyder, at hjemmesiden stadig bliver testet og udviklet – den officielle udgivelse finder sted engang i 2020.
                            Data på ditomraade.dk kan derfor visse steder være mangelfuld eller ikke opdateret. Opdatering af data sker løbende i løbet af beta-perioden.
                            <br />
                            <br />
                            Hvis du støder på fejl, manglende funktionalitet eller andre problemer på hjemmesiden, er du meget velkommen til at kontakte os, så vi kan udbedre problemet. Din hjælp er meget værdsat!
                        </p>

                        <h3>Roadmap</h3>
                        <p>
                            Vi arbejder løbende på at forbedre ditomraade.dk.
                            <br />
                            <br />
                            Se mere om, hvad vi arbejder på, under <a [routerLink]="['/roadmap']">roadmap</a>.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>