<div class="b-container" *ngIf="item">
    <div class="ui cards">
        <div class="card">
            <!-- Loader -->
            <div class="ui active inverted dimmer" *ngIf="isLoading">
                <div class="ui medium text loader">Henter data for {{itemName}}...</div>
            </div>

            <div class="content">
                <div class="right floated b-close-button-container">
                    <a class="ui label blue b-map-popup-compare-button" (click)="clickClose()">
                        <span>Luk</span>
                    </a>
                </div>
                <div class="header">
                    {{item.name}}
                </div>
                <div class="meta" *ngIf="markerItem.markerTypeContainer.isRealEstateType">
                    {{item.city}}
                </div>
                <div class="description">

                    <div id="b-selected-marker-item-flex-content-container" class="b-flex-content-container">

                        <div>
                            <div class="right floated" style="padding-top: 5px;" *ngIf="markerItem.isCompareEnabled">
                                <a class="ui label b-map-popup-compare-button" (click)="clickToggleCompare()" [ngClass]="{'active blue' : item.isCompared == true }">
                                    <i class="exchange icon"></i>
                                    <span>Sammenlign</span>
                                </a>
                            </div>

                            <div class="b-address-content"
                                *ngIf="false == markerItem.markerTypeContainer.isPublicTransportationType && false == markerItem.markerTypeContainer.isSupermarketType && false == markerItem.markerTypeContainer.isRealEstateType && false == markerItem.markerTypeContainer.isSchoolDistrictType && false == markerItem.markerTypeContainer.isTraficCountType">
                                <p><span *ngIf="item.address">{{item.address}}</span><span *ngIf="!item.address"><em>(ingen adresse tilgængelig)</em></span></p>
                                <p><span *ngIf="item.city">{{item.city}}</span><span *ngIf="!item.city"><em>(ingen by tilgængelig)</em></span></p>
                            </div>

                            <div class="b-distance-to-address-content" *ngIf="hasAddress && item.distanceToAddress">
                                <p><strong>Afstand:</strong> {{item.distanceToAddress | number: '1.2-2'}} km</p>
                            </div>
                        </div>

                        <div class="b-flex-content">

                            <div *ngIf="markerItem.markerTypeContainer.isDaycareType">
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span> <span *ngIf="item.phone">{{item.phone}}</span><span *ngIf="!item.phone">-</span></p>
                                    <p><span><strong>E-mail: </strong></span> <a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isPrivateDaycareType">
                                <div class="b-category-content">
                                    <p><span><strong>Dagplejer: </strong></span></p>
                                    <p><span *ngIf="item.managerName">{{item.managerName}}</span><span *ngIf="!item.managerName">-</span></p>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span><span *ngIf="item.mobile">{{item.mobile}}</span><span *ngIf="item.phone"> / {{item.phone}}</span><span *ngIf="!(item.mobile || item.phone)">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isPrimarySchoolType">
                                <div class="b-category-content">
                                    <p><span><strong>Skoleinspektør: </strong></span></p>
                                    <p><span *ngIf="item.managerName">{{item.managerName}}</span><span *ngIf="!item.managerName">-</span></p>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span><span *ngIf="item.phone">{{item.phone}}</span><span *ngIf="!item.phone">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>

                                <div class="b-category-content">

                                    <table class="b-table-primary-schools">
                                        <thead>
                                            <tr>
                                                <th>Statistik</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fraværsprocent</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.absense">{{item.latestKeyFigures.absense.value | number}} {{item.latestKeyFigures.absense.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.absense">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Karaktérgennemsnit</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.grade">{{item.latestKeyFigures.grade.value | number}} {{item.latestKeyFigures.grade.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.grade">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gymnasial ansøgningsrate</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.highSchoolApplicationRate">{{item.latestKeyFigures.highSchoolApplicationRate.value | number}} {{item.latestKeyFigures.highSchoolApplicationRate.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.highSchoolApplicationRate">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Inklusionsgrad</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.inclusionRate">{{item.latestKeyFigures.inclusionRate.value | number}} {{item.latestKeyFigures.inclusionRate.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.inclusionRate">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Socioøkonomisk reference</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.socialEconomicReference">{{item.latestKeyFigures.socialEconomicReference.value | number}} {{item.latestKeyFigures.socialEconomicReference.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.socialEconomicReference">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Andel lærerpersonale</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.staffDistribution">{{item.latestKeyFigures.staffDistribution.value | number}} {{item.latestKeyFigures.staffDistribution.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.staffDistribution">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Elever i alt</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.studentCountPerClass">{{item.latestKeyFigures.studentCountPerClass.value | number}} {{item.latestKeyFigures.studentCountPerClass.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.studentCountPerClass">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Elever med dansk oprindelse</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.studentCountPerOrigin">{{item.latestKeyFigures.studentCountPerOrigin.value | number}} {{item.latestKeyFigures.studentCountPerOrigin.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.studentCountPerOrigin">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Antal elever pr. lærerårsværk</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.studentsPerTeacherManHour">{{item.latestKeyFigures.studentsPerTeacherManHour.value | number}} {{item.latestKeyFigures.studentsPerTeacherManHour.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.studentsPerTeacherManHour">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gennemsnitsalder på lærere</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.teacherAverageAge">{{item.latestKeyFigures.teacherAverageAge.value | number}} {{item.latestKeyFigures.teacherAverageAge.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.teacherAverageAge">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ressourcer brugt på undervisning</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.teaching">{{item.latestKeyFigures.teaching.value | number}} {{item.latestKeyFigures.teaching.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.teaching">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Trivselsindikator (1-5)</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.wellbeing">{{item.latestKeyFigures.wellbeing.value | number}} {{item.latestKeyFigures.wellbeing.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.wellbeing">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Undervisningstimer pr. år pr. klassetrin</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.latestKeyFigures.yearlyLessonHoursPerLevel">{{item.latestKeyFigures.yearlyLessonHoursPerLevel.value | number}} {{item.latestKeyFigures.yearlyLessonHoursPerLevel.unit}}</span>
                                                    <span *ngIf="!item.latestKeyFigures.yearlyLessonHoursPerLevel">-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isHighSchoolType">
                                <div class="b-category-content">
                                    <p><span><strong>Rektor: </strong></span></p>
                                    <p><span *ngIf="item.managerName">{{item.managerName}}</span><span *ngIf="!item.managerName">-</span></p>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span><span *ngIf="item.phone">{{item.phone}}</span><span *ngIf="!item.phone">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                                <div class="b-category-content b-results-container-high-schools" *ngIf="item.educationKeyFigures.length > 0">
                                    <div>
                                        <p><strong>Resultater:</strong></p>
                                    </div>

                                    <div class="b-table-container-high-schools" *ngFor="let educationKeyFigure of item.educationKeyFigures">
                                        <table class="b-table-high-schools">
                                            <thead>
                                                <tr>
                                                    <th>{{educationKeyFigure.educationName}}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Eksamensresultat</td>
                                                    <td class="right aligned">
                                                        <span *ngIf="educationKeyFigure.latestKeyFigures.grade">{{educationKeyFigure.latestKeyFigures.grade.value | number}} {{educationKeyFigure.latestKeyFigures.grade.unit}}</span>
                                                        <span *ngIf="!educationKeyFigure.latestKeyFigures.grade">-</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Socioøkonomiske reference</td>
                                                    <td class="right aligned">
                                                        <span *ngIf="educationKeyFigure.latestKeyFigures.socialEconomicReference">{{educationKeyFigure.latestKeyFigures.socialEconomicReference.value | number}}
                                                            {{educationKeyFigure.latestKeyFigures.socialEconomicReference.unit}}</span>
                                                        <span *ngIf="!educationKeyFigure.latestKeyFigures.socialEconomicReference">-</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Trivsel (1-5)</td>
                                                    <td class="right aligned">
                                                        <span *ngIf="educationKeyFigure.latestKeyFigures.wellbeing">{{educationKeyFigure.latestKeyFigures.wellbeing.value | number}} {{educationKeyFigure.latestKeyFigures.wellbeing.unit}}</span>
                                                        <span *ngIf="!educationKeyFigure.latestKeyFigures.wellbeing">-</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isFacilityType">
                                <div class="b-category-content" *ngIf="item.facilityPlacement > 0">
                                    <p><span><strong>Placering: </strong></span><span *ngIf="item.facilityPlacementName">{{item.facilityPlacementName}}</span><span *ngIf="!item.facilityPlacementName">-</span></p>
                                </div>
                                <div class="b-category-content" *ngIf="item.courses.length > 0">
                                    <p><span><strong>Baner: </strong></span></p>
                                    <div *ngFor="let couse of item.courses">
                                        {{couse.name}}: {{couse.value}}
                                    </div>
                                </div>
                                <div class="b-category-content" *ngIf="item.externalRemarks">
                                    <p><span><strong>Bemærkninger: </strong></span></p>
                                    <p>{{item.externalRemarks}}</p>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Ejerskab: </strong></span><span *ngIf="item.ownership">{{item.ownership}}</span><span *ngIf="!item.ownership">-</span></p>
                                    <p><span><strong>Drift: </strong></span><span *ngIf="item.operation">{{item.operation}}</span><span *ngIf="!item.operation">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isSportsUnionType">
                                <div class="b-category-content" *ngIf="item.activities.length > 0">
                                    <p><span><strong>Aktiviteter: </strong></span></p>
                                    <div *ngFor="let activity of item.activities">
                                        {{activity}}
                                    </div>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span><span *ngIf="item.phone">{{item.phone}}</span><span *ngIf="!item.phone">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isPublicTransportationType">
                                <div class="b-category-content b-public-transportation-container" *ngFor="let routeGroup of item.routeGroups">
                                    <p><span><strong>{{routeGroup.typeName}}:</strong></span></p>
                                    <button *ngFor="let route of routeGroup.routes" class="ui button mini b-public-transportation-item" [ngClass]="{
                                            'yellow' : routeGroup.type == 3, 
                                            'olive' : routeGroup.type == 2, 
                                            'red' : routeGroup.type == 109, 
                                            'purple' : routeGroup.type == 1, 
                                            'teal' : routeGroup.type == 0, 
                                            'blue' : routeGroup.type == 4}">
                                        {{route.name}}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isDoctorType">
                                <div class="b-category-content" *ngIf="item.staff.length > 0">
                                    <p><span><strong>Personale: </strong></span></p>
                                    <div *ngFor="let staff of item.staff">
                                        {{staff.name}}
                                    </div>
                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Tlf: </strong></span><span *ngIf="item.phone">{{item.phone}}</span><span *ngIf="!item.phone">-</span></p>
                                    <p><span><strong>Hjemmeside: </strong></span><a href="{{item.website}}" target="_blank" *ngIf="item.website">Link</a><span *ngIf="!item.website">-</span></p>
                                    <p><span><strong>E-mail: </strong></span><a href="mailto:{{item.email}}" *ngIf="item.email">Link</a><span *ngIf="!item.email">-</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isSupermarketType">
                                <div class="b-category-content">
                                    <p><span>Yderligere information om adresse, åbningstider, m.m. er på vej...</span></p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isRealEstateType">

                                <div class="b-category-content">

                                    <table class="b-table-real-estate">
                                        <thead>
                                            <tr>
                                                <th>Basisinformationer</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Boligtype</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.itemTypeName">{{item.itemTypeName}}</span>
                                                    <span *ngIf="!item.itemTypeName">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Kontantpris</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.paymentCash">{{item.paymentCash | number}} kr.</span>
                                                    <span *ngIf="!item.paymentCash">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Udbetaling</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.downPayment">{{item.downPayment | number}} kr.</span>
                                                    <span *ngIf="!item.downPayment">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Samlet boligareal</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.areaResidential">{{item.areaResidential | number}} m<sup>2</sup></span>
                                                    <span *ngIf="!item.areaResidential">-</span>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>Kælderareal</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.areaBasement">{{item.areaBasement | number}} m<sup>2</sup></span>
                                                    <span *ngIf="!item.areaBasement">-</span>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td>Grundareal</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.areaParcel">{{item.areaParcel | number}} m<sup>2</sup></span>
                                                    <span *ngIf="!item.areaParcel">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Antal værelser</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.numberOfRooms">{{item.numberOfRooms | number}}</span>
                                                    <span *ngIf="!item.numberOfRooms">-</span>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td>Antal etager</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.numberOfFloors">{{item.numberOfFloors | number}}</span>
                                                    <span *ngIf="!item.numberOfFloors">-</span>
                                                </td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td>Byggeår</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.buildYear">{{item.buildYear}}</span>
                                                    <span *ngIf="!item.buildYear">-</span>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td>Liggetid</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.salesPeriod">{{item.salesPeriod | number}} dage</span>
                                                    <span *ngIf="!item.salesPeriod">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Salgsannonce oprettet</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.dateAnnounced">{{item.dateAnnounced | date}}</span>
                                                    <span *ngIf="!item.dateAnnounced">-</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>

                                </div>
                                <div class="b-category-content">
                                    <p><span><strong>Mægler: </strong></span></p>
                                    <p><span *ngIf="item.agentChainName">{{item.agentChainName}}</span><span *ngIf="!item.agentChainName">-</span></p>
                                    <p><span></span><a href="{{item.url}}" target="_blank" *ngIf="item.url">Link til salgsannonce</a><span *ngIf="!item.url">-</span></p>
                                </div>

                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isSchoolDistrictType">
                                <div class="b-category-content" *ngIf="item.levelStartName && item.levelEndName">
                                    <p><span><strong>Klasstrin: </strong></span></p>
                                    <p>{{item.levelStartName}} til {{item.levelEndName}}</p>
                                </div>
                            </div>
                            <div *ngIf="markerItem.markerTypeContainer.isTraficCountType">
                                <div class="b-category-content">

                                    <table class="b-table-primary-schools">
                                        <thead>
                                            <tr>
                                                <th>Statistik</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <app-text-with-tooltip [text]="'Årsdøgnstrafik'" [tooltip]="'Gennemsnitligt antal køretøjer pr. døgn taget over alle dage i året'"></app-text-with-tooltip>
                                                </td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.yearDayTrafic">{{item.yearDayTrafic | number}} køretøjer/døgn</span>
                                                    <span *ngIf="!item.yearDayTrafic">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <app-text-with-tooltip [text]="'Hverdagsdøgntrafik'" [tooltip]="'Gennemsnitligt antal køretøjer pr. døgn taget over alle hverdage i året. Weekender, sommerferie-periode og helligdage indgår ikke'"></app-text-with-tooltip>
                                                </td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.weekdayDayTrafic">{{item.weekdayDayTrafic | number}} køretøjer/døgn</span>
                                                    <span *ngIf="!item.weekdayDayTrafic">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <app-text-with-tooltip [text]="'Julidøgnstrafik'" [tooltip]="'Trafikken på en dag i juli'"></app-text-with-tooltip>
                                                </td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.julyDayTrafic">{{item.julyDayTrafic | number}} køretøjer/døgn</span>
                                                    <span *ngIf="!item.julyDayTrafic">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Tung trafik</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.truckPct">{{item.truckPct | number}} %</span>
                                                    <span *ngIf="!item.truckPct">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Gennemsnitshastighed</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.averageSpeed">{{item.averageSpeed | number}} km/t</span>
                                                    <span *ngIf="!item.averageSpeed">-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Hastighedsoverskridelser</td>
                                                <td class="right aligned">
                                                    <span *ngIf="item.speedingPct">{{item.speedingPct | number}} %</span>
                                                    <span *ngIf="!item.speedingPct">-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>

                                <div class="b-category-content">
                                    <p><span><strong>Opgørelsesår: </strong></span><span *ngIf="item.year">{{item.year}}</span><span *ngIf="!item.year">-</span></p>
                                    <p><span><strong>Opgørelsestid: </strong></span><span *ngIf="item.year">{{item.countDays | number}} dage</span><span *ngIf="!item.countDays">-</span></p>
                                </div>

                            </div>
                        </div>

                        <div *ngIf="markerItem.dataSourceType > 0" class="b-data-source-container">
                            <p><span class="text small">Kilde: {{markerItem.dataSourceTypeContainer.description}}</span></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>