<div class="b-checkbox-container" *ngIf="baseMarkerItem.showMarkers">
    <div class="b-checkbox-header">
        <p><strong>Vælg {{baseMarkerItem.headlineLowered}}</strong></p>
    </div>
    <div class="b-checkbox-body">
        <div class="b-checkbox-body-header">
            <p>{{baseMarkerItem.dropdownHeader}}</p>
        </div>
        <div class="ui divider"></div>
        <div class="ui form">

            <div class="field" *ngFor="let subCategory of baseMarkerItem.subCategories">
                <div class="ui checkbox {{elementId}}">
                    <input type="checkbox" name="{{elementId}}" tabindex="0" [attr.data-item-id]="subCategory.id" class="hidden">
                    <label>{{subCategory.name}}</label>
                </div>
            </div>

        </div>
    </div>
</div>