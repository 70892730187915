
        <div class="b-overview__segment-inner" (click)="clickMenuItem(fragment)">
            <div>
                <h4 class="b-overview__segment-header">
                    {{item.headline}}
                </h4>
            </div>
            <div class="b-overview__segment-pie-chart">
                <canvas baseChart 
                    [datasets]="item.chart.dataSets" 
                    [labels]="item.chart.labels"
                    [colors]="settings.colors" [chartType]="settings.type"
                    [options]="settings.options"></canvas>
            </div>
        </div>
    