/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./categories-list-item-desktop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./categories-list-item-desktop.component";
import * as i4 from "./marker.service";
import * as i5 from "./map.service";
import * as i6 from "./route-data.service";
var styles_CategoriesListItemDesktopComponent = [i0.styles];
var RenderType_CategoriesListItemDesktopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoriesListItemDesktopComponent, data: {} });
export { RenderType_CategoriesListItemDesktopComponent as RenderType_CategoriesListItemDesktopComponent };
function View_CategoriesListItemDesktopComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui toggle checkbox right floated"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickToggleCompare() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "checked": 0, "with-address": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "label", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ui toggle checkbox right floated"; var currVal_1 = _ck(_v, 3, 0, _co.item.isOnCompareList, _co.hasAddress); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.isCompared; _ck(_v, 4, 0, currVal_2); }); }
function View_CategoriesListItemDesktopComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "b-small-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "text small"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " km"])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _co.item.distanceToAddress, "1.2-2")); _ck(_v, 2, 0, currVal_0); }); }
function View_CategoriesListItemDesktopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "b-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesListItemDesktopComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "b-body"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickToggleSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "active": 0, "faded": 1 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "b-default-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesListItemDesktopComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCompareEnabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = "b-body"; var currVal_2 = _ck(_v, 6, 0, _co.item.isSelected, ((false == !!_co.item.isSelected) && (_co.markerItem.selectedItems.length > 0))); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_4 = _co.hasAddress; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.item.name; _ck(_v, 9, 0, currVal_3); }); }
export function View_CategoriesListItemDesktopComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesListItemDesktopComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CategoriesListItemDesktopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-categories-list-item-desktop", ""]], null, null, null, View_CategoriesListItemDesktopComponent_0, RenderType_CategoriesListItemDesktopComponent)), i1.ɵdid(1, 245760, null, 0, i3.CategoriesListItemDesktopComponent, [i4.MarkerService, i5.MapService, i6.RouteDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoriesListItemDesktopComponentNgFactory = i1.ɵccf("[app-categories-list-item-desktop]", i3.CategoriesListItemDesktopComponent, View_CategoriesListItemDesktopComponent_Host_0, { markerItem: "markerItem", item: "item", isCompareEnabled: "isCompareEnabled" }, {}, []);
export { CategoriesListItemDesktopComponentNgFactory as CategoriesListItemDesktopComponentNgFactory };
