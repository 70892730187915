/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./feedback.component";
import * as i4 from "../../services/utility.service";
import * as i5 from "../../services/dataaccess.service";
import * as i6 from "@angular/router";
var styles_FeedbackComponent = [i0.styles];
var RenderType_FeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackComponent, data: {} });
export { RenderType_FeedbackComponent as RenderType_FeedbackComponent };
export function View_FeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "b-site-feedback-button-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "basic circular ui icon button"], ["data-duration", "100"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickShow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "yellow smile icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0Feedback "])), (_l()(), i1.ɵeld(4, 0, null, null, 28, "div", [["class", "transition hidden b-site-feedback-container"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 27, "div", [["class", "ui card"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "b-close-button-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "ui circular blue icon big button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "icon close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 16, "div", [["class", "content"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hvordan vil du vurdere din oplevelse af siden?"])), (_l()(), i1.ɵeld(12, 0, null, null, 13, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "ui massive star rating"], ["data-max-rating", "5"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 11, "div", [["class", "b-textarea-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["class", "ui form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 6, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 5, "textarea", [["placeholder", "Fort\u00E6l os om din oplevelse..."], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.feedback.comment = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(22, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 2, "div", [["class", "b-send-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 1, "button", [["class", "ui primary large button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickSend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Send "])), (_l()(), i1.ɵeld(26, 0, null, null, 6, "div", [["class", "content b-finish-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 3, "div", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "div", [["class", "b-finish-button-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 1, "button", [["class", "ui primary large button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Luk "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Tak for din feedback!"]))], function (_ck, _v) { var _co = _v.component; var currVal_15 = _co.feedback.comment; _ck(_v, 20, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.feedbackStep != 0); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.feedbackButtonId, ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.feedbackContainerId, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.feedbackStep == 0); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.feedbackStep == 3); _ck(_v, 6, 0, currVal_4); var currVal_5 = (_co.feedbackStep == 3); _ck(_v, 9, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.feedbackRatingId, ""); _ck(_v, 13, 0, currVal_6); var currVal_7 = ((_co.feedbackStep != 2) && (false == _co.isRated)); _ck(_v, 14, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 22).ngClassValid; var currVal_13 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = (_co.feedbackStep != 3); _ck(_v, 26, 0, currVal_16); }); }
export function View_FeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback", [], null, null, null, View_FeedbackComponent_0, RenderType_FeedbackComponent)), i1.ɵdid(1, 4308992, null, 0, i3.FeedbackComponent, [i4.UtilityService, i5.DataAccessService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackComponentNgFactory = i1.ɵccf("app-feedback", i3.FeedbackComponent, View_FeedbackComponent_Host_0, {}, {}, []);
export { FeedbackComponentNgFactory as FeedbackComponentNgFactory };
