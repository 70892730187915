import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RouteDataService } from '../../route-data.service';
import { MarkerService } from '../../marker.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: '[app-comparison-primary-school]',
    templateUrl: './comparison-primary-school.component.html',
    styleUrls: ['./comparison-primary-school.component.scss']
})
export class ComparisonPrimarySchoolComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();
    
    @Input() items: any[];
    @Input() dateUpdated: any;
    
    hasAddress: boolean = false;

    constructor(private _markerService: MarkerService, private _routeDataService: RouteDataService) { }

    ngOnInit() {
        this.hasAddress = this._routeDataService.getCurrentRouteData().hasAddress;

        this._markerService.onToggleComparisonVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                this.hasAddress = this._routeDataService.getCurrentRouteData().hasAddress;
            })
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }
}
