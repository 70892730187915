import { MarkerType } from './marker-type';
import { FeatureCollection, IFeatureCollection } from '../shared/types/geometry/featureCollection';
import { MarkerFacilityType } from './marker-facility-type';
import { Coordinate } from '../shared/types/geometry/coordinate';
import { PointGeometry } from '../shared/types/geometry/point';
import { Feature } from '../shared/types/geometry/feature';
import { MarkerTypeContainer } from './marker-type-container';
import { MarkerPrimarySchoolType } from './marker-primary-school-type';
import { MarkerHighSchoolType } from './marker-high-school-type';
import { MarkerDaycareType } from './marker-daycare-type';
import { MarkerRealEstateType } from './marker-realestate-type';
import { MarkerSchoolDistrictType } from './marker-school-district-type';
import { MultiPolygonGeometry } from '../shared/types/geometry/multiPolygon';

export interface IMarkerContainer {
    marker: any;
    updateMarkerItems(items: any[], markerTypeContainer: MarkerTypeContainer): any;

    getData(coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): any;
    addData(data: any, coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): void;
    hasData(coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): boolean;
}

export class MarkerContainer implements IMarkerContainer {
    private _daycare = {
        age0to2: <{ [key: string]: any; }>{},
        age3to5: <{ [key: string]: any; }>{},
        age0to5: <{ [key: string]: any; }>{},
    };
    private _primarySchool = {
        government: <{ [key: string]: any; }>{},
        private: <{ [key: string]: any; }>{},
        boarding: <{ [key: string]: any; }>{},
    };
    private _highSchool = {
        government: <{ [key: string]: any; }>{},
        private: <{ [key: string]: any; }>{},
        studentPreparation: <{ [key: string]: any; }>{},
    };
    private _privateDaycare: { [key: string]: any; } = {};
    private _publicTransportation: { [key: string]: any; } = {};
    private _supermarket: { [key: string]: any; } = {};
    private _noise: { [key: string]: any; } = {};
    private _traficCount: { [key: string]: any; } = {};
    private _doctor: { [key: string]: any; } = {};
    private _realEstate = {
        cooperativeDwelling: <{ [key: string]: any; }>{},
        ownerOccupiedFlat: <{ [key: string]: any; }>{},
        recreationalProperty: <{ [key: string]: any; }>{},
        recreationalPlot: <{ [key: string]: any; }>{},
        allYearRoundPlot: <{ [key: string]: any; }>{},
        allotment: <{ [key: string]: any; }>{},
        ruralProperty: <{ [key: string]: any; }>{},
        pleasureProperty: <{ [key: string]: any; }>{},
        townHouse: <{ [key: string]: any; }>{},
        brickHouse: <{ [key: string]: any; }>{},
        brickHouseApartment: <{ [key: string]: any; }>{},
    };
    private _residentsForRent: { [key: string]: any; } = {};
    private _sportsUnion: { [key: string]: any; } = {};
    private _facility = {
        badminton: <{ [key: string]: any; }>{},
        curling: <{ [key: string]: any; }>{},
        fitness: <{ [key: string]: any; }>{},
        golf: <{ [key: string]: any; }>{},
        horseriding: <{ [key: string]: any; }>{},
        icehockey: <{ [key: string]: any; }>{},
        olympicsite: <{ [key: string]: any; }>{},
        shootingrange: <{ [key: string]: any; }>{},
        soccer: <{ [key: string]: any; }>{},
        stadiumLarge: <{ [key: string]: any; }>{},
        stadiumMedium: <{ [key: string]: any; }>{},
        stadiumSmall: <{ [key: string]: any; }>{},
        swimming: <{ [key: string]: any; }>{},
        tennisIndoor: <{ [key: string]: any; }>{},
        tennisOutdoor: <{ [key: string]: any; }>{},
    };
    private _schoolDistrict = {
        preSchool: <{ [key: string]: any; }>{},
        class0: <{ [key: string]: any; }>{},
        class1: <{ [key: string]: any; }>{},
        class2: <{ [key: string]: any; }>{},
        class3: <{ [key: string]: any; }>{},
        class4: <{ [key: string]: any; }>{},
        class5: <{ [key: string]: any; }>{},
        class6: <{ [key: string]: any; }>{},
        class7: <{ [key: string]: any; }>{},
        class8: <{ [key: string]: any; }>{},
        class9: <{ [key: string]: any; }>{},
        class10: <{ [key: string]: any; }>{},
    };

    public marker: any = {
        daycare: {
            age0to2: {
                markerType: MarkerType.Daycare,
                markerSubType: MarkerDaycareType.Age0to2,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            age3to5: {
                markerType: MarkerType.Daycare,
                markerSubType: MarkerDaycareType.Age3to5,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            age0to5: {
                markerType: MarkerType.Daycare,
                markerSubType: MarkerDaycareType.Age0to5,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
        },
        primarySchool: {
            government: {
                markerType: MarkerType.PrimarySchool,
                markerSubType: MarkerPrimarySchoolType.Government,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            private: {
                markerType: MarkerType.PrimarySchool,
                markerSubType: MarkerPrimarySchoolType.Private,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            boarding: {
                markerType: MarkerType.PrimarySchool,
                markerSubType: MarkerPrimarySchoolType.Boarding,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
        },
        highSchool: {
            government: {
                markerType: MarkerType.HighSchool,
                markerSubType: MarkerHighSchoolType.Government,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            private: {
                markerType: MarkerType.HighSchool,
                markerSubType: MarkerHighSchoolType.Private,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            studentPreparation: {
                markerType: MarkerType.HighSchool,
                markerSubType: MarkerHighSchoolType.StudentPreparation,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
        },
        privateDaycare: {
            markerType: MarkerType.PrivateDaycare,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        publicTransportation: {
            markerType: MarkerType.PublicTransportation,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        supermarket: {
            markerType: MarkerType.Supermarket,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        noise: {
            markerType: MarkerType.Noise,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        traficCount: {
            markerType: MarkerType.TraficCount,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        doctor: {
            markerType: MarkerType.Doctor,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        realEstate: {
            cooperativeDwelling: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.CooperativeDwelling,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            ownerOccupiedFlat: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.OwnerOccupiedFlat,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            recreationalProperty: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.RecreationalProperty,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            recreationalPlot: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.RecreationalPlot,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            allYearRoundPlot: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.AllYearRoundPlot,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            allotment: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.Allotment,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            ruralProperty: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.RuralProperty,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            pleasureProperty: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.PleasureProperty,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            townHouse: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.TownHouse,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            brickHouse: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.BrickHouse,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            brickHouseApartment: {
                markerType: MarkerType.RealEstate,
                markerSubType: MarkerRealEstateType.BrickHouseApartment,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
        },
        residentsForRent: {
            markerType: MarkerType.ResidentsForRent,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        sportsUnion: {
            markerType: MarkerType.SportsUnion,
            featureCollection: new FeatureCollection() as IFeatureCollection,
        },
        facility: {
            badminton: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Badminton,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            curling: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Curling,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            fitness: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Fitness,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            golf: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Golf,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            horseriding: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Horseriding,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            icehockey: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Icehockey,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            olympicsite: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Olympicsite,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            shootingrange: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Shootingrange,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            soccer: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Soccer,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            stadiumLarge: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.StadiumLarge,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            stadiumMedium: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.StadiumMedium,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            stadiumSmall: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.StadiumSmall,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            swimming: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.Swimming,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            tennisIndoor: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.TennisIndoor,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            },
            tennisOutdoor: {
                markerType: MarkerType.Facility,
                markerSubType: MarkerFacilityType.TennisOutdoor,
                featureCollection: new FeatureCollection() as IFeatureCollection,
            }
        },
        schoolDistrict: {
            preSchool: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.PreSchool,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class0: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class0,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class1: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class1,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class2: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class2,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class3: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class3,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class4: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class4,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class5: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class5,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class6: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class6,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class7: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class7,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class8: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class8,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class9: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class9,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
            class10: {
                markerType: MarkerType.SchoolDistrict,
                markerSubType: MarkerSchoolDistrictType.Class10,
                featureCollection: new FeatureCollection() as IFeatureCollection,
                center: new FeatureCollection() as IFeatureCollection,
            },
        }
    }

    updateMarkerItems(items: any[], markerTypeContainer: MarkerTypeContainer): any {
        let marker = this.getMarker(markerTypeContainer);

        let featureCollection: IFeatureCollection = marker.featureCollection;
        featureCollection.clearFeatures();

        let center: IFeatureCollection;
        if (marker.center) {
            center = marker.center;
            center.clearFeatures();
        }

        if (items) {
            for (const item of items) {
                // Main feature
                let geometry = this.getGeometry(item.geometry);
                let properties = { id: item.id, markerType: markerTypeContainer.markerType, markerSubType: markerTypeContainer.markerSubType };
                let feature = new Feature(geometry, properties, item.id);
                featureCollection.addFeature(feature);

                // Center
                if (geometry.type == 'MultiPolygon') {
                    let g = <MultiPolygonGeometry>geometry;
                    let p = { id: item.id, name: item.name };
                    let f = new Feature(g.visualCenter, p);
                    center.addFeature(f);
                }
            }
        }
    }

    getData(coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): any {
        let key = this.getKeyFromCoordinate(coordinate);
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            return this[prop][key];
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            return this[prop][subProp][key];
        }
    }

    addData(data: any, coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): void {
        // TODO: Do not cache forever, as the cache could get quite large if a user was using the site for a long while without doing a hard refresh. Therefore, cashe should have a timeout or a max size.
        let key = this.getKeyFromCoordinate(coordinate);
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            this[prop][key] = data;
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            this[prop][subProp][key] = data;
        }
    }

    hasData(coordinate: Coordinate, markerTypeContainer: MarkerTypeContainer): boolean {
        let key = this.getKeyFromCoordinate(coordinate);
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            return key in this[prop];
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            return key in this[prop][subProp];
        }
    }

    private getGeometry(geometry: any): PointGeometry | MultiPolygonGeometry {
        if (geometry.type == 'MultiPolygon')
            return new MultiPolygonGeometry(geometry.coordinates, true);
        else
            return new PointGeometry(geometry.coordinates);
    }

    private getKeyFromCoordinate(coordinate: Coordinate): string {
        return coordinate.lng.toString().replace(".", "_") + "__" + coordinate.lat.toString().replace(".", "_");
    }

    private getMarker(markerTypeContainer: MarkerTypeContainer): any {
        let prop = markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            return this.marker[prop];
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            return this.marker[prop][subProp];
        }
    }
}