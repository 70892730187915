import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MarkerItem } from './marker-item';
import { MarkerService } from './marker.service';
import { takeUntil } from 'rxjs/operators';
import { RouteDataService } from './route-data.service';
import { MapService } from './map.service';

declare var $: any;

@Component({
    selector: 'app-selected-marker-item-box-desktop',
    templateUrl: './selected-marker-item-box-desktop.component.html',
    styleUrls: ['./selected-marker-item-box-desktop.component.scss']
})
export class SelectedMarkerItemBoxDesktopComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();

    isLoading: boolean = false;
    itemName: string = null;

    item: any = null;
    markerItem: MarkerItem = null;
    hasAddress: boolean = false;

    constructor(private _markerService: MarkerService, private _mapService: MapService, private _routeDataService: RouteDataService) { }

    ngOnInit(): void {
        this._markerService.onToggleSelect
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (x == null)
                    return;

                this.hasAddress = this._routeDataService.getCurrentRouteData().hasAddress;
                let markerItem = (<MarkerItem>x.markerItem);
                let item = markerItem.getSelectedItem();
                if (item != null) {
                    // this.markerItem = x.markerItem;
                    // this.item = item;

                    this.itemName = x.item.name;
                    this.markerItem = x.markerItem;

                    if (markerItem.markerTypeContainer.isRealEstateType) {
                        // Only set loading if we need to do any loading
                        if (false == this._mapService.hasDetailsData(markerItem.markerTypeContainer, item.id)) {
                            this.isLoading = true;
                        }

                        this._mapService.loadDetailsData(markerItem.markerTypeContainer, item.id)
                            .pipe(takeUntil(this._unsubscribe))
                            .subscribe((details: any) => {
                                this.item = details.item;
                                this.isLoading = false;
                            });
                    }
                    else {
                        this.item = item;
                    }
                }
                else {
                    this.markerItem = null;
                    this.item = null;
                }

                // Adjust flex container max height
                setTimeout(() => {
                    let topRowElement = $('#b-top-right-content-box-desktop-first-row')
                    let topRowElementHeight = topRowElement.outerHeight();
                    let adjustedHeight = topRowElementHeight + 144;

                    let container = $('#b-selected-marker-item-flex-content-container')
                    container.css({ "maxHeight": `calc(100vh - ${adjustedHeight}px)` });
                })
            })
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    clickClose() {
        this._markerService.toggleSelected(this.item.id, this.markerItem.markerTypeContainer)
    }

    clickToggleCompare() {
        this._markerService.toggleCompare(this.item.id, this.markerItem.markerTypeContainer)
    }

    clickToggleComparisonVisibility() {
        this._markerService.toggleComparisonVisibility(this.markerItem.markerTypeContainer)
    }

}
