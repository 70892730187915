/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./table-cell.component";
var styles_TableCellComponent = [i0.styles];
var RenderType_TableCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableCellComponent, data: {} });
export { RenderType_TableCellComponent as RenderType_TableCellComponent };
function View_TableCellComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.item.primary.value, "1.0")); var currVal_1 = _co.item.primary.unit; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TableCellComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_TableCellComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "text small"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.item.secondary.value, "1.0")); var currVal_1 = _co.item.secondary.unit; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_TableCellComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text small"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_TableCellComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableCellComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableCellComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableCellComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableCellComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.primary; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.item.primary; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.item.secondary; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.item.secondary; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_TableCellComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableCellComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-cell", [], null, null, null, View_TableCellComponent_0, RenderType_TableCellComponent)), i1.ɵdid(1, 49152, null, 0, i3.TableCellComponent, [], null, null)], null, null); }
var TableCellComponentNgFactory = i1.ɵccf("app-table-cell", i3.TableCellComponent, View_TableCellComponent_Host_0, { item: "item" }, {}, []);
export { TableCellComponentNgFactory as TableCellComponentNgFactory };
