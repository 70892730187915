/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popup-desktop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./popup-desktop.component";
import * as i4 from "./marker.service";
var styles_PopupDesktopComponent = [i0.styles];
var RenderType_PopupDesktopComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopupDesktopComponent, data: {} });
export { RenderType_PopupDesktopComponent as RenderType_PopupDesktopComponent };
function View_PopupDesktopComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ui card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_PopupDesktopComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PopupDesktopComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PopupDesktopComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_PopupDesktopComponent_0, RenderType_PopupDesktopComponent)), i1.ɵdid(1, 49152, null, 0, i3.PopupDesktopComponent, [i4.MarkerService], null, null)], null, null); }
var PopupDesktopComponentNgFactory = i1.ɵccf("ng-component", i3.PopupDesktopComponent, View_PopupDesktopComponent_Host_0, { markerItem: "markerItem", item: "item" }, {}, []);
export { PopupDesktopComponentNgFactory as PopupDesktopComponentNgFactory };
