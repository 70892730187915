import { Injectable } from '@angular/core';
import { MenuPosition } from '../types/menuPosition.enum';
import { Router, ActivationStart, GuardsCheckEnd, UrlSegment } from '@angular/router';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private _isInitialized: boolean = false;
    private _defaultSettings: any = {
        position: MenuPosition.Responsive,
        isOverlayed: false,
        isFooterEnabled: true,
        isSearchBoxEnabled: false
    }

    settings: any = {
        position: this._defaultSettings.position,
        isOverlayed: this._defaultSettings.isOverlayed,
        isFooterEnabled: this._defaultSettings.isFooterEnabled,
        isSearchBoxEnabled: this._defaultSettings.isSearchBoxEnabled,

        isSidebarActive: this._defaultSettings.isSidebarActive,
    }

    sidebar: any = {
        isActive: false,
    }

    menu: any = {
        onLoaded: new ReplaySubject(1),
        // currentUrl: [],
        items: {
            left: [
                {
                    "title": "Forside",
                    "link": "/",
                    active: false
                },
                {
                    title: "Kort",
                    link: "/kort",
                    active: false
                },
                {
                    title: "Kommune",
                    link: "/kommune",
                    active: false
                },
                {
                    title: "Roadmap",
                    link: "/roadmap",
                    active: false
                },
                {
                    title: "Om DitOmraade",
                    link: "/om-ditomraade",
                    active: false
                }
            ],
            right: [
                // {
                //     title: "Konto",
                //     link: "/log-ind"
                // }
            ]
        }
    }

    constructor(private _router: Router) {
    }

    init(): void {
        if (false == this._isInitialized) {
            let data: any = null;
            let url: any = null;

            // https://stackoverflow.com/questions/41465867/reset-service-variable-on-route-change-in-angular2
            this._router.events.subscribe((event: any) => {
                // Fetch data here
                if (event instanceof ActivationStart) {
                    // this.menu.currentUrl = this.getCurrentUrl(event.snapshot.url);
                    url = this.getCurrentUrl(event.snapshot.url);
                    data = event.snapshot.data;
                }

                // Set settings here
                if (event instanceof GuardsCheckEnd) {
                    if (event.shouldActivate) {
                        this.setActiveUrl(url);
                        this.resetSettings(data.layoutSettings);
                    }
                }
            });

            this._isInitialized = true;
        }
    }

    setSidebarActive(isActive: boolean): void {
        this.sidebar.isActive = isActive;
    }

    menuLoaded(): void {
        this.menu.onLoaded.next();
    }

    private getCurrentUrl(urlSegments: UrlSegment[]): string[] {
        return urlSegments.map((x: UrlSegment) => x.path);
    }

    private setActiveUrl(url: string[]) {
        let basePath = (url.length > 0 ? url[0] : '');
        for (const item of this.menu.items.left) {
            if (item.link.substring(1).toLowerCase() == basePath) {
                item.active = true;
            }
            else {
                item.active = false;
            }
        }
    }

    private resetSettings(settings: any) {
        if (settings && settings.hasOwnProperty('position')) {
            this.settings.position = settings.position;
        }
        else {
            this.settings.position = this._defaultSettings.position;
        }

        if (settings && settings.hasOwnProperty('isOverlayed')) {
            this.settings.isOverlayed = settings.isOverlayed;
        }
        else {
            this.settings.isOverlayed = this._defaultSettings.isOverlayed;
        }

        if (settings && settings.hasOwnProperty('isFooterEnabled')) {
            this.settings.isFooterEnabled = settings.isFooterEnabled;
        }
        else {
            this.settings.isFooterEnabled = this._defaultSettings.isFooterEnabled;
        }

        if (settings && settings.hasOwnProperty('isSearchBoxEnabled')) {
            this.settings.isSearchBoxEnabled = settings.isSearchBoxEnabled;
        }
        else {
            this.settings.isSearchBoxEnabled = this._defaultSettings.isSearchBoxEnabled;
        }
    }
}
