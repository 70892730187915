<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Almindelige vilkår for brug af ditomraade.dk</h1>

                        <h3>Indledning</h3>
                        <p>
                            Ved brug af ditomraade.dk forpligter du dig til at anvende nedenstående brugerbetingelser, der regulerer forholdet mellem Boligder ApS, cvr 39699524 ("ditomraade.dk") og
                            dig ("Bruger"), vedrørende brugen af disse ditomraade.dk opslagssider og deres indhold.
                            <br />
                            <br />
                            Du forpligter dig desuden til ikke at anvende webstedet og websiderne på nogen måde, som
                        </p>
                        <ul>
                            <li>
                                kan stride mod gældende lovgivning
                            </li>
                            <li>
                                belaste vores infrastruktur urimeligt eller forstyrre driften af ditomraade.dk
                            </li>
                            <li>
                                benytte nogen former for robot eller andre automatiske midler til at få adgang til ditomraade.dk eller data, der er tilgængelige på ditomraade.dk
                            </li>
                            <li>
                                sammenstille eller på anden måde indsamler oplysninger om andre, herunder e-mailadresser uden deres udtrykkelige skriftlige samtykke
                            </li>
                        </ul>

                        <h3>Om informationer på ditomraade.dk</h3>
                        <p>
                            Data på ditomraade.dk vedligeholdes af Boligder ApS og har et alment informationsformål. Data hentes fra tredjemand, og Boligder ApS indestår ikke for validiteten, aktualiteten og/eller rigtigheden af oplysningerne.
                            Boligder ApS er ikke ansvarlig for nogen former for følger (direkte og indirekte) af at benytte oplysningerne på ditomraade.dk
                        </p>

                        <h3>Ansvarsfraskrivelse</h3>
                        <p>
                            På trods af at Boligder ApS tager skridt til at sørge for, at alle oplysninger er korrekte og opdaterede, kan oplysningerne indeholde fejl eller trykfejl, og oplysninger kan være forkerte, forældede eller lignende.
                            Boligder ApS forbeholder sig retten til at foretage ændringer, korrektioner og/eller forbedringer af oplysningerne for de data, der beskrives og videreformidles, når som helst og uden forvarsel.
                            <br />
                            <br />
                            Boligder ApS giver ingen garantier eller løfter om ægtheden af oplysningerne. Boligder ApS påtager sig
                            intet ansvar for eventuelle fejl eller mangler i indholdet på webstedet. Boligder ApS giver ingen garantier for fuldstændigheden eller korrektheden af oplysningerne på
                            siden eller disses mulige anvendelsesområder. Oplysningerne bør derfor omhyggeligt vurderes af den besøgende selv.
                            <br />
                            <br />
                            Hverken Boligder ApS eller underliggende dataleverandører, der medvirker og skaber, producerer eller leverer indhold til webstedet, vil være ansvarlig for eventuelle
                            direkte/indirekte fejl og mangler af indholdet på webstedet. Boligder ApS har ikke ansvaret for og kan derfor ikke holdes ansvarlig for eventuelle skader eller virusser, der kan inficere dit edb-udstyr eller anden ejendom
                            på grund af din tilgang til eller brug af oplysningerne. Boligder ApS forbeholder sig retten til at lukke dette websted når som helst.
                        </p>

                        <h3>Links til andre hjemmesider</h3>
                        <p>
                            Vi har ingen indflydelse på, om andre hjemmesider overholder lovens krav om persondatabeskyttelse. Vi anbefaler, at du går ind og læser den enkelte hjemmesides
                            retningslinjer for beskyttelse.
                            <br />
                            <br />
                            Da vi heller ikke har indflydelse på lovligheden af indholdet på andre hjemmesider, påtager vi os intet ansvar for indholdet på de sider, vi linker til.
                        </p>

                        <h3>Personoplysninger</h3>
                        <p>Behandling af personoplysninger sker i overensstemmelse med ditomraade.dk's <a [routerLink]="['/privatlivspolitik']">privatlivspolitik</a>.</p>


                        <h3>Cookies</h3>
                        <p>Når du benytter denne hjemmeside, sættes der cookies i overenstemmelse med ditomraade.dk's <a [routerLink]="['/cookiepolitik']">cookiepolitik</a>.</p>


                        <h3>Værneting</h3>
                        <p>Eventuelle tvister i forbindelse med eller i relation til vilkårene skal, hvis de ikke kan afgøres mindeligt mellem parterne, afgøres ved Københavns Byret.</p>

                        <h3>Revideringer</h3>
                        <p>
                            Boligder ApS kan når som helst revidere disse vilkår ved uden forvarsel at revidere denne side. Du er bundet af sådanne ændringer og bør derfor regelmæssigt besøge denne side for at gennemse de aktuelle vilkår, du er
                            bundet til.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>