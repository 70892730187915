import { BaseArea, IBaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';

export class Region extends BaseArea implements IRegion, IBaseArea {
    areaLevel: AreaLevel = AreaLevel.Region;
    countryId: number = 208;
    regionId: number;

    constructor() {
        super();
    }

    init(obj: IBaseRegion): void {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
    }

    get fullName() {
        return this.name;
    }
}

export interface IRegion {
    countryId: number;
    regionId: number;

    // IBaseArea
    areaLevel: AreaLevel;
    name: string;
    urlParameter: string;
    fullName: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}

export interface IBaseRegion {
    name: string;
    countryId: number;
    regionId: number;
}