/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./version.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./version.component";
var styles_VersionComponent = [i0.styles];
var RenderType_VersionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VersionComponent, data: {} });
export { RenderType_VersionComponent as RenderType_VersionComponent };
export function View_VersionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "b-version"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["v0.3.15234"]))], null, null); }
export function View_VersionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-version", ""]], null, null, null, View_VersionComponent_0, RenderType_VersionComponent)), i1.ɵdid(1, 114688, null, 0, i2.VersionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VersionComponentNgFactory = i1.ɵccf("[app-version]", i2.VersionComponent, View_VersionComponent_Host_0, {}, {}, []);
export { VersionComponentNgFactory as VersionComponentNgFactory };
