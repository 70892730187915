import { BaseArea, IBaseArea } from 'src/app/map/base-area';
import { AreaLevel } from 'src/app/shared/types/areaLevel.enum';

export class Municipality extends BaseArea implements IMunicipality, IBaseArea {
    areaLevel: AreaLevel = AreaLevel.Municipality;
    countryId: number = 208;
    municipalityId: number;
    regionId: number;

    constructor() { 
        super();
    }

    init(obj: IBaseMunicipality): void {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
        this.municipalityId = obj.municipalityId;
    }

    get fullName() {
        let municipalityNamesEndingWithS: number[] = [101, 400];
        if (municipalityNamesEndingWithS.includes(this.municipalityId)) {
            return this.name + 's kommune';
        }
        else {
            return this.name + ' kommune';
        }
    }
}

export interface IMunicipality {
    countryId: number;
    regionId: number;
    municipalityId: number;

    // IBaseArea
    areaLevel: AreaLevel;
    name: string;
    urlParameter: string;
    fullName: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}

export interface IBaseMunicipality {
    name: string;
    countryId: number;
    regionId: number;
    municipalityId: number;
}