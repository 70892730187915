/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-segment.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./overview-segment-number.component";
import * as i4 from "./scroll.service";
var styles_OverviewNumberSegmentComponent = [i0.styles];
var RenderType_OverviewNumberSegmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewNumberSegmentComponent, data: {} });
export { RenderType_OverviewNumberSegmentComponent as RenderType_OverviewNumberSegmentComponent };
function View_OverviewNumberSegmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.item.statistics.municipality.present.value, "1.0")); var currVal_1 = _co.item.statistics.municipality.present.unit; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OverviewNumberSegmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_OverviewNumberSegmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "b-overview__segment-footer-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.description; _ck(_v, 2, 0, currVal_0); }); }
export function View_OverviewNumberSegmentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "b-overview__segment-inner"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickMenuItem(_co.fragment) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "b-overview__segment-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "ui grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "sixteen wide column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "h1", [["class", "b-overview__segment-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewNumberSegmentComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewNumberSegmentComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewNumberSegmentComponent_3)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.item.statistics && _co.item.statistics.municipality); _ck(_v, 10, 0, currVal_1); var currVal_2 = !(_co.item.statistics && _co.item.statistics.municipality); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.item.description; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.headline; _ck(_v, 4, 0, currVal_0); }); }
export function View_OverviewNumberSegmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-overview-segment-number", ""]], null, null, null, View_OverviewNumberSegmentComponent_0, RenderType_OverviewNumberSegmentComponent)), i1.ɵdid(1, 49152, null, 0, i3.OverviewNumberSegmentComponent, [i4.ScrollService], null, null)], null, null); }
var OverviewNumberSegmentComponentNgFactory = i1.ɵccf("[app-overview-segment-number]", i3.OverviewNumberSegmentComponent, View_OverviewNumberSegmentComponent_Host_0, { fragment: "fragment", item: "item" }, {}, []);
export { OverviewNumberSegmentComponentNgFactory as OverviewNumberSegmentComponentNgFactory };
