import { Injectable, EventEmitter } from '@angular/core';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { Observable } from 'rxjs/internal/Observable';
import { IAreaContainer, AreaContainer } from './area-container';
import { MarkerType } from './marker-type';
import { Coordinate } from '../shared/types/geometry/coordinate';
import { IMarkerContainer, MarkerContainer } from './marker-container';
import { IMarkerDetailsContainer, MarkerDetailsContainer } from './marker-details-container';
import { forkJoin, BehaviorSubject, Subject, of } from 'rxjs';
import { IAreaContainerCountryItem } from './area-container-country-item';
import { IAreaContainerRegionItem } from './area-container-region-item';
import { IAreaContainerMunicipalityItem } from './area-container-municipality-item';
import { IAreaContainerParishItem } from './area-container-parish-item';
import { IAreaContainerGeometries } from './area-container-geometries';
import { ICurrentAreaEventData } from './current-area-event-data';
import { MarkerTypeContainer } from './marker-type-container';
import { UtilityService } from '../shared/services/utility.service';
import { DeviceType } from '../shared/types/device-type.enum';
import { IBounds } from '../shared/types/geometry/bounds';

@Injectable({
    providedIn: 'root'
})
export class MapService {
    private _areaContainer: IAreaContainer = new AreaContainer();
    private _markerContainer: IMarkerContainer = new MarkerContainer();
    private _markerDetailsContainer: IMarkerDetailsContainer = new MarkerDetailsContainer();

    constructor(private _dataAccessService: DataAccessService, private _utilityService: UtilityService) {
    }

    public onFitBoundsStart: EventEmitter<ICurrentAreaEventData> = new EventEmitter<ICurrentAreaEventData>();
    public onFitBoundsEnd: BehaviorSubject<ICurrentAreaEventData> = new BehaviorSubject<ICurrentAreaEventData>(null);
    public onMobileMenuHeightChanged: EventEmitter<any> = new EventEmitter<any>();
    public onResetBounds: Subject<void> = new Subject<void>();
    private onBoundsChangedSubject: Subject<IBounds> = new Subject<IBounds>();
    onBoundsChanged = this.onBoundsChangedSubject.asObservable();

    public onAreaDataReady: EventEmitter<any> = new EventEmitter<any>();
    public onToggleLoadMarkers: BehaviorSubject<any> = new BehaviorSubject<any>({
        markerTypeContainer: new MarkerTypeContainer(MarkerType.None, null),
        isLoading: false,
        isLoaded: true,
    });

    public marker: any = this._markerContainer.marker;

    private _isAreaDataReady: boolean = false;
    get isAreaDataReady(): boolean {
        return this._isAreaDataReady;
    }

    public changeBounds(bounds: IBounds) {
        this.onBoundsChangedSubject.next(bounds);
    }

    public loadInitialAreaData(): Observable<void> {
        // Load initial data
        return new Observable((observer) => {
            if (false == this._isAreaDataReady) {
                let countryAreaGeometries = this.getCountryAreaGeometries();
                let regionAreaGeometries = this._dataAccessService.getMapAreaGeometries(AreaLevel.Country);
                let areaIdsData = this.getMapAreaIdsData();

                forkJoin(countryAreaGeometries, regionAreaGeometries, areaIdsData)
                    .subscribe((results) => {
                        let country = results[0];
                        let region = results[1];
                        let areaIds = results[2];

                        // Core 
                        this._areaContainer.addCountry(country);
                        this._areaContainer.addRegions(areaIds.regions);
                        this._areaContainer.addMunicipalities(areaIds.municipalities);
                        this._areaContainer.addParishes(areaIds.parishes);

                        // Geometries
                        this._areaContainer.addCountryGeometry(country);
                        this._areaContainer.addRegionGeometries(region.map.areaGeometries.regions);

                        this._isAreaDataReady = true;

                        this.onAreaDataReady.next();
                        this.onAreaDataReady.complete();

                        observer.next();
                        observer.complete();
                    });
            }
            else {
                observer.next();
                observer.complete();
            }
        })
    }

    public getMapAreaIdsData(): Observable<any> { // coordinate: Coordinate, regionId: number, municipalityId: number, parishId: number, markerSkipCount: number, markerTakeCount: number
        return new Observable((observer) => {
            this._dataAccessService
                .getMapAreaIds()
                .subscribe((x: any) => {
                    observer.next(x.map.areaIds);
                    observer.complete();
                });
        })
    }

    public getCountryAreaGeometries(): Observable<any> {
        return new Observable((observer) => {
            let country = {
                areaLevel: AreaLevel.Country,
                bounds: [[8.0443998933, 54.538384098], [15.2050413988, 57.7875799259]],
                id: 1,
                countryId: 208,
                name: 'Danmark'
            }

            observer.next(country);
            observer.complete();
        })
    }

    public loadRegionAreaGeometries(): Observable<void> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasRegionGeometries()) {
                this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Country)
                    .subscribe(x => {
                        this._areaContainer.addRegionGeometries(x.map.areaGeometries.regions);

                        observer.next();
                        observer.complete();
                    });
            }
            else {
                observer.next();
                observer.complete();
            }
        })
    }

    public loadMunicipalityAreaGeometries(regionId: number): Observable<void> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasMunicipalityGeometriesByRegionId(regionId)) {
                this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Region, regionId)
                    .subscribe(x => {
                        this._areaContainer.addMunicipalityGeometries(x.map.areaGeometries.municipalities);

                        observer.next();
                        observer.complete();
                    });
            }
            else {
                observer.next();
                observer.complete();
            }
        })
    }

    public loadParishAreaGeometries(municipalityId: number): Observable<void> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasParishGeometriesByMunicipalityId(municipalityId)) {
                this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Municipality, null, municipalityId)
                    .subscribe(x => {
                        this._areaContainer.addParishGeometries(x.map.areaGeometries.parishes);

                        observer.next();
                        observer.complete();
                    });
            }
            else {
                observer.next();
                observer.complete();
            }
        })
    }

    public loadParishAreaGeometriesByRegionId(regionId: number): Observable<void> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasParishGeometriesByRegionId(regionId)) {
                this._dataAccessService
                    .getMapAreaGeometries(AreaLevel.Municipality, regionId, null)
                    .subscribe(x => {
                        this._areaContainer.addParishGeometries(x.map.areaGeometries.parishes);

                        observer.next();
                        observer.complete();
                    });
            }
            else {
                observer.next();
                observer.complete();
            }
        })
    }

    public loadMunicipalityFactsheet(municipalityId: number): Observable<any> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasMunicipalityFactsheet(municipalityId)) {
                this._dataAccessService
                    .getMapFactsheetMunicipality(municipalityId)
                    .subscribe(x => {
                        let factsheet = this._areaContainer.addMunicipalityFactsheet(municipalityId, x.map.municipality.factsheet.data);

                        observer.next(factsheet);
                        observer.complete();
                    });
            }
            else {
                let municipality = this._areaContainer.getMunicipality(municipalityId);

                observer.next(municipality.factsheet);
                observer.complete();
            }
        })
    }

    public loadParishFactsheet(parishId: number): Observable<any> {
        return new Observable((observer) => {
            if (false == this._areaContainer.hasParishFactsheet(parishId)) {
                this._dataAccessService
                    .getMapFactsheetParish(parishId)
                    .subscribe(x => {
                        let factsheet = this._areaContainer.addParishFactsheet(parishId, x.map.parish.factsheet.data);

                        observer.next(factsheet);
                        observer.complete();
                    });
            }
            else {
                let parish = this._areaContainer.getParish(parishId);

                observer.next(parish.factsheet);
                observer.complete();
            }
        })
    }

    public loadMapData(): Observable<any> { // coordinate: Coordinate, regionId: number, municipalityId: number, parishId: number, markerSkipCount: number, markerTakeCount: number
        return new Observable((observer) => {
            this._dataAccessService
                .getMapData()
                .subscribe((x: any) => {
                    observer.next(x.map);
                    observer.complete();
                });
        })
    }

    public loadMarkerData(markerTypeContainer: MarkerTypeContainer, coordinate: Coordinate, bounds: IBounds, municipalityId: number, markerSkipCount: number, markerTakeCount: number): Observable<any> {
        let prop = markerTypeContainer.markerTypePropertyName;

        return new Observable((observer) => {
            if (false == this._markerContainer.hasData(coordinate, markerTypeContainer)) {
                this.onToggleLoadMarkers.next({
                    markerTypeContainer: markerTypeContainer,
                    isLoading: true,
                    isLoaded: false,
                });

                this.requestMarkerData(markerTypeContainer, coordinate, bounds, municipalityId, markerSkipCount, markerTakeCount)
                    .subscribe((x: any) => {
                        let data: any;
                        if (markerTypeContainer.isRealEstateType) {
                            data = x.map.markers;
                        }
                        else {
                            data = x.map[prop];
                        }

                        this._markerContainer.addData(data, coordinate, markerTypeContainer);
                        this._markerContainer.updateMarkerItems(data.items, markerTypeContainer);

                        this.onToggleLoadMarkers.next({
                            markerTypeContainer: markerTypeContainer,
                            isLoading: false,
                            isLoaded: true,
                        });

                        observer.next(data);
                        observer.complete();
                    });
            }
            else {
                let data = this._markerContainer.getData(coordinate, markerTypeContainer);
                this._markerContainer.updateMarkerItems(data.items, markerTypeContainer);

                this.onToggleLoadMarkers.next({
                    markerTypeContainer: markerTypeContainer,
                    isLoading: false,
                    isLoaded: true,
                });

                observer.next(data);
                observer.complete();
            }
        })
    }

    private requestMarkerData(markerTypeContainer: MarkerTypeContainer, coordinate: Coordinate, bounds: IBounds, municipalityId: number, markerSkipCount: number, markerTakeCount: number): Observable<any> {
        return new Observable((observer) => {
            let deviceType = this._utilityService.getClientDeviceType();
            let obs: Observable<any>;
            switch (markerTypeContainer.markerType) {
                case MarkerType.Nursery:
                    obs = this._dataAccessService
                        .getNurseryMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Kindergarden:
                    obs = this._dataAccessService
                        .getKindergaardenMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.PrimarySchool:
                    obs = this._dataAccessService
                        .getPrimarySchoolMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.HighSchool:
                    obs = this._dataAccessService
                        .getHighSchoolMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.PrivateDaycare:
                    // Specific default for private daycare on desktop
                    let markerTakeCountPrivateDaycare = (deviceType == DeviceType.Mobile ? 5 : 100);
                    obs = this._dataAccessService
                        .getPrivateDaycareMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCountPrivateDaycare);
                    break;
                case MarkerType.PublicTransportation:
                    obs = this._dataAccessService
                        .getPublicTransportationMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Supermarket:
                    obs = this._dataAccessService
                        .getSupermarketMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Noise:
                    obs = this._dataAccessService
                        .getNoiseMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Doctor:
                    obs = this._dataAccessService
                        .getDoctorMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.RealEstate:
                    let markerTakeCountRealEstate = (deviceType == DeviceType.Mobile ? 5 : null);
                    obs = this._dataAccessService
                        .getRealEstateMapMarkers(coordinate.lng, coordinate.lat, bounds.bbox, markerTypeContainer.markerSubType, markerSkipCount, markerTakeCountRealEstate);
                    break;
                case MarkerType.ResidentsForRent:
                    obs = this._dataAccessService
                        .getResidentsForRentMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Facility:
                    obs = this._dataAccessService
                        .getFacilityMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.SportsUnion:
                    obs = this._dataAccessService
                        .getSportsUnionMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount);
                    break;
                case MarkerType.Daycare:
                    obs = this._dataAccessService
                        .getDaycareMarkerData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.SchoolDistrict:
                    obs = this._dataAccessService
                        .getSchoolDistrictData(coordinate.lng, coordinate.lat, municipalityId, markerSkipCount, markerTakeCount, markerTypeContainer.markerSubType);
                    break;
                case MarkerType.TraficCount:
                    let markerTakeCountTraficCount = (deviceType == DeviceType.Mobile ? 5 : null);
                    obs = this._dataAccessService
                        .getTraficCountData(coordinate.lng, coordinate.lat, bounds.bbox, municipalityId, markerSkipCount, markerTakeCountTraficCount);
                    break;
                default:
                    break;
            }

            obs.subscribe((x: any) => {
                observer.next(x);
                observer.complete();
            });
        })
    }

    public hasDetailsData(markerTypeContainer: MarkerTypeContainer, markerId: number): boolean {
        return this._markerDetailsContainer.hasData(markerTypeContainer, markerId);
    }

    public loadDetailsData(markerTypeContainer: MarkerTypeContainer, markerId: number): Observable<any> {
        let prop = markerTypeContainer.markerTypePropertyName;

        return new Observable((observer) => {
            if (false == this._markerDetailsContainer.hasData(markerTypeContainer, markerId)) {
                // this.onToggleLoadMarkers.next({
                //     markerTypeContainer: markerTypeContainer,
                //     isLoading: true,
                //     isLoaded: false,
                // });

                this.requestDetailsData(markerTypeContainer, markerId)
                    .subscribe((x: any) => {
                        let data: any = x.map[prop];
                        this._markerDetailsContainer.addData(markerTypeContainer, markerId, data);

                        // this.onToggleLoadMarkers.next({
                        //     markerTypeContainer: markerTypeContainer,
                        //     isLoading: false,
                        //     isLoaded: true,
                        // });

                        observer.next(data);
                        observer.complete();
                    });
            }
            else {
                let data = this._markerDetailsContainer.getData(markerTypeContainer, markerId);

                // this.onToggleLoadMarkers.next({
                //     markerTypeContainer: markerTypeContainer,
                //     isLoading: false,
                //     isLoaded: true,
                // });

                observer.next(data);
                observer.complete();
            }
        })
    }

    private requestDetailsData(markerTypeContainer: MarkerTypeContainer, markerId: number): Observable<any> {
        return new Observable((observer) => {
            let obs: Observable<any>;
            switch (markerTypeContainer.markerType) {
                case MarkerType.RealEstate:
                    obs = this._dataAccessService
                        .getRealEstateMapDetails(markerId);
                    break;
                default:
                    break;
            }

            obs.subscribe((x: any) => {
                observer.next(x);
                observer.complete();
            });
        });
    }



    public getRegionIds(): number[] {
        return this._areaContainer.getRegions()
            .map(x => x.core.regionId);
    }
    public getMunicipalityIds(regionId: number): number[] {
        return this._areaContainer.getMunicipalities(regionId)
            .map(x => x.core.municipalityId);
    }


    public getRegionAreas(): IAreaContainerGeometries[] {
        return this._areaContainer.getRegions()
            .map(x => x.geometry);
    }
    public getMunicipalityAreas(regionId: number): IAreaContainerGeometries[] {
        return this._areaContainer.getMunicipalities(regionId)
            .map(x => x.geometry);
    }
    public getParishAreas(municipalityId: number): IAreaContainerGeometries[] {
        return this._areaContainer.getParishes(municipalityId)
            .map(x => x.geometry);
    }


    public getCountryAreaContainer(): IAreaContainerCountryItem {
        return this._areaContainer.getCountry();
    }
    public getRegionAreaContainer(id: number): IAreaContainerRegionItem {
        return this._areaContainer.getRegion(id);
    }
    public getMunicipalityAreaContainer(id: number): IAreaContainerMunicipalityItem {
        return this._areaContainer.getMunicipality(id);
    }
    public getParishAreaContainer(id: number): IAreaContainerParishItem {
        return this._areaContainer.getParish(id);
    }


    public getCountryAreaContainerByUrlParameter(): IAreaContainerCountryItem {
        return this._areaContainer.getCountry();
    }
    public getRegionAreaContainerByUrlParameter(regionUrlParameter: string): IAreaContainerRegionItem {
        return this._areaContainer.getRegionByUrlParameter(regionUrlParameter);
    }
    public getMunicipalityAreaContainerByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string): IAreaContainerMunicipalityItem {
        return this._areaContainer.getMunicipalityByUrlParameter(regionUrlParameter, municipalityUrlParameter);
    }
    public getParishAreaContainerByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string, parishUrlParameter: string): IAreaContainerParishItem {
        return this._areaContainer.getParishByUrlParameter(regionUrlParameter, municipalityUrlParameter, parishUrlParameter);
    }



    public hasMunicipalityGeometriesByRegionId(regionId: number): boolean {
        return this._areaContainer.hasMunicipalityGeometriesByRegionId(regionId);
    }
    public hasParishGeometriesByRegionId(regionId: number): boolean {
        return this._areaContainer.hasParishGeometriesByRegionId(regionId);
    }
}
