import { Component, OnInit, Input } from '@angular/core';
import { ScrollService } from './scroll.service';

@Component({
    selector: '[app-overview-summary-mobile]',
    templateUrl: './overview-summary-mobile.component.html',
    styleUrls: ['./overview-summary-mobile.component.scss']
})
export class OverviewSummaryMobileComponent implements OnInit {
    @Input() fragment: string;

    @Input() item: any;
    @Input()
    set imageName(val: string) {
        this.imageSrc = '/assets/municipality/' + val;
    }

    imageSrc: string;

    constructor(private _scrollService: ScrollService) {
    }

    ngOnInit() {
    }

    public clickMenuItem(fragment: any) {
        this._scrollService.scrollToId(fragment)
    }
}
