import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { VersionComponent } from './components/version/version.component';
import { AddressSearchBoxComponent } from '../map/address-search-box.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

@NgModule({
    declarations: [
        MenuComponent,
        FooterComponent,
        SidebarComponent,
        CookieConsentComponent,
        VersionComponent,
        AddressSearchBoxComponent,
        FeedbackComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        FormsModule,

        MenuComponent,
        FooterComponent,
        AddressSearchBoxComponent,
        FeedbackComponent
    ]
})
export class SharedModule { }
