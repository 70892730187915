import { Feature } from './feature';

export interface IFeatureCollection {
    type: string;
    features: Feature[];

    addFeature(feature: Feature): void;
    clearFeatures(): void;
    hasFeatures(): boolean;
}

export class FeatureCollection implements IFeatureCollection {
    type: string = "FeatureCollection";
    features: Feature[] = [];

    public addFeature(feature: Feature): void {
        this.features.push(feature);
    }

    public clearFeatures(): void {
        this.features.splice(0, this.features.length);
    }

    public hasFeatures(): boolean {
        return this.features.length > 0;
    }
}