<div *ngIf="area && area.areaLevel >= 30">
    <div class="b-header-container">
        <h3>Interessepunkter</h3>
        <!-- <button *ngIf="area.areaLevel == 30" class="ui right floated primary mini button" [routerLink]="['/kommune', area.urlParameter]">Se flere detaljer</button> -->
    </div>

    <div class="b-categories-container">
        <div class="ui four column grid">
            <!-- <div class="column" [ngClass]="{'active': categories.nursery.showMarkers}" (click)="clickIcon(10)">
                <img [src]="categories.nursery.iconUrl">
                <span class="text small">{{categories.nursery.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.kindergarden.showMarkers}" (click)="clickIcon(20)">
                <img [src]="categories.kindergarden.iconUrl">
                <span class="text small">{{categories.kindergarden.headline}}</span>
            </div> -->
            <div class="column" [ngClass]="{'active': categories.daycare.base.showMarkers}" (click)="clickIcon(15)">
                <img [src]="categories.daycare.base.iconUrl">
                <span class="text small">{{categories.daycare.base.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.privateDaycare.showMarkers}" (click)="clickIcon(25)">
                <img [src]="categories.privateDaycare.iconUrl">
                <span class="text small">{{categories.privateDaycare.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.primarySchool.base.showMarkers}" (click)="clickIcon(30)">
                <img [src]="categories.primarySchool.base.iconUrl">
                <span class="text small">{{categories.primarySchool.base.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.highSchool.base.showMarkers}" (click)="clickIcon(32)">
                <img [src]="categories.highSchool.base.iconUrl">
                <span class="text small">{{categories.highSchool.base.headline}}</span>
            </div>
        </div>
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.facility.base.showMarkers}" (click)="clickIcon(50)">
                <img [src]="categories.facility.base.iconUrl">
                <span class="text small">{{categories.facility.base.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.sportsUnion.showMarkers}" (click)="clickIcon(55)">
                <img [src]="categories.sportsUnion.iconUrl">
                <span class="text small">{{categories.sportsUnion.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.publicTransportation.showMarkers}" (click)="clickIcon(60)">
                <img [src]="categories.publicTransportation.iconUrl">
                <span class="text small">{{categories.publicTransportation.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.supermarket.showMarkers}" (click)="clickIcon(65)">
                <img [src]="categories.supermarket.iconUrl">
                <span class="text small">{{categories.supermarket.headline}}</span>
            </div>
        </div>
        <div class="ui four column grid">
            <!-- <div class="column" [ngClass]="{'active': categories.noise.showMarkers}" (click)="clickIcon(70)">
                <img [src]="categories.noise.iconUrl">
                <span class="text small">{{categories.noise.headline}}</span>
            </div> -->
            <div class="column" [ngClass]="{'active': categories.traficCount.showMarkers}" (click)="clickIcon(75)">
                <img [src]="categories.traficCount.iconUrl">
                <span class="text small">{{categories.traficCount.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.doctor.showMarkers}" (click)="clickIcon(80)">
                <img [src]="categories.doctor.iconUrl">
                <span class="text small">{{categories.doctor.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.base.showMarkers}" (click)="clickIcon(90)">
                <img [src]="categories.realEstate.base.iconUrl">
                <span class="text small">{{categories.realEstate.base.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.residentsForRent.showMarkers}" (click)="clickIcon(95)">
                <img [src]="categories.residentsForRent.iconUrl">
                <span class="text small">{{categories.residentsForRent.headline}}</span>
            </div>
        </div>
    </div>




    <div class="b-sub-categories-container" *ngIf="categories.facility.base.showMarkers">
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.facility.badminton.showMarkers}" (click)="clickIcon(50, 130)">
                <img [src]="categories.facility.badminton.iconUrl">
                <span class="text small">{{categories.facility.badminton.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.fitness.showMarkers}" (click)="clickIcon(50, 30)">
                <img [src]="categories.facility.fitness.iconUrl">
                <span class="text small">{{categories.facility.fitness.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.golf.showMarkers}" (click)="clickIcon(50, 50)">
                <img [src]="categories.facility.golf.iconUrl">
                <span class="text small">{{categories.facility.golf.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.horseriding.showMarkers}" (click)="clickIcon(50, 80)">
                <img [src]="categories.facility.horseriding.iconUrl">
                <span class="text small">{{categories.facility.horseriding.headline}}</span>
            </div>
        </div>
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.facility.soccer.showMarkers}" (click)="clickIcon(50, 40)">
                <img [src]="categories.facility.soccer.iconUrl">
                <span class="text small">{{categories.facility.soccer.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.swimming.showMarkers}" (click)="clickIcon(50, 10)">
                <img [src]="categories.facility.swimming.iconUrl">
                <span class="text small">{{categories.facility.swimming.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.tennisIndoor.showMarkers}" (click)="clickIcon(50, 120)">
                <img [src]="categories.facility.tennisIndoor.iconUrl">
                <span class="text small">{{categories.facility.tennisIndoor.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.facility.tennisOutdoor.showMarkers}" (click)="clickIcon(50, 110)">
                <img [src]="categories.facility.tennisOutdoor.iconUrl">
                <span class="text small">{{categories.facility.tennisOutdoor.headline}}</span>
            </div>
        </div>
    </div>


    <div class="b-sub-categories-container" *ngIf="categories.primarySchool.base.showMarkers">
        <div class="ui three column grid">
            <div class="column" [ngClass]="{'active': categories.primarySchool.government.showMarkers}" (click)="clickIcon(30, 1012)">
                <img [src]="categories.primarySchool.government.iconUrl">
                <span class="text small">{{categories.primarySchool.government.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.primarySchool.private.showMarkers}" (click)="clickIcon(30, 1013)">
                <img [src]="categories.primarySchool.private.iconUrl">
                <span class="text small">{{categories.primarySchool.private.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.primarySchool.boarding.showMarkers}" (click)="clickIcon(30, 1011)">
                <img [src]="categories.primarySchool.boarding.iconUrl">
                <span class="text small">{{categories.primarySchool.boarding.headline}}</span>
            </div>
        </div>
    </div>


    <div class="b-sub-categories-container" *ngIf="categories.highSchool.base.showMarkers">
        <div class="ui three column grid">
            <div class="column" [ngClass]="{'active': categories.highSchool.government.showMarkers}" (click)="clickIcon(32, 1061)">
                <img [src]="categories.highSchool.government.iconUrl">
                <span class="text small">{{categories.highSchool.government.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.highSchool.private.showMarkers}" (click)="clickIcon(32, 1062)">
                <img [src]="categories.highSchool.private.iconUrl">
                <span class="text small">{{categories.highSchool.private.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.highSchool.studentPreparation.showMarkers}" (click)="clickIcon(32, 1063)">
                <img [src]="categories.highSchool.studentPreparation.iconUrl">
                <span class="text small">{{categories.highSchool.studentPreparation.headline}}</span>
            </div>
        </div>
    </div>


    <div class="b-sub-categories-container" *ngIf="categories.daycare.base.showMarkers">
        <div class="ui three column grid">
            <div class="column" [ngClass]="{'active': categories.daycare.age0to2.showMarkers}" (click)="clickIcon(15, 6010)">
                <img [src]="categories.daycare.age0to2.iconUrl">
                <span class="text small">{{categories.daycare.age0to2.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.daycare.age3to5.showMarkers}" (click)="clickIcon(15, 6011)">
                <img [src]="categories.daycare.age3to5.iconUrl">
                <span class="text small">{{categories.daycare.age3to5.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.daycare.age0to5.showMarkers}" (click)="clickIcon(15, 6012)">
                <img [src]="categories.daycare.age0to5.iconUrl">
                <span class="text small">{{categories.daycare.age0to5.headline}}</span>
            </div>
        </div>
    </div>

    <div class="b-sub-categories-container" *ngIf="categories.realEstate.base.showMarkers">
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.realEstate.brickHouse.showMarkers}" (click)="clickIcon(90, 100)">
                <img [src]="categories.realEstate.brickHouse.iconUrl">
                <span class="text small">{{categories.realEstate.brickHouse.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.townHouse.showMarkers}" (click)="clickIcon(90, 200)">
                <img [src]="categories.realEstate.townHouse.iconUrl">
                <span class="text small">{{categories.realEstate.townHouse.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.ownerOccupiedFlat.showMarkers}" (click)="clickIcon(90, 300)">
                <img [src]="categories.realEstate.ownerOccupiedFlat.iconUrl">
                <span class="text small">{{categories.realEstate.ownerOccupiedFlat.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.recreationalProperty.showMarkers}" (click)="clickIcon(90, 400)">
                <img [src]="categories.realEstate.recreationalProperty.iconUrl">
                <span class="text small">{{categories.realEstate.recreationalProperty.headline}}</span>
            </div>
        </div>
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.realEstate.allotment.showMarkers}" (click)="clickIcon(90, 401)">
                <img [src]="categories.realEstate.allotment.iconUrl">
                <span class="text small">{{categories.realEstate.allotment.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.cooperativeDwelling.showMarkers}" (click)="clickIcon(90, 500)">
                <img [src]="categories.realEstate.cooperativeDwelling.iconUrl">
                <span class="text small">{{categories.realEstate.cooperativeDwelling.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.ruralProperty.showMarkers}" (click)="clickIcon(90, 600)">
                <img [src]="categories.realEstate.ruralProperty.iconUrl">
                <span class="text small">{{categories.realEstate.ruralProperty.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.allYearRoundPlot.showMarkers}" (click)="clickIcon(90, 700)">
                <img [src]="categories.realEstate.allYearRoundPlot.iconUrl">
                <span class="text small">{{categories.realEstate.allYearRoundPlot.headline}}</span>
            </div>
        </div>
        <div class="ui four column grid">
            <div class="column" [ngClass]="{'active': categories.realEstate.recreationalPlot.showMarkers}" (click)="clickIcon(90, 800)">
                <img [src]="categories.realEstate.recreationalPlot.iconUrl">
                <span class="text small">{{categories.realEstate.recreationalPlot.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.brickHouseApartment.showMarkers}" (click)="clickIcon(90, 900)">
                <img [src]="categories.realEstate.brickHouseApartment.iconUrl">
                <span class="text small">{{categories.realEstate.brickHouseApartment.headline}}</span>
            </div>
            <div class="column" [ngClass]="{'active': categories.realEstate.pleasureProperty.showMarkers}" (click)="clickIcon(90, 1400)">
                <img [src]="categories.realEstate.pleasureProperty.iconUrl">
                <span class="text small">{{categories.realEstate.pleasureProperty.headline}}</span>
            </div>
        </div>
    </div>

    <div>
        <div app-categories-list-mobile></div>

        <!-- <div *ngIf="categories.realEstate.base.showMarkers">
            <div style="text-align: center; padding-bottom: 10px; padding-top: 15px;">
                <p style="font-size: 1rem;color: rgba(0, 0, 0, 0.87);"><strong>Boliger til salg</strong></p>
            </div>
    
            <div style="text-align: center; margin-top: 20px; margin-bottom: 10px;">
                <p style="color: rgba(0,0,0,.87);"><strong>Manglende data... Data er på vej.</strong></p>
            </div>
        </div> -->
    </div>
</div>