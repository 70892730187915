<div class="b-container" *ngIf="area && area.areaLevel >= 30">
    <div class="b-collapse-button-container" [ngClass]="{'b-collapsed': isCollapsed}">
        <button class="ui primary icon button b-side" (click)="clickCollapse()"><i class="{{ iconCollapseSide }} chevron icon"></i></button>
        <button class="ui primary icon button b-bottom" (click)="clickCollapse()"><i class="{{ iconCollapseBottom }} chevron icon"></i></button>
    </div>
    <div class="ui cards" *ngIf="false == isCollapsed">
        <div class="card">
            <!-- Loader -->
            <div class="ui active inverted dimmer" *ngIf="isLoading">
                <div class="ui medium text loader">Henter data for {{area.fullName}}...</div>
            </div>

            <div class="content">
                <div class="header">
                    {{area.fullName}}
                </div>
                <div class="description">

                    <div class="b-flex-content-container">

                        <div class="b-top-content">

                            <div class="b-subtitle-container">
                                <h5>Vælg interessepunkt</h5>
                            </div>

                            <div class="ui grid">
                                <div class="four wide column" [ngClass]="{'active': categories.daycare.base.showMarkers}" (click)="clickIcon(15)">
                                    <img [src]="categories.daycare.base.iconUrl">
                                    <span class="text small">{{categories.daycare.base.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.privateDaycare.showMarkers}" (click)="clickIcon(25)">
                                    <img [src]="categories.privateDaycare.iconUrl">
                                    <span class="text small">{{categories.privateDaycare.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.primarySchool.base.showMarkers}" (click)="clickIcon(30)">
                                    <img [src]="categories.primarySchool.base.iconUrl">
                                    <span class="text small">{{categories.primarySchool.base.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.schoolDistrict.base.showMarkers}" (click)="clickIcon(35)">
                                    <img [src]="categories.schoolDistrict.base.iconUrl">
                                    <span class="text small">{{categories.schoolDistrict.base.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.highSchool.base.showMarkers}" (click)="clickIcon(32)">
                                    <img [src]="categories.highSchool.base.iconUrl">
                                    <span class="text small">{{categories.highSchool.base.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.facility.base.showMarkers}" (click)="clickIcon(50)">
                                    <img [src]="categories.facility.base.iconUrl">
                                    <span class="text small">{{categories.facility.base.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.sportsUnion.showMarkers}" (click)="clickIcon(55)">
                                    <img [src]="categories.sportsUnion.iconUrl">
                                    <span class="text small">{{categories.sportsUnion.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.publicTransportation.showMarkers}" (click)="clickIcon(60)">
                                    <img [src]="categories.publicTransportation.iconUrl">
                                    <span class="text small">{{categories.publicTransportation.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.supermarket.showMarkers}" (click)="clickIcon(65)">
                                    <img [src]="categories.supermarket.iconUrl">
                                    <span class="text small">{{categories.supermarket.headline}}</span>
                                </div>
                                <!-- <div class="four wide column" [ngClass]="{'active': categories.noise.showMarkers}" (click)="clickIcon(70)">
                                    <img [src]="categories.noise.iconUrl">
                                    <span class="text small">{{categories.noise.headline}}</span>
                                </div> -->
                                <div class="four wide column" [ngClass]="{'active': categories.traficCount.showMarkers}" (click)="clickIcon(75)">
                                    <img [src]="categories.traficCount.iconUrl">
                                    <span class="text small">{{categories.traficCount.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.doctor.showMarkers}" (click)="clickIcon(80)">
                                    <img [src]="categories.doctor.iconUrl">
                                    <span class="text small">{{categories.doctor.headline}}</span>
                                </div>
                                <div class="four wide column" [ngClass]="{'active': categories.realEstate.base.showMarkers}" (click)="clickIcon(90)">
                                    <img [src]="categories.realEstate.base.iconUrl">
                                    <span class="text small">{{categories.realEstate.base.headline}}</span>
                                </div>
                                <!-- <div class="four wide column" [ngClass]="{'active': categories.residentsForRent.showMarkers}" (click)="clickIcon(95)">
                                    <img [src]="categories.residentsForRent.iconUrl">
                                    <span class="text small">{{categories.residentsForRent.headline}}</span>
                                </div> -->
                            </div>

                            <div app-categories-dropdown [baseMarkerItem]="categories.facility.base"></div>
                            <div app-categories-dropdown [baseMarkerItem]="categories.highSchool.base"></div>
                            <div app-categories-dropdown [baseMarkerItem]="categories.primarySchool.base"></div>
                            <div app-categories-dropdown [baseMarkerItem]="categories.daycare.base"></div>
                            <div app-categories-checkbox [baseMarkerItem]="categories.realEstate.base"></div>
                            <div app-categories-radio-button [baseMarkerItem]="categories.schoolDistrict.base"></div>
                        </div>

                        <div class="b-bottom-content">

                            <div class="b-category-lists-container">
                                <div app-categories-list-desktop [markerItem]="category" *ngFor="let category of visibleCategories"></div>
                            </div>

                            <!-- <div *ngIf="categories.realEstate.base.showMarkers">
                                <div style="margin-bottom: 5px; padding-top: 10px ;padding-left: 6px;">
                                    <p style="font-size: 16px;color: rgba(0, 0, 0, 0.87);"><strong>Boliger til salg</strong></p>
                                </div>

                                <div style="text-align: center;margin-top: 20px; margin-bottom: 10px;">
                                    <p style="color: rgba(0,0,0,.87);"><strong>Manglende data... Data er på vej.</strong></p>
                                </div>
                            </div> -->

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>