import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TopRightContentBoxDesktopService {
    private _onBoxHeightChanged: Subject<{ elementHeight: number, windowHeight: number }> = new Subject<{ elementHeight: number, windowHeight: number }>();
    public onBoxHeightChanged: Observable<{ elementHeight: number, windowHeight: number }> = this._onBoxHeightChanged.asObservable();

    constructor() { }

    emitOnBoxHeightChanged(eventData: { elementHeight: number, windowHeight: number }) {
        this._onBoxHeightChanged.next(eventData);
    }
}
