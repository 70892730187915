import { Injectable } from '@angular/core';
import { IMunicipality, Municipality } from './types/municipality';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { Observable, forkJoin } from 'rxjs';
import { RequestDetailsParameters } from './types/request-details-parameters';
import { MunicipalityDataContainer } from './types/municipality-data-container';

@Injectable({
    providedIn: 'root'
})
export class MunicipalityService {
    private _municipalities: IMunicipality[] = [];
    private _data: { [id: number]: MunicipalityDataContainer; } = {};

    constructor(private _dataAccessService: DataAccessService) {
    }

    public getMunicipalities(): Observable<IMunicipality[]> {
        return new Observable((observer) => {
            if (this._municipalities.length == 0) {
                this._dataAccessService
                    .getMapGetMunicipalityList()
                    .subscribe(x => {
                        for (const item of x.map.municipalityList.municipalities) {
                            let municipality = new Municipality();
                            municipality.init(item);
                            this._municipalities.push(municipality);
                        }

                        observer.next(this._municipalities);
                        observer.complete();
                    });
            }
            else {
                observer.next(this._municipalities);
                observer.complete();
            }
        })
    }



    // METHODS - GET DATA
    // ===========================================================================================================

    public getAllData(p: RequestDetailsParameters): Observable<MunicipalityDataContainer> {
        return new Observable((observer) => {
            if (false == (p.municipalityId in this._data)) {
                // Data binding
                let observableCommon = this.getCommonData();
                let observableOverviewSummary = this.getOverviewSummaryData(p.municipalityId);
                let observableSafety = this.getSafetyData(p.municipalityId, p.violationType);
                let observablePopulation = this.getPopulationData(p.municipalityId, p.civilStatusType, p.familyCompositionType, p.originType, p.educationType, p.ageType, p.growthType, p.immigrationType);
                let observableEconomy = this.getEconomyData(p.municipalityId, p.economyPropertyCategoryId, p.incomeType, p.taxType);
                let observableEducation = this.getEducationData(p.municipalityId);
                let observableFreetime = this.getFreetimeData(p.municipalityId, p.expenseType);
                let observableResidential = this.getResidentialData(p.municipalityId, p.housingType, p.propertyType, p.yearType, p.sizeType);
                let observableHealth = this.getHealthData(p.municipalityId);
                let observableDaycare = this.getDaycareData(p.municipalityId, p.availabilityType, p.fareType);

                forkJoin(observableCommon, observableOverviewSummary, observableSafety, observablePopulation, observableEconomy, observableEducation, observableFreetime, observableResidential, observableHealth, observableDaycare)
                    .subscribe((results) => {
                        let data: MunicipalityDataContainer =
                        {
                            common: results[0],
                            summary: results[1],
                            safety: results[2],
                            population: results[3],
                            economy: results[4],
                            education: results[5],
                            freetime: results[6],
                            residential: results[7],
                            health: results[8],
                            daycare: results[9]
                        };

                        this._data[p.municipalityId] = data;

                        observer.next(data);
                        observer.complete();
                    });
            }
            else {
                let data = this._data[p.municipalityId];
                observer.next(data);
                observer.complete();
            }
        })
    }

    public getPopulationOriginData(municipalityId: number, typeId: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationOriginData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.origin);
                    observer.complete();
                });
        })
    }
    public getPopulationEducationData(municipalityId: number, typeId: string): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationEducationData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.education);
                    observer.complete();
                });
        })
    }
    public getPopulationFamilyCompositionData(municipalityId: number, typeId: string): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationFamilyCompositionData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.familyComposition);
                    observer.complete();
                });
        })
    }
    public getPopulationCivilStatusData(municipalityId: number, typeId: string): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationCivilStatusData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.civilStatus);
                    observer.complete();
                });
        })
    }
    public getPopulationGrowthData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationGrowthData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.growth);
                    observer.complete();
                });
        })
    }
    public getPopulationImmigrationData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationImmigrationData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.immigration);
                    observer.complete();
                });
        })
    }
    public getPopulationAgeData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationAgeData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.population.age);
                    observer.complete();
                });
        })
    }




    public getSafetyCrimeReportingData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getSafetyCrimeReportingData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.safety.crimeReporting);
                    observer.complete();
                });
        })
    }




    public getFreetimeExpensesData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getFreetimeExpensesData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.freetime.expenses);
                    observer.complete();
                });
        })
    }




    public getEconomyIncomeData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getEconomyIncomeData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.economy.income);
                    observer.complete();
                });
        })
    }

    public getEconomyTaxData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getEconomyTaxData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.economy.tax);
                    observer.complete();
                });
        })
    }






    public getResidentialHousingData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialHousingData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.residential.housings);
                    observer.complete();
                });
        })
    }

    public getResidentialPriceData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialPriceData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.residential.price);
                    observer.complete();
                });
        })
    }

    public getResidentialSalesPeriodData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialSalesPeriodData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.residential.salesPeriod);
                    observer.complete();
                });
        })
    }

    public getResidentialConstructionYearData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialConstructionYearData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.residential.constructionYear);
                    observer.complete();
                });
        })
    }

    public getResidentialSizeData(municipalityId: number, typeId: number): any {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialSizeData(municipalityId, typeId)
                .subscribe((respData: any) => {
                    observer.next(respData.residential.size);
                    observer.complete();
                });
        })
    }






    public getDaycareAvailabilityData(municipalityId: number, availabilityType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getDaycareAvailabilityData(municipalityId, availabilityType)
                .subscribe(respData => {
                    observer.next(respData.daycare.availability);
                    observer.complete();
                });
        })
    }


    public getDaycareFaresData(municipalityId: number, fareType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getDaycareFaresData(municipalityId, fareType)
                .subscribe(respData => {
                    observer.next(respData.daycare.fares);
                    observer.complete();
                });
        })
    }






    private getCommonData(): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getCommonData()
                .subscribe(respData => {
                    observer.next(respData.settings.frontend);
                    observer.complete();
                });
        })
    }




    private getOverviewSummaryData(municipalityId: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getOverviewSummaryData(municipalityId)
                .subscribe(respData => {
                    observer.next(respData.summary);
                    observer.complete();
                });
        })
    }


    

    private getSafetyData(municipalityId: number, violationType: number = null): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getSafetyData(municipalityId, violationType)
                .subscribe(respData => {
                    observer.next(respData.safety);
                    observer.complete();
                });
        })
    }

    private getPopulationData(municipalityId: number, civilStatusType: string, familyCompositionType: string, originType: number, educationType: string, ageType: number, growthType: number, immigrationType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getPopulationData(municipalityId, civilStatusType, familyCompositionType, originType, educationType, ageType, growthType, immigrationType)
                .subscribe((respData: any) => {
                    observer.next(respData.population);
                    observer.complete();
                });
        })
    }

    private getEconomyData(municipalityId: number, propertyCategoryId: number, incomeType: number, taxType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getEconomyData(municipalityId, propertyCategoryId, incomeType, taxType)
                .subscribe(respData => {
                    observer.next(respData.economy);
                    observer.complete();
                });
        })
    }

    private getEducationData(municipalityId: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getEducationData(municipalityId)
                .subscribe(respData => {
                    observer.next(respData.education);
                    observer.complete();
                });
        })
    }

    private getFreetimeData(municipalityId: number, expenseType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getFreetimeData(municipalityId, expenseType)
                .subscribe(respData => {
                    observer.next(respData.freetime);
                    observer.complete();
                });
        })
    }

    private getResidentialData(municipalityId: number, housingType: number, propertyType: number, yearType: number, sizeType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getResidentialData(municipalityId, housingType, propertyType, yearType, sizeType)
                .subscribe(respData => {
                    observer.next(respData.residential);
                    observer.complete();
                });
        })
    }

    private getHealthData(municipalityId: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getHealthData(municipalityId)
                .subscribe(respData => {
                    observer.next(respData.health);
                    observer.complete();
                });
        })
    }

    private getDaycareData(municipalityId: number, availabilityType: number, fareType: number): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getDaycareData(municipalityId, availabilityType, fareType)
                .subscribe(respData => {
                    observer.next(respData.daycare);
                    observer.complete();
                });
        })
    }
}
