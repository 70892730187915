<div class="ui breadcrumb">
    <div class="active section" *ngIf="areaLevel == 10">{{path.country.core.name}}</div>
    <a class="section" *ngIf="areaLevel > 10" (click)="clickArea(10)"><span *ngIf="areaLevel < 30">{{path.country.core.name}}</span><span *ngIf="areaLevel >= 30">...</span></a>
    <i class="right angle icon divider" *ngIf="areaLevel > 10"></i>

    <div class="active section" *ngIf="areaLevel == 20">{{path.region.core.name}}</div>
    <a class="section" *ngIf="areaLevel > 20" (click)="clickArea(20, path.region.core.regionId)"><span *ngIf="areaLevel < 40">{{path.region.core.name}}</span><span *ngIf="areaLevel >= 40">...</span></a>
    <i class="right angle icon divider" *ngIf="areaLevel > 20"></i>

    <div class="active section" *ngIf="areaLevel == 30">{{path.municipality.core.name}}</div>
    <a class="section" *ngIf="areaLevel > 30" (click)="clickArea(30, path.municipality.core.regionId, path.municipality.core.municipalityId)">{{path.municipality.core.name}}</a>
    <i class="right angle icon divider" *ngIf="areaLevel > 30"></i>

    <div class="active section" *ngIf="areaLevel == 40">{{path.parish.core.name}}</div>
</div>