import { MenuItemsService } from 'src/app/municipality/menu-items.service';
import * as i0 from "@angular/core";
import * as i1 from "./menu-items.service";
var VisibilityService = /** @class */ (function () {
    function VisibilityService(_menuItemsService) {
        this._menuItemsService = _menuItemsService;
    }
    VisibilityService.prototype.setupLeftMenuAfterViewInit = function (elementId, isEnableTopMenu) {
        if (isEnableTopMenu === void 0) { isEnableTopMenu = false; }
        // LEFT MENU
        var $leftMenuContainer = $("app-left-menu .b-left-menu:not(.placeholder) .ui.menu");
        var $leftMenuParentItems = $leftMenuContainer.find("div.item");
        var $leftMenuParentInnerItems = $leftMenuContainer.find(".content.menu");
        var $leftMenuChildItems = $leftMenuContainer.find("a.item");
        var leftMenuFragments = this._menuItemsService.getAllChildItemFragments();
        // TOP MENU
        var $topMenuContainer = $("app-top-menu .ui.menu");
        var $topMenuItems = $topMenuContainer.find("a.item");
        var topMenuFragments = this._menuItemsService.getAllParentItemFragments();
        // Pull toggleActive into scope variable
        var toggleActive = this.toggleActive;
        var toggleActiveAll = this.toggleActiveAll;
        $("#" + elementId).visibility({
            observeChanges: false,
            once: false,
            offset: 116,
            onTopPassed: function () {
                // LEFT MENU
                var itemId = $(this).attr('id');
                var leftMenuFragment = itemId;
                var leftMenuIndex = leftMenuFragments.indexOf(leftMenuFragment);
                var $leftMenuItem = $leftMenuChildItems.eq(leftMenuIndex);
                toggleActiveAll(toggleActive, $leftMenuChildItems, $leftMenuItem, $leftMenuParentItems, $leftMenuParentInnerItems);
                // TOP MENU
                if (isEnableTopMenu) {
                    var topMenuFragment = itemId.split('-')[0];
                    var topMenuIndex = topMenuFragments.indexOf(topMenuFragment);
                    var $topMenuItem = $topMenuItems.eq(topMenuIndex);
                    toggleActive($topMenuItems, $topMenuItem);
                }
            },
            onTopPassedReverse: function () {
                // LEFT MENU
                var itemId = $(this).attr('id');
                var leftMenuFragment = itemId;
                var leftMenuIndex = leftMenuFragments.indexOf(leftMenuFragment);
                var $leftMenuItem = $leftMenuChildItems.eq(leftMenuIndex - 1);
                if (leftMenuIndex != 0) {
                    toggleActiveAll(toggleActive, $leftMenuChildItems, $leftMenuItem, $leftMenuParentItems, $leftMenuParentInnerItems);
                }
                // TOP MENU
                if (isEnableTopMenu) {
                    var topMenuFragment = itemId.split('-')[0];
                    var topMenuIndex = topMenuFragments.indexOf(topMenuFragment);
                    var $topMenuItem = $topMenuItems.eq(topMenuIndex - 1);
                    if (topMenuIndex != 0) {
                        toggleActive($topMenuItems, $topMenuItem);
                    }
                }
            }
        });
    };
    VisibilityService.prototype.toggleActiveAll = function (toggleActive, $menuChildItems, $menuChildItem, $menuParentItems, $menuParentInnerItems) {
        // Parent item needs to be found dynamically for previous items
        var $menuParentItem = $menuChildItem.parent().parent();
        var $menuParentInnerItem = $menuChildItem.parent();
        // Child font effect
        toggleActive($menuChildItems, $menuChildItem);
        // Parent background effect
        toggleActive($menuParentItems, $menuParentItem);
        // Parent collapse effect
        toggleActive($menuParentInnerItems, $menuParentInnerItem);
    };
    VisibilityService.prototype.toggleActive = function (items, item) {
        items
            .filter('.active')
            .removeClass('active');
        item
            .addClass('active');
    };
    VisibilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VisibilityService_Factory() { return new VisibilityService(i0.ɵɵinject(i1.MenuItemsService)); }, token: VisibilityService, providedIn: "root" });
    return VisibilityService;
}());
export { VisibilityService };
