import { Injectable, EventEmitter } from '@angular/core';
import { MarkerItem } from './marker-item';
import { MarkerType } from './marker-type';
import { MarkerFacilityType } from './marker-facility-type';
import { LayerType } from './layer-type';
import { BaseMarkerItem } from './base-marker-item';
import { IFeatureCollection, FeatureCollection } from '../shared/types/geometry/featureCollection';
import { PointGeometry } from '../shared/types/geometry/point';
import { Feature } from '../shared/types/geometry/feature';
import { MarkerTypeContainer } from './marker-type-container';
import { MarkerHighSchoolType } from './marker-high-school-type';
import { MarkerPrimarySchoolType } from './marker-primary-school-type';
import { BehaviorSubject } from 'rxjs';
import { MarkerDaycareType } from './marker-daycare-type';
import { DataSourceTypeContainer } from './data-source-type-container';
import { DataSourceType } from './data-source-type';
import { MarkerRealEstateType } from './marker-realestate-type';
import { MarkerSchoolDistrictType } from './marker-school-district-type';
import { MapService } from './map.service';

@Injectable({
    providedIn: 'root'
})
export class MarkerService {
    private _categories = {
        home: new MarkerItem(MarkerType.Home, null, DataSourceType.None, false, 'Home', 'home'),

        // nursery: new MarkerItem(MarkerType.Nursery, null, false, 'Vuggestuer', 'nursery'),
        // kindergarden: new MarkerItem(MarkerType.Kindergarden, null, false, 'Børnehaver', 'kindergarden'),
        daycare: {
            base: new BaseMarkerItem(MarkerType.Daycare, 'Dagtilbud', 'Vælg alderstrin', 'daycare', true),
            hasSubCategories: true,

            age0to2: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age0to2, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 0-2 år', 'age0to2', 'daycare'),
            age3to5: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age3to5, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 3-5 år', 'age3to5', 'daycare'),
            age0to5: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age0to5, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 0-5 år', 'age0to5', 'daycare'),
            // daycare: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Daycare, false, 'Dagpleje', 'daycare', 'daycare'),
            // age2to5: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age2to5, false, 'Alder 2-5 år', 'age2to5', 'daycare'),
            // recreation: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Recreation, false, 'Fritidshjem', 'recreation', 'daycare'),
        },
        privateDaycare: new MarkerItem(MarkerType.PrivateDaycare, null, DataSourceType.None, false, 'Privat børnepasning', 'privateDaycare'),
        primarySchool: {
            base: new BaseMarkerItem(MarkerType.PrimarySchool, 'Grundskoler', 'Vælg skoletype', 'primarySchool', true),
            hasSubCategories: true,

            government: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Government, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Folkeskoler', 'government', 'primarySchool'),
            private: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Private, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Private og frie skoler', 'private', 'primarySchool'),
            boarding: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Boarding, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Efterskoler', 'boarding', 'primarySchool'),
        },
        highSchool: {
            base: new BaseMarkerItem(MarkerType.HighSchool, 'Gymnasier', 'Vælg skoletype', 'highSchool', true),
            hasSubCategories: true,

            government: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.Government, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Statslige gymnasier', 'government', 'highSchool'),
            private: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.Private, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Private gymnasier', 'private', 'highSchool'),
            studentPreparation: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.StudentPreparation, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Studenterkurser', 'studentPreparation', 'highSchool'),
        },
        publicTransportation: new MarkerItem(MarkerType.PublicTransportation, null, DataSourceType.Rejseplanen, false, 'Offentlig transport', 'publicTransportation'),
        supermarket: new MarkerItem(MarkerType.Supermarket, null, DataSourceType.None, false, 'Supermarkeder', 'supermarket'),
        noise: new MarkerItem(MarkerType.Noise, null, DataSourceType.None, false, 'Støj', 'noise'),
        traficCount: new MarkerItem(MarkerType.TraficCount, null, DataSourceType.Vejdirektoratet, false, 'Trafik', 'traficCount'),
        doctor: new MarkerItem(MarkerType.Doctor, null, DataSourceType.SundhedsDataStyrelsen, false, 'Læger', 'doctor'),
        realEstate: {
            base: new BaseMarkerItem(MarkerType.RealEstate, 'Boliger til salg', 'Vælg boligtype', 'realEstate', false),
            hasSubCategories: true,

            cooperativeDwelling: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.CooperativeDwelling, DataSourceType.None, false, 'Andelsboliger', 'cooperativeDwelling', 'realEstate', true),
            ownerOccupiedFlat: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.OwnerOccupiedFlat, DataSourceType.None, false, 'Ejerlejligheder', 'ownerOccupiedFlat', 'realEstate', true),
            recreationalProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RecreationalProperty, DataSourceType.None, false, 'Fritidsboliger', 'recreationalProperty', 'realEstate', true),
            recreationalPlot: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RecreationalPlot, DataSourceType.None, false, 'Fritidsgrunde', 'recreationalPlot', 'realEstate', true),
            allYearRoundPlot: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.AllYearRoundPlot, DataSourceType.None, false, 'Helårsgrunde', 'allYearRoundPlot', 'realEstate', true),
            allotment: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.Allotment, DataSourceType.None, false, 'Kolonihaveer', 'allotment', 'realEstate', true),
            ruralProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RuralProperty, DataSourceType.None, false, 'Landejendomme', 'ruralProperty', 'realEstate', true),
            pleasureProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.PleasureProperty, DataSourceType.None, false, 'Lystejendomme', 'pleasureProperty', 'realEstate', true),
            townHouse: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.TownHouse, DataSourceType.None, false, 'Rækkehuse', 'townHouse', 'realEstate', true),
            brickHouse: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.BrickHouse, DataSourceType.None, false, 'Villaer', 'brickHouse', 'realEstate', true),
            brickHouseApartment: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.BrickHouseApartment, DataSourceType.None, false, 'Villalejligheder', 'brickHouseApartment', 'realEstate', true),
        },
        residentsForRent: new MarkerItem(MarkerType.ResidentsForRent, null, DataSourceType.None, false, 'Lejeboliger', 'residentsForRent'),
        sportsUnion: new MarkerItem(MarkerType.SportsUnion, null, DataSourceType.Dgi, false, 'Idrætsforeninger', 'sportsUnion'),
        facility: {
            base: new BaseMarkerItem(MarkerType.Facility, 'Faciliteter', 'Vælg facilitetstype', 'facility', true),
            hasSubCategories: true,

            badminton: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Badminton, DataSourceType.Facilitetsdatabasen, false, 'Badmintonhaller', 'badminton', 'facility'),
            curling: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Curling, DataSourceType.Facilitetsdatabasen, false, 'Curlinghaller', 'curling', 'facility'),
            fitness: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Fitness, DataSourceType.Facilitetsdatabasen, false, 'Fitnesscentre', 'fitness', 'facility'),
            golf: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Golf, DataSourceType.Facilitetsdatabasen, false, 'Golfanlæg', 'golf', 'facility'),
            horseriding: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Horseriding, DataSourceType.Facilitetsdatabasen, false, 'Rideanlæg', 'horseriding', 'facility'),
            icehockey: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Icehockey, DataSourceType.Facilitetsdatabasen, false, 'Skøjteanlæg', 'icehockey', 'facility'),
            olympicsite: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Olympicsite, DataSourceType.Facilitetsdatabasen, false, 'Atletikanlæg', 'olympicsite', 'facility'),
            shootingrange: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Shootingrange, DataSourceType.Facilitetsdatabasen, false, 'Skydeanlæg', 'shootingrange', 'facility'),
            soccer: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Soccer, DataSourceType.Facilitetsdatabasen, false, 'Fodboldanlæg', 'soccer', 'facility'),
            stadiumLarge: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumLarge, DataSourceType.Facilitetsdatabasen, false, 'Store idrætshaller', 'stadiumLarge', 'facility'),
            stadiumMedium: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumMedium, DataSourceType.Facilitetsdatabasen, false, 'Små idrætshaller', 'stadiumMedium', 'facility'),
            stadiumSmall: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumSmall, DataSourceType.Facilitetsdatabasen, false, 'Idrætslokaler/sale', 'stadiumSmall', 'facility'),
            swimming: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Swimming, DataSourceType.Facilitetsdatabasen, false, 'Svømmeanlæg', 'swimming', 'facility'),
            tennisIndoor: new MarkerItem(MarkerType.Facility, MarkerFacilityType.TennisIndoor, DataSourceType.Facilitetsdatabasen, false, 'Tennishaller', 'tennisIndoor', 'facility'),
            tennisOutdoor: new MarkerItem(MarkerType.Facility, MarkerFacilityType.TennisOutdoor, DataSourceType.Facilitetsdatabasen, false, 'Tennisanlæg, udendørs', 'tennisOutdoor', 'facility')
        },
        schoolDistrict: {
            base: new BaseMarkerItem(MarkerType.SchoolDistrict, 'Skoledistrikter', 'Vælg klassetrin', 'schoolDistrict', false),
            hasSubCategories: true,

            preSchool: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.PreSchool, DataSourceType.KommunernesLandsforening, false, 'Førskole', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class0: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class0, DataSourceType.KommunernesLandsforening, false, '0. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class1: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class1, DataSourceType.KommunernesLandsforening, false, '1. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class2: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class2, DataSourceType.KommunernesLandsforening, false, '2. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class3: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class3, DataSourceType.KommunernesLandsforening, false, '3. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class4: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class4, DataSourceType.KommunernesLandsforening, false, '4. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class5: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class5, DataSourceType.KommunernesLandsforening, false, '5. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class6: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class6, DataSourceType.KommunernesLandsforening, false, '6. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class7: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class7, DataSourceType.KommunernesLandsforening, false, '7. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class8: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class8, DataSourceType.KommunernesLandsforening, false, '8. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class9: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class9, DataSourceType.KommunernesLandsforening, false, '9. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            class10: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class10, DataSourceType.KommunernesLandsforening, false, '10. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
        },

        municipality: new MarkerItem(MarkerType.Municipality, null, DataSourceType.None, false, null, 'municipality', null, false, LayerType.Area),
        parish: new MarkerItem(MarkerType.Parish, null, DataSourceType.None, false, null, 'parish', null, false, LayerType.Area),
    }

    public onToggleCompare = new EventEmitter<any>();
    public onToggleComparisonVisibility = new EventEmitter<any>();
    public onToggleMarkerVisibility = new EventEmitter<any>();
    public onToggleSelect = new BehaviorSubject<{ item: any, markerItem: MarkerItem }>(null);

    public dataSourceTypeItems: DataSourceTypeContainer[] = [];
    public categories: any = this._categories;
    public visibleCategories: Array<MarkerItem | BaseMarkerItem> = [];

    public comparison: any = null;
    public selectedImageProperties = {
        iconUrl: '/assets/images/selected.png',
        imageId: 'marker-highlight-image',
        dataSourceId: 'marker-highlight-source',
        layerMarkersId: 'marker-highlight-layer'
    };

    public addItems(items: any[], markerTypeContainer: MarkerTypeContainer) {
        // Clear existing items
        this.clearMarkerItems(markerTypeContainer);

        if (items) {
            // Add new items
            for (const item of items) {
                this.addItem(item, markerTypeContainer);
            }
        }
    }

    public addSubCategories(subCategories: any[], markerType: MarkerType) {
        let markerTypeContainer = new MarkerTypeContainer(markerType);
        let prop = markerTypeContainer.markerTypePropertyName;
        this._categories[prop].base.addSubCategories(subCategories);
    }

    public addDataSourceTypes(dataSourceTypeItems: any[]) {
        // Add markeritems to array
        for (const item of dataSourceTypeItems) {
            let dataSourceTypeContainer = new DataSourceTypeContainer(item);
            this.dataSourceTypeItems.push(dataSourceTypeContainer);
        }

        // Assigning dataSourceTypeContainer's to each MarkerItem
        var markerItems = this.findMarkerItems();
        for (const markerItem of markerItems) {
            let dataSourceTypeContainer = this.getDataSourceTypeContainer(markerItem.dataSourceType);
            markerItem.setDataSourceTypeContainer(dataSourceTypeContainer);
        }
    }

    public setDataUpdated(dataUpdated: any, markerTypeContainer: MarkerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.setDataUpdated(dataUpdated);
    }

    public getDataSourceTypeContainer(dataSourceType: number): DataSourceTypeContainer {
        for (const item of this.dataSourceTypeItems) {
            if (dataSourceType == item.id) {
                return item;
            }
        }

        return null;
    }

    public getComparedItems(markerTypeContainer: MarkerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.comparedItems;
    }

    public isItemCompared(itemId: number, markerTypeContainer: MarkerTypeContainer): boolean {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.isItemCompared(itemId);
    }


    public toggleComparisonVisibility(markerTypeContainer: MarkerTypeContainer): void {
        var obj = this.findMarkerItem(markerTypeContainer);
        this.comparison = obj;

        this.onToggleComparisonVisibility.emit({
            markerItem: obj
        });
    }

    public getMarkerVisibility(markerTypeContainer: MarkerTypeContainer): boolean {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        return obj.showMarkers;
    }

    public getMarkerShowAllSubCategoriesEnabled(markerTypeContainer: MarkerTypeContainer): boolean {
        if (false == markerTypeContainer.isMarkerWithPossibleSubType)
            return false;

        var obj = this.findBaseMarkerItem(markerTypeContainer);
        return obj.isShowAllSubCategoriesEnabled;
    }

    public toggleMarkerVisibilityAndClearAllOther(markerTypeContainer: MarkerTypeContainer, isVisible: boolean, isShowAllSubCategoriesEnabled: boolean = false): MarkerItem | BaseMarkerItem {
        let obj = this.toggleMarkerVisibilityImpl(markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled);

        this.clearMarkersVisibilityExcept(markerTypeContainer);

        this.onToggleMarkerVisibility.emit({
            markerTypeContainer: markerTypeContainer,
            isVisible: obj.showMarkers,
            isAnyVisible: this.isAnyMarkerVisible(),
            isAnySubMarkerVisible: this.isAnySubMarkerVisible(),
        });

        return obj;
    }

    public toggleMarkerVisibility(markerTypeContainer: MarkerTypeContainer, isVisible: boolean, isShowAllSubCategoriesEnabled: boolean = false): MarkerItem | BaseMarkerItem {
        let obj = this.toggleMarkerVisibilityImpl(markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled);

        this.onToggleMarkerVisibility.emit({
            markerTypeContainer: markerTypeContainer,
            isVisible: obj.showMarkers,
            isAnyVisible: this.isAnyMarkerVisible(),
            isAnySubMarkerVisible: this.isAnySubMarkerVisible(),
        });

        return obj;
    }

    public clearMarkersVisibility(): void {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
            }
        }
    }

    public clearSubMarkersVisibility(markerTypeContainer: MarkerTypeContainer): void {
        var markerItems = (this.findMarkerItemsOrBaseMarkerItems() as MarkerItem[])
            .filter(x => x.markerTypeContainer.markerType == markerTypeContainer.markerType);
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
            }
        }
    }

    public clearMarkersVisibilityExcept(markerTypeContainer: MarkerTypeContainer): void {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers && false == markerTypeContainer.matches(markerItem.markerTypeContainer)) {
                if (false == markerTypeContainer.hasSubType || (markerTypeContainer.markerType == markerItem.markerTypeContainer.markerType && markerItem.markerTypeContainer.hasSubType)) {
                    this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
                }
            }
        }
    }

    public getVisibleMarker(): MarkerItem {
        var markerItems = this.findMarkerItems();
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                if (markerItem.showMarkers)
                    return markerItem;
            }
        }

        return null;
    }

    public isAnyMarkerVisible(): boolean {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers)
                return true;
        }

        return false;
    }

    public isAnySubMarkerVisible(): boolean {
        var markerItems = this.findMarkerItems();
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerItem.showMarkers)
                return true;
        }

        return false;
    }

    public isMarkerVisible(markerTypeContainer: MarkerTypeContainer): boolean {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        return obj.showMarkers;
    }

    public toggleCompare(itemId: number, markerTypeContainer: MarkerTypeContainer): boolean {
        var obj = this.findMarkerItem(markerTypeContainer);
        var isCompared = obj.toggleCompare(itemId);

        this.onToggleCompare.emit({
            layerComparisonId: obj.layerInfo.layerComparisonId,
            layerType: obj.layerTypeId,
            isCompared: isCompared,
            markerItem: obj
        });

        return isCompared;
    }

    public clearCompare(markerTypeContainer: MarkerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.clearCompare();

        this.onToggleCompare.emit({
            layerComparisonId: obj.layerInfo.layerComparisonId,
            layerTypeId: obj.layerTypeId,
            isCompared: false,
            markerItem: obj
        });
    }

    public isComparable(markerTypeContainer: MarkerTypeContainer): boolean {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.isComparable;
    }

    // Returns a set of (markerType, markerSubType) where items has been marked for comparison
    public getComparisonSets(): any[] {
        // Removing all items
        var comparisonSets = [];

        // Adding all items
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.comparedItems.length > 0) {
                        comparisonSets.push({ markerType: obj.markerTypeContainer.markerType, markerSubType: null, layerComparisonId: obj.layerInfo.layerComparisonId });
                    }
                }
                else {
                    for (var subProperty in obj) {
                        if (obj.hasOwnProperty(subProperty)) {
                            var subObj = obj[subProperty];
                            if (subObj instanceof MarkerItem) {
                                comparisonSets.push({ markerType: subObj.markerTypeContainer.markerType, markerSubType: subObj.markerTypeContainer.markerSubType, layerComparisonId: subObj.layerInfo.layerComparisonId });
                            }
                        }
                    }
                }
            }
        }

        return comparisonSets;
    }

    public toggleSelected(itemId: number, markerTypeContainer: MarkerTypeContainer): void {
        // Clearing all selected items except the item in consideration
        this.clearSelectedExcept(itemId, markerTypeContainer);

        // Toggling selected on the item in consideration
        let obj = this.findMarkerItem(markerTypeContainer);
        let item = obj.toggleSelected(itemId);

        this.onToggleSelect.next({
            item: item,
            markerItem: obj
        });
    }

    public getSelectedMarkerItemByType(markerTypeContainer: MarkerTypeContainer): any {
        let markerItem = this.getMarkerItemThatContainsTheSelectedItem();

        if (markerItem != null && markerTypeContainer.matches(markerItem.markerTypeContainer)) {
            return markerItem.getSelectedItem();
        }

        return null;
    }

    public getSelectedMarkerItemAsFeatureCollection(): IFeatureCollection {
        let markerItem = this.getMarkerItemThatContainsTheSelectedItem();

        let featureCollection: IFeatureCollection = new FeatureCollection() as IFeatureCollection;
        if (markerItem != null) {
            let selectedItem = markerItem.getSelectedItem();

            let geometry = new PointGeometry(selectedItem.geometry.coordinates);
            let properties = { id: selectedItem.id, markerType: markerItem.markerTypeContainer.markerType, markerSubType: markerItem.markerTypeContainer.markerSubType };
            let feature = new Feature(geometry, properties, selectedItem.id);
            featureCollection.addFeature(feature);
        }

        return featureCollection;
    }











    private toggleMarkerVisibilityImpl(markerTypeContainer: MarkerTypeContainer, isVisible: boolean, isShowAllSubcategoriesEnabled: boolean): MarkerItem | BaseMarkerItem {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        obj.toggleMarkerVisibility(isVisible);

        // Toggle selected on dropdownItems
        var baseItem = this.findBaseMarkerItem(new MarkerTypeContainer(markerTypeContainer.markerType));
        if (baseItem != null && markerTypeContainer.hasSubType) {
            baseItem.toggleDropdownSelected(markerTypeContainer.markerSubType, isVisible);
        }

        // If it is the first time a category with subcategories is enabled, all subcategories are selected by default
        if (isShowAllSubcategoriesEnabled) {
            this.showAllSubCategortiesIfFirstTime(markerTypeContainer);
        }

        // Add/remove item(s) to/from array
        this.updateVisibleMarkersArray(baseItem, markerTypeContainer, obj);

        return obj;
    }

    private showAllSubCategortiesIfFirstTime(markerTypeContainer: MarkerTypeContainer) {
        var baseItem = this.findBaseMarkerItem(new MarkerTypeContainer(markerTypeContainer.markerType));
        if (baseItem != null && baseItem.isFirstTimeShowingCategory) {
            var markerItems = this.findMarkerItems()
                .filter(x => x.markerTypeContainer.markerType == markerTypeContainer.markerType);

            for (const markerItem of markerItems) {
                markerItem.toggleMarkerVisibility(true);
                baseItem.toggleDropdownSelected(markerItem.markerTypeContainer.markerSubType, true);
            }

            baseItem.isFirstTimeShowingCategory = false;
        }
    }

    private updateVisibleMarkersArray(baseItem: BaseMarkerItem, markerTypeContainer: MarkerTypeContainer, obj: MarkerItem | BaseMarkerItem) {
        if (false == (baseItem != null && false == markerTypeContainer.hasSubType)) {
            if (obj.showMarkers) {
                // Checking whether element already exists in collection
                let hasMarkerItem = this.visibleCategories
                    .some(x => x.markerTypeContainer.matches(markerTypeContainer))

                // Only adding if item not already present
                if (false == hasMarkerItem) {
                    this.visibleCategories.unshift(obj);
                }
            }
            else {
                for (let i = 0; i < this.visibleCategories.length; i++) {
                    const item = <MarkerItem>this.visibleCategories[i];
                    if (item.markerTypeContainer.matches(markerTypeContainer)) {
                        this.visibleCategories.splice(i, 1);
                    }
                }
            }
        }
        else {
            if (false == obj.showMarkers) {
                // Removing all marker sub items for the markerType in question
                var markerItems = this.findMarkerItemsOrBaseMarkerItems()
                    .filter(x => x.markerTypeContainer.markerType == markerTypeContainer.markerType);
                for (const markerItem of markerItems) {
                    for (let i = 0; i < this.visibleCategories.length; i++) {
                        const item = <MarkerItem>this.visibleCategories[i];
                        if (markerItem.markerTypeContainer.matches(item.markerTypeContainer)) {
                            this.visibleCategories.splice(i, 1);
                        }
                    }
                }
            }
        }
    }

    private getMarkerItemThatContainsTheSelectedItem(): MarkerItem {
        let markerItems = this.findMarkerItems();
        for (const markerItem of markerItems) {
            if (markerItem.hasSelectedItem()) {
                return markerItem;
            }
        }

        return null;
    }

    private clearSelectedExcept(itemId: number, markerTypeContainer: MarkerTypeContainer): void {
        var markerItems = this.findMarkerItems();
        for (let i = 0; i < markerItems.length; i++) {
            const markerItem = markerItems[i];
            if (markerTypeContainer.matches(markerItem.markerTypeContainer)) {
                markerItem.clearSelectedExcept(itemId);
            }
            else {
                markerItem.clearSelected();
            }
        }
    }

    private addItem(item: any, markerTypeContainer: MarkerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.addItemIfNotExists(item);
    }

    private clearMarkerItems(markerTypeContainer: MarkerTypeContainer) {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        obj.clearItems();
                    }
                }
                else {
                    let baseMarkerItem = <BaseMarkerItem>obj.base;
                    if (baseMarkerItem.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        for (var subProperty in obj) {
                            if (obj.hasOwnProperty(subProperty)) {
                                var subObj = obj[subProperty];
                                if (subObj instanceof MarkerItem) {
                                    if (subObj.markerTypeContainer.markerSubType == markerTypeContainer.markerSubType) {
                                        subObj.clearItems();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private findMarkerItemOrBaseMarkerItem(markerTypeContainer: MarkerTypeContainer): MarkerItem | BaseMarkerItem {
        var baseMarkerItem = this.findBaseMarkerItem(markerTypeContainer);
        if (baseMarkerItem == null) {
            let markerItem = this.findMarkerItem(markerTypeContainer);
            return markerItem;
        }

        return baseMarkerItem;
    }

    private findMarkerItem(markerTypeContainer: MarkerTypeContainer): MarkerItem {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        return obj;
                    }
                }
                else {
                    let baseMarkerItem = <BaseMarkerItem>obj.base;
                    if (baseMarkerItem.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        if (obj.hasSubCategories == true && markerTypeContainer.hasSubType) {
                            for (var subProperty in obj) {
                                if (obj.hasOwnProperty(subProperty)) {
                                    var subObj = obj[subProperty];
                                    if (subObj instanceof MarkerItem) {
                                        if (subObj.markerTypeContainer.markerSubType == markerTypeContainer.markerSubType) {
                                            return subObj;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return null;
    }


    private findMarkerItems(): Array<MarkerItem> {
        return <Array<MarkerItem>>this.findMarkerItemsOrBaseMarkerItems();
    }

    private findMarkerItemsOrBaseMarkerItems(isIncludeBaseItems: boolean = false): Array<MarkerItem | BaseMarkerItem> {
        var markerItems: Array<MarkerItem | BaseMarkerItem> = [];

        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    markerItems.push(obj);
                }
                else if (obj.hasOwnProperty('base')) {
                    for (var subProperty in obj) {
                        if (obj.hasOwnProperty(subProperty)) {
                            var subObj = obj[subProperty];
                            if (subObj instanceof MarkerItem) {
                                markerItems.push(subObj);
                            }
                            else if (subObj instanceof BaseMarkerItem && isIncludeBaseItems) {
                                markerItems.push(subObj);
                            }
                        }
                    }
                }
            }
        }

        return markerItems;
    }

    private findBaseMarkerItem(markerTypeContainer: MarkerTypeContainer): BaseMarkerItem {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];

                // Looking for a "base" property first
                if (obj.hasOwnProperty('base')) {
                    let markerItem = <BaseMarkerItem>obj.base;
                    if (markerItem.markerTypeContainer.matches(markerTypeContainer)) {
                        return markerItem;
                    }
                }
            }
        }

        return null;
    }
}
