import { MarkerTypeContainer } from './marker-type-container';
var BaseMarkerItem = /** @class */ (function () {
    function BaseMarkerItem(markerType, headline, dropdownHeader, iconName, isShowAllSubCategoriesEnabled) {
        this._baseIconUrl = '../../assets/images/';
        this.isFirstTimeShowingCategory = true;
        this.isShowAllSubCategoriesEnabled = true;
        this.markerTypeContainer = new MarkerTypeContainer(markerType, null);
        this.headline = headline;
        this.dropdownHeader = dropdownHeader;
        this._iconUrlEnabled = this._baseIconUrl + iconName + '.png';
        this._iconUrlDisabled = this._baseIconUrl + 'disabled/' + iconName + '.png';
        this.isShowAllSubCategoriesEnabled = isShowAllSubCategoriesEnabled;
        this.iconUrl = this._iconUrlDisabled;
        this.showMarkers = false;
        this.subCategories = null;
        this.dropdownItems = [];
    }
    Object.defineProperty(BaseMarkerItem.prototype, "headlineLowered", {
        get: function () {
            return this.getLoweredName(this.headline);
        },
        enumerable: true,
        configurable: true
    });
    BaseMarkerItem.prototype.toggleMarkerVisibility = function (isVisible) {
        // If "isVisible" is not explicitly set, then set it to the opposite value of "showMarkers"
        if (isVisible == null)
            isVisible = !this.showMarkers;
        this.showMarkers = isVisible;
        if (isVisible)
            this.iconUrl = this._iconUrlEnabled;
        else
            this.iconUrl = this._iconUrlDisabled;
    };
    BaseMarkerItem.prototype.addSubCategories = function (subCategories) {
        if (this.subCategories == null) {
            // Only take subcategories that are actually enabled
            var availableSubCategories_1 = this.markerTypeContainer.markerSubTypeValues;
            var subCategoriesFiltered = subCategories
                .filter(function (x) { return availableSubCategories_1.includes(x.id); });
            // Adding facility types
            this.subCategories = subCategoriesFiltered;
            // Adding select elements
            for (var i = 0; i < subCategoriesFiltered.length; i++) {
                var element = subCategoriesFiltered[i];
                this.dropdownItems.push({
                    name: element.name,
                    value: element.id
                });
            }
        }
    };
    BaseMarkerItem.prototype.hasSubCategories = function () {
        return this.subCategories != null && this.subCategories.length > 0;
    };
    BaseMarkerItem.prototype.toggleDropdownSelected = function (markerSubType, isSelected) {
        for (var i = 0; i < this.dropdownItems.length; i++) {
            var item = this.dropdownItems[i];
            if (item.value == markerSubType) {
                item.selected = isSelected;
                break;
            }
        }
    };
    BaseMarkerItem.prototype.getLoweredName = function (name) {
        return name.charAt(0).toLowerCase() + name.slice(1);
    };
    return BaseMarkerItem;
}());
export { BaseMarkerItem };
