import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ScrollService {
    public scrollToId(id: string, offset: number = 80)
    {
        var element = document.getElementById(id);

        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        $('html, body').stop().animate({ scrollTop: offsetPosition }, 300);
    }
}