import { IAreaContainerGeometries, AreaContainerGeometries } from './area-container-geometries';
import { IMunicipality, Municipality } from '../municipality/types/municipality';
import { MultiPolygonGeometry } from '../shared/types/geometry/multiPolygon';
import { Feature } from '../shared/types/geometry/feature';
import { PointGeometry } from '../shared/types/geometry/point';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Bounds } from '../shared/types/geometry/bounds';
import { FeatureCollection } from '../shared/types/geometry/featureCollection';
import { IBaseAreaContainerItem } from './base-area-container-item';

export class AreaContainerMunicipalityItem implements IAreaContainerMunicipalityItem, IBaseAreaContainerItem {
    private _isGeometryAdded: boolean = false;
    private _isFactsheetAdded: boolean = false;

    core: IMunicipality;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    constructor() {
        this.geometry = new AreaContainerGeometries();
    }

    addCore(municipality: any): void {
        let core = new Municipality();
        core.init(municipality);
        this.core = core;
    }

    addGeometry(municipality: any): void {
        // Area
        let geometry = new MultiPolygonGeometry(municipality.geometry.coordinates);
        let properties = { municipalityId: municipality.municipalityId, regionId: municipality.regionId, areaLevel: AreaLevel.Municipality /*, bbox: new Bounds(municipality.bounds).bbox */ };
        let feature = new Feature(geometry, properties, municipality.municipalityId);
        this.geometry.areaFeature = feature;

        // Visual center
        let g = new PointGeometry([municipality.visualCenter.lng, municipality.visualCenter.lat]);
        let p = { municipalityId: municipality.municipalityId, regionId: municipality.regionId, name: municipality.name };
        let f = new Feature(g, p);
        this.geometry.centerFeature = f;

        // Visual center
        this.geometry.visualCenter = municipality.visualCenter;

        // Bounds
        let b = new Bounds(municipality.bounds);
        this.geometry.bounds = b;

        // Change state
        this._isGeometryAdded = true;
    }

    addFactsheet(factsheet: any): void {
        this.factsheet = factsheet;

        // Change state
        this._isFactsheetAdded = true;
    }


    hasGeometry(): boolean {
        return this._isGeometryAdded;
    }

    hasFactsheet(): boolean {
        return this._isFactsheetAdded;
    }
}

export interface IAreaContainerMunicipalityItem {
    core: IMunicipality;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    addCore(municipality: any): void;
    addGeometry(municipality: any): void;
    addFactsheet(factsheet: any): void;

    hasGeometry(): boolean;
    hasFactsheet(): boolean;
}