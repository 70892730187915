import { EventEmitter } from '@angular/core';
import { MarkerItem } from './marker-item';
import { MarkerType } from './marker-type';
import { MarkerFacilityType } from './marker-facility-type';
import { LayerType } from './layer-type';
import { BaseMarkerItem } from './base-marker-item';
import { FeatureCollection } from '../shared/types/geometry/featureCollection';
import { PointGeometry } from '../shared/types/geometry/point';
import { Feature } from '../shared/types/geometry/feature';
import { MarkerTypeContainer } from './marker-type-container';
import { MarkerHighSchoolType } from './marker-high-school-type';
import { MarkerPrimarySchoolType } from './marker-primary-school-type';
import { BehaviorSubject } from 'rxjs';
import { MarkerDaycareType } from './marker-daycare-type';
import { DataSourceTypeContainer } from './data-source-type-container';
import { DataSourceType } from './data-source-type';
import { MarkerRealEstateType } from './marker-realestate-type';
import { MarkerSchoolDistrictType } from './marker-school-district-type';
import * as i0 from "@angular/core";
var MarkerService = /** @class */ (function () {
    function MarkerService() {
        this._categories = {
            home: new MarkerItem(MarkerType.Home, null, DataSourceType.None, false, 'Home', 'home'),
            // nursery: new MarkerItem(MarkerType.Nursery, null, false, 'Vuggestuer', 'nursery'),
            // kindergarden: new MarkerItem(MarkerType.Kindergarden, null, false, 'Børnehaver', 'kindergarden'),
            daycare: {
                base: new BaseMarkerItem(MarkerType.Daycare, 'Dagtilbud', 'Vælg alderstrin', 'daycare', true),
                hasSubCategories: true,
                age0to2: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age0to2, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 0-2 år', 'age0to2', 'daycare'),
                age3to5: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age3to5, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 3-5 år', 'age3to5', 'daycare'),
                age0to5: new MarkerItem(MarkerType.Daycare, MarkerDaycareType.Age0to5, DataSourceType.BoerneOgUndervisningsMinisteriet, false, 'Alder 0-5 år', 'age0to5', 'daycare'),
            },
            privateDaycare: new MarkerItem(MarkerType.PrivateDaycare, null, DataSourceType.None, false, 'Privat børnepasning', 'privateDaycare'),
            primarySchool: {
                base: new BaseMarkerItem(MarkerType.PrimarySchool, 'Grundskoler', 'Vælg skoletype', 'primarySchool', true),
                hasSubCategories: true,
                government: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Government, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Folkeskoler', 'government', 'primarySchool'),
                private: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Private, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Private og frie skoler', 'private', 'primarySchool'),
                boarding: new MarkerItem(MarkerType.PrimarySchool, MarkerPrimarySchoolType.Boarding, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Efterskoler', 'boarding', 'primarySchool'),
            },
            highSchool: {
                base: new BaseMarkerItem(MarkerType.HighSchool, 'Gymnasier', 'Vælg skoletype', 'highSchool', true),
                hasSubCategories: true,
                government: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.Government, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Statslige gymnasier', 'government', 'highSchool'),
                private: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.Private, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Private gymnasier', 'private', 'highSchool'),
                studentPreparation: new MarkerItem(MarkerType.HighSchool, MarkerHighSchoolType.StudentPreparation, DataSourceType.BoerneOgUndervisningsMinisteriet, true, 'Studenterkurser', 'studentPreparation', 'highSchool'),
            },
            publicTransportation: new MarkerItem(MarkerType.PublicTransportation, null, DataSourceType.Rejseplanen, false, 'Offentlig transport', 'publicTransportation'),
            supermarket: new MarkerItem(MarkerType.Supermarket, null, DataSourceType.None, false, 'Supermarkeder', 'supermarket'),
            noise: new MarkerItem(MarkerType.Noise, null, DataSourceType.None, false, 'Støj', 'noise'),
            traficCount: new MarkerItem(MarkerType.TraficCount, null, DataSourceType.Vejdirektoratet, false, 'Trafik', 'traficCount'),
            doctor: new MarkerItem(MarkerType.Doctor, null, DataSourceType.SundhedsDataStyrelsen, false, 'Læger', 'doctor'),
            realEstate: {
                base: new BaseMarkerItem(MarkerType.RealEstate, 'Boliger til salg', 'Vælg boligtype', 'realEstate', false),
                hasSubCategories: true,
                cooperativeDwelling: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.CooperativeDwelling, DataSourceType.None, false, 'Andelsboliger', 'cooperativeDwelling', 'realEstate', true),
                ownerOccupiedFlat: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.OwnerOccupiedFlat, DataSourceType.None, false, 'Ejerlejligheder', 'ownerOccupiedFlat', 'realEstate', true),
                recreationalProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RecreationalProperty, DataSourceType.None, false, 'Fritidsboliger', 'recreationalProperty', 'realEstate', true),
                recreationalPlot: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RecreationalPlot, DataSourceType.None, false, 'Fritidsgrunde', 'recreationalPlot', 'realEstate', true),
                allYearRoundPlot: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.AllYearRoundPlot, DataSourceType.None, false, 'Helårsgrunde', 'allYearRoundPlot', 'realEstate', true),
                allotment: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.Allotment, DataSourceType.None, false, 'Kolonihaveer', 'allotment', 'realEstate', true),
                ruralProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.RuralProperty, DataSourceType.None, false, 'Landejendomme', 'ruralProperty', 'realEstate', true),
                pleasureProperty: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.PleasureProperty, DataSourceType.None, false, 'Lystejendomme', 'pleasureProperty', 'realEstate', true),
                townHouse: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.TownHouse, DataSourceType.None, false, 'Rækkehuse', 'townHouse', 'realEstate', true),
                brickHouse: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.BrickHouse, DataSourceType.None, false, 'Villaer', 'brickHouse', 'realEstate', true),
                brickHouseApartment: new MarkerItem(MarkerType.RealEstate, MarkerRealEstateType.BrickHouseApartment, DataSourceType.None, false, 'Villalejligheder', 'brickHouseApartment', 'realEstate', true),
            },
            residentsForRent: new MarkerItem(MarkerType.ResidentsForRent, null, DataSourceType.None, false, 'Lejeboliger', 'residentsForRent'),
            sportsUnion: new MarkerItem(MarkerType.SportsUnion, null, DataSourceType.Dgi, false, 'Idrætsforeninger', 'sportsUnion'),
            facility: {
                base: new BaseMarkerItem(MarkerType.Facility, 'Faciliteter', 'Vælg facilitetstype', 'facility', true),
                hasSubCategories: true,
                badminton: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Badminton, DataSourceType.Facilitetsdatabasen, false, 'Badmintonhaller', 'badminton', 'facility'),
                curling: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Curling, DataSourceType.Facilitetsdatabasen, false, 'Curlinghaller', 'curling', 'facility'),
                fitness: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Fitness, DataSourceType.Facilitetsdatabasen, false, 'Fitnesscentre', 'fitness', 'facility'),
                golf: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Golf, DataSourceType.Facilitetsdatabasen, false, 'Golfanlæg', 'golf', 'facility'),
                horseriding: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Horseriding, DataSourceType.Facilitetsdatabasen, false, 'Rideanlæg', 'horseriding', 'facility'),
                icehockey: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Icehockey, DataSourceType.Facilitetsdatabasen, false, 'Skøjteanlæg', 'icehockey', 'facility'),
                olympicsite: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Olympicsite, DataSourceType.Facilitetsdatabasen, false, 'Atletikanlæg', 'olympicsite', 'facility'),
                shootingrange: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Shootingrange, DataSourceType.Facilitetsdatabasen, false, 'Skydeanlæg', 'shootingrange', 'facility'),
                soccer: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Soccer, DataSourceType.Facilitetsdatabasen, false, 'Fodboldanlæg', 'soccer', 'facility'),
                stadiumLarge: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumLarge, DataSourceType.Facilitetsdatabasen, false, 'Store idrætshaller', 'stadiumLarge', 'facility'),
                stadiumMedium: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumMedium, DataSourceType.Facilitetsdatabasen, false, 'Små idrætshaller', 'stadiumMedium', 'facility'),
                stadiumSmall: new MarkerItem(MarkerType.Facility, MarkerFacilityType.StadiumSmall, DataSourceType.Facilitetsdatabasen, false, 'Idrætslokaler/sale', 'stadiumSmall', 'facility'),
                swimming: new MarkerItem(MarkerType.Facility, MarkerFacilityType.Swimming, DataSourceType.Facilitetsdatabasen, false, 'Svømmeanlæg', 'swimming', 'facility'),
                tennisIndoor: new MarkerItem(MarkerType.Facility, MarkerFacilityType.TennisIndoor, DataSourceType.Facilitetsdatabasen, false, 'Tennishaller', 'tennisIndoor', 'facility'),
                tennisOutdoor: new MarkerItem(MarkerType.Facility, MarkerFacilityType.TennisOutdoor, DataSourceType.Facilitetsdatabasen, false, 'Tennisanlæg, udendørs', 'tennisOutdoor', 'facility')
            },
            schoolDistrict: {
                base: new BaseMarkerItem(MarkerType.SchoolDistrict, 'Skoledistrikter', 'Vælg klassetrin', 'schoolDistrict', false),
                hasSubCategories: true,
                preSchool: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.PreSchool, DataSourceType.KommunernesLandsforening, false, 'Førskole', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class0: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class0, DataSourceType.KommunernesLandsforening, false, '0. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class1: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class1, DataSourceType.KommunernesLandsforening, false, '1. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class2: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class2, DataSourceType.KommunernesLandsforening, false, '2. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class3: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class3, DataSourceType.KommunernesLandsforening, false, '3. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class4: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class4, DataSourceType.KommunernesLandsforening, false, '4. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class5: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class5, DataSourceType.KommunernesLandsforening, false, '5. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class6: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class6, DataSourceType.KommunernesLandsforening, false, '6. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class7: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class7, DataSourceType.KommunernesLandsforening, false, '7. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class8: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class8, DataSourceType.KommunernesLandsforening, false, '8. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class9: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class9, DataSourceType.KommunernesLandsforening, false, '9. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
                class10: new MarkerItem(MarkerType.SchoolDistrict, MarkerSchoolDistrictType.Class10, DataSourceType.KommunernesLandsforening, false, '10. klasse', 'schoolDistrict', null, false, LayerType.Area, '#663bb7'),
            },
            municipality: new MarkerItem(MarkerType.Municipality, null, DataSourceType.None, false, null, 'municipality', null, false, LayerType.Area),
            parish: new MarkerItem(MarkerType.Parish, null, DataSourceType.None, false, null, 'parish', null, false, LayerType.Area),
        };
        this.onToggleCompare = new EventEmitter();
        this.onToggleComparisonVisibility = new EventEmitter();
        this.onToggleMarkerVisibility = new EventEmitter();
        this.onToggleSelect = new BehaviorSubject(null);
        this.dataSourceTypeItems = [];
        this.categories = this._categories;
        this.visibleCategories = [];
        this.comparison = null;
        this.selectedImageProperties = {
            iconUrl: '/assets/images/selected.png',
            imageId: 'marker-highlight-image',
            dataSourceId: 'marker-highlight-source',
            layerMarkersId: 'marker-highlight-layer'
        };
    }
    MarkerService.prototype.addItems = function (items, markerTypeContainer) {
        // Clear existing items
        this.clearMarkerItems(markerTypeContainer);
        if (items) {
            // Add new items
            for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
                var item = items_1[_i];
                this.addItem(item, markerTypeContainer);
            }
        }
    };
    MarkerService.prototype.addSubCategories = function (subCategories, markerType) {
        var markerTypeContainer = new MarkerTypeContainer(markerType);
        var prop = markerTypeContainer.markerTypePropertyName;
        this._categories[prop].base.addSubCategories(subCategories);
    };
    MarkerService.prototype.addDataSourceTypes = function (dataSourceTypeItems) {
        // Add markeritems to array
        for (var _i = 0, dataSourceTypeItems_1 = dataSourceTypeItems; _i < dataSourceTypeItems_1.length; _i++) {
            var item = dataSourceTypeItems_1[_i];
            var dataSourceTypeContainer = new DataSourceTypeContainer(item);
            this.dataSourceTypeItems.push(dataSourceTypeContainer);
        }
        // Assigning dataSourceTypeContainer's to each MarkerItem
        var markerItems = this.findMarkerItems();
        for (var _a = 0, markerItems_1 = markerItems; _a < markerItems_1.length; _a++) {
            var markerItem = markerItems_1[_a];
            var dataSourceTypeContainer = this.getDataSourceTypeContainer(markerItem.dataSourceType);
            markerItem.setDataSourceTypeContainer(dataSourceTypeContainer);
        }
    };
    MarkerService.prototype.setDataUpdated = function (dataUpdated, markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.setDataUpdated(dataUpdated);
    };
    MarkerService.prototype.getDataSourceTypeContainer = function (dataSourceType) {
        for (var _i = 0, _a = this.dataSourceTypeItems; _i < _a.length; _i++) {
            var item = _a[_i];
            if (dataSourceType == item.id) {
                return item;
            }
        }
        return null;
    };
    MarkerService.prototype.getComparedItems = function (markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.comparedItems;
    };
    MarkerService.prototype.isItemCompared = function (itemId, markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.isItemCompared(itemId);
    };
    MarkerService.prototype.toggleComparisonVisibility = function (markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        this.comparison = obj;
        this.onToggleComparisonVisibility.emit({
            markerItem: obj
        });
    };
    MarkerService.prototype.getMarkerVisibility = function (markerTypeContainer) {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        return obj.showMarkers;
    };
    MarkerService.prototype.getMarkerShowAllSubCategoriesEnabled = function (markerTypeContainer) {
        if (false == markerTypeContainer.isMarkerWithPossibleSubType)
            return false;
        var obj = this.findBaseMarkerItem(markerTypeContainer);
        return obj.isShowAllSubCategoriesEnabled;
    };
    MarkerService.prototype.toggleMarkerVisibilityAndClearAllOther = function (markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled) {
        if (isShowAllSubCategoriesEnabled === void 0) { isShowAllSubCategoriesEnabled = false; }
        var obj = this.toggleMarkerVisibilityImpl(markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled);
        this.clearMarkersVisibilityExcept(markerTypeContainer);
        this.onToggleMarkerVisibility.emit({
            markerTypeContainer: markerTypeContainer,
            isVisible: obj.showMarkers,
            isAnyVisible: this.isAnyMarkerVisible(),
            isAnySubMarkerVisible: this.isAnySubMarkerVisible(),
        });
        return obj;
    };
    MarkerService.prototype.toggleMarkerVisibility = function (markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled) {
        if (isShowAllSubCategoriesEnabled === void 0) { isShowAllSubCategoriesEnabled = false; }
        var obj = this.toggleMarkerVisibilityImpl(markerTypeContainer, isVisible, isShowAllSubCategoriesEnabled);
        this.onToggleMarkerVisibility.emit({
            markerTypeContainer: markerTypeContainer,
            isVisible: obj.showMarkers,
            isAnyVisible: this.isAnyMarkerVisible(),
            isAnySubMarkerVisible: this.isAnySubMarkerVisible(),
        });
        return obj;
    };
    MarkerService.prototype.clearMarkersVisibility = function () {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
            }
        }
    };
    MarkerService.prototype.clearSubMarkersVisibility = function (markerTypeContainer) {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems()
            .filter(function (x) { return x.markerTypeContainer.markerType == markerTypeContainer.markerType; });
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
            }
        }
    };
    MarkerService.prototype.clearMarkersVisibilityExcept = function (markerTypeContainer) {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers && false == markerTypeContainer.matches(markerItem.markerTypeContainer)) {
                if (false == markerTypeContainer.hasSubType || (markerTypeContainer.markerType == markerItem.markerTypeContainer.markerType && markerItem.markerTypeContainer.hasSubType)) {
                    this.toggleMarkerVisibility(markerItem.markerTypeContainer, false);
                }
            }
        }
    };
    MarkerService.prototype.getVisibleMarker = function () {
        var markerItems = this.findMarkerItems();
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers) {
                if (markerItem.showMarkers)
                    return markerItem;
            }
        }
        return null;
    };
    MarkerService.prototype.isAnyMarkerVisible = function () {
        var markerItems = this.findMarkerItemsOrBaseMarkerItems(true);
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers)
                return true;
        }
        return false;
    };
    MarkerService.prototype.isAnySubMarkerVisible = function () {
        var markerItems = this.findMarkerItems();
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerItem.showMarkers)
                return true;
        }
        return false;
    };
    MarkerService.prototype.isMarkerVisible = function (markerTypeContainer) {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        return obj.showMarkers;
    };
    MarkerService.prototype.toggleCompare = function (itemId, markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        var isCompared = obj.toggleCompare(itemId);
        this.onToggleCompare.emit({
            layerComparisonId: obj.layerInfo.layerComparisonId,
            layerType: obj.layerTypeId,
            isCompared: isCompared,
            markerItem: obj
        });
        return isCompared;
    };
    MarkerService.prototype.clearCompare = function (markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.clearCompare();
        this.onToggleCompare.emit({
            layerComparisonId: obj.layerInfo.layerComparisonId,
            layerTypeId: obj.layerTypeId,
            isCompared: false,
            markerItem: obj
        });
    };
    MarkerService.prototype.isComparable = function (markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        return obj.isComparable;
    };
    // Returns a set of (markerType, markerSubType) where items has been marked for comparison
    MarkerService.prototype.getComparisonSets = function () {
        // Removing all items
        var comparisonSets = [];
        // Adding all items
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.comparedItems.length > 0) {
                        comparisonSets.push({ markerType: obj.markerTypeContainer.markerType, markerSubType: null, layerComparisonId: obj.layerInfo.layerComparisonId });
                    }
                }
                else {
                    for (var subProperty in obj) {
                        if (obj.hasOwnProperty(subProperty)) {
                            var subObj = obj[subProperty];
                            if (subObj instanceof MarkerItem) {
                                comparisonSets.push({ markerType: subObj.markerTypeContainer.markerType, markerSubType: subObj.markerTypeContainer.markerSubType, layerComparisonId: subObj.layerInfo.layerComparisonId });
                            }
                        }
                    }
                }
            }
        }
        return comparisonSets;
    };
    MarkerService.prototype.toggleSelected = function (itemId, markerTypeContainer) {
        // Clearing all selected items except the item in consideration
        this.clearSelectedExcept(itemId, markerTypeContainer);
        // Toggling selected on the item in consideration
        var obj = this.findMarkerItem(markerTypeContainer);
        var item = obj.toggleSelected(itemId);
        this.onToggleSelect.next({
            item: item,
            markerItem: obj
        });
    };
    MarkerService.prototype.getSelectedMarkerItemByType = function (markerTypeContainer) {
        var markerItem = this.getMarkerItemThatContainsTheSelectedItem();
        if (markerItem != null && markerTypeContainer.matches(markerItem.markerTypeContainer)) {
            return markerItem.getSelectedItem();
        }
        return null;
    };
    MarkerService.prototype.getSelectedMarkerItemAsFeatureCollection = function () {
        var markerItem = this.getMarkerItemThatContainsTheSelectedItem();
        var featureCollection = new FeatureCollection();
        if (markerItem != null) {
            var selectedItem = markerItem.getSelectedItem();
            var geometry = new PointGeometry(selectedItem.geometry.coordinates);
            var properties = { id: selectedItem.id, markerType: markerItem.markerTypeContainer.markerType, markerSubType: markerItem.markerTypeContainer.markerSubType };
            var feature = new Feature(geometry, properties, selectedItem.id);
            featureCollection.addFeature(feature);
        }
        return featureCollection;
    };
    MarkerService.prototype.toggleMarkerVisibilityImpl = function (markerTypeContainer, isVisible, isShowAllSubcategoriesEnabled) {
        var obj = this.findMarkerItemOrBaseMarkerItem(markerTypeContainer);
        obj.toggleMarkerVisibility(isVisible);
        // Toggle selected on dropdownItems
        var baseItem = this.findBaseMarkerItem(new MarkerTypeContainer(markerTypeContainer.markerType));
        if (baseItem != null && markerTypeContainer.hasSubType) {
            baseItem.toggleDropdownSelected(markerTypeContainer.markerSubType, isVisible);
        }
        // If it is the first time a category with subcategories is enabled, all subcategories are selected by default
        if (isShowAllSubcategoriesEnabled) {
            this.showAllSubCategortiesIfFirstTime(markerTypeContainer);
        }
        // Add/remove item(s) to/from array
        this.updateVisibleMarkersArray(baseItem, markerTypeContainer, obj);
        return obj;
    };
    MarkerService.prototype.showAllSubCategortiesIfFirstTime = function (markerTypeContainer) {
        var baseItem = this.findBaseMarkerItem(new MarkerTypeContainer(markerTypeContainer.markerType));
        if (baseItem != null && baseItem.isFirstTimeShowingCategory) {
            var markerItems = this.findMarkerItems()
                .filter(function (x) { return x.markerTypeContainer.markerType == markerTypeContainer.markerType; });
            for (var _i = 0, markerItems_2 = markerItems; _i < markerItems_2.length; _i++) {
                var markerItem = markerItems_2[_i];
                markerItem.toggleMarkerVisibility(true);
                baseItem.toggleDropdownSelected(markerItem.markerTypeContainer.markerSubType, true);
            }
            baseItem.isFirstTimeShowingCategory = false;
        }
    };
    MarkerService.prototype.updateVisibleMarkersArray = function (baseItem, markerTypeContainer, obj) {
        if (false == (baseItem != null && false == markerTypeContainer.hasSubType)) {
            if (obj.showMarkers) {
                // Checking whether element already exists in collection
                var hasMarkerItem = this.visibleCategories
                    .some(function (x) { return x.markerTypeContainer.matches(markerTypeContainer); });
                // Only adding if item not already present
                if (false == hasMarkerItem) {
                    this.visibleCategories.unshift(obj);
                }
            }
            else {
                for (var i = 0; i < this.visibleCategories.length; i++) {
                    var item = this.visibleCategories[i];
                    if (item.markerTypeContainer.matches(markerTypeContainer)) {
                        this.visibleCategories.splice(i, 1);
                    }
                }
            }
        }
        else {
            if (false == obj.showMarkers) {
                // Removing all marker sub items for the markerType in question
                var markerItems = this.findMarkerItemsOrBaseMarkerItems()
                    .filter(function (x) { return x.markerTypeContainer.markerType == markerTypeContainer.markerType; });
                for (var _i = 0, markerItems_3 = markerItems; _i < markerItems_3.length; _i++) {
                    var markerItem = markerItems_3[_i];
                    for (var i = 0; i < this.visibleCategories.length; i++) {
                        var item = this.visibleCategories[i];
                        if (markerItem.markerTypeContainer.matches(item.markerTypeContainer)) {
                            this.visibleCategories.splice(i, 1);
                        }
                    }
                }
            }
        }
    };
    MarkerService.prototype.getMarkerItemThatContainsTheSelectedItem = function () {
        var markerItems = this.findMarkerItems();
        for (var _i = 0, markerItems_4 = markerItems; _i < markerItems_4.length; _i++) {
            var markerItem = markerItems_4[_i];
            if (markerItem.hasSelectedItem()) {
                return markerItem;
            }
        }
        return null;
    };
    MarkerService.prototype.clearSelectedExcept = function (itemId, markerTypeContainer) {
        var markerItems = this.findMarkerItems();
        for (var i = 0; i < markerItems.length; i++) {
            var markerItem = markerItems[i];
            if (markerTypeContainer.matches(markerItem.markerTypeContainer)) {
                markerItem.clearSelectedExcept(itemId);
            }
            else {
                markerItem.clearSelected();
            }
        }
    };
    MarkerService.prototype.addItem = function (item, markerTypeContainer) {
        var obj = this.findMarkerItem(markerTypeContainer);
        obj.addItemIfNotExists(item);
    };
    MarkerService.prototype.clearMarkerItems = function (markerTypeContainer) {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        obj.clearItems();
                    }
                }
                else {
                    var baseMarkerItem = obj.base;
                    if (baseMarkerItem.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        for (var subProperty in obj) {
                            if (obj.hasOwnProperty(subProperty)) {
                                var subObj = obj[subProperty];
                                if (subObj instanceof MarkerItem) {
                                    if (subObj.markerTypeContainer.markerSubType == markerTypeContainer.markerSubType) {
                                        subObj.clearItems();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };
    MarkerService.prototype.findMarkerItemOrBaseMarkerItem = function (markerTypeContainer) {
        var baseMarkerItem = this.findBaseMarkerItem(markerTypeContainer);
        if (baseMarkerItem == null) {
            var markerItem = this.findMarkerItem(markerTypeContainer);
            return markerItem;
        }
        return baseMarkerItem;
    };
    MarkerService.prototype.findMarkerItem = function (markerTypeContainer) {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    if (obj.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        return obj;
                    }
                }
                else {
                    var baseMarkerItem = obj.base;
                    if (baseMarkerItem.markerTypeContainer.markerType == markerTypeContainer.markerType) {
                        if (obj.hasSubCategories == true && markerTypeContainer.hasSubType) {
                            for (var subProperty in obj) {
                                if (obj.hasOwnProperty(subProperty)) {
                                    var subObj = obj[subProperty];
                                    if (subObj instanceof MarkerItem) {
                                        if (subObj.markerTypeContainer.markerSubType == markerTypeContainer.markerSubType) {
                                            return subObj;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null;
    };
    MarkerService.prototype.findMarkerItems = function () {
        return this.findMarkerItemsOrBaseMarkerItems();
    };
    MarkerService.prototype.findMarkerItemsOrBaseMarkerItems = function (isIncludeBaseItems) {
        if (isIncludeBaseItems === void 0) { isIncludeBaseItems = false; }
        var markerItems = [];
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                if (obj instanceof MarkerItem) {
                    markerItems.push(obj);
                }
                else if (obj.hasOwnProperty('base')) {
                    for (var subProperty in obj) {
                        if (obj.hasOwnProperty(subProperty)) {
                            var subObj = obj[subProperty];
                            if (subObj instanceof MarkerItem) {
                                markerItems.push(subObj);
                            }
                            else if (subObj instanceof BaseMarkerItem && isIncludeBaseItems) {
                                markerItems.push(subObj);
                            }
                        }
                    }
                }
            }
        }
        return markerItems;
    };
    MarkerService.prototype.findBaseMarkerItem = function (markerTypeContainer) {
        for (var property in this._categories) {
            if (this._categories.hasOwnProperty(property)) {
                var obj = this._categories[property];
                // Looking for a "base" property first
                if (obj.hasOwnProperty('base')) {
                    var markerItem = obj.base;
                    if (markerItem.markerTypeContainer.matches(markerTypeContainer)) {
                        return markerItem;
                    }
                }
            }
        }
        return null;
    };
    MarkerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MarkerService_Factory() { return new MarkerService(); }, token: MarkerService, providedIn: "root" });
    return MarkerService;
}());
export { MarkerService };
