import { SentryService } from './sentry.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./sentry.service";
var HotjarService = /** @class */ (function () {
    function HotjarService(_sentryService) {
        this._sentryService = _sentryService;
    }
    HotjarService.prototype.startTracking = function () {
        if (environment.keys.hotjar.isEnabled) {
            this.appendTrackingCode();
        }
    };
    HotjarService.prototype.appendTrackingCode = function () {
        try {
            var script = document.createElement('script');
            script.innerHTML = "\n                (function(h,o,t,j,a,r){\n                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};\n                    h._hjSettings={hjid:" + environment.keys.hotjar.key + ",hjsv:6};\n                    a=o.getElementsByTagName('head')[0];\n                    r=o.createElement('script');r.async=1;\n                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;\n                    a.appendChild(r);\n                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');\n            ";
            document.head.appendChild(script);
        }
        catch (ex) {
            this._sentryService.captureException(new Error('Error appending hotjar'));
        }
    };
    HotjarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HotjarService_Factory() { return new HotjarService(i0.ɵɵinject(i1.SentryService)); }, token: HotjarService, providedIn: "root" });
    return HotjarService;
}());
export { HotjarService };
