import * as i0 from "@angular/core";
var MenuItemsService = /** @class */ (function () {
    function MenuItemsService() {
        this.items = [
            {
                "title": "Overblik",
                "fragment": "summary",
                "icon": "chevron right",
                "cssClass": "active",
                "hideSubItems": true,
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "summary-overview",
                        "hidden": true
                    }
                ]
            },
            {
                "title": "Befolkning",
                "fragment": "population",
                "icon": "users",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "population-overview",
                        "hidden": true
                    },
                    {
                        "title": "Herkomst",
                        "fragment": "population-origin"
                    },
                    {
                        "title": "Uddannelsesniveau",
                        "fragment": "population-education"
                    },
                    {
                        "title": "Familiesammensætning",
                        "fragment": "population-familycomposition"
                    },
                    {
                        "title": "Ægteskablig status",
                        "fragment": "population-civilStatus"
                    },
                    {
                        "title": "Tilflytning",
                        "fragment": "population-growth",
                    },
                    {
                        "title": "Indvandring",
                        "fragment": "population-immigration",
                    },
                    {
                        "title": "Alder",
                        "fragment": "population-age"
                    },
                    {
                        "title": "Formue",
                        "fragment": "population-wealth"
                    },
                    {
                        "title": "Arbejdsløshed",
                        "fragment": "population-unemployment"
                    },
                ]
            },
            {
                "title": "Økonomi",
                "fragment": "economy",
                "icon": "money bill alternate",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "economy-overview",
                        "hidden": true
                    },
                    {
                        "title": "Indkomstfordeling",
                        "fragment": "economy-income"
                    },
                    {
                        "title": "Skatter og afgifter",
                        "fragment": "economy-tax"
                    },
                ]
            },
            {
                "title": "Sundhed",
                "fragment": "health",
                "icon": "heartbeat",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "health-overview",
                        "hidden": true
                    },
                    {
                        "title": "Forventet middellevetid",
                        "fragment": "health-lifetime",
                    },
                ]
            },
            {
                "title": "Bolig",
                "fragment": "residential",
                "icon": "home",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "residential-overview",
                        "hidden": true
                    },
                    {
                        "title": "Boligtype",
                        "fragment": "residential-housings",
                    },
                    {
                        "title": "Boligstørrelse",
                        "fragment": "residential-size",
                    },
                    {
                        "title": "Opførselsår",
                        "fragment": "residential-construction-year",
                    },
                    {
                        "title": "Pris pr. kvadratmeter",
                        "fragment": "residential-price",
                    },
                    {
                        "title": "Liggetider ved salg",
                        "fragment": "residential-sales-period",
                    },
                ]
            },
            {
                "title": "Tryghed",
                "fragment": "safety",
                "icon": "smile outline",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "safety-overview",
                        "hidden": true
                    },
                    {
                        "title": "Kriminalitet",
                        "fragment": "safety-crime-reporting",
                    },
                ]
            },
            {
                "title": "Fritid",
                "fragment": "freetime",
                "icon": "futbol",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "freetime-overview",
                        "hidden": true
                    },
                    {
                        "title": "Udgifter",
                        "fragment": "freetime-expenses",
                    },
                ]
            },
            {
                "title": "Skole",
                "fragment": "school",
                "icon": "graduation cap",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "school-overview",
                        "hidden": true
                    },
                    {
                        "title": "Privatskoleelever",
                        "fragment": "school-private-school-students",
                    },
                    {
                        "title": "Klassekvotient",
                        "fragment": "school-student-count",
                    },
                    {
                        "title": "Undervisningsudgifter",
                        "fragment": "school-teaching-expenses",
                    },
                ]
            },
            {
                "title": "Børnepasning",
                "fragment": "daycare",
                "icon": "child",
                "items": [
                    {
                        "title": "Overview",
                        "fragment": "daycare-overview",
                        "hidden": true
                    },
                    {
                        "title": "Pladser",
                        "fragment": "daycare-availability",
                    },
                    {
                        "title": "Takster",
                        "fragment": "daycare-fares",
                    },
                ]
            },
        ];
    }
    MenuItemsService.prototype.getAllParentItemFragments = function () {
        return this.items
            .map(function (x) { return x.fragment; });
    };
    MenuItemsService.prototype.getAllChildItemFragments = function () {
        return this.getAllChildItems()
            .map(function (x) { return x.fragment; });
    };
    MenuItemsService.prototype.getVisibleChildItems = function (childItems) {
        return childItems
            .filter(function (x) { return x.hidden != true; });
    };
    MenuItemsService.prototype.getAllChildItems = function () {
        return this.items
            .map(function (p) { return p.items; })
            .reduce(function (a, b) { return a.concat(b); });
    };
    MenuItemsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuItemsService_Factory() { return new MenuItemsService(); }, token: MenuItemsService, providedIn: "root" });
    return MenuItemsService;
}());
export { MenuItemsService };
