import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DataSourcesComponent } from './data-sources.component';

@NgModule({
    declarations: [
        DataSourcesComponent
    ],
    imports: [
        SharedModule
    ]
})
export class DataSourcesModule { }
