import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IChart } from './types/chart';
import { IChartSettings } from './types/chartSettings';

import { ScrollService } from './scroll.service';

@Component({
    selector: '[app-overview-segment-chart]',
    styleUrls: ['./overview-segment.scss'],
    template: `
        <div class="b-overview__segment-inner" (click)="clickMenuItem(fragment)">
            <div>
                <h4 class="b-overview__segment-header">
                    {{item.headline}}
                </h4>
            </div>
            <div class="b-overview__segment-pie-chart">
                <canvas baseChart 
                    [datasets]="item.chart.dataSets" 
                    [labels]="item.chart.labels"
                    [colors]="settings.colors" [chartType]="settings.type"
                    [options]="settings.options"></canvas>
            </div>
        </div>
    `
})
export class OverviewChartSegmentComponent {
    @Input() fragment: string;
    @Input() item: any;
    @Input() settings: IChartSettings;

    constructor(private _scrollService: ScrollService) {
    }

    public clickMenuItem(fragment: any) {
        this._scrollService.scrollToId(fragment)
    }
}