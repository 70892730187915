<div class="b-container" *ngIf="markerItem && isAnyMarkerVisible">
    <div class="ui active inverted dimmer" *ngIf="isLoading">
        <div class="ui medium text loader">Opdaterer...</div>
    </div>

    <div class="b-header">
        <div class="b-headline">
            <p><strong>{{markerItem.headline}}</strong></p>
        </div>
    </div>

    <div>
        <div app-categories-list-item-mobile *ngFor="let item of markerItem.items" [item]="item" [markerItem]="markerItem"></div>
    </div>

    <div *ngIf="markerItem.markerTypeContainer.isNoiseType || markerItem.markerTypeContainer.isResidentsForRentType" style="text-align: center;margin-top: 20px; margin-bottom: 10px;">
        <p style="color: rgba(0,0,0,.87);"><strong>Manglende data... Data er på vej.</strong></p>
    </div>

    <div *ngIf="false == markerItem.markerTypeContainer.isNoiseType && false == markerItem.markerTypeContainer.isResidentsForRentType && (!markerItem.items || markerItem.items.length == 0) && false == isLoading && isLoaded" style="text-align: center;margin-top: 20px; margin-bottom: 10px;">
        <p style="color: rgba(0,0,0,.87);">Ingen data i den valgte kategori for dette område</p>
    </div>

</div>