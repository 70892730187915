export const environment = {
    production: true,
    keys: {
        sentry: {
            isEnabled: true,
            appId: '1384019',
            key: '4037e4f696964850a94b6c70894441aa'
        },
        hotjar: {
            isEnabled: true,
            key: 926864
        },
        analytics: {
            isEnabled: true,
            key: 'UA-122094375-1'
        },
        tagManager: {
            isEnabled: true,
            key: 'GTM-NNBQHFQ'
        }
    }
};
