import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
registerLocaleData(localeDa, 'da');

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { SentryErrorHandler } from './shared/handlers/sentryError.handler';
import { CustomRouteReuseStrategy } from './shared/handlers/customRouteStrategy.handler';

import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';

import { MunicipalityModule } from './municipality/municipality.module';
import { MapModule } from './map/map.module';
import { HomeModule } from './home/home.module';
import { AboutModule } from './about/about.module';
import { ContactModule } from './contact/contact.module';
import { CookiesModule } from './cookies/cookies.module';
import { DataSourcesModule } from './data-sources/data-sources.module';
import { PageNotFoundModule } from './pagenotfound/pagenotfound.module';
import { SitemapModule } from './sitemap/sitemap.module';
import { TermsAndConditionsModule } from './terms-and-conditions/terms-and-conditions.module';
import { VersionComponent } from './shared/components/version/version.component';
import { PrivacyTermsModule } from './privacy-terms/privacy-terms.module';
import { BetaModule } from './beta/beta.module';
import { RoadmapModule } from './roadmap/roadmap.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        AppRoutingModule,

        AboutModule,
        ContactModule,
        CookiesModule,
        DataSourcesModule,
        HomeModule,
        MapModule,
        MunicipalityModule,
        PageNotFoundModule,
        SitemapModule,
        TermsAndConditionsModule,
        PrivacyTermsModule,
        BetaModule,
        RoadmapModule
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'da'
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        }, {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
    ],
    bootstrap: [AppComponent, VersionComponent, SidebarComponent, CookieConsentComponent]
})
export class AppModule { }
