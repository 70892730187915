import { MapService } from './map.service';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./map.service";
var FactsheetService = /** @class */ (function () {
    function FactsheetService(_mapService) {
        var _this = this;
        this._mapService = _mapService;
        this._factsheet = null;
        this.onLoad = new BehaviorSubject({ factsheet: null, isLoading: false, isLoaded: false });
        this._mapService.onFitBoundsStart
            .subscribe(function (x) {
            _this.onLoad.next({
                factsheet: _this._factsheet,
                isLoading: true,
                isLoaded: (_this._factsheet != null)
            });
        });
        this._mapService.onFitBoundsEnd
            .subscribe(function (x) {
            if (x != null) {
                if (x.area.core.areaLevel < AreaLevel.Municipality)
                    return;
                _this.loadFactsheetData(x.area.core.areaLevel, x.path);
            }
        });
    }
    FactsheetService.prototype.loadFactsheetData = function (areaLevel, path) {
        var _this = this;
        // Finding observable to subscribe to
        var obs;
        switch (areaLevel) {
            case AreaLevel.Municipality:
                obs = this._mapService
                    .loadMunicipalityFactsheet(path.municipality.core.municipalityId);
                break;
            case AreaLevel.Parish:
                obs = this._mapService
                    .loadParishFactsheet(path.parish.core.parishId);
                break;
            default:
                throw new Error('Loading factsheet information is not supported for area: ' + areaLevel);
        }
        // Subscribing
        obs
            .subscribe(function (x) {
            _this._factsheet = x;
            _this.onLoad.next({
                factsheet: _this._factsheet,
                isLoading: false,
                isLoaded: true
            });
        });
    };
    FactsheetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FactsheetService_Factory() { return new FactsheetService(i0.ɵɵinject(i1.MapService)); }, token: FactsheetService, providedIn: "root" });
    return FactsheetService;
}());
export { FactsheetService };
