import { Injectable } from '@angular/core';
import { MenuItemsService } from 'src/app/municipality/menu-items.service';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {
    constructor(private _menuItemsService: MenuItemsService) {
    }

    public setupLeftMenuAfterViewInit(elementId: string, isEnableTopMenu: boolean = false) {
        // LEFT MENU
        let $leftMenuContainer = $(`app-left-menu .b-left-menu:not(.placeholder) .ui.menu`);

        let $leftMenuParentItems = $leftMenuContainer.find(`div.item`);
        let $leftMenuParentInnerItems = $leftMenuContainer.find(`.content.menu`);
        let $leftMenuChildItems = $leftMenuContainer.find(`a.item`);
        let leftMenuFragments = this._menuItemsService.getAllChildItemFragments();

        // TOP MENU
        let $topMenuContainer = $(`app-top-menu .ui.menu`);
        let $topMenuItems = $topMenuContainer.find(`a.item`);
        let topMenuFragments = this._menuItemsService.getAllParentItemFragments();

        // Pull toggleActive into scope variable
        let toggleActive = this.toggleActive;
        let toggleActiveAll = this.toggleActiveAll;

        $(`#${elementId}`).visibility({
            observeChanges: false,
            once: false,
            offset: 116, // Just 1px more than the offset of the top-menu used for mobile and tablet design
            onTopPassed: function () {
                // LEFT MENU
                let itemId = $(this).attr('id');
                let leftMenuFragment = itemId;
                let leftMenuIndex = leftMenuFragments.indexOf(leftMenuFragment);
                let $leftMenuItem = $leftMenuChildItems.eq(leftMenuIndex);

                toggleActiveAll(toggleActive, $leftMenuChildItems, $leftMenuItem, $leftMenuParentItems, $leftMenuParentInnerItems);

                // TOP MENU
                if (isEnableTopMenu) {
                    let topMenuFragment = itemId.split('-')[0];
                    let topMenuIndex = topMenuFragments.indexOf(topMenuFragment);
                    let $topMenuItem = $topMenuItems.eq(topMenuIndex);

                    toggleActive($topMenuItems, $topMenuItem);
                }
            },
            onTopPassedReverse: function () {
                // LEFT MENU
                let itemId = $(this).attr('id');
                let leftMenuFragment = itemId;
                let leftMenuIndex = leftMenuFragments.indexOf(leftMenuFragment);
                let $leftMenuItem = $leftMenuChildItems.eq(leftMenuIndex - 1);

                if (leftMenuIndex != 0) {
                    toggleActiveAll(toggleActive, $leftMenuChildItems, $leftMenuItem, $leftMenuParentItems, $leftMenuParentInnerItems);
                }

                // TOP MENU
                if (isEnableTopMenu) {
                    let topMenuFragment = itemId.split('-')[0];
                    let topMenuIndex = topMenuFragments.indexOf(topMenuFragment);
                    let $topMenuItem = $topMenuItems.eq(topMenuIndex - 1);

                    if (topMenuIndex != 0) {
                        toggleActive($topMenuItems, $topMenuItem);
                    }
                }
            }
        });
    }

    private toggleActiveAll(toggleActive: (items: any, item: any) => void, $menuChildItems: any, $menuChildItem: any, $menuParentItems: any, $menuParentInnerItems: any): void {
        // Parent item needs to be found dynamically for previous items
        let $menuParentItem = $menuChildItem.parent().parent();
        let $menuParentInnerItem = $menuChildItem.parent();

        // Child font effect
        toggleActive($menuChildItems, $menuChildItem);
        // Parent background effect
        toggleActive($menuParentItems, $menuParentItem);
        // Parent collapse effect
        toggleActive($menuParentInnerItems, $menuParentInnerItem);
    }

    private toggleActive(items: any, item: any): void {
        items
            .filter('.active')
            .removeClass('active');
        item
            .addClass('active');
    }
}