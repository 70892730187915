import * as tslib_1 from "tslib";
import { BaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';
var Region = /** @class */ (function (_super) {
    tslib_1.__extends(Region, _super);
    function Region() {
        var _this = _super.call(this) || this;
        _this.areaLevel = AreaLevel.Region;
        _this.countryId = 208;
        return _this;
    }
    Region.prototype.init = function (obj) {
        this.name = obj.name;
        // this.countryId = obj.countryId;
        this.regionId = obj.regionId;
    };
    Object.defineProperty(Region.prototype, "fullName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    return Region;
}(BaseArea));
export { Region };
