<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Kontakt</h1>

                        <p>Vi vil altid gerne høre fra vores brugere. Hvis du har spørgsmål, forslag, eller oplever problemer med siden, er du velkommen til at kontakte os på <a href="mailto:info@boligder.dk">info@boligder.dk</a></p>
                        <p>
                            <em>
                                Boligder ApS<br />
                                Lindehjørnet 14<br />
                                2605 Brøndby<br />
                                CVR: 39699524<br />
                                E-mail: <a href="mailto:info@boligder.dk">info@boligder.dk</a>
                            </em>
                        </p>
                        <p>Vi gør vores bedste for at besvare henvendelser inden for 24 timer.</p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>