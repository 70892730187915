import { Component, OnInit, Input } from '@angular/core';
import { MarkerService } from './marker.service';
import { IBaseArea } from './base-area';
import { MarkerType } from './marker-type';
import { MarkerTypeContainer } from './marker-type-container';

@Component({
    selector: 'app-categories-mobile',
    templateUrl: './categories-mobile.component.html',
    styleUrls: ['./categories-mobile.component.scss']
})
export class CategoriesMobileComponent implements OnInit {
    @Input() area: IBaseArea;

    categories: any;

    constructor(private _markerService: MarkerService) {
    }

    ngOnInit(): void {
        this.categories = this._markerService.categories;
    }

    clickIcon(markerType: MarkerType, markerSubType: number = null): void {
        let markerTypeContainer = new MarkerTypeContainer(markerType, markerSubType);
        this._markerService.toggleMarkerVisibilityAndClearAllOther(markerTypeContainer, null);
    }
}
