import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PrivacyTermsComponent } from './privacy-terms.component';

@NgModule({
    declarations: [
        PrivacyTermsComponent
    ],
    imports: [
        SharedModule
    ]
})
export class PrivacyTermsModule { }
