import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IBaseArea } from './base-area';
import { Subject } from 'rxjs';
import { FactsheetService } from './factsheet.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-factsheet-desktop',
    templateUrl: './factsheet-desktop.component.html',
    styleUrls: ['./factsheet-desktop.component.scss']
})
export class FactsheetDesktopComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();
    
    @Input() area: IBaseArea;
    @Input() isCompared: boolean;
    @Input() isComparable: boolean;
    @Output() onToggleCompare = new EventEmitter<any>();
    @Output() onToggleComparisonVisibility = new EventEmitter<any>();

    factsheet: any;
    isLoading: boolean;
    isLoaded: boolean;

    constructor(private _factsheetService: FactsheetService) { }

    ngOnInit(): void {
        this._factsheetService.onLoad
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (x.isLoaded) {
                    this.factsheet = x.factsheet;
                }

                this.isLoading = x.isLoading;
                this.isLoaded = x.isLoaded;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    clickToggleCompare(): void {
        this.onToggleCompare.emit();
    }

    clickToggleComparisonVisibility(): void {
        this.onToggleComparisonVisibility.emit();
    }
}
