/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./zoom-controls.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./zoom-controls.component";
import * as i3 from "./zoom.service";
import * as i4 from "./top-right-content-box-desktop.service";
var styles_ZoomControlsComponent = [i0.styles];
var RenderType_ZoomControlsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ZoomControlsComponent, data: {} });
export { RenderType_ZoomControlsComponent as RenderType_ZoomControlsComponent };
export function View_ZoomControlsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "b-controls"], ["id", "b-zoom-controls-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "ui icon basic button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickZoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "plus icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "ui icon basic button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickZoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "minus icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "b-progress-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "ui tiny progress"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "bar"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.areaLevel < 20) || (_co.areaLevel > 40)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ZoomControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-zoom-controls", [], null, null, null, View_ZoomControlsComponent_0, RenderType_ZoomControlsComponent)), i1.ɵdid(1, 4898816, null, 0, i2.ZoomControlsComponent, [i3.ZoomService, i4.TopRightContentBoxDesktopService], null, null)], null, null); }
var ZoomControlsComponentNgFactory = i1.ɵccf("app-zoom-controls", i2.ZoomControlsComponent, View_ZoomControlsComponent_Host_0, { areaLevel: "areaLevel" }, {}, []);
export { ZoomControlsComponentNgFactory as ZoomControlsComponentNgFactory };
