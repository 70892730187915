<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Datakilder på ditomraade.dk</h1>

                        <h3>1. Indledning</h3>
                        <p>
                            Ditomraade.dk præsenterer data fra offentligt tilgængelige datakilder og fra private institutioner.
                        </p>

                        <h3>2. Datakilder</h3>
                        <p>
                            Ditomraade.dk henter data fra følgende kilder:
                        </p>
                        <ul class="ui list">
                            <li>
                                Adresser og georeferencer hentes fra
                                <br />
                                <a href="http://dawa.aws.dk" target="_blank">Styrelsen for Dataforsyning og Effektivisering Adresse Web Services (AWS)</a>
                            </li>
                            <li>
                                Boligfakta omkring kvadratmeterpriser og liggetider hentes fra
                                <br />
                                <a href="https://finansdanmark.dk/toerre-tal/boligstatistik/" target="_blank">Finans Danmarks boligmarkedsstatistik</a>
                            </li>
                            <li>
                                Information om dagtilbud, grundskoler og gymnasier stammer fra
                                <br />
                                <a href="https://www.uvm.dk/statistik" target="_blank">Undervisningsministeriet</a>
                            </li>
                            <li>
                                Kommunale nøgletal hentes fra
                                <br />
                                <a href="http://www.noegletal.dk/" target="_blank">Social- og Indenrigsministeriets Kommunale Nøgletal</a> og
                                <a href="https://www.dst.dk/da" target="_blank">Danmarks Statistik</a>
                            </li>
                            <li>
                                Information om lokale faciliteter kommer fra
                                <br />
                                <a href="http://www.facilitetsdatabasen.dk/" target="_blank">Facilitetsdatabasen</a>
                            </li>
                            <li>
                                Information om idrætsklubber hentes fra DGI
                                <br />
                                <a href="https://www.dgi.dk/" target="_blank">DGI</a>
                            </li>
                            <li>
                                Information om offentlig transport og stoppesteder hentes fra Rejseplanen
                                <br />
                                <a href="https://www.rejseplanen.dk/" target="_blank">Rejseplanen</a>
                            </li>
                            <li>
                                Information om læger hentes fra Sundhedsdatastyrelsen
                                <br />
                                <a href="https://sundhedsdatastyrelsen.dk/" target="_blank">Sundhedsdatastyrelsen</a>
                            </li>
                            <li>
                                Information om privat børnepasning er indhentet med hjælp fra de private børnepasningsforeninger, herunder
                                <br />
                                <a href="https://privatbornepasning.dk/" target="_blank">Privat pasning i Nordjylland</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>