import { ChartJsLabelType } from './chartJsLabelType.enum';
import { ColorScheme } from './colorScheme.enum';
import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TableCellWidth } from './table-cell-width.enum';

@Injectable({
    providedIn: 'root'
})
export class MunicipalitySettingsContainer {
    constructor(private _decimalPipe: DecimalPipe) {
    }

    private _fontFamily = "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif";
    private _defaultChartJsSettings: any = {
        legend: true,
        options: {
            responsive: true,
            animation: {
                duration: 0 //500;
            },
            plugins: {
                datalabels: false,
                bands: false
            }
        }
    };

    settings = {
        daycare: {
            isReady: false,
            isLoading: false,
            availability: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                }
            },
            fares: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                }
            },
        },
        health: {
            isReady: false,
            isLoading: false,
            lifetime: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                }
            },
        },
        residential: {
            isReady: false,
            isLoading: false,
            housings: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            price: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Dkk)
                }
            },
            salesPeriod: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Day)
                }
            },
            constructionYear: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            size: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
        },
        freetime: {
            isReady: false,
            isLoading: false,
            expenses: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Dkk)
                }
            },
        },
        safety: {
            isReady: false,
            isLoading: false,
            crimeReporting: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                }
            },
        },
        population: {
            isReady: false,
            isLoading: false,
            overview: {
                chart: {
                    colors: [this.getColorPaletteOverview(ColorScheme.Municipality), this.getColorPaletteOverview(ColorScheme.Country)],
                    type: 'doughnut',
                    options: {
                        responsive: this._defaultChartJsSettings.options.responsive,
                        animation: this._defaultChartJsSettings.options.animation,
                        plugins: {
                            datalabels: {
                                color: 'white',
                                font: {
                                    weight: 'bold',
                                    family: this._fontFamily
                                },
                                display: function (context: any) {
                                    return context;
                                },
                                formatter: function (context: any) {
                                    return Math.round(context) + '%';
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                fontFamily: this._fontFamily
                            }
                        },
                        // cutoutPercentage: 90
                        cutoutPercentage: 0
                    }
                },
            },
            growth: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Percent, 0, ['rgba(255,0,0,1)'])
                }
            },
            immigration: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Percent, 0, ['rgba(255,0,0,1)'])
                }
            },
            origin: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            status: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            composition: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            education: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            familyComposition: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            civilStatus: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
            age: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Year),
                    resetOptions: (typeId: number) => {
                        this.settings.population.age.chart.options = null;
                        setTimeout(() => {
                            if (typeId == 0)
                                this.settings.population.age.chart.options = this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Year);
                            else
                                this.settings.population.age.chart.options = this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent);
                        }, 1);
                    }
                }
            },
            wealth: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Dkk)
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Big
                    }
                }
            },
            unemployment: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Percent)
                }
            },
        },
        economy: {
            isReady: false,
            isLoading: false,
            overview: {
                chart: {
                    colors: [this.getColorPaletteOverview(ColorScheme.Municipality), this.getColorPaletteOverview(ColorScheme.Country)],
                    type: 'doughnut',
                    options: {
                        responsive: this._defaultChartJsSettings.options.responsive,
                        animation: this._defaultChartJsSettings.options.animation,
                        plugins: {
                            datalabels: {
                                color: 'white',
                                font: {
                                    weight: 'bold',
                                    family: this._fontFamily
                                },
                                display: function (context: any) {
                                    return context;
                                },
                                formatter: function (context: any) {
                                    return Math.round(context) + '%';
                                }
                            }
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                fontFamily: this._fontFamily
                            }
                        },
                        cutoutPercentage: 0
                    }
                },
            },
            income: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Dkk, 0, ['rgba(255,0,0,1)']),
                    resetOptions: (typeId: number) => {
                        this.settings.economy.income.chart.options = null;
                        setTimeout(() => {
                            if (typeId == 0)
                                this.settings.economy.income.chart.options = this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Dkk, 0, ['rgba(255,0,0,1)']);
                            else
                                this.settings.economy.income.chart.options = this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Percent, 0, ['rgba(255,0,0,1)']);
                        }, 1);
                    }
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Large
                    }
                }
            },
            tax: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Percent, 0, ['rgba(255,0,0,1)']),
                    resetOptions: (typeId: number) => {
                        this.settings.economy.tax.chart.options = null;
                        setTimeout(() => {
                            if (typeId == 2)
                                this.settings.economy.tax.chart.options = this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Permille, 0, ['rgba(255,0,0,1)']);
                            else
                                this.settings.economy.tax.chart.options = this.getDefaultChartJsOptionsWithThresholdColor(ChartJsLabelType.Default, ChartJsLabelType.Percent, 0, ['rgba(255,0,0,1)']);
                        }, 1);
                    }
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Big
                    }
                }
            },
        },
        education: {
            isReady: false,
            isLoading: false,
            privateSchoolStudentsPer100Students: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Large
                    }
                }
            },
            studentCountAverage: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Default)
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Large
                    }
                }
            },
            teachingExpenses: {
                chart: {
                    colors: [this.getColor(ColorScheme.Municipality, true), this.getColor(ColorScheme.Country, true)],
                    legend: true,
                    type: 'line',
                    options: this.getDefaultChartJsOptions(ChartJsLabelType.Default, ChartJsLabelType.Dkk)
                },
                table: {
                    mobile: {
                        cellWidth: TableCellWidth.Large
                    }
                }
            },
        },
    }

    private getColor(colorScheme: ColorScheme, useTransparrentBackgroup: boolean = false): any {
        switch (colorScheme) {
            case ColorScheme.NearAddress:
                return { // red
                    backgroundColor: (useTransparrentBackgroup ? 'transparent' : 'rgba(237, 85, 101, 0.2)'),
                    borderColor: 'rgba(237, 85, 101, 1)',
                };
            case ColorScheme.Institution:
                return { // green
                    backgroundColor: (useTransparrentBackgroup ? 'transparent' : 'rgba(26, 179, 148, 0.4)'),
                    borderColor: 'rgba(26, 179, 148, 1)',
                    pointBackgroundColor: 'rgba(26, 179, 148, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(26, 179, 148, 0.8)'
                };
            case ColorScheme.Zip:
                return {   // orange
                    backgroundColor: (useTransparrentBackgroup ? 'transparent' : 'rgba(239, 170, 97, 0.0)'),
                    borderColor: 'rgba(239, 170, 97, 1)',
                    pointBackgroundColor: 'rgba(239, 170, 97, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(239, 170, 97, 0.8)'
                };
            case ColorScheme.Municipality:
                return {   // blue
                    backgroundColor: (useTransparrentBackgroup ? 'transparent' : 'rgba(61, 135, 195, 0.5)'),
                    borderColor: 'rgba(61, 135, 195, 1)',
                    pointBackgroundColor: 'rgba(61, 135, 195, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(61, 135, 195, 0.8)'
                };
            case ColorScheme.Country:
                return {   // grey
                    backgroundColor: (useTransparrentBackgroup ? 'transparent' : 'rgba(148, 159, 177, 0.2)'),
                    borderColor: 'rgba(148, 159, 177, 1)',
                    pointBackgroundColor: 'rgba(148, 159, 177, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(148, 159, 177, 0.8)'
                };
            default: return {};
        }
    }

    private getColorPaletteOverview(colorScheme: ColorScheme): any {
        var bgColors = this.getColorPalette(colorScheme);

        return {
            backgroundColor: [bgColors[0], bgColors[1], bgColors[2]],
            hoverBackgroundColor: [bgColors[0], bgColors[1], bgColors[2]]
        };
    }

    private getColorPalette(colorScheme: ColorScheme): any[] {
        var bgColors = [];

        switch (colorScheme) {
            case ColorScheme.NearAddress:
                for (let i = 9; i > 0; i = i - 2) {
                    bgColors.push('rgba(237, 85, 101, 0.' + i + ')');
                }
                break;
            case ColorScheme.Institution:
                for (let i = 9; i > 0; i = i - 2) {
                    bgColors.push('rgba(26, 179, 148, 0.' + i + ')');
                }
                break;
            case ColorScheme.Zip:
                for (let i = 9; i > 0; i = i - 2) {
                    bgColors.push('rgba(239, 170, 97, 0.' + i + ')');
                }
                break;
            case ColorScheme.Municipality:
                for (let i = 9; i > 0; i = i - 2) {
                    bgColors.push('rgba(61, 135, 195, 0.' + i + ')');
                }
                break;
            case ColorScheme.Country:
                for (let i = 9; i > 0; i = i - 2) {
                    bgColors.push('rgba(148, 159, 177, 0.' + i + ')');
                }
                break;
            default: break;
        }

        return bgColors;
    }

    private getDefaultChartJsOptionsWithThresholdColor(
        chartJsLabelTypeX: ChartJsLabelType = ChartJsLabelType.Default,
        chartJsLabelTypeY: ChartJsLabelType = ChartJsLabelType.Default,
        belowThresholdValue: number,
        belowThresholdColor: string[]
    ): any {
        var options = this.getDefaultChartJsOptions(chartJsLabelTypeX, chartJsLabelTypeY);
        options.bands = {
            yValue: belowThresholdValue,
            bandLine: {
                stroke: 1,
                colour: "black",
                type: 'dashed',
                label: 'The important value',
                fontSize: '12',
                fontFamily: this._fontFamily,
                fontStyle: 'normal',
            },
            belowThresholdColour: belowThresholdColor
        }

        return options;
    }

    public getDefaultChartJsOptions(
        chartJsLabelTypeX: ChartJsLabelType = ChartJsLabelType.Default,
        chartJsLabelTypeY: ChartJsLabelType = ChartJsLabelType.Default,
        hasGridLines: boolean = true,
        hasBorder: boolean = true,
        displayX: boolean = true,
        displayY: boolean = true,
        startAtZero: boolean = false
    ): any {
        // Localizing references
        var pipe = this._decimalPipe;
        var getChartLabel = this.getChartLabel;

        var options = {
            legend: {
                labels: {
                    fontFamily: this._fontFamily
                }
            },
            responsive: this._defaultChartJsSettings.options.responsive,
            animation: this._defaultChartJsSettings.options.animation,
            plugins: this._defaultChartJsSettings.options.plugins,
            tooltips: this.getDefaultChartJsOptionsTooltip(chartJsLabelTypeX),
            scales: {
                xAxes: [{
                    display: displayX,
                    gridLines: {
                        display: hasGridLines,
                        drawBorder: hasBorder
                    },
                    ticks: {
                        fontFamily: this._fontFamily,
                        callback: function (value: any, index: any, values: any) {
                            return getChartLabel(value, chartJsLabelTypeX, pipe);
                        }
                    }
                }],
                yAxes: [{
                    display: displayY,
                    gridLines: {
                        display: hasGridLines,
                        drawBorder: hasBorder
                    },
                    ticks: {
                        fontFamily: this._fontFamily,
                        callback: function (value: any, index: any, values: any) {
                            return getChartLabel(value, chartJsLabelTypeY, pipe);
                        },
                        min: (startAtZero == true ? 0 : undefined)
                    }
                }]
            }
        }

        return options;
    }

    public getDefaultChartJsOptionsTooltip(chartJsLabelType: ChartJsLabelType): any {
        // Localizing references
        var pipe = this._decimalPipe;
        var getChartLabel = this.getChartLabel;

        return {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    return getChartLabel(tooltipItem.yLabel, chartJsLabelType, pipe);
                }
            }
        }
    }

    private getChartLabel(value: any, chartJsLabelType: ChartJsLabelType, pipe: DecimalPipe): string {
        switch (chartJsLabelType) {
            case ChartJsLabelType.Dkk:
                return pipe.transform(value, '1.0') + ' kr.';
            case ChartJsLabelType.Year:
                return value + ' år';
            case ChartJsLabelType.Day:
                return value + ' dage';
            case ChartJsLabelType.Percent:
                return value + '%';
            case ChartJsLabelType.Permille:
                return value + '‰';
            case ChartJsLabelType.SquareMeter:
                return value + ' kvm';
            case ChartJsLabelType.Default:
            default:
                return value;
        }
    }
}