import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './data-sources.component.html',
    styleUrls: ['./data-sources.component.scss']
})
export class DataSourcesComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
