import { DeviceType } from '../types/device-type.enum';
import * as i0 from "@angular/core";
var UtilityService = /** @class */ (function () {
    function UtilityService() {
    }
    UtilityService.prototype.getClientDeviceDimensions = function () {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return {
            width: w,
            height: h
        };
    };
    UtilityService.prototype.getClientDeviceType = function () {
        var clientDeviceDimensions = this.getClientDeviceDimensions();
        if (clientDeviceDimensions.width < 768) {
            return DeviceType.Mobile;
        }
        else {
            return DeviceType.Desktop;
        }
    };
    UtilityService.prototype.getRandomString = function () {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return '_' + Math.random().toString(36).substr(2, 9);
    };
    ;
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
