/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./area-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./area-label.component";
var styles_AreaLabelComponent = [i0.styles];
var RenderType_AreaLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AreaLabelComponent, data: {} });
export { RenderType_AreaLabelComponent as RenderType_AreaLabelComponent };
export function View_AreaLabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui black label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_AreaLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-area-label", [], null, null, null, View_AreaLabelComponent_0, RenderType_AreaLabelComponent)), i1.ɵdid(1, 49152, null, 0, i2.AreaLabelComponent, [], null, null)], null, null); }
var AreaLabelComponentNgFactory = i1.ɵccf("app-area-label", i2.AreaLabelComponent, View_AreaLabelComponent_Host_0, { text: "text" }, {}, []);
export { AreaLabelComponentNgFactory as AreaLabelComponentNgFactory };
