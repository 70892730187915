import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BaseMarkerItem } from './base-marker-item';
import { UtilityService } from '../shared/services/utility.service';
import { MarkerService } from './marker.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MarkerTypeContainer } from './marker-type-container';
import { RouteDataService } from './route-data.service';

declare var $: any;

@Component({
    selector: '[app-categories-dropdown]',
    templateUrl: './categories-dropdown.component.html',
    styleUrls: ['./categories-dropdown.component.scss']
})
export class CategoriesDropdownComponent implements OnInit, OnDestroy {
    @Input() baseMarkerItem: BaseMarkerItem;

    private _unsubscribe: Subject<void> = new Subject<void>();

    elementId: string;
    elementIdJq: string;

    constructor(private _utilityService: UtilityService, private _markerService: MarkerService, private _routeDataService: RouteDataService) {
        this.elementId = this._utilityService.getRandomString();
        this.elementIdJq = '#' + this.elementId;
    }

    ngOnInit() {
        if (this.baseMarkerItem.showMarkers) {
            this.enableDropdown();
        }

        this._markerService.onToggleMarkerVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (this.baseMarkerItem.markerTypeContainer.markerType == x.markerTypeContainer.markerType && this.baseMarkerItem.showMarkers) {
                    this.enableDropdown();
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    clickClearMarkersVisibility(): void {
        this._markerService.clearSubMarkersVisibility(this.baseMarkerItem.markerTypeContainer);
        $(this.elementIdJq).dropdown('clear');
    }



    private enableDropdown() {
        var self = this;

        // Enabling dropdown
        setTimeout(() => {
            $(this.elementIdJq)
                .dropdown({
                    values: self.baseMarkerItem.dropdownItems,
                    placeholder: 'Vælg...',
                    onAdd: function (value: any, text: any, choice: any) {
                        self.onSelectionAdd(value, text, choice);
                    },
                    onRemove: function (value: any, text: any, choice: any) {
                        self.onSelectionRemove(value, text, choice);
                    }
                })
        });
    }

    private onSelectionAdd(addedValue: any, addedText: any, addedChoice: any) {
        let markerTypeContainer = new MarkerTypeContainer(this.baseMarkerItem.markerTypeContainer.markerType, Number(addedValue));
        this._markerService.toggleMarkerVisibility(markerTypeContainer, true);
    }

    private onSelectionRemove(removedValue: any, removedText: any, removedChoice: any) {
        let markerTypeContainer = new MarkerTypeContainer(this.baseMarkerItem.markerTypeContainer.markerType, Number(removedValue));
        this._markerService.toggleMarkerVisibility(markerTypeContainer, false);
    }
}
