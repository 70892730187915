import { OnInit, Component, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';

@Component({
    selector: '[app-sidebar]',
    styleUrls: ['./sidebar.component.scss'],
    template: `
        <a class="item" [ngClass]="{'active' : item.active}" *ngFor="let item of items.left" [routerLink]="item.link" (click)="clickMenuItem()">
            {{item?.title}}
        </a>
        <div app-version class="b-version-mobile"></div>
    `,
})
export class SidebarComponent implements OnInit, AfterViewInit {
    items: any = {}

    constructor(private _layoutService: LayoutService) {
    }

    ngOnInit(): void {
        this._layoutService.init();
        this.items.left = this._layoutService.menu.items.left;
    }

    ngAfterViewInit() {
        // Wait until menu has loaded before initializing sidebar, since sidebar depends on the "menu-sidebar-toggle" element in the menu
        this._layoutService
            .menu
            .onLoaded
            .subscribe(() => {
                var self = this;

                // Reenable visibility after page loaded
                $("#menu-sidebar").css("visibility", "visible")

                $('#menu-sidebar')
                    .sidebar({
                        dimPage: true,
                        transition: 'overlay',
                        mobileTransition: 'overlay',
                        exclusive: true,
                        closable: true,
                        onVisible: function () {
                            self._layoutService.setSidebarActive(true);
                            // $('.pusher').css('overflow', '');
                        },
                        onHide: function () {
                            self._layoutService.setSidebarActive(false);
                        },
                        onHidden: function () {
                            // $('.pusher').css('overflow', 'initial');
                        }
                    });

                $('#menu-sidebar')
                    .sidebar('attach events', '#menu-sidebar-toggle')
                    ;

                // Initially set overflow to initial:
                // https://github.com/Semantic-Org/Semantic-UI/issues/5827
                // $('.pusher').css('overflow', 'initial');
            })
    }


    clickMenuItem() {
        $('#menu-sidebar')
            .sidebar('toggle');
    }
}
