/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-search-box.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./address-search-box.component";
import * as i5 from "../shared/services/dataaccess.service";
var styles_AddressSearchBoxComponent = [i0.styles];
var RenderType_AddressSearchBoxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AddressSearchBoxComponent, data: {} });
export { RenderType_AddressSearchBoxComponent as RenderType_AddressSearchBoxComponent };
function View_AddressSearchBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "search link icon"]], null, null, null, null, null))], null, null); }
export function View_AddressSearchBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["id", "dawa-autocomplete-input"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchStr = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressSearchBoxComponent_1)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.searchStr; _ck(_v, 4, 0, currVal_9); var currVal_10 = _co.icon; _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "ui input autocomplete-container ", _co.icon, " ", _co.size, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = "S\u00F8g p\u00E5 en adresse..."; var currVal_2 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 6).ngClassValid; var currVal_7 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AddressSearchBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-address-search-box", [], null, null, null, View_AddressSearchBoxComponent_0, RenderType_AddressSearchBoxComponent)), i1.ɵdid(1, 4440064, null, 0, i4.AddressSearchBoxComponent, [i1.NgZone, i5.DataAccessService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressSearchBoxComponentNgFactory = i1.ɵccf("app-address-search-box", i4.AddressSearchBoxComponent, View_AddressSearchBoxComponent_Host_0, { size: "size", showIcon: "showIcon" }, { onSelected: "onSelected" }, []);
export { AddressSearchBoxComponentNgFactory as AddressSearchBoxComponentNgFactory };
