import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CookiesComponent } from './cookies.component';

@NgModule({
    declarations: [
        CookiesComponent
    ],
    imports: [
        SharedModule
    ]
})
export class CookiesModule { }
