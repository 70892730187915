import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { DataAccessService } from '../../services/dataaccess.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
    feedbackStep: number = 0;
    isRated: boolean = false;

    feedback: any = {
        rating: null,
        comment: null,
    }

    feedbackButtonId: string;
    feedbackContainerId: string;
    feedbackRatingId: string;

    constructor(private _utilityService: UtilityService, private _dataAccessService: DataAccessService, private _activatedRoute: ActivatedRoute) {
        this.feedbackButtonId = this._utilityService.getRandomString();
        this.feedbackContainerId = this._utilityService.getRandomString();
        this.feedbackRatingId = this._utilityService.getRandomString();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        var self = this;

        $('#' + this.feedbackRatingId)
            .rating({
                onRate: function (value) {
                    self.clickRate(self, value);
                }
            });
    }

    clickShow(): void {
        this.feedbackStep = 1;

        $('#' + this.feedbackContainerId)
            .transition();
    }

    clickClose(): void {
        var self = this;

        $('#' + this.feedbackContainerId)
            .transition({
                onHide: function () {
                    self.feedbackStep = 0;
                }
            });
    }

    clickRate(self: FeedbackComponent, value: any): void {
        self.feedbackStep = 2;
        self.isRated = true;
        self.feedback.rating = value;
    }

    clickBack(): void {
        this.feedbackStep = 1;
    }

    clickSend(): void {
        let routeData = this._activatedRoute
            .snapshot
            .url
            .map(x => x.path);

        this._dataAccessService
            .postFeedbackData(routeData, this.feedback.rating, this.feedback.comment)
            .subscribe((x: any) => {
                this.feedbackStep = 3;
            });
    }
}
