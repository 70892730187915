import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { ICurrentAreaEventData } from './current-area-event-data';

@Injectable({
    providedIn: 'root'
})
export class FactsheetService {
    private _factsheet: any = null;

    public onLoad: BehaviorSubject<any> = new BehaviorSubject<any>({ factsheet: null, isLoading: false, isLoaded: false });

    constructor(private _mapService: MapService) {
        this._mapService.onFitBoundsStart
            .subscribe((x: ICurrentAreaEventData) => {
                this.onLoad.next({
                    factsheet: this._factsheet,
                    isLoading: true,
                    isLoaded: (this._factsheet != null)
                });
            })

        this._mapService.onFitBoundsEnd
            .subscribe((x: ICurrentAreaEventData) => {
                if (x != null) {
                    if (x.area.core.areaLevel < AreaLevel.Municipality)
                        return;

                    this.loadFactsheetData(x.area.core.areaLevel, x.path);
                }
            });
    }

    public loadFactsheetData(areaLevel: AreaLevel, path: any): void {
        // Finding observable to subscribe to
        let obs: Observable<any>;
        switch (areaLevel) {
            case AreaLevel.Municipality:
                obs = this._mapService
                    .loadMunicipalityFactsheet(path.municipality.core.municipalityId);
                break;
            case AreaLevel.Parish:
                obs = this._mapService
                    .loadParishFactsheet(path.parish.core.parishId);
                break;
            default:
                throw new Error('Loading factsheet information is not supported for area: ' + areaLevel);
        }

        // Subscribing
        obs
            .subscribe((x: any) => {
                this._factsheet = x;

                this.onLoad.next({
                    factsheet: this._factsheet,
                    isLoading: false,
                    isLoaded: true
                });
            });
    }
}
