<div id="b-top-right-content-box-desktop" class="b-container" *ngIf="area && (area.areaLevel == 30 || area.areaLevel == 40)">
    <div class="b-collapse-button-container" [ngClass]="{'b-collapsed': isCollapsed}">
        <button class="ui primary icon button b-side" (click)="clickCollapse()"><i class="{{ iconCollapseSide }} chevron icon"></i></button>
        <button class="ui primary icon button b-bottom" (click)="clickCollapse()"><i class="{{ iconCollapseBottom }} chevron icon"></i></button>
    </div>
    <table class="b-content" [hidden]="isCollapsed">
        <tr id="b-top-right-content-box-desktop-first-row" class="b-first-row">
            <td>
                <app-factsheet-desktop [area]="area" [isCompared]="isCompared" [isComparable]="isComparable">
                </app-factsheet-desktop>
            </td>
        </tr>
        <tr class="b-second-row" [hidden]="false == showSelectedMarker">
            <td>
                <app-selected-marker-item-box-desktop>
                </app-selected-marker-item-box-desktop>
            </td>
        </tr>
    </table>
</div>