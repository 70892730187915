<div class="ui blue label">
    <div class="ui tiny breadcrumb">
        <div class="active section" *ngIf="areaLevel == 10"><a (click)="resetArea()">{{path.country.core.name}}</a></div>
        <a class="section" *ngIf="areaLevel > 10" (click)="clickArea(10)">{{path.country.core.name}}</a>
        <i class="right chevron icon divider" *ngIf="areaLevel > 10"></i>

        <div class="active section" *ngIf="areaLevel == 20"><a (click)="resetArea()">{{path.region.core.name}}</a></div>
        <a class="section" *ngIf="areaLevel > 20" (click)="clickArea(20, path.region.core.regionId)">{{path.region.core.name}}</a>
        <i class="right angle icon divider" *ngIf="areaLevel > 20"></i>

        <div class="active section" *ngIf="areaLevel == 30"><a (click)="resetArea()">{{path.municipality.core.name}}</a></div>
        <a class="section" *ngIf="areaLevel > 30" (click)="clickArea(30, path.municipality.core.regionId, path.municipality.core.municipalityId)">{{path.municipality.core.name}}</a>
        <i class="right angle icon divider" *ngIf="areaLevel > 30"></i>
    
        <div class="active section" *ngIf="areaLevel == 40"><a (click)="resetArea()">{{path.parish.core.name}}</a></div>
    </div>
</div>