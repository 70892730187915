import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Address } from './address';
import { AddressLevel } from '../shared/types/addressLevel.enum';
var RouteData = /** @class */ (function () {
    // zipCode: number;
    // zipName: string;
    // roadName: string;
    // houseNumber: string;
    // floor: string;
    // door: string;
    function RouteData() {
        this.areaLevel = AreaLevel.None;
        this.regionId = null;
        this.municipalityId = null;
        this.parishId = null;
        this.address = new Address();
    }
    Object.defineProperty(RouteData.prototype, "addressLevel", {
        get: function () {
            return this.address.addressLevel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RouteData.prototype, "hasAddress", {
        get: function () {
            return this.address && this.address.addressLevel != AddressLevel.None;
        },
        enumerable: true,
        configurable: true
    });
    return RouteData;
}());
export { RouteData };
