import { IAreaContainerGeometries, AreaContainerGeometries } from './area-container-geometries';
import { ICountry, Country } from './country';
import { Bounds } from '../shared/types/geometry/bounds';
import { IBaseAreaContainerItem } from './base-area-container-item';

export class AreaContainerCountryItem implements IAreaContainerCountryItem, IBaseAreaContainerItem {
    private _isGeometryAdded: boolean = false;

    core: ICountry;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    constructor() {
        this.geometry = new AreaContainerGeometries();
    }

    addCore(country: any): void {
        let core = new Country();
        core.init(country);
        this.core = core;
    }

    addGeometry(country: any): void {
        // Visual center
        // this.geometry.visualCenter = country.visualCenter;

        // Bounds
        let b = new Bounds(country.bounds);
        this.geometry.bounds = b;

        // Change state
        this._isGeometryAdded = true;
    }

    hasGeometry(): boolean {
        return this._isGeometryAdded;
    }

    hasFactsheet(): boolean {
        return false;
    }
}

export interface IAreaContainerCountryItem {
    core: ICountry;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    addCore(country: any): void;
    addGeometry(country: any): void;

    hasGeometry(): boolean;
    hasFactsheet(): boolean;
}