import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// import { map } from 'rxjs/operators';
var DataAccessService = /** @class */ (function () {
    function DataAccessService(_http) {
        this._http = _http;
    }
    DataAccessService.prototype.getDawaAddressInfoById = function (id, srid) {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)
        if (srid === void 0) { srid = 4326; }
        return this._http
            .get("https://dawa.aws.dk/adresser/" + id + "?srid=" + srid);
    };
    DataAccessService.prototype.getDawaAddressInfoByAddress = function (zip, roadName, houseNumber, floor, door, srid) {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)
        // if (srid == null)
        //     srid = 4326;
        if (srid === void 0) { srid = 4326; }
        return this._http
            .get("https://dawa.aws.dk/adresser?postnr=" + zip + "&vejnavn=" + roadName + "&husnr=" + houseNumber + "&etage=" + floor + "&d\u00F8r=" + door + "&srid=" + srid); // &struktur=mini
    };
    DataAccessService.prototype.getMapAreaIds = function () {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapAreaIds");
    };
    DataAccessService.prototype.getMapAreaGeometries = function (areaLevel, regionId, municipalityId) {
        if (regionId === void 0) { regionId = null; }
        if (municipalityId === void 0) { municipalityId = null; }
        return this._http
            .get("/api/areainformation/?requestAction=GetMapAreaGeometries&areaLevel=" + areaLevel + "&regionId=" + regionId + "&municipalityId=" + municipalityId);
    };
    DataAccessService.prototype.getMapGetMunicipalityList = function () {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapMunicipalityList");
    };
    DataAccessService.prototype.getMapFactsheetMunicipality = function (municipalityId) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapFactsheetMunicipality&municipalityId=" + municipalityId);
    };
    DataAccessService.prototype.getMapFactsheetParish = function (parishId) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapFactsheetParish&parishId=" + parishId);
    };
    DataAccessService.prototype.getMapData = function () {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapData");
    };
    DataAccessService.prototype.getMapFacilityTypes = function () {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapFacilityTypes");
    };
    DataAccessService.prototype.getNurseryMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapNursery&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getKindergaardenMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapKindergaarden&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getPrimarySchoolMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount, markerPrimarySchoolType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapPrimarySchool&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount + "&institutionTypeId=" + markerPrimarySchoolType);
    };
    DataAccessService.prototype.getHighSchoolMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount, markerHighSchoolType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapHighSchool&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount + "&institutionTypeId=" + markerHighSchoolType);
    };
    DataAccessService.prototype.getPrivateDaycareMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapPrivateDaycare&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getPublicTransportationMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapPublicTransportation&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getSupermarketMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapSupermarkets&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getNoiseMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return new Observable(function (observer) {
            var data = {
                map: {
                    noise: {
                        items: []
                    }
                }
            };
            observer.next(data);
            observer.complete();
        });
    };
    DataAccessService.prototype.getDoctorMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapDoctors&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getRealEstateMapMarkers = function (longitude, latitude, bbox, realEstateType, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/realestate/markers?longitude=" + longitude + "&latitude=" + latitude + "&swLongitude=" + bbox[0][0] + "&swLatitude=" + bbox[0][1] + "&neLongitude=" + bbox[1][0] + "&neLatitude=" + bbox[1][1] + "&realEstateItemTypeId=" + realEstateType + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getRealEstateMapDetails = function (realEstateId) {
        return this._http
            .get("/api/realestate/details?realEstateId=" + realEstateId);
    };
    DataAccessService.prototype.getResidentsForRentMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return new Observable(function (observer) {
            var data = {
                map: {
                    residentsForRent: {
                        items: []
                    }
                }
            };
            observer.next(data);
            observer.complete();
        });
    };
    DataAccessService.prototype.getFacilityMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount, markerFacilityType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapFacility&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount + "&facilityTypeId=" + markerFacilityType);
    };
    DataAccessService.prototype.getSportsUnionMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapSportsUnions&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
        // return new Observable((observer) => {
        //     let data = {
        //         map: {
        //             sportsUnion: {
        //                 items: []
        //             }
        //         }
        //     }
        //     observer.next(data);
        //     observer.complete();
        // })
    };
    DataAccessService.prototype.getDaycareMarkerData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount, daycareInstitutionType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapDaycare&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount + "&daycareInstitutionTypeId=" + daycareInstitutionType);
    };
    DataAccessService.prototype.getSchoolDistrictData = function (longitude, latitude, municipalityId, markerSkipCount, markerTakeCount, markerSchoolDistrictType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapSchoolDistricts&longitude=" + longitude + "&latitude=" + latitude + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount + "&levelId=" + markerSchoolDistrictType);
    };
    DataAccessService.prototype.getTraficCountData = function (longitude, latitude, bbox, municipalityId, markerSkipCount, markerTakeCount) {
        return this._http
            .get("/api/areainformation/?requestAction=GetMapTraficCount&longitude=" + longitude + "&latitude=" + latitude + "&swLongitude=" + bbox[0][0] + "&swLatitude=" + bbox[0][1] + "&neLongitude=" + bbox[1][0] + "&neLatitude=" + bbox[1][1] + "&municipalityId=" + municipalityId + "&markerSkipCount=" + markerSkipCount + "&markerTakeCount=" + markerTakeCount);
    };
    DataAccessService.prototype.getCommonData = function () {
        return this._http
            .get("/api/areainformation/?requestAction=GetCommonData");
    };
    DataAccessService.prototype.getOverviewSummaryData = function (municipalityId) {
        return this._http
            .get("/api/areainformation/?requestAction=GetSummary&municipalityId=" + municipalityId);
    };
    DataAccessService.prototype.getSafetyData = function (municipalityId, violationType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetSafety&municipalityId=" + municipalityId + "&violationType=" + violationType);
    };
    DataAccessService.prototype.getSafetyCrimeReportingData = function (municipalityId, violationType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetSafetyCrimeReporting&municipalityId=" + municipalityId + "&violationType=" + violationType);
    };
    DataAccessService.prototype.getPopulationData = function (municipalityId, civilStatusType, familyCompositionType, originType, educationType, ageType, growthType, immigrationType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulation&municipalityId=" + municipalityId + "&civilStatusType=" + civilStatusType + "&familyCompositionType=" + familyCompositionType + "&originType=" + originType + "&educationType=" + educationType + "&ageType=" + ageType + "&growthType=" + growthType + "&immigrationType=" + immigrationType);
    };
    DataAccessService.prototype.getPopulationOriginData = function (municipalityId, originType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationOrigin&municipalityId=" + municipalityId + "&originType=" + originType);
    };
    DataAccessService.prototype.getPopulationEducationData = function (municipalityId, educationType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationEducation&municipalityId=" + municipalityId + "&educationType=" + educationType);
    };
    DataAccessService.prototype.getPopulationFamilyCompositionData = function (municipalityId, familyCompositionType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationFamilyComposition&municipalityId=" + municipalityId + "&familyCompositionType=" + familyCompositionType);
    };
    DataAccessService.prototype.getPopulationCivilStatusData = function (municipalityId, civilStatusType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationCivilStatus&municipalityId=" + municipalityId + "&civilStatusType=" + civilStatusType);
    };
    DataAccessService.prototype.getPopulationGrowthData = function (municipalityId, growthType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationGrowth&municipalityId=" + municipalityId + "&growthType=" + growthType);
    };
    DataAccessService.prototype.getPopulationImmigrationData = function (municipalityId, immigrationType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationImmigration&municipalityId=" + municipalityId + "&immigrationType=" + immigrationType);
    };
    DataAccessService.prototype.getPopulationAgeData = function (municipalityId, ageType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetPopulationAge&municipalityId=" + municipalityId + "&ageType=" + ageType);
    };
    DataAccessService.prototype.getEconomyData = function (municipalityId, propertyCategoryId, incomeType, taxType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetEconomy&municipalityId=" + municipalityId + "&propertyCategoryId=" + propertyCategoryId + "&incomeType=" + incomeType + "&taxType=" + taxType);
    };
    DataAccessService.prototype.getEconomyIncomeData = function (municipalityId, incomeType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetEconomyIncome&municipalityId=" + municipalityId + "&incomeType=" + incomeType);
    };
    DataAccessService.prototype.getEconomyTaxData = function (municipalityId, taxType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetEconomyTax&municipalityId=" + municipalityId + "&taxType=" + taxType);
    };
    DataAccessService.prototype.getEducationData = function (municipalityId) {
        return this._http
            .get("/api/areainformation/?requestAction=GetEducation&municipalityId=" + municipalityId);
    };
    DataAccessService.prototype.getFreetimeData = function (municipalityId, expenseType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetFreetime&municipalityId=" + municipalityId + "&expenseType=" + expenseType);
    };
    DataAccessService.prototype.getFreetimeExpensesData = function (municipalityId, expenseType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetFreetimeExpenses&municipalityId=" + municipalityId + "&expenseType=" + expenseType);
    };
    DataAccessService.prototype.getResidentialData = function (municipalityId, housingType, propertyType, yearType, sizeType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidential&municipalityId=" + municipalityId + "&housingType=" + housingType + "&propertyType=" + propertyType + "&yearType=" + yearType + "&sizeType=" + sizeType);
    };
    DataAccessService.prototype.getResidentialHousingData = function (municipalityId, housingType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidentialHousings&municipalityId=" + municipalityId + "&housingType=" + housingType);
    };
    DataAccessService.prototype.getResidentialPriceData = function (municipalityId, propertyType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidentialPrice&municipalityId=" + municipalityId + "&propertyType=" + propertyType);
    };
    DataAccessService.prototype.getResidentialSalesPeriodData = function (municipalityId, propertyType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidentialSalesPeriod&municipalityId=" + municipalityId + "&propertyType=" + propertyType);
    };
    DataAccessService.prototype.getResidentialConstructionYearData = function (municipalityId, yearType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidentialConstructionYear&municipalityId=" + municipalityId + "&yearType=" + yearType);
    };
    DataAccessService.prototype.getResidentialSizeData = function (municipalityId, sizeType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetResidentialSize&municipalityId=" + municipalityId + "&sizeType=" + sizeType);
    };
    DataAccessService.prototype.getHealthData = function (municipalityId) {
        return this._http
            .get("/api/areainformation/?requestAction=GetHealth&municipalityId=" + municipalityId);
    };
    DataAccessService.prototype.getDaycareData = function (municipalityId, availabilityType, fareType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetDaycare&municipalityId=" + municipalityId + "&availabilityType=" + availabilityType + "&fareType=" + fareType);
    };
    DataAccessService.prototype.getDaycareAvailabilityData = function (municipalityId, availabilityType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetDaycareAvailability&municipalityId=" + municipalityId + "&availabilityType=" + availabilityType);
    };
    DataAccessService.prototype.getDaycareFaresData = function (municipalityId, fareType) {
        return this._http
            .get("/api/areainformation/?requestAction=GetDaycareFares&municipalityId=" + municipalityId + "&fareType=" + fareType);
    };
    DataAccessService.prototype.postFeedbackData = function (routeData, rating, comment) {
        var data = {
            routeData: routeData,
            rating: rating,
            comment: comment,
        };
        return this._http
            .post("/api/feedback", data);
    };
    DataAccessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataAccessService_Factory() { return new DataAccessService(i0.ɵɵinject(i1.HttpClient)); }, token: DataAccessService, providedIn: "root" });
    return DataAccessService;
}());
export { DataAccessService };
