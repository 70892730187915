import { EventEmitter } from '@angular/core';
import { Zoom } from '../shared/types/zoom/zoom';
import { ZoomLevel } from '../shared/types/zoom/zoomLevel';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import * as i0 from "@angular/core";
var ZoomService = /** @class */ (function () {
    function ZoomService() {
        this.onZoomChange = new EventEmitter();
        this._settings = {
            country: {
                zoom: new Zoom(new ZoomLevel(-2, 2, -1), 1.1),
            },
            region: {
                zoom: new Zoom(new ZoomLevel(-4, 4), 1.1),
            },
            municipality: {
                zoom: new Zoom(new ZoomLevel(-4, 4), 1.1),
            },
            parish: {
                zoom: new Zoom(new ZoomLevel(-4, 2, -1), 1.3),
            },
        };
    }
    ZoomService.prototype.emitZoomIn = function (areaLevel) {
        var zoom = this.getZoomForArea(areaLevel);
        if (zoom.zoomIn()) {
            this.onZoomChange.emit(zoom);
        }
    };
    ZoomService.prototype.emitZoomOut = function (areaLevel) {
        var zoom = this.getZoomForArea(areaLevel);
        if (zoom.zoomOut()) {
            this.onZoomChange.emit(zoom);
        }
    };
    ZoomService.prototype.getZoomForArea = function (areaLevel) {
        if (areaLevel == AreaLevel.Country) {
            return this._settings.country.zoom;
        }
        else if (areaLevel == AreaLevel.Region) {
            return this._settings.region.zoom;
        }
        else if (areaLevel == AreaLevel.Municipality) {
            return this._settings.municipality.zoom;
        }
        else if (areaLevel == AreaLevel.Parish) {
            return this._settings.parish.zoom;
        }
        throw new Error('No zoom info found for area ' + AreaLevel[areaLevel]);
    };
    ZoomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZoomService_Factory() { return new ZoomService(); }, token: ZoomService, providedIn: "root" });
    return ZoomService;
}());
export { ZoomService };
