import { AreaLevel } from '../shared/types/areaLevel.enum';
// import { Coordinate } from '../shared/types/geometry/coordinate';

export abstract class BaseArea {
    name: string;

    get urlParameter(): string {
        let output: string = null;
        output = this.replaceWithLowerCase(this.name);
        output = this.replaceDanishLetters(output);
        output = this.replaceSpace(output);

        return output;
    }

    public isUrlParameterMatch(urlParameter: string): boolean {
        return urlParameter == this.urlParameter;
    }

    private replaceWithLowerCase(value: string): string {
        let output = value
            .toLowerCase()

        return output;
    }

    private replaceDanishLetters(value: string): string {
        let output = value
            .replace('æ', 'ae')
            .replace('ø', 'oe')
            .replace('å', 'aa')
            .replace('Æ', 'AE')
            .replace('Ø', 'OE')
            .replace('Å', 'AA');

        return output;
    }

    private replaceSpace(value: string): string {
        return value
            .replace(' ', '-');
    }
}

export interface IBaseArea {
    areaLevel: AreaLevel;
    name: string;
    urlParameter: string;
    fullName: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}