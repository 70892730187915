import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { ReplaySubject, Observable } from 'rxjs';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { IRouteData, RouteData } from './route-data';
import { MapService } from './map.service';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { Coordinate } from '../shared/types/geometry/coordinate';
import { Address } from './address';
import { AddressLevel } from '../shared/types/addressLevel.enum';

@Injectable({
    providedIn: 'root'
})
export class RouteDataService {
    public onRouteChange: ReplaySubject<IRouteData> = new ReplaySubject<IRouteData>(1);
    private _routeData: IRouteData = null;

    constructor(private _mapService: MapService, private _dataAccessService: DataAccessService) {
    }

    public emitRouteChange(urlSegments: UrlSegment[]) {
        const routeParameters = urlSegments
            .map(x => x.path);

        if (this._mapService.isAreaDataReady) {
            this.getRouteData(routeParameters)
                .subscribe((routeData) => {
                    this.onRouteChange.next(routeData);
                });
        }
        else {
            this._mapService.onAreaDataReady
                .subscribe(() => {
                    this.getRouteData(routeParameters)
                        .subscribe((routeData) => {
                            this.onRouteChange.next(routeData);
                        });
                });
        }
    }

    public getCurrentRouteData(): IRouteData {
        return this._routeData;
    }

    private getRouteData(routeParameters: string[]): Observable<IRouteData> {
        return new Observable((observer) => {
            let output = new RouteData();
            let areaRequestData = this.getAreaRequestData(routeParameters);

            if (areaRequestData.addressLevel != AddressLevel.None) {
                this.getAddressDataFromRoute(areaRequestData)
                    .subscribe((x) => {
                        let address = new Address();
                        address.init({
                            addressLevel: x.addressLevel,
                            zipCode: x.zipCode,
                            zipName: x.zipName,
                            roadName: x.roadName,
                            houseNumber: x.houseNumber,
                            floor: x.floor,
                            door: x.door,

                            name: x.fullAddress,
                            coordinate: x.coordinate
                        });

                        output.areaLevel = areaRequestData.areaLevel;
                        output.regionId = x.regionId;
                        output.municipalityId = x.municipalityId;
                        output.parishId = x.parishId;
                        output.address = address;

                        observer.next(output);
                        observer.complete();
                    })
            }
            else {
                if (areaRequestData.areaLevel == AreaLevel.Country) {
                    let area = this._mapService.getCountryAreaContainerByUrlParameter();
                    output.areaLevel = area.core.areaLevel;
                }
                else if (areaRequestData.areaLevel == AreaLevel.Region) {
                    let area = this._mapService.getRegionAreaContainerByUrlParameter(areaRequestData.regionUrlParameter);
                    output.areaLevel = area.core.areaLevel;
                    output.regionId = area.core.regionId;
                }
                else if (areaRequestData.areaLevel == AreaLevel.Municipality) {
                    let area = this._mapService.getMunicipalityAreaContainerByUrlParameter(areaRequestData.regionUrlParameter, areaRequestData.municipalityUrlParameter);
                    output.areaLevel = area.core.areaLevel;
                    output.regionId = area.core.regionId;
                    output.municipalityId = area.core.municipalityId;
                }
                else if (areaRequestData.areaLevel == AreaLevel.Parish) {
                    let area = this._mapService.getParishAreaContainerByUrlParameter(areaRequestData.regionUrlParameter, areaRequestData.municipalityUrlParameter, areaRequestData.parishUrlParameter);
                    output.areaLevel = area.core.areaLevel;
                    output.regionId = area.core.regionId;
                    output.municipalityId = area.core.municipalityId;
                    output.parishId = area.core.parishId;
                }
                else {
                    output.areaLevel = AreaLevel.None;
                }

                observer.next(output);
                observer.complete();
            }

            this._routeData = output;
        })
    }

    private getAreaRequestData(routeParameters: string[]): { areaLevel: AreaLevel, addressLevel: AddressLevel, regionUrlParameter: string, municipalityUrlParameter: string, parishUrlParameter: string, zipUrlParameter: string, roadUrlParameter: string, levelUrlParameter: string } {
        let output = {
            areaLevel: AreaLevel.None,
            addressLevel: AddressLevel.None,
            regionUrlParameter: null,
            municipalityUrlParameter: null,
            parishUrlParameter: null,
            zipUrlParameter: null,
            roadUrlParameter: null,
            levelUrlParameter: null,
        };

        if (routeParameters.length == 1) {
            output.areaLevel = AreaLevel.Country;
        }
        else if (routeParameters.length == 2) {
            output.areaLevel = AreaLevel.Region;
            output.regionUrlParameter = routeParameters[1];
        }
        else if (routeParameters.length == 3) {
            output.areaLevel = AreaLevel.Municipality;
            output.regionUrlParameter = routeParameters[1];
            output.municipalityUrlParameter = routeParameters[2];
        }
        else if (routeParameters.length == 4) {
            output.areaLevel = AreaLevel.Parish;
            output.regionUrlParameter = routeParameters[1];
            output.municipalityUrlParameter = routeParameters[2];
            output.parishUrlParameter = routeParameters[3];
        }
        else if (routeParameters.length == 6) {
            output.areaLevel = AreaLevel.Parish;
            output.addressLevel = AddressLevel.Road;
            output.regionUrlParameter = routeParameters[1];
            output.municipalityUrlParameter = routeParameters[2];
            output.parishUrlParameter = routeParameters[3];

            output.zipUrlParameter = routeParameters[4];
            output.roadUrlParameter = routeParameters[5];
        }
        else if (routeParameters.length == 7) {
            output.areaLevel = AreaLevel.Parish;
            output.addressLevel = AddressLevel.Level;
            output.regionUrlParameter = routeParameters[1];
            output.municipalityUrlParameter = routeParameters[2];
            output.parishUrlParameter = routeParameters[3];

            output.zipUrlParameter = routeParameters[4];
            output.roadUrlParameter = routeParameters[5];
            output.levelUrlParameter = routeParameters[6];
        }

        return output;
    }

    private getAddressDataFromRoute(routeParams: any): Observable<any> {
        return new Observable((observer) => {
            var zipCode = this.getZipCode(routeParams.zipUrlParameter);
            var roadName = this.getRoadName(routeParams.roadUrlParameter);
            var houseNumber = this.getHouseNumber(routeParams.roadUrlParameter);
            var floor = this.getFloor(routeParams.levelUrlParameter);
            var door = this.getDoor(routeParams.levelUrlParameter);

            return this._dataAccessService
                .getDawaAddressInfoByAddress(zipCode, roadName, houseNumber, floor || '', door || '')
                .subscribe(x => {
                    // When searching for address, multiple matches are possible
                    // TODO: Error handling, when there are no matches (someone might fiddle with the url parameters to make a FAKE address)
                    // TODO: Investigate if we can actually have more than 1 element in the array, if the input address is "correct"/conform.
                    var firstAddress = x[0];

                    let data = this.mapAddressResponse(firstAddress, routeParams.areaLevel);

                    observer.next(data);
                    observer.complete();
                })
        })
    }

    private mapAddressResponse(address: any, areaLevel: AreaLevel): any {
        let coordinate = new Coordinate();
        coordinate.lng = address.adgangsadresse.adgangspunkt.koordinater[0];
        coordinate.lat = address.adgangsadresse.adgangspunkt.koordinater[1];

        let output = {
            id: address.adgangsadresse.id,
            regionId: +address.adgangsadresse.region.kode,
            regionName: address.adgangsadresse.region.navn,
            municipalityId: +address.adgangsadresse.kommune.kode,
            municipalityName: address.adgangsadresse.kommune.navn,
            parishId: +address.adgangsadresse.sogn.kode,
            parishName: address.adgangsadresse.sogn.navn,
            zipCode: +address.adgangsadresse.postnummer.nr,
            zipName: address.adgangsadresse.postnummer.navn,
            roadName: address.adgangsadresse.vejstykke.navn,
            houseNumber: address.adgangsadresse.husnr,
            floor: address.etage,
            door: address.dør,
            fullAddress: address.adressebetegnelse,
            areaLevel: areaLevel,
            addressLevel: AddressLevel.None,
            coordinate: coordinate
        }

        if (output.zipCode && output.zipName && output.roadName && output.houseNumber && !output.floor && !output.door)
            output.addressLevel = AddressLevel.Road;
        else if (output.zipCode && output.zipName && output.roadName && output.houseNumber && (output.floor || output.door))
            output.addressLevel = AddressLevel.Level;

        return output;
    }

    private getZipCode(zip: string): string {
        return zip.split('-', 1)[0];
    }

    private getRoadName(address: string): string {
        address = address.split('--').join('|');    // Replacing '--' with '|' since we need to split by '-' later. "-" in addresses are replaced by "--" (eg. 'C--Vej-1' and 'Slap--a--vej-1') whereas ' ' are replaced by '-' (eg. 'Vigerslev-Allé-23')
        var addressParts = address.split('-');
        addressParts.splice(addressParts.length - 1, 1);    // Remove the number part

        return addressParts
            .join(' ')              // Replacing all '-'  with ' '
            .split('|').join('-');  // Replacing all '|' with '-'

        // === NB ===
        // //  Using RegEx with negative lookbehind is not supported in other browsers than Chrome, therefore it should not be used.
        // var addressParts = address.split(/(?<!-)-(?!-)/);   // "-" in addresses are replaced by "--" (eg. 'C--Vej-1' and 'Slap--a--vej-1') whereas ' ' are replaced by '-' (eg. 'Vigerslev-Allé-23')
        // addressParts.splice(addressParts.length - 1, 1);    // Remove the number part

        // return addressParts
        //     .join(' ')              // Replacing all '-'  with ' '
        //     .split('--').join('-'); // Replacing all '--' with '-'
    }

    private getHouseNumber(address: string): string {
        var addressParts = address.split('-');
        return addressParts[addressParts.length - 1];
    }

    private getFloor(level: string): string {
        if (level)
            return level.split('-')[0];
        else
            return null;
    }

    private getDoor(level: string): string {
        if (level) {
            level = level.split('--').join('|');    // Replacing '--' with '|' since we need to split by '-' later. "-" in door are replaced by "--" (eg. '22--2') whereas ' ' are replaced by '-'
            var levelParts = level.split('-');
            levelParts.splice(0, 1);    // Remove the floor part

            return levelParts
                .join(' ')              // Replacing all '-'  with ' '
                .split('|').join('-');  // Replacing all '|' with '-'
        }
        else {
            return null;
        }
    }

}
