import { Coordinate } from '../shared/types/geometry/coordinate';
import { IBounds } from '../shared/types/geometry/bounds';
import { IFeature } from '../shared/types/geometry/feature';

export class AreaContainerGeometries implements IAreaContainerGeometries {
    areaFeature: IFeature;
    centerFeature: IFeature;
    visualCenter: Coordinate;
    bounds: IBounds;
}

export interface IAreaContainerGeometries {
    areaFeature: IFeature;
    centerFeature: IFeature;
    visualCenter: Coordinate;
    bounds: IBounds;
}