<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Cookiepolitik for ditomraade.dk</h1>
                        <p>
                            Når du besøger ditomraade.dk registrerer ditomraade.dk visse personoplysninger om dig. Vi har pligt til at
                            oplyse dig om, hvordan vi behandler disse personoplysninger og om vores brug af cookies.
                        </p>
                        <h3>1. Indsamling af oplysninger</h3>

                        <h4>1.1 Automatisk registrering</h4>
                        <p>
                            Visse oplysninger registreres automatisk, fordi du ved at besøge ditomraade.dk efterlader en række
                            elektroniske spor. Rent praktisk kommer sporene, fordi din browser automatisk sender informationer
                            til ditomraade.dk's server.
                        </p>
                        <p>De faktiske elektroniske spor som du efterlader dig er blandt andet:</p>
                        <ul>
                            <li>
                                Hvilken side du kom fra
                            </li>
                            <li>
                                Hvilke søgninger laver du
                            </li>
                            <li>
                                Hvilken browser du bruger
                            </li>
                            <li>
                                Din IP-adresse
                            </li>
                            <li>
                                Dit operativsystem (eks. Windows 10)
                            </li>
                        </ul>

                        <h4>1.2 Samtykke</h4>
                        <p>
                            Ved at bruge "Hjemmesiden" giver du samtykke til, at ditomraade.dk bruger cookies, som er beskrevet i
                            skemaet nedenfor - medmindre du med dine browserindstillinger har fravalgt cookies. Hvis du ikke
                            har fravalgt cookies på tidspunktet for afgivelse af samtykke, kan du tilbagetrække samtykket ved
                            at blokere cookies i din webbrowser. Flere oplysninger om, hvordan du kan blokere og slette cookies
                            findes nedenfor under afsnit 1.3.5.
                        </p>

                        <h4>1.3 Cookies</h4>
                        <h5>1.3.1 Hvad er en cookie?</h5>
                        <p>
                            Cookies anvendes af stort set alle websites. I nogle tilfælde er cookies den eneste måde at få et
                            website til at fungere efter hensigten.
                            <br /><br />
                            En cookie er en fil, som lægges på din computer eller et andet stykke it-udstyr. Den gør det muligt at
                            genkende din computer og samle information om, hvilke sider og funktioner, der besøges med din
                            browser. Cookies kan ikke se, hvem du er, hvad du hedder, hvor du bor eller om computeren bruges af
                            en eller flere personer. Den kan heller ikke sprede computervirus eller andre skadelige programmer.
                            <br /><br />
                            Ditomraade.dk anvender cookies.
                        </p>

                        <h5>1.3.2 Hvad bruger vi cookies til?</h5>
                        <p>På ditomraade.dk benytter vi cookies til følgende formål:</p>
                        <ul>
                            <li>Forbedring af teknisk funktionalitet</li>
                            <li>Trafikmåling</li>
                            <li>Annonceafvikling så vi kan styre, hvor ofte samme annonce vises til den samme bruger og
                                registrere, hvor mange, der klikker på den. Du kan se og fravælge annoncenetværk her:<br />
                                <a href="http://minecookies.org/fravalg" target="_blank">http://minecookies.org/fravalg</a></li>
                        </ul>

                        <h5>1.3.3 Google Analytics (trafikmåling)</h5>
                        <p>

                            Ditomraade.dk bruger cookies fra Google Analytics til at måle trafikken på websitet. Google Analytics
                            anvendes på sitet til for eksempel at måle antallet af besøg, antallet af sidevisninger og antallet
                            af brugere på sitet. Disse informationer kan i anonymiseret form bruges til at dokumentere værdi og
                            effekt af markedsføringsmateriale. Google Analytics registrerer ikke personoplysninger.
                        </p>
                        <p>
                            Du kan fravælge cookies fra Google Analytics her: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
                            Dette sker ved, at du installerer et modul til din browser.
                        </p>

                        <h5>1.3.4 Facebook</h5>
                        <p>
                            Ditomraade.dk bruger cookies fra Facebook bl.a. til at kunne vise relevante annoncer for dig på
                            Facebook. Du kan læse om den måde, som Facebook bruger cookies på her: <a href="https://www.facebook.com/help/cookies/" target="_blank">https://www.facebook.com/help/cookies/</a>
                        </p>

                        <h5>1.3.5 Afvis eller slet cookies</h5>
                        <p>
                            Du kan altid afvise cookies på din computer ved at ændre indstillingerne i din browser. Hvor du
                            finder indstillingerne afhænger af, hvilken browser du anvender. Du skal dog være opmærksom på, at
                            hvis du gør det, er der mange funktioner og services, som du ikke kan bruge, fordi de forudsætter,
                            at webstedet kan huske, de valg du foretager.
                        </p>
                        <p>
                            Alle browsere tillader at du sletter cookies enkeltvis eller alle på en gang. Hvordan du gør det
                            afhænger af, hvilken browser du anvender. Følg nedenstående links for at få vejledninger til din
                            browser. Husk, at hvis du bruger flere browsere, så skal du slette cookies i dem alle. Benytter du
                            en PC med en nyere browser, så kan du slette dine cookies ved at bruge genvejstasterne: CTRL +
                            SHIFT + Delete.
                        </p>
                        <ul>
                            <li><a href="http://windows.microsoft.com/da-DK/windows-vista/Delete-your-Internet-cookies" target="_blank">Internet Explorer</a></li>
                            <li><a href="http://www.google.com/support/chrome/bin/answer.py?hl=da&answer=95647" target="_blank">Chrome</a></li>
                            <li><a href="http://support.mozilla.com/da/kb/Deleting cookies" target="_blank">Firefox</a></li>
                            <li><a href="http://support.apple.com/kb/ph11920" target="_blank">Safari</a></li>
                            <li><a href="http://help.opera.com/Windows/9.51/da/cookies.html" target="_blank">Opera</a></li>
                            <li><a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html" target="_blank">Flash cookies (gælder for alle browsere)</a></li>
                        </ul>

                        <h3>2. Frivillig afgivelse af oplysninger</h3>
                        <p>
                            Udover hvad der er nævnt under 1., er det frivilligt at afgive personoplysninger. Adgangen til
                            ditomraade.dk kan imidlertidig være begrænset, hvis du ikke ønsker at afgive oplysninger om dig selv.
                        </p>

                        <h3>3. Anvendelse af indsamlet information</h3>
                        <p>
                            Oplysningerne opsamles og bruges til administration, udbud, udvikling og markedsføring af
                            ditomraade.dk’s ydelser på internettet og til brug for udbud af finansielle tjenesteydelser af enhver
                            art herunder kreditvurdering, kunderådgivning, kundepleje, risikostyring, udarbejdelse af engagementsoversigter
                            og betalingsformidling.
                            <br /><br />
                            Udover ved brug af cookies videregiver ditomraade.dk ikke personoplysninger til tredjemand, medmindre
                            du har givet samtykke hertil.
                        </p>

                        <h3>4. Indsigt</h3>
                        <p>
                            Ved at henvende dig til ditomraade.dk kan du blandt andet få oplyst, hvilke oplysninger, der er
                            indsamlet, formålet hermed, og hvorfra oplysningerne stammer. Hvis du mener, at ditomraade.dk ikke bruger dine
                            personoplysninger korrekt, kan du gøre indsigelse mod, at der behandles oplysninger om dig.
                            <br /><br />
                            Den dataansvarlige er Boligder ApS, Lindehjørnet 14, 2605 Brøndby, CVR 39699524.
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>