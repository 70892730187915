<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <!-- Loader -->
        <div class="ui active dimmer" *ngIf="false == settings.isMapLoaded">
            <div class="ui big text loader">Henter kort...</div>
        </div>
        <div class="ui vertical segment b-map-container">
            <app-map-box (onMapLoaded)="mapboxMapLoaded($event)" (onLayerClick)="mapboxLayerClick($event)"></app-map-box>

            <app-zoom-controls *ngIf="settings.isMapLoaded" [areaLevel]="data.current.area.core.areaLevel"></app-zoom-controls>

            <app-breadcrumb-desktop *ngIf="settings.isMapLoaded" [areaLevel]="data.current.area.core.areaLevel" [path]="data.current.path" (onClickArea)="breadcrumbDesktopClickArea($event)" (onResetArea)="breadcrumbDesktopResetArea()">
            </app-breadcrumb-desktop>

            <app-top-right-content-box-desktop [area]="data.current.area.core" [isCompared]="data.current.isCompared" [isComparable]="data.current.isComparable"></app-top-right-content-box-desktop>

            <app-categories-desktop [area]="data.current.area.core" [isLoading]="settings.isMapLoading"></app-categories-desktop>
        </div>

        <div class="ui segment b-content-container" id="b-mobile-container" *ngIf="settings.isMapLoaded"
            [ngClass]="{'active': settings.isMobileContentActive, 'full-height': settings.isMarkerSelected}">
            <div class="ui label medium b-arrows" (click)="toggleContentActive()" *ngIf="settings.isMobileContentEnabled">
                <i class="angle double up icon" *ngIf="false == settings.isMobileContentActive"></i>
                <i class="angle double down icon" *ngIf="settings.isMobileContentActive"></i>
            </div>

            <app-zoom-controls *ngIf="false == settings.isMobileContentActive" [areaLevel]="data.current.area.core.areaLevel"></app-zoom-controls>

            <div class="ui grid">
                <div class="sixteen wide column">
                    <app-breadcrumb-mobile [areaLevel]="data.current.area.core.areaLevel" [path]="data.current.path" (onClickArea)="breadcrumbMobileClickArea($event)">
                    </app-breadcrumb-mobile>
                </div>

                <div class="sixteen wide column b-column-mobile" *ngIf="settings.isMobileContentActive">
                    <app-factsheet-mobile *ngIf="false == settings.isMarkerSelected" [area]="data.current.area.core" [isCompared]="data.current.isCompared" [isComparable]="data.current.isComparable">
                    </app-factsheet-mobile>

                    <app-categories-mobile *ngIf="false == settings.isMarkerSelected" [area]="data.current.area.core"></app-categories-mobile>

                    <app-popup-mobile></app-popup-mobile>

                    <!-- <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim facilisis gravida neque convallis a cras. Nulla
                        malesuada pellentesque elit eget gravida. Diam donec adipiscing tristique risus nec. Quisque non tellus orci ac auctor augue mauris augue neque. Potenti nullam ac tortor vitae
                        purus faucibus. Nunc consequat interdum varius sit amet. Potenti nullam ac tortor vitae purus faucibus ornare suspendisse. A scelerisque purus semper eget duis. Quisque egestas
                        diam in arcu cursus.
                        <br /><br />
                        Mauris cursus mattis molestie a iaculis. Vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras. Tellus mauris a diam maecenas sed enim. Luctus accumsan tortor
                        posuere ac ut. Ac turpis egestas maecenas pharetra convallis posuere morbi leo urna. Pellentesque adipiscing commodo elit at imperdiet dui. Laoreet non curabitur gravida arcu
                        ac tortor. Arcu cursus vitae congue mauris rhoncus. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue. In nisl nisi scelerisque eu ultrices vitae. Ut
                        aliquam purus sit amet luctus venenatis lectus magna. Volutpat ac tincidunt vitae semper quis. Maecenas ultricies mi eget mauris pharetra et. Feugiat sed lectus vestibulum
                        mattis ullamcorper velit sed ullamcorper. Eu mi bibendum neque egestas congue.
                        <br /><br />
                        Sed adipiscing diam donec adipiscing tristique risus nec feugiat. Aliquet porttitor lacus luctus accumsan tortor posuere ac ut. Id neque aliquam vestibulum morbi. Mauris nunc
                        congue nisi vitae suscipit tellus mauris. Nec feugiat nisl pretium fusce id velit. Lobortis feugiat vivamus at augue eget arcu. Vulputate ut pharetra sit amet aliquam. Dolor
                        sed viverra ipsum nunc aliquet bibendum enim. Blandit cursus risus at ultrices. Nec dui nunc mattis enim. Venenatis lectus magna fringilla urna porttitor. Mauris vitae
                        ultricies leo integer malesuada nunc vel risus commodo. Metus vulputate eu scelerisque felis imperdiet. Id cursus metus aliquam eleifend mi in nulla posuere. Feugiat in ante
                        metus dictum at. Feugiat in fermentum posuere urna nec tincidunt praesent. Risus sed vulputate odio ut enim blandit volutpat. Magna fringilla urna porttitor rhoncus dolor.
                        Sollicitudin aliquam ultrices sagittis orci a scelerisque purus.
                        <br /><br />
                        Placerat vestibulum lectus mauris ultrices eros in. Aliquet risus feugiat in ante metus dictum at. Morbi tincidunt augue interdum velit. Sit amet nisl suscipit adipiscing
                        bibendum est ultricies integer quis. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Feugiat in ante metus dictum at tempor commodo. Nec nam aliquam sem et
                        tortor. In ante metus dictum at tempor. Mattis enim ut tellus elementum sagittis. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida. Enim ut tellus
                        elementum sagittis vitae et. Vitae auctor eu augue ut. Ultrices sagittis orci a scelerisque purus semper. In fermentum et sollicitudin ac orci phasellus egestas tellus. Id leo
                        in vitae turpis massa sed elementum. Aliquet nibh praesent tristique magna sit amet purus gravida. Leo duis ut diam quam nulla porttitor.
                        <br /><br />
                        In fermentum et sollicitudin ac orci phasellus. Vitae et leo duis ut diam quam nulla porttitor massa. Semper risus in hendrerit gravida. Consectetur libero id faucibus nisl
                        tincidunt eget nullam. Pharetra et ultrices neque ornare aenean. Vitae congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Sapien pellentesque habitant morbi
                        tristique senectus et netus et. Gravida rutrum quisque non tellus orci ac. In massa tempor nec feugiat. Nec ullamcorper sit amet risus nullam eget felis eget nunc. Mauris nunc
                        congue nisi vitae suscipit tellus. Neque vitae tempus quam pellentesque nec nam aliquam sem. In nulla posuere sollicitudin aliquam ultrices sagittis orci a. Duis ultricies
                        lacus sed turpis tincidunt id aliquet risus. Eu volutpat odio facilisis mauris. Auctor elit sed vulputate mi sit amet mauris. Tristique magna sit amet purus gravida quis
                        blandit.
                    </p> -->

                </div>
            </div>
        </div>

        <app-comparison></app-comparison>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
        <!-- {{somePropYes}} -->
    </div>
</div>