export enum MarkerRealEstateType {
    None = 0,

    CooperativeDwelling = 500, // Andelsbolig
    OwnerOccupiedFlat = 300, // Ejerlejlighed
    RecreationalProperty = 400, // Fritidsbolig
    RecreationalPlot = 800, // Fritidsgrund
    AllYearRoundPlot = 700, // Helårsgrund
    Allotment = 401, // Kolonihave
    RuralProperty = 600, // Landejendom
    PleasureProperty = 1400,// Lystejendom
    TownHouse = 200, // Rækkehus
    BrickHouse = 100, // Villa
    BrickHouseApartment = 900, // Villalejlighed
}