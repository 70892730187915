// import { Coordinate } from '../shared/types/geometry/coordinate';
var BaseArea = /** @class */ (function () {
    function BaseArea() {
    }
    Object.defineProperty(BaseArea.prototype, "urlParameter", {
        get: function () {
            var output = null;
            output = this.replaceWithLowerCase(this.name);
            output = this.replaceDanishLetters(output);
            output = this.replaceSpace(output);
            return output;
        },
        enumerable: true,
        configurable: true
    });
    BaseArea.prototype.isUrlParameterMatch = function (urlParameter) {
        return urlParameter == this.urlParameter;
    };
    BaseArea.prototype.replaceWithLowerCase = function (value) {
        var output = value
            .toLowerCase();
        return output;
    };
    BaseArea.prototype.replaceDanishLetters = function (value) {
        var output = value
            .replace('æ', 'ae')
            .replace('ø', 'oe')
            .replace('å', 'aa')
            .replace('Æ', 'AE')
            .replace('Ø', 'OE')
            .replace('Å', 'AA');
        return output;
    };
    BaseArea.prototype.replaceSpace = function (value) {
        return value
            .replace(' ', '-');
    };
    return BaseArea;
}());
export { BaseArea };
