export enum MarkerFacilityType {
    None = 0,

    Swimming = 10,
    Olympicsite = 20,       // Atletikanlæg
    Fitness = 30,
    Soccer = 40,
    Golf = 50,
    StadiumSmall = 60,
    StadiumMedium = 70,
    Horseriding = 80,
    Shootingrange = 90,
    StadiumLarge = 100,
    TennisOutdoor = 110,
    TennisIndoor = 120,
    Badminton = 130,
    Icehockey = 140,
    Curling = 150,
}