import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-area-label',
    templateUrl: './area-label.component.html',
    styleUrls: ['./area-label.component.scss']
})
export class AreaLabelComponent {
    @Input() text: string;

    constructor() { }
}