import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, SimpleChanges } from '@angular/core';
import { IBaseArea } from './base-area';
import { MarkerService } from './marker.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FactsheetService } from './factsheet.service';

@Component({
    selector: 'app-factsheet-mobile',
    templateUrl: './factsheet-mobile.component.html',
    styleUrls: ['./factsheet-mobile.component.scss']
})
export class FactsheetMobileComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();

    @Input() area: IBaseArea;
    @Input() isCompared: boolean;
    @Input() isComparable: boolean;
    @Output() onToggleCompare = new EventEmitter<any>();
    @Output() onToggleComparisonVisibility = new EventEmitter<any>();

    factsheet: any;
    isLoading: boolean;
    isLoaded: boolean;
    isAnyMarkerEnabled = false;

    constructor(private _factsheetService: FactsheetService, private _markerService: MarkerService) { }

    ngOnInit(): void {
        this.isAnyMarkerEnabled = this._markerService.isAnyMarkerVisible();

        this._markerService.onToggleMarkerVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                this.isAnyMarkerEnabled = x.isAnyVisible
            });


        this._factsheetService.onLoad
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (x.isLoaded) {
                    this.factsheet = x.factsheet;
                }

                this.isLoading = x.isLoading;
                this.isLoaded = x.isLoaded;
            });

    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    clickToggleCompare(): void {
        this.onToggleCompare.emit();
    }

    clickToggleComparisonVisibility(): void {
        this.onToggleComparisonVisibility.emit();
    }
}
