/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map-box.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./map-box.component";
import * as i3 from "../shared/services/utility.service";
import * as i4 from "./map.service";
import * as i5 from "./zoom.service";
import * as i6 from "./marker.service";
import * as i7 from "./route-data.service";
var styles_MapBoxComponent = [i0.styles];
var RenderType_MapBoxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MapBoxComponent, data: {} });
export { RenderType_MapBoxComponent as RenderType_MapBoxComponent };
export function View_MapBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "map"]], null, null, null, null, null))], null, null); }
export function View_MapBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map-box", [], null, null, null, View_MapBoxComponent_0, RenderType_MapBoxComponent)), i1.ɵdid(1, 4440064, null, 0, i2.MapBoxComponent, [i3.UtilityService, i4.MapService, i5.ZoomService, i6.MarkerService, i7.RouteDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapBoxComponentNgFactory = i1.ɵccf("app-map-box", i2.MapBoxComponent, View_MapBoxComponent_Host_0, {}, { onMapLoaded: "onMapLoaded", onLayerClick: "onLayerClick" }, []);
export { MapBoxComponentNgFactory as MapBoxComponentNgFactory };
