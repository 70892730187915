/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-with-tooltip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./text-with-tooltip.component";
import * as i3 from "../../../shared/services/utility.service";
var styles_TextWithTooltipComponent = [i0.styles];
var RenderType_TextWithTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextWithTooltipComponent, data: {} });
export { RenderType_TextWithTooltipComponent as RenderType_TextWithTooltipComponent };
export function View_TextWithTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["data-inverted", ""]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.elementId, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 1, 0, currVal_1); }); }
export function View_TextWithTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-with-tooltip", [], null, null, null, View_TextWithTooltipComponent_0, RenderType_TextWithTooltipComponent)), i1.ɵdid(1, 4440064, null, 0, i2.TextWithTooltipComponent, [i3.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextWithTooltipComponentNgFactory = i1.ɵccf("app-text-with-tooltip", i2.TextWithTooltipComponent, View_TextWithTooltipComponent_Host_0, { text: "text", tooltip: "tooltip" }, {}, []);
export { TextWithTooltipComponentNgFactory as TextWithTooltipComponentNgFactory };
