import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { MapComponent } from './map.component';
import { MapBoxComponent } from './map-box.component';
import { BreadcrumbMobileComponent } from './breadcrumb-mobile.component';
import { ZoomControlsComponent } from './zoom-controls.component';
import { BreadcrumbDesktopComponent } from './breadcrumb-desktop.component';
import { FactsheetDesktopComponent } from './factsheet-desktop.component';
import { FactsheetMobileComponent } from './factsheet-mobile.component';
import { CategoriesDesktopComponent } from './categories-desktop.component';
import { CategoriesMobileComponent } from './categories-mobile.component';
import { CategoriesListDesktopComponent } from './categories-list-desktop.component';
import { CategoriesListItemDesktopComponent } from './categories-list-item-desktop.component';
import { PopupDesktopComponent } from './popup-desktop.component';
import { PopupMobileComponent } from './popup-mobile.component';
import { CategoriesListMobileComponent } from './categories-list-mobile.component';
import { CategoriesListItemMobileComponent } from './categories-list-item-mobile.component';
import { TopRightContentBoxDesktopComponent } from './top-right-content-box-desktop.component';
import { SelectedMarkerItemBoxDesktopComponent } from './selected-marker-item-box-desktop.component';
import { ComparisonModule } from './comparison/comparison.module';
import { CategoriesDropdownComponent } from './categories-dropdown.component';
import { CategoriesCheckboxComponent } from './categories-checkbox.component';
import { TextWithTooltipComponent } from './selected-marker/text-with-tooltip/text-with-tooltip.component';
import { CategoriesRadioButtonComponent } from './categories-radio-button.component';

@NgModule({
    declarations: [
        MapComponent,
        MapBoxComponent,
        BreadcrumbMobileComponent,
        ZoomControlsComponent,
        BreadcrumbDesktopComponent,
        FactsheetDesktopComponent,
        FactsheetMobileComponent,
        CategoriesDesktopComponent,
        CategoriesMobileComponent,
        CategoriesListDesktopComponent,
        CategoriesListItemDesktopComponent,
        PopupDesktopComponent,
        PopupMobileComponent,
        CategoriesListMobileComponent,
        CategoriesListItemMobileComponent,
        TopRightContentBoxDesktopComponent,
        SelectedMarkerItemBoxDesktopComponent,
        CategoriesDropdownComponent,
        CategoriesCheckboxComponent,
        TextWithTooltipComponent,
        CategoriesRadioButtonComponent,
    ],
    entryComponents: [
        PopupDesktopComponent
    ],
    imports: [
        SharedModule,
        ComparisonModule
    ]
})
export class MapModule { }
