import { BaseArea, IBaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';

export class Country extends BaseArea implements ICountry, IBaseArea {
    areaLevel: AreaLevel = AreaLevel.Country;
    countryId: number = 208;

    constructor() {
        super();
    }

    init(obj: IBaseCountry): void {
        this.name = obj.name;
        // this.countryId = obj.countryId;
    }

    get fullName() {
        return this.name;
    }
}

export interface ICountry {
    countryId: number;

    // IBaseArea
    areaLevel: AreaLevel;
    name: string;
    urlParameter: string;
    fullName: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}

export interface IBaseCountry {
    name: string;
    countryId: number;
}