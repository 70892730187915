<tr>
    <td>
        Adresse
    </td>
    <td *ngFor="let item of items">
        {{item.address}}
    </td>
</tr>
<tr>
    <td>
        Kommune
    </td>
    <td *ngFor="let item of items">
        {{item.municipalityName}}
    </td>
</tr>
<tr *ngIf="hasAddress">
    <td>
        Afstand
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.distanceToAddress">{{item.distanceToAddress | number: '1.2-2'}} km</span>
        <span *ngIf="!item.distanceToAddress">-</span>
    </td>
</tr>
<tr>
    <td>
        Resultater <sup>1)</sup>
    </td>
    <td *ngFor="let item of items">

        <div class="b-table-container-high-schools" *ngFor="let educationKeyFigure of item.educationKeyFigures">
            <table class="ui basic compact table b-table-high-schools">
                <thead>
                    <tr>
                        <th>{{educationKeyFigure.educationName}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Eksamensresultat</td>
                        <td class="right aligned">
                            <span *ngIf="educationKeyFigure.latestKeyFigures.grade">{{educationKeyFigure.latestKeyFigures.grade.value | number}} {{educationKeyFigure.latestKeyFigures.grade.unit}}</span>
                            <span *ngIf="!educationKeyFigure.latestKeyFigures.grade">-</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Socioøkonomisk reference</td>
                        <td class="right aligned">
                            <span *ngIf="educationKeyFigure.latestKeyFigures.socialEconomicReference">{{educationKeyFigure.latestKeyFigures.socialEconomicReference.value | number}} {{educationKeyFigure.latestKeyFigures.socialEconomicReference.unit}}</span>
                            <span *ngIf="!educationKeyFigure.latestKeyFigures.socialEconomicReference">-</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Trivsel (1-5)</td>
                        <td class="right aligned">
                            <span *ngIf="educationKeyFigure.latestKeyFigures.wellbeing">{{educationKeyFigure.latestKeyFigures.wellbeing.value | number}} {{educationKeyFigure.latestKeyFigures.wellbeing.unit}}</span>
                            <span *ngIf="!educationKeyFigure.latestKeyFigures.wellbeing">-</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="item.educationKeyFigures.length == 0">
            <em>(ingen tilgængelige resultater)</em>
        </div>
    </td>
</tr>
<tr>
    <td [attr.colspan]="items.length + 1">
        <p>
            <span>
                1) Den socioøkonomiske reference for gymnasiekarakterer er et statistisk beregnet udtryk, som viser,
                <br />
                hvordan elever på landsplan med samme baggrundsforhold som institutionens elever har klaret prøverne.
            </span>

            <br />

            <span style="display: inline-block; padding-top: 10px;">
                "Socioøkonomisk" refererer til elevernes sociale og økonomiske baggrund, mens "reference" fortæller,
                <br />
                at tallet kan bruges som et sammenligningsgrundlag for institutionens faktisk opnåede karakterer.
            </span>

            <br />

            <span style="display: inline-block; padding-top: 10px;">
                Tallene stammer fra opgørelser lavet i {{dateUpdated.grade}} for eksamensresultat, i {{dateUpdated.socialEconomicReference}} for socioøkonomisk reference 
                <br />
                og i {{dateUpdated.wellbeing}} for trivsel.
            </span>

            <br />

            <span style="display: inline-block; padding-top: 10px;">
                <em>Kilde: <a href="https://www.uvm.dk/statistik/gymnasiale-uddannelser/karakterer/sociooekonomisk-reference-for-gymnasiekarakterer" target="_blank">Børne- og Undervisningsministeriet</a></em>
            </span>
        </p>
    </td>
</tr>



<!-- <tr>
    <td>
        Karaktérgennemsnit
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.gradeAverageExamns">{{item.latestKeyFigure.gradeAverageExamns
                            | number: '1.2-2'}}</span>
        <span *ngIf="!item.latestKeyFigure.gradeAverageExamns">-</span>
    </td>
</tr>
<tr>
    <td>
        Antal elever
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.studentCount">{{item.latestKeyFigure.studentCount}}</span>
        <span *ngIf="!item.latestKeyFigure.studentCount">-</span>
    </td>
</tr>
<tr>
    <td>
        Elever med fremmed herkomst
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.percentForeignStudents">{{item.latestKeyFigure.percentForeignStudents}}
            %</span>
        <span *ngIf="!item.latestKeyFigure.percentForeignStudents">-</span>
    </td>
</tr>
<tr>
    <td>
        Fraværsprocent
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.percentAbsence">{{item.latestKeyFigure.percentAbsence}} %</span>
        <span *ngIf="!item.latestKeyFigure.percentAbsence">-</span>
    </td>
</tr>
<tr>
    <td>
        Trivselsindikator (1-5)
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.wellbeing">{{item.latestKeyFigure.wellbeing}}</span>
        <span *ngIf="!item.latestKeyFigure.wellbeing">-</span>
    </td>
</tr>
<tr>
    <td>
        Elever i specialklasse
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.percentSpecialClass">{{item.latestKeyFigure.percentSpecialClass}}
            %</span>
        <span *ngIf="!item.latestKeyFigure.percentSpecialClass">-</span>
    </td>
</tr>
<tr>
    <td>
        Socioøkonomisk reference <sup>1)</sup>
    </td>
    <td *ngFor="let item of items">
        <span *ngIf="item.latestKeyFigure.significantSocialReference">{{item.latestKeyFigure.significantSocialReference}}</span>
        <span *ngIf="!item.latestKeyFigure.significantSocialReference">-</span>
    </td>
</tr> -->