import { AddressLevel } from '../shared/types/addressLevel.enum';
var Address = /** @class */ (function () {
    function Address() {
        this.addressLevel = AddressLevel.None;
    }
    Address.prototype.init = function (obj) {
        // this.countryId = obj.countryId;
        // this.regionId = obj.regionId;
        // this.municipalityId = obj.municipalityId;
        // this.parishId = obj.parishId;
        this.addressLevel = obj.addressLevel;
        this.zipCode = obj.zipCode;
        this.zipName = obj.zipName;
        this.roadName = obj.roadName;
        this.houseNumber = obj.houseNumber;
        this.floor = obj.floor;
        this.door = obj.door;
        this.name = obj.name;
        this.coordinate = obj.coordinate;
    };
    Object.defineProperty(Address.prototype, "fullName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "urlParameterZip", {
        get: function () {
            var zipCode = this.zipCode;
            var zipName = this.getDashEscapedString(this.zipName);
            var param = zipCode + "-" + zipName;
            return this.prepareUrlParameter(param);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "urlParameterRoad", {
        get: function () {
            var roadName = this.getDashEscapedString(this.roadName);
            var houseNumber = this.houseNumber;
            var param = roadName + "-" + houseNumber;
            return this.prepareUrlParameter(param);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Address.prototype, "urlParameterLevel", {
        get: function () {
            var floor = this.floor;
            var door = this.getDashEscapedString(this.door); // Can contain "/" and "-"
            var param = floor + "-" + door;
            // Handling missing data for param 3
            if (floor != null && door == null)
                param = floor + '-';
            else if (floor == null && door != null)
                param = '-' + door;
            else if (floor == null && door == null)
                param = null;
            return this.prepareUrlParameter(param);
        },
        enumerable: true,
        configurable: true
    });
    Address.prototype.isUrlParameterMatch = function (urlParameter) {
        return urlParameter == this.prepareUrlParameter(this.name);
    };
    Address.prototype.prepareUrlParameter = function (value) {
        var output = null;
        if (value != null) {
            output = value
                // .toLowerCase()
                .replace(/ /g, '-');
        }
        return output;
    };
    // If value contains "-" it needs to be replaced
    Address.prototype.getDashEscapedString = function (input) {
        if (input) {
            var output = '';
            var inputParts = input.split('-');
            for (var i = 0; i < inputParts.length; i++) {
                output += (i > 0 ? '--' : '') + inputParts[i];
            }
            return output;
        }
        else {
            return null;
        }
    };
    return Address;
}());
export { Address };
