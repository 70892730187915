import { Coordinate } from '../shared/types/geometry/coordinate';
import { MarkerTypeContainer } from './marker-type-container';

export interface IMarkerDetailsContainer {
    // marker: any;
    // updateMarkerItems(items: any[], markerTypeContainer: MarkerTypeContainer): any;

    getData(markerTypeContainer: MarkerTypeContainer, key: number): any;
    addData(markerTypeContainer: MarkerTypeContainer, key: number, data: any): void;
    hasData(markerTypeContainer: MarkerTypeContainer, key: number): boolean;
}

export class MarkerDetailsContainer implements IMarkerDetailsContainer {
    private _daycare = {
        age0to2: <{ [key: number]: any; }>{},
        age3to5: <{ [key: number]: any; }>{},
        age0to5: <{ [key: number]: any; }>{},
    };
    private _primarySchool = {
        government: <{ [key: number]: any; }>{},
        private: <{ [key: number]: any; }>{},
        boarding: <{ [key: number]: any; }>{},
    };
    private _highSchool = {
        government: <{ [key: number]: any; }>{},
        private: <{ [key: number]: any; }>{},
        studentPreparation: <{ [key: number]: any; }>{},
    };
    private _privateDaycare: { [key: number]: any; } = {};
    private _publicTransportation: { [key: number]: any; } = {};
    private _supermarket: { [key: number]: any; } = {};
    private _noise: { [key: number]: any; } = {};
    private _doctor: { [key: number]: any; } = {};
    private _realEstate = {
        cooperativeDwelling: <{ [key: number]: any; }>{},
        ownerOccupiedFlat: <{ [key: number]: any; }>{},
        recreationalProperty: <{ [key: number]: any; }>{},
        recreationalPlot: <{ [key: number]: any; }>{},
        allYearRoundPlot: <{ [key: number]: any; }>{},
        allotment: <{ [key: number]: any; }>{},
        ruralProperty: <{ [key: number]: any; }>{},
        pleasureProperty: <{ [key: number]: any; }>{},
        townHouse: <{ [key: number]: any; }>{},
        brickHouse: <{ [key: number]: any; }>{},
        brickHouseApartment: <{ [key: number]: any; }>{},
    };
    private _residentsForRent: { [key: number]: any; } = {};
    private _sportsUnion: { [key: number]: any; } = {};
    private _facility = {
        badminton: <{ [key: number]: any; }>{},
        curling: <{ [key: number]: any; }>{},
        fitness: <{ [key: number]: any; }>{},
        golf: <{ [key: number]: any; }>{},
        horseriding: <{ [key: number]: any; }>{},
        icehockey: <{ [key: number]: any; }>{},
        olympicsite: <{ [key: number]: any; }>{},
        shootingrange: <{ [key: number]: any; }>{},
        soccer: <{ [key: number]: any; }>{},
        stadiumLarge: <{ [key: number]: any; }>{},
        stadiumMedium: <{ [key: number]: any; }>{},
        stadiumSmall: <{ [key: number]: any; }>{},
        swimming: <{ [key: number]: any; }>{},
        tennisIndoor: <{ [key: number]: any; }>{},
        tennisOutdoor: <{ [key: number]: any; }>{},
    };
    private _schoolDistrict = {
        preSchool: <{ [key: number]: any; }>{},
        class0: <{ [key: number]: any; }>{},
        class1: <{ [key: number]: any; }>{},
        class2: <{ [key: number]: any; }>{},
        class3: <{ [key: number]: any; }>{},
        class4: <{ [key: number]: any; }>{},
        class5: <{ [key: number]: any; }>{},
        class6: <{ [key: number]: any; }>{},
        class7: <{ [key: number]: any; }>{},
        class8: <{ [key: number]: any; }>{},
        class9: <{ [key: number]: any; }>{},
        class10: <{ [key: number]: any; }>{},
    };

    getData(markerTypeContainer: MarkerTypeContainer, key: number): any {
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            return this[prop][key];
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            return this[prop][subProp][key];
        }
    }

    addData(markerTypeContainer: MarkerTypeContainer, key: number, data: any): void {
        // TODO: Do not cache forever, as the cache could get quite large if a user was using the site for a long while without doing a hard refresh. Therefore, cashe should have a timeout or a max size.
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            this[prop][key] = data;
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            this[prop][subProp][key] = data;
        }
    }

    hasData(markerTypeContainer: MarkerTypeContainer, key: number): boolean {
        let prop = "_" + markerTypeContainer.markerTypePropertyName;

        if (false == markerTypeContainer.hasSubType) {
            return key in this[prop];
        }
        else {
            let subProp = markerTypeContainer.markerSubTypePropertyName;
            return key in this[prop][subProp];
        }
    }

    // private getGeometry(geometry: any): PointGeometry | MultiPolygonGeometry {
    //     if (geometry.type == 'MultiPolygon')
    //         return new MultiPolygonGeometry(geometry.coordinates, true);
    //     else
    //         return new PointGeometry(geometry.coordinates);
    // }

    // private getKeyFromCoordinate(coordinate: Coordinate): string {
    //     return coordinate.lng.toString().replace(".", "_") + "__" + coordinate.lat.toString().replace(".", "_");
    // }

    // private getMarkerDetails(markerTypeContainer: MarkerTypeContainer, markerId: number): any {
    //     let prop = markerTypeContainer.markerTypePropertyName;

    //     if (false == markerTypeContainer.hasSubType) {
    //         return this.marker[prop];
    //     }
    //     else {
    //         let subProp = markerTypeContainer.markerSubTypePropertyName;
    //         return this.marker[prop][subProp];
    //     }
    // }
}