import { Component, OnInit, Output, Input, EventEmitter, NgZone, ViewEncapsulation } from '@angular/core';
import * as dawaAutocompleteModule from 'dawa-autocomplete2';
import { Observable } from 'rxjs';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Coordinate } from '../shared/types/geometry/coordinate';
import { Region } from './region';
import { Municipality } from '../municipality/types/municipality';
import { Parish } from './parish';
import { Address } from './address';
import { AddressLevel } from '../shared/types/addressLevel.enum';

@Component({
    selector: 'app-address-search-box',
    templateUrl: './address-search-box.component.html',
    styleUrls: ['./address-search-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddressSearchBoxComponent implements OnInit {
    @Input() size: string;
    @Input() showIcon: string;

    @Output() onSelected = new EventEmitter<any>();

    icon: string;
    searchInst: any;
    searchStr: string = null;

    constructor(private _ngZone: NgZone, private _dataAccessService: DataAccessService) {
        window['angularComponentRef'] = { component: this, zone: _ngZone };
    }

    ngOnInit() {
        if (this.showIcon) {
            this.icon = 'icon';
        }
    }

    ngAfterViewInit() {
        // Dawa
        var inputElem = document.getElementById('dawa-autocomplete-input');
        var optn = {
            select: (selected: any) => {
                window['angularComponentRef'].zone.run(() => {
                    this.onSelect(selected);
                });
            }
        };
        this.searchInst = dawaAutocompleteModule.dawaAutocomplete(inputElem, optn);
    }

    ngOnDestroy() {
        window['angularComponentRef'] = null;
    }

    onSelect(selected: any) {
        this.getAddressDataFromAddressId(selected.data.id)
            .subscribe((x: any) => {
                let address = new Address();
                address.init({
                    addressLevel: x.addressLevel,
                    zipCode: x.zipCode,
                    zipName: x.zipName,
                    roadName: x.roadName,
                    houseNumber: x.houseNumber,
                    floor: x.floor,
                    door: x.door,

                    name: x.fullAddress,
                    coordinate: x.coordinate
                });

                let region = new Region();
                region.name = x.regionName;

                let municipality = new Municipality();
                municipality.name = x.municipalityName;

                let parish = new Parish();
                parish.name = x.parishName;

                var output = {
                    regionParam: region.urlParameter,
                    municipalityParam: municipality.urlParameter,
                    parishParam: parish.urlParameter,
                    zipParam: address.urlParameterZip,
                    roadParam: address.urlParameterRoad,
                    levelParam: address.urlParameterLevel,
                }

                // Emit selected event
                this.onSelected.emit(output);
            });
    }

    private getAddressDataFromAddressId(id: string): Observable<any> {
        return new Observable((observer) => {
            this._dataAccessService
                .getDawaAddressInfoById(id)
                .subscribe(x => {
                    let data = this.mapAddressResponse(x, AreaLevel.Parish);
                    observer.next(data);
                    observer.complete();
                })
        })
    }

    private mapAddressResponse(address: any, areaLevel: AreaLevel): any {
        let coordinate = new Coordinate();
        coordinate.lng = address.adgangsadresse.adgangspunkt.koordinater[0];
        coordinate.lat = address.adgangsadresse.adgangspunkt.koordinater[1];

        let output = {
            id: address.adgangsadresse.id,
            regionId: address.adgangsadresse.region.kode,
            regionName: address.adgangsadresse.region.navn,
            municipalityId: address.adgangsadresse.kommune.kode,
            municipalityName: address.adgangsadresse.kommune.navn,
            parishId: address.adgangsadresse.sogn.kode,
            parishName: address.adgangsadresse.sogn.navn,
            zipCode: address.adgangsadresse.postnummer.nr,
            zipName: address.adgangsadresse.postnummer.navn,
            roadName: address.adgangsadresse.vejstykke.navn,
            houseNumber: address.adgangsadresse.husnr,
            floor: address.etage,
            door: address.dør,
            fullAddress: address.adressebetegnelse,
            areaLevel: areaLevel as AreaLevel,
            addressLevel: AddressLevel.None,
            coordinate: coordinate
        }

        if (output.zipCode && output.zipName && output.roadName && output.houseNumber && !output.floor && !output.door)
            output.addressLevel = AddressLevel.Road;
        else if (output.zipCode && output.zipName && output.roadName && output.houseNumber && output.floor && output.door)
            output.addressLevel = AddressLevel.Level;

        return output;
    }

    private getDashEscapedString(input: string): string {
        if (input) {
            var output = '';
            var inputParts = input.split('-');
            for (var i = 0; i < inputParts.length; i++) {
                output += (i > 0 ? '--' : '') + inputParts[i];
            }
            return output;
        }
        else {
            return null;
        }
    }
}
