import { Coordinate } from '../shared/types/geometry/coordinate';
import { AddressLevel } from '../shared/types/addressLevel.enum';

export class Address implements IAddress {
    // countryId: number;
    // regionId: number;
    // municipalityId: number;
    // parishId: number;

    addressLevel: AddressLevel;
    zipCode: number;
    zipName: string;
    roadName: string;
    houseNumber: string;
    floor: string;
    door: string;

    name: string;
    coordinate: Coordinate;

    constructor() {
        this.addressLevel = AddressLevel.None;
    }

    init(obj: IBaseAddress): void {
        // this.countryId = obj.countryId;
        // this.regionId = obj.regionId;
        // this.municipalityId = obj.municipalityId;
        // this.parishId = obj.parishId;

        this.addressLevel = obj.addressLevel;
        this.zipCode = obj.zipCode;
        this.zipName = obj.zipName;
        this.roadName = obj.roadName;
        this.houseNumber = obj.houseNumber;
        this.floor = obj.floor;
        this.door = obj.door;

        this.name = obj.name;
        this.coordinate = obj.coordinate;
    }

    get fullName() {
        return this.name;
    }

    get urlParameterZip(): string {
        let zipCode = this.zipCode;
        let zipName = this.getDashEscapedString(this.zipName);
        let param = `${zipCode}-${zipName}`;

        return this.prepareUrlParameter(param);
    }

    get urlParameterRoad(): string {
        let roadName = this.getDashEscapedString(this.roadName);
        let houseNumber = this.houseNumber;
        let param = `${roadName}-${houseNumber}`;

        return this.prepareUrlParameter(param);
    }

    get urlParameterLevel(): string {
        var floor = this.floor;
        var door = this.getDashEscapedString(this.door);       // Can contain "/" and "-"
        let param = `${floor}-${door}`;

        // Handling missing data for param 3
        if (floor != null && door == null)
            param = floor + '-';
        else if (floor == null && door != null)
            param = '-' + door;
        else if (floor == null && door == null)
            param = null;

        return this.prepareUrlParameter(param);
    }

    isUrlParameterMatch(urlParameter: string): boolean {
        return urlParameter == this.prepareUrlParameter(this.name);
    }

    prepareUrlParameter(value: string): string {
        let output: string = null;
        if (value != null) {
            output = value
                // .toLowerCase()
                .replace(/ /g, '-');
        }

        return output;
    }

    // If value contains "-" it needs to be replaced
    private getDashEscapedString(input: string): string {
        if (input) {
            var output = '';
            var inputParts = input.split('-');
            for (var i = 0; i < inputParts.length; i++) {
                output += (i > 0 ? '--' : '') + inputParts[i];
            }
            return output;
        }
        else {
            return null;
        }
    }
}

export interface IBaseAddress {
    addressLevel: AddressLevel;
    zipCode: number;
    zipName: string;
    roadName: string;
    houseNumber: string;
    floor: string;
    door: string;
    name: string;
    coordinate: Coordinate;
}

export interface IAddress {
    // countryId: number;
    // regionId: number;
    // municipalityId: number;
    // parishId: number;

    // IBaseAddress
    addressLevel: AddressLevel;
    zipCode: number;
    zipName: string;
    roadName: string;
    houseNumber: string;
    floor: string;
    door: string;
    name: string;
    coordinate: Coordinate;

    urlParameterZip: string;
    urlParameterRoad: string;
    urlParameterLevel: string;

    isUrlParameterMatch(urlParameter: string): boolean;
}