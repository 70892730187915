<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Siden blev ikke fundet!</h1>
                        <p>
                            Vi kunne ikke finde, det du søgte. Det kan skyldes, at siden ikke findes, at siden er flyttet eller at linket er skrevet forkert.
                        </p>

                        <ul>
                            <li>
                                Tjek om adressen i adresselinjen er korrekt, hvis du selv har indtastet den.
                            </li>
                            <li>
                                Klik på browserens Tilbageknap
                            </li>
                            <li>
                                Gå til <a [routerLink]="['/']">Forsiden</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>