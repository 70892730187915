import { Component, Input, EventEmitter, Output, SimpleChanges, OnInit } from '@angular/core';
import { ITable } from './types/table';
import { IChart } from './types/chart';
import { IChartSettings } from './types/chartSettings';
import { VisibilityService } from './visibility.service';
import { TableCellWidth } from './types/table-cell-width.enum';
import { UtilityService } from '../shared/services/utility.service';

declare var $: any;

@Component({
    selector: 'app-sub-section',
    templateUrl: './sub-section.component.html',
    styleUrls: ['./sub-section.component.scss']
})
export class SubSectionComponent implements OnInit {
    @Input() id: string;
    @Input() headline: string;
    @Input() chart: IChart;
    @Input() chartSettings: IChartSettings;
    @Input() tableSettings: any;
    @Input() table: ITable;
    @Input() category: any;
    @Input() dataSourceDescription: string;

    @Output() onCategoryClick = new EventEmitter<any>();

    isLoading: boolean = false;
    progressId: string;
    cellWidthCss : string;

    constructor(private _visibilityService: VisibilityService, private _utilityService: UtilityService) {
        this.progressId = this._utilityService.getRandomString();
    }

    ngOnInit(): void {
        if (this.tableSettings) {
            let cellWidth = this.tableSettings.mobile.cellWidth;
            if (cellWidth == TableCellWidth.Large) {
                this.cellWidthCss = 'b-large-cell-width';
            }
            else if (cellWidth == TableCellWidth.Big) {
                this.cellWidthCss = 'b-big-cell-width';
            }
            else
            {
                this.cellWidthCss = '';
            }
        }
    }

    ngAfterViewInit() {
        this._visibilityService.setupLeftMenuAfterViewInit(this.id);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.chart) {
            $('#' + this.progressId).progress({
                percent: 100
            });

            setTimeout(x => {
                $('#' + this.progressId).css("visibility", "hidden");
                $('#' + this.progressId).progress('reset');
            }, 600);
        }
    }

    public onClick(cat: any) {
        if (this.onCategoryClick.observers.length > 0) {
            this.onCategoryClick.emit(cat);

            $('#' + this.progressId).css('visibility', 'visible')
            $('#' + this.progressId).progress({
                percent: 25
            });
        }
    }

    public isActive(category: any, item: any): boolean {
        if (!category && this.onCategoryClick.observers.length == 0) {
            return true;
        }
        else {
            return (category != undefined ? category.id : null) == item.type.id;
        }
    }
}