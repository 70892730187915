import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import { ICurrentAreaEventData } from './current-area-event-data';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    public onLoad: BehaviorSubject<any> = new BehaviorSubject<any>({ isLoading: false, isLoaded: false });

    constructor(private _mapService: MapService) {
        this._mapService
            .onFitBoundsStart
            .subscribe((x: ICurrentAreaEventData) => {
                this.onLoad.next({
                    isLoading: true,
                    isLoaded: false
                });
            })

        this._mapService
            .onToggleLoadMarkers
            .subscribe((x: any) => {
                this.onLoad.next({
                    isLoading: x.isLoading,
                    isLoaded: x.isLoaded
                });
            });
    }
}
