<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Om ditomraade.dk</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column">
                        <h3>Ideen bag ditomraade.dk</h3>
                        <p>
                            De fleste mennesker er nysgerrige omkring den verden, der omgiver dem. At gøre det muligt for alle at finde viden om lige præcis det nærområde, der interesserer dem, har været motivationen for at udvikle ditomraade.dk.
                            Viden om vores børns nuværende og kommende skoler, viden om forureningen på de gader, vi overvejer at bo på, viden om den gennemsnitlige alder i det område, hvor vi bor eller viden om, hvor mange indbrud, der bliver begået
                            i vores nærområde.<br />

                            Det er spændende viden – men det er også vigtigt viden i nogle af livets store beslutninger, for eksempel når man er på udkig efter sin næste bolig.<br />

                            Der findes uanede mængder data "derude", og de fleste er offentligt tilgængelige, men de er kompliceret at finde, og endnu mere kompliceret at få præsenteret på en enkel og overskuelig måde.
                            <br />
                            <br />
                            Tager man skridtet videre og ønsker at sammenligne data på tværs af områder og kommuner, bliver opgaven for alvor kompliceret. Det er en svær og tidskrævende opgave at indsamle og validere de nødvendige data, og dernæst
                            skal man sørge for, at al data er opdateret.<br />
                            <br />
                            Konceptet bag ditomraade.dk går i alt sin enkelthed ud på at automatisere alt det komplicerede og gøre data tilgængelig på en overskuelig måde.
                            <br />
                            <br />
                            Vores koncept bygger på fire søjler:
                        </p>
                        <ul>
                            <li>
                                <strong>Visualisering</strong> - data skal præsenteres på en overskuelig og forståelig måde <br />
                            </li>
                            <li>
                                <strong>Interaktion</strong> - det skal være muligt at interagere med den data, der vises <br />
                            </li>
                            <li>
                                <strong>Sammenligning</strong> - det skal være muligt at sammenligne data <br />
                            </li>
                            <li>
                                <strong>Altid opdateret</strong> - data skal være opdateret og valideret
                            </li>
                        </ul>

                        <h3>Hvad kan du bruge ditomraade.dk til?</h3>
                        <p>
                            Ditomraade.dk er udviklet for at give den enkelte bruger unik og værdifuld viden om et det nærområde i Danmark, der interessserer dem. Hvis man for eksempel står over for et boligkøb og gerne vil vide så meget som muligt
                            om ens potentielle hjemsted, kan denne information være guld værd. Hvis man omvendt skal sælge sin bolig, kan det være fordelagtigt at vide så meget som muligt om, hvad det område, man fraflytter, har at tilbyde.
                            <br />
                            <br />
                            Ditomraade.dk er uafhængig af økonomiske interessenter som for eksempel ejendomsmæglere, banker, realkreditinstitutter, forsikringsselskaber, osv. Ditomraade.dk ønsker at visualisere data om danske nærområder, uden
                            kommerciel interesse i selve bolighandlen.
                        </p>

                        <h3>Roadmap</h3>
                        <p>
                            Vi arbejder løbende på at forbedre ditomraade.dk.
                            <br />
                            <br />
                            Se mere om, hvad vi arbejder på, under <a [routerLink]="['/roadmap']">roadmap</a>.
                        </p>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>