/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./categories-dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./categories-dropdown.component";
import * as i4 from "../shared/services/utility.service";
import * as i5 from "./marker.service";
import * as i6 from "./route-data.service";
var styles_CategoriesDropdownComponent = [i0.styles];
var RenderType_CategoriesDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoriesDropdownComponent, data: {} });
export { RenderType_CategoriesDropdownComponent as RenderType_CategoriesDropdownComponent };
function View_CategoriesDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "b-dropdown-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "ui right floated mini black button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickClearMarkersVisibility() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Nulstil valgte "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "b-dropdown-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["V\u00E6lg ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "b-dropdown-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "select", [["class", "ui fluid search dropdown"], ["multiple", ""]], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.baseMarkerItem.headlineLowered; _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.elementId, ""); _ck(_v, 9, 0, currVal_1); }); }
export function View_CategoriesDropdownComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesDropdownComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.baseMarkerItem.showMarkers; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CategoriesDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-categories-dropdown", ""]], null, null, null, View_CategoriesDropdownComponent_0, RenderType_CategoriesDropdownComponent)), i1.ɵdid(1, 245760, null, 0, i3.CategoriesDropdownComponent, [i4.UtilityService, i5.MarkerService, i6.RouteDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoriesDropdownComponentNgFactory = i1.ɵccf("[app-categories-dropdown]", i3.CategoriesDropdownComponent, View_CategoriesDropdownComponent_Host_0, { baseMarkerItem: "baseMarkerItem" }, {}, []);
export { CategoriesDropdownComponentNgFactory as CategoriesDropdownComponentNgFactory };
