import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Address } from './address';
import { AddressLevel } from '../shared/types/addressLevel.enum';

export class RouteData implements IRouteData {
    areaLevel: AreaLevel;
    region: string;
    regionId: number;
    municipality: string;
    municipalityId: number;
    parish: string;
    parishId: number;

    address: Address;

    get addressLevel(): AddressLevel {
        return this.address.addressLevel;
    }

    get hasAddress(): boolean {
        return this.address && this.address.addressLevel != AddressLevel.None;
    }

    // zipCode: number;
    // zipName: string;
    // roadName: string;
    // houseNumber: string;
    // floor: string;
    // door: string;

    constructor() {
        this.areaLevel = AreaLevel.None;
        this.regionId = null;
        this.municipalityId = null;
        this.parishId = null;

        this.address = new Address();
    }

    // init(routeParameters: string[]) {

    // }
}

export interface IRouteData {
    areaLevel: AreaLevel;
    region: string;
    regionId: number;
    municipality: string;
    municipalityId: number;
    parish: string;
    parishId: number;

    address: Address;
    addressLevel: AddressLevel;
    hasAddress: boolean;
}