import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map, delay } from 'rxjs/operators';

import { SentryService } from './sentry.service';

import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    constructor(private _router: Router, private _sentryService: SentryService) {
    }

    startTracking() {
        if (environment.keys.analytics.isEnabled) {
            var success = this.appendTrackingCode();
            if (success) {
                this.subscribeToRouteChanges()
            }
        }
    }

    private appendTrackingCode(): boolean {
        try {
            const script = document.createElement('script');
            script.innerHTML = `
                (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        
                ga('create', '` + environment.keys.analytics.key + `', 'auto');
            `;
            document.head.appendChild(script);

            return true;
        } catch (ex) {
            this._sentryService.captureException(new Error('Error appending google analytics'));
            return false;
        }
    }

    private subscribeToRouteChanges() {
        this._router.events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map((e: NavigationEnd) => {
                    return { url: e.urlAfterRedirects };
                }),
                delay(0),
            )
            .subscribe((x: { url: string }) => {
                ga('set', 'page', x.url);
                ga('send', 'pageview');
            });
    }
}
