import { IRegion } from './region';
import { IParish } from './parish';
import { IMunicipality } from '../municipality/types/municipality';
import { IAreaContainerCountryItem, AreaContainerCountryItem } from './area-container-country-item';
import { IAreaContainerRegionItem, AreaContainerRegionItem } from './area-container-region-item';
import { IAreaContainerMunicipalityItem, AreaContainerMunicipalityItem } from './area-container-municipality-item';
import { IAreaContainerParishItem, AreaContainerParishItem } from './area-container-parish-item';

export class AreaContainer implements IAreaContainer {
    private _countriesAll: IAreaContainerCountryItem[] = [];
    private _countriesByCountryId: { [countryId: number]: IAreaContainerCountryItem; } = {};

    private _regionsAll: IAreaContainerRegionItem[] = [];
    private _regionsByRegionId: { [regionId: number]: IAreaContainerRegionItem; } = {};

    private _municipalitiesAll: IAreaContainerMunicipalityItem[] = [];
    private _municipalitiesByMunicipalityId: { [municipalityId: number]: IAreaContainerMunicipalityItem; } = {};
    private _municipalitiesByRegionId: { [regionId: number]: IAreaContainerMunicipalityItem[]; } = {};

    private _parishesAll: IAreaContainerParishItem[] = [];
    private _parishesByParishId: { [parishId: number]: IAreaContainerParishItem; } = {};
    private _parishesByMunicipalityId: { [municipalityId: number]: IAreaContainerParishItem[]; } = {};
    private _parishesByRegionId: { [regionId: number]: IAreaContainerParishItem[]; } = {};


    getCountry(): IAreaContainerCountryItem {
        let countryId: number = 208;
        return this._countriesByCountryId[countryId];
    }
    getCountries(): IAreaContainerCountryItem[] {
        return this._parishesAll;
    }

    getRegion(regionId: number): IAreaContainerRegionItem {
        return this._regionsByRegionId[regionId];
    }
    getRegions(): IAreaContainerRegionItem[] {
        return this._regionsAll;
    }
    getRegionByUrlParameter(regionUrlParameter: string): IAreaContainerRegionItem {
        for (const id in this._regionsByRegionId) {
            if (this._regionsByRegionId.hasOwnProperty(id)) {
                const item = this._regionsByRegionId[id];
                if (item.core.isUrlParameterMatch(regionUrlParameter)) {
                    return item;
                }
            }
        }
    }


    getMunicipality(municipalityId: number): IAreaContainerMunicipalityItem {
        return this._municipalitiesByMunicipalityId[municipalityId];
    }
    getMunicipalities(regionId: number): IAreaContainerMunicipalityItem[] {
        return this._municipalitiesByRegionId[regionId];
    }
    getMunicipalityByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string): IAreaContainerMunicipalityItem {
        var regionId = this.getRegionByUrlParameter(regionUrlParameter).core.regionId;
        const municipalities = this._municipalitiesByRegionId[regionId];
        for (const item of municipalities) {
            if (item.core.isUrlParameterMatch(municipalityUrlParameter)) {
                return item;
            }
        }
    }


    getParish(parishId: number): IAreaContainerParishItem {
        return this._parishesByParishId[parishId];
    }
    getParishes(municipalityId: number): IAreaContainerParishItem[] {
        return this._parishesByMunicipalityId[municipalityId];
    }
    getParishByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string, parishUrlParameter: string): IAreaContainerParishItem {
        var municipalityId = this.getMunicipalityByUrlParameter(regionUrlParameter, municipalityUrlParameter).core.municipalityId;
        const parishes = this._parishesByMunicipalityId[municipalityId];
        for (const item of parishes) {
            if (item.core.isUrlParameterMatch(parishUrlParameter)) {
                return item;
            }
        }
    }


    addCountry(item: any): void {
        let areaItem = new AreaContainerCountryItem();
        areaItem.addCore(item);
        this._countriesByCountryId[item.countryId] = areaItem;

        this._countriesAll.push(areaItem);
    }
    addCountryGeometry(item: any): void {
        let areaItem = this._countriesByCountryId[item.countryId];
        areaItem.addGeometry(item);
        this._countriesByCountryId[item.countryId] = areaItem;
    }


    addRegions(items: any[]): void {
        for (const item of items) {
            let areaItem = new AreaContainerRegionItem();
            areaItem.addCore(item);

            this.addRegion(areaItem);
        }
    }
    addRegionGeometries(items: any[]): void {
        for (const item of items) {
            let areaItem = this._regionsByRegionId[item.regionId]
            areaItem.addGeometry(item);
        }
    }
    private addRegion(item: IAreaContainerRegionItem): void {
        this._regionsByRegionId[item.core.regionId] = item;
        this._regionsAll.push(item);
    }


    addMunicipalities(items: any[]): void {
        for (const item of items) {
            let areaItem = new AreaContainerMunicipalityItem();
            areaItem.addCore(item);

            this.addMunicipality(areaItem);
            this.addMunicipalityByRegion(areaItem);
        }
    }
    addMunicipalityGeometries(items: any[]): void {
        for (const item of items) {
            let areaItem = this._municipalitiesByMunicipalityId[item.municipalityId]
            areaItem.addGeometry(item);
        }
    }
    addMunicipalityFactsheet(municipalityId: number, item: any): any {
        let areaItem = this._municipalitiesByMunicipalityId[municipalityId]
        areaItem.addFactsheet(item);

        return areaItem.factsheet;
    }
    private addMunicipality(item: IAreaContainerMunicipalityItem): void {
        this._municipalitiesByMunicipalityId[item.core.municipalityId] = item;
        this._municipalitiesAll.push(item);
    }
    private addMunicipalityByRegion(item: IAreaContainerMunicipalityItem): void {
        if (this._municipalitiesByRegionId[item.core.regionId]) {
            this._municipalitiesByRegionId[item.core.regionId].push(item);
        }
        else {
            this._municipalitiesByRegionId[item.core.regionId] = [item];
        }
    }


    addParishes(items: any[]): void {
        for (const item of items) {
            let areaItem = new AreaContainerParishItem();
            areaItem.addCore(item);

            this.addParish(areaItem);
            this.addParishByRegion(areaItem);
            this.addParishByMunicipality(areaItem);
        }
    }
    addParishGeometries(items: any[]): void {
        for (const item of items) {
            let areaItem = this._parishesByParishId[item.parishId]
            areaItem.addGeometry(item);
        }
    }
    addParishFactsheet(parishId: number, item: any): any {
        let areaItem = this._parishesByParishId[parishId]
        areaItem.addFactsheet(item);

        return areaItem.factsheet;
    }
    private addParish(item: IAreaContainerParishItem): void {
        this._parishesByParishId[item.core.parishId] = item;
        this._parishesAll.push(item);
    }
    private addParishByMunicipality(item: IAreaContainerParishItem): void {
        if (this._parishesByMunicipalityId[item.core.municipalityId]) {
            this._parishesByMunicipalityId[item.core.municipalityId].push(item);
        }
        else {
            this._parishesByMunicipalityId[item.core.municipalityId] = [item];
        }
    }
    private addParishByRegion(item: IAreaContainerParishItem): void {
        if (this._parishesByRegionId[item.core.regionId]) {
            this._parishesByRegionId[item.core.regionId].push(item);
        }
        else {
            this._parishesByRegionId[item.core.regionId] = [item];
        }
    }


    hasRegionGeometries(): boolean {
        for (const item in this._regionsByRegionId) {
            if (this._regionsByRegionId.hasOwnProperty(item)) {
                const element = this._regionsByRegionId[item];
                if (false == element.hasGeometry())
                    return false;
            }
        }

        return true;
    }


    hasMunicipalityGeometries(): boolean {
        for (const id in this._municipalitiesByMunicipalityId) {
            if (this._municipalitiesByMunicipalityId.hasOwnProperty(id)) {
                const element = this._municipalitiesByMunicipalityId[id];
                if (false == element.hasGeometry())
                    return false;
            }
        }

        return true;
    }
    hasMunicipalityGeometriesByRegionId(regionId: number): boolean {
        for (const element of this._municipalitiesByRegionId[regionId]) {
            if (false == element.hasGeometry())
                return false;
        }

        return true;
    }

    hasParishGeometries(): boolean {
        for (const id in this._parishesByParishId) {
            if (this._parishesByParishId.hasOwnProperty(id)) {
                const element = this._parishesByParishId[id];
                if (false == element.hasGeometry())
                    return false;
            }
        }

        return true;
    }
    hasParishGeometriesByRegionId(regionId: number): boolean {
        for (const element of this._parishesByRegionId[regionId]) {
            if (false == element.hasGeometry())
                return false;
        }

        return true;
    }
    hasParishGeometriesByMunicipalityId(municipalityId: number): boolean {
        for (const element of this._parishesByMunicipalityId[municipalityId]) {
            if (false == element.hasGeometry())
                return false;
        }

        return true;
    }



    hasMunicipalityFactsheet(municipalityId: number): boolean {
        const element = this._municipalitiesByMunicipalityId[municipalityId];
        return element.hasFactsheet();
    }
    hasParishFactsheet(parishId: number): boolean {
        const element = this._parishesByParishId[parishId];
        return element.hasFactsheet();
    }



    // private getDictionaryItemsAsArray(items: { [key: number]: any }): Array<any> {
    //     var keys = Object.keys(items);
    //     return keys.map(function (v) { return items[v]; });
    // }
}

export interface IAreaContainer {
    getCountry(): IAreaContainerCountryItem;
    getCountries(): IAreaContainerCountryItem[];

    getRegion(regionId: number): IAreaContainerRegionItem;
    getRegions(): IAreaContainerRegionItem[];
    getRegionByUrlParameter(regionUrlParameter: string): IAreaContainerRegionItem;

    getMunicipality(municipalityId: number): IAreaContainerMunicipalityItem;
    getMunicipalities(regionId: number): IAreaContainerMunicipalityItem[];
    getMunicipalityByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string): IAreaContainerMunicipalityItem;

    getParish(parishId: number): IAreaContainerParishItem;
    getParishes(municipalityId: number): IAreaContainerParishItem[];
    getParishByUrlParameter(regionUrlParameter: string, municipalityUrlParameter: string, parishUrlParameter: string): IAreaContainerParishItem;



    addCountry(item: any): void;
    addCountryGeometry(item: any): void

    addRegions(items: any[]): void;
    addRegionGeometries(items: any[]): void

    addMunicipalities(items: any[]): void;
    addMunicipalityGeometries(items: any[]): void;
    addMunicipalityFactsheet(municipalityId: number, items: any[]): any

    addParishes(items: any[]): void;
    addParishGeometries(items: any[]): void;
    addParishFactsheet(parishId: number, items: any[]): any



    hasRegionGeometries(): boolean;
    hasMunicipalityGeometries(): boolean;
    hasMunicipalityGeometriesByRegionId(regionId: number): boolean;
    hasParishGeometries(): boolean;
    hasParishGeometriesByRegionId(regionId: number): boolean;
    hasParishGeometriesByMunicipalityId(municipalityId: number): boolean;

    hasMunicipalityFactsheet(municipalityId: number): boolean;
    hasParishFactsheet(parishId: number): boolean;
}