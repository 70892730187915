import { Component, Input } from '@angular/core';

import { VisibilityService } from './visibility.service';

@Component({
    selector: '[app-overview-headline]',
    styleUrls: ['./overview-headline.component.scss'],
    template: `
        <p id="{{id}}">{{headline}}</p>
    `
})
export class OverviewHeadlineComponent {
    @Input() id: string;
    @Input() headline: string;

    constructor(private _visibilityService: VisibilityService) {
    }

    ngAfterViewInit() {
        this._visibilityService.setupLeftMenuAfterViewInit(this.id, true);
    }
}