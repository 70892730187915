/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-headline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overview-headline.component";
import * as i3 from "./visibility.service";
var styles_OverviewHeadlineComponent = [i0.styles];
var RenderType_OverviewHeadlineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewHeadlineComponent, data: {} });
export { RenderType_OverviewHeadlineComponent as RenderType_OverviewHeadlineComponent };
export function View_OverviewHeadlineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.id, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.headline; _ck(_v, 1, 0, currVal_1); }); }
export function View_OverviewHeadlineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-overview-headline", ""]], null, null, null, View_OverviewHeadlineComponent_0, RenderType_OverviewHeadlineComponent)), i1.ɵdid(1, 4243456, null, 0, i2.OverviewHeadlineComponent, [i3.VisibilityService], null, null)], null, null); }
var OverviewHeadlineComponentNgFactory = i1.ɵccf("[app-overview-headline]", i2.OverviewHeadlineComponent, View_OverviewHeadlineComponent_Host_0, { id: "id", headline: "headline" }, {}, []);
export { OverviewHeadlineComponentNgFactory as OverviewHeadlineComponentNgFactory };
