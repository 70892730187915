import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';

@NgModule({
    declarations: [
        TermsAndConditionsComponent
    ],
    imports: [
        SharedModule
    ]
})
export class TermsAndConditionsModule { }
