export class Coordinate implements ICoordinate {
    lng: number;
    lat: number;

    // constructor(lng: number, lat: number) {
    //     this.lat = lat;
    //     this.lng = lng;
    // }
}

export interface ICoordinate {
    lng: number;
    lat: number;
}
