import { Component, Input } from '@angular/core';
import { ScrollService } from './scroll.service';
import { MenuItemsService } from './menu-items.service';

declare var $: any;

@Component({
    selector: 'app-left-menu',
    templateUrl: './left-menu.component.html',
    styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent {
    public items: any;

    constructor(private _scrollService: ScrollService, private _menuItemsService: MenuItemsService) {
        this.items = _menuItemsService.items;
    }

    public clickMenuItem(item: any, isOverview: boolean) {
        var offset = 80;
        var fragmentName = item.fragment;
        if (isOverview)
        {
            offset = 90;    // Off-set is different for headline
            fragmentName = item.items[0].fragment
        }

        this._scrollService.scrollToId(fragmentName, offset)
    }
    
    public getVisibleChildItems(items: Array<any>) {
        return this._menuItemsService.getVisibleChildItems(items);
    }

    ngAfterViewInit() {
        $('.b-left-menu')
            .visibility({
                type: 'fixed',
                offset: 65
            });
    }
}