
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SentryService } from '../shared/services/sentry.service';

@Component({
    templateUrl: './pagenotfound.component.html'
})
export class PageNotFoundComponent implements OnInit {
    constructor(private _activatedRoute: ActivatedRoute, private _sentryService: SentryService) {
    }

    ngOnInit() {
        let path = 'UNKNOWN';
        if (this._activatedRoute && this._activatedRoute.snapshot && this._activatedRoute.snapshot.url && this._activatedRoute.snapshot.url.length && this._activatedRoute.snapshot.url.length > 0)
            path = this._activatedRoute.snapshot.url[0].path;

        this._sentryService.captureException(new Error(`Error: Page not found. URL Segment: '${path}'`));
    }
}
