import { Component, Input } from '@angular/core';
import { ITableCellItem } from './types/tableCellItem';

@Component({
    selector: 'app-table-cell',
    styleUrls: ['./table-cell.component.scss'],
    template: `
        <div *ngIf="item">
            <span *ngIf="item.primary">{{item.primary.value | number: '1.0'}} {{item.primary.unit}}</span>
            <span *ngIf="!item.primary">-</span>
            <br />
            <span class="text small" *ngIf="item.secondary">{{item.secondary.value | number: '1.0'}} {{item.secondary.unit}}</span> 
            <span class="text small" *ngIf="!item.secondary">-</span> 
        <div>
    `
})
export class TableCellComponent {
    @Input() item: ITableCellItem;
}