<div class="b-dropdown-container" *ngIf="baseMarkerItem.showMarkers">
    <div>
        <button class="ui right floated mini black button" (click)="clickClearMarkersVisibility()">
            Nulstil valgte
        </button>
    </div>
    <div class="b-dropdown-header">
        <p><strong>Vælg {{baseMarkerItem.headlineLowered}}</strong></p>
    </div>
    <div class="b-dropdown-body">
        <select id="{{elementId}}" class="ui fluid search dropdown" multiple="">
        </select>
    </div>
</div>