import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var TopRightContentBoxDesktopService = /** @class */ (function () {
    function TopRightContentBoxDesktopService() {
        this._onBoxHeightChanged = new Subject();
        this.onBoxHeightChanged = this._onBoxHeightChanged.asObservable();
    }
    TopRightContentBoxDesktopService.prototype.emitOnBoxHeightChanged = function (eventData) {
        this._onBoxHeightChanged.next(eventData);
    };
    TopRightContentBoxDesktopService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TopRightContentBoxDesktopService_Factory() { return new TopRightContentBoxDesktopService(); }, token: TopRightContentBoxDesktopService, providedIn: "root" });
    return TopRightContentBoxDesktopService;
}());
export { TopRightContentBoxDesktopService };
