<div class="approot-content-wrapper">
    <!-- Menu / header -->
    <div class="appmenu">
        <app-menu></app-menu>
    </div>

    <!-- Main -->
    <div class="appcontent">
        <div class="ui vertical stripe segment">
            <div class="ui middle aligned stackable grid container">
                <div class="row">
                    <div class="sixteen wide column">
                        <h1>Roadmap</h1>
                    </div>
                </div>

                <div class="row">
                    <div class="sixteen wide column">
                        <p>
                            Vi arbejder hele tiden på at forbedre ditomraade.dk, og i de kommende måneder planlægger vi at tilføje følgende data på <a [routerLink]="['/kort']">kortsiden</a>:
                        </p>
                        <ul>
                            <li>
                                Boliger til salg
                            </li>
                            <li>
                                Trafikstøj
                            </li>
                            <li>
                                Radonrisiko
                            </li>
                            <li>
                                Oversvømmelserisiko (skybrud, stromflod, etc.)
                            </li>
                            <li>
                                Lejeboliger
                            </li>
                            <li>
                                Lokalplaner
                            </li>
                            <li>
                                Trakfikinformation
                            </li>
                            <li>
                                Klimapåvirkning i dit nærområde
                            </li>
                        </ul>
                        <p>
                            Derudover har vi følgende kommende forbedringer på <a [routerLink]="['/kort']">kortsiden</a>:
                        </p>
                        <ul>
                            <li>
                                Heatmap
                            </li>
                            <li>
                                Forbedret generel performance
                            </li>
                            <li>
                                Forbedret datakvalitet på interessepunkter
                            </li>
                        </ul>
                        <p>
                            og følgende kommende forbedringer på <a [routerLink]="['/kommune']">kommunesiden</a>:
                        </p>
                        <ul>
                            <li>
                                Sammenligning af kommuner på tværs af alle parametre
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Footer -->
    <div class="appfooter">
        <app-footer></app-footer>
    </div>
</div>