import { Municipality } from './types/municipality';
import { DataAccessService } from '../shared/services/dataaccess.service';
import { Observable, forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/dataaccess.service";
var MunicipalityService = /** @class */ (function () {
    function MunicipalityService(_dataAccessService) {
        this._dataAccessService = _dataAccessService;
        this._municipalities = [];
        this._data = {};
    }
    MunicipalityService.prototype.getMunicipalities = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (_this._municipalities.length == 0) {
                _this._dataAccessService
                    .getMapGetMunicipalityList()
                    .subscribe(function (x) {
                    for (var _i = 0, _a = x.map.municipalityList.municipalities; _i < _a.length; _i++) {
                        var item = _a[_i];
                        var municipality = new Municipality();
                        municipality.init(item);
                        _this._municipalities.push(municipality);
                    }
                    observer.next(_this._municipalities);
                    observer.complete();
                });
            }
            else {
                observer.next(_this._municipalities);
                observer.complete();
            }
        });
    };
    // METHODS - GET DATA
    // ===========================================================================================================
    MunicipalityService.prototype.getAllData = function (p) {
        var _this = this;
        return new Observable(function (observer) {
            if (false == (p.municipalityId in _this._data)) {
                // Data binding
                var observableCommon = _this.getCommonData();
                var observableOverviewSummary = _this.getOverviewSummaryData(p.municipalityId);
                var observableSafety = _this.getSafetyData(p.municipalityId, p.violationType);
                var observablePopulation = _this.getPopulationData(p.municipalityId, p.civilStatusType, p.familyCompositionType, p.originType, p.educationType, p.ageType, p.growthType, p.immigrationType);
                var observableEconomy = _this.getEconomyData(p.municipalityId, p.economyPropertyCategoryId, p.incomeType, p.taxType);
                var observableEducation = _this.getEducationData(p.municipalityId);
                var observableFreetime = _this.getFreetimeData(p.municipalityId, p.expenseType);
                var observableResidential = _this.getResidentialData(p.municipalityId, p.housingType, p.propertyType, p.yearType, p.sizeType);
                var observableHealth = _this.getHealthData(p.municipalityId);
                var observableDaycare = _this.getDaycareData(p.municipalityId, p.availabilityType, p.fareType);
                forkJoin(observableCommon, observableOverviewSummary, observableSafety, observablePopulation, observableEconomy, observableEducation, observableFreetime, observableResidential, observableHealth, observableDaycare)
                    .subscribe(function (results) {
                    var data = {
                        common: results[0],
                        summary: results[1],
                        safety: results[2],
                        population: results[3],
                        economy: results[4],
                        education: results[5],
                        freetime: results[6],
                        residential: results[7],
                        health: results[8],
                        daycare: results[9]
                    };
                    _this._data[p.municipalityId] = data;
                    observer.next(data);
                    observer.complete();
                });
            }
            else {
                var data = _this._data[p.municipalityId];
                observer.next(data);
                observer.complete();
            }
        });
    };
    MunicipalityService.prototype.getPopulationOriginData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationOriginData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.origin);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationEducationData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationEducationData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.education);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationFamilyCompositionData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationFamilyCompositionData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.familyComposition);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationCivilStatusData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationCivilStatusData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.civilStatus);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationGrowthData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationGrowthData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.growth);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationImmigrationData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationImmigrationData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.immigration);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationAgeData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationAgeData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.population.age);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getSafetyCrimeReportingData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getSafetyCrimeReportingData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.safety.crimeReporting);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getFreetimeExpensesData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getFreetimeExpensesData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.freetime.expenses);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getEconomyIncomeData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getEconomyIncomeData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.economy.income);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getEconomyTaxData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getEconomyTaxData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.economy.tax);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialHousingData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialHousingData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.residential.housings);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialPriceData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialPriceData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.residential.price);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialSalesPeriodData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialSalesPeriodData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.residential.salesPeriod);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialConstructionYearData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialConstructionYearData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.residential.constructionYear);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialSizeData = function (municipalityId, typeId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialSizeData(municipalityId, typeId)
                .subscribe(function (respData) {
                observer.next(respData.residential.size);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getDaycareAvailabilityData = function (municipalityId, availabilityType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getDaycareAvailabilityData(municipalityId, availabilityType)
                .subscribe(function (respData) {
                observer.next(respData.daycare.availability);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getDaycareFaresData = function (municipalityId, fareType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getDaycareFaresData(municipalityId, fareType)
                .subscribe(function (respData) {
                observer.next(respData.daycare.fares);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getCommonData = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getCommonData()
                .subscribe(function (respData) {
                observer.next(respData.settings.frontend);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getOverviewSummaryData = function (municipalityId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getOverviewSummaryData(municipalityId)
                .subscribe(function (respData) {
                observer.next(respData.summary);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getSafetyData = function (municipalityId, violationType) {
        var _this = this;
        if (violationType === void 0) { violationType = null; }
        return new Observable(function (observer) {
            _this._dataAccessService
                .getSafetyData(municipalityId, violationType)
                .subscribe(function (respData) {
                observer.next(respData.safety);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getPopulationData = function (municipalityId, civilStatusType, familyCompositionType, originType, educationType, ageType, growthType, immigrationType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getPopulationData(municipalityId, civilStatusType, familyCompositionType, originType, educationType, ageType, growthType, immigrationType)
                .subscribe(function (respData) {
                observer.next(respData.population);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getEconomyData = function (municipalityId, propertyCategoryId, incomeType, taxType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getEconomyData(municipalityId, propertyCategoryId, incomeType, taxType)
                .subscribe(function (respData) {
                observer.next(respData.economy);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getEducationData = function (municipalityId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getEducationData(municipalityId)
                .subscribe(function (respData) {
                observer.next(respData.education);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getFreetimeData = function (municipalityId, expenseType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getFreetimeData(municipalityId, expenseType)
                .subscribe(function (respData) {
                observer.next(respData.freetime);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getResidentialData = function (municipalityId, housingType, propertyType, yearType, sizeType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getResidentialData(municipalityId, housingType, propertyType, yearType, sizeType)
                .subscribe(function (respData) {
                observer.next(respData.residential);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getHealthData = function (municipalityId) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getHealthData(municipalityId)
                .subscribe(function (respData) {
                observer.next(respData.health);
                observer.complete();
            });
        });
    };
    MunicipalityService.prototype.getDaycareData = function (municipalityId, availabilityType, fareType) {
        var _this = this;
        return new Observable(function (observer) {
            _this._dataAccessService
                .getDaycareData(municipalityId, availabilityType, fareType)
                .subscribe(function (respData) {
                observer.next(respData.daycare);
                observer.complete();
            });
        });
    };
    MunicipalityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MunicipalityService_Factory() { return new MunicipalityService(i0.ɵɵinject(i1.DataAccessService)); }, token: MunicipalityService, providedIn: "root" });
    return MunicipalityService;
}());
export { MunicipalityService };
