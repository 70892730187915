import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
    selector: 'app-text-with-tooltip',
    templateUrl: './text-with-tooltip.component.html',
    styleUrls: ['./text-with-tooltip.component.scss']
})
export class TextWithTooltipComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() text: string;
    @Input() tooltip: string;

    elementId: string;
    elementIdJq: string;

    constructor(private _utilityService: UtilityService) {
        this.elementId = this._utilityService.getRandomString();
        this.elementIdJq = '#' + this.elementId;
    }

    ngOnInit() {
    }

    ngAfterViewInit(): void {
        let self = this;
        $(this.elementIdJq)
            .popup({
                content: self.tooltip,
                position: 'left center',
                transition: 'scale',
                variation : 'small inverted',
                delay: {
                    show: 0,
                    hide: 0
                }
            });
    }

    ngOnDestroy(): void {
    }
}
