import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { AreaLevel } from '../types/areaLevel.enum';
// import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DataAccessService implements IDataAccessService {
    constructor(private _http: HttpClient) {
    }

    getDawaAddressInfoById(id: string, srid: number = 4326): Observable<any> {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)

        return this._http
            .get(`https://dawa.aws.dk/adresser/${id}?srid=${srid}`)
    }

    getDawaAddressInfoByAddress(zip: string, roadName: string, houseNumber: string, floor: string, door: string, srid: number = 4326): Observable<any> {
        // Coordinates can be fetched in different formats by using "srid" parameter:
        // 4326: WGS 84 (http://epsg.io/4326)
        // 25832: ETRS89 / UTM zone 32N (http://epsg.io/25832)
        // if (srid == null)
        //     srid = 4326;

        return this._http
            .get(`https://dawa.aws.dk/adresser?postnr=${zip}&vejnavn=${roadName}&husnr=${houseNumber}&etage=${floor}&dør=${door}&srid=${srid}`); // &struktur=mini
    }

    getMapAreaIds(): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapAreaIds`);
    }

    getMapAreaGeometries(areaLevel: AreaLevel, regionId: number = null, municipalityId: number = null): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapAreaGeometries&areaLevel=${areaLevel}&regionId=${regionId}&municipalityId=${municipalityId}`);
    }

    getMapGetMunicipalityList(
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapMunicipalityList`);
    }


    getMapFactsheetMunicipality(
        municipalityId: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapFactsheetMunicipality&municipalityId=${municipalityId}`);
    }

    getMapFactsheetParish(
        parishId: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapFactsheetParish&parishId=${parishId}`);
    }






    getMapData(
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapData`);
    }
    getMapFacilityTypes(
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapFacilityTypes`);
    }
    getNurseryMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapNursery&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getKindergaardenMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapKindergaarden&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getPrimarySchoolMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerPrimarySchoolType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapPrimarySchool&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}&institutionTypeId=${markerPrimarySchoolType}`);
    }
    getHighSchoolMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerHighSchoolType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapHighSchool&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}&institutionTypeId=${markerHighSchoolType}`);
    }
    getPrivateDaycareMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapPrivateDaycare&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getPublicTransportationMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapPublicTransportation&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getSupermarketMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapSupermarkets&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getNoiseMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return new Observable((observer) => {
            let data = {
                map: {
                    noise: {
                        items: []
                    }
                }
            }
            observer.next(data);
            observer.complete();
        })
    }
    getDoctorMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapDoctors&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }



    getRealEstateMapMarkers(
        longitude: number,
        latitude: number,
        bbox: [[number, number], [number, number]],
        realEstateType: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/realestate/markers?longitude=${longitude}&latitude=${latitude}&swLongitude=${bbox[0][0]}&swLatitude=${bbox[0][1]}&neLongitude=${bbox[1][0]}&neLatitude=${bbox[1][1]}&realEstateItemTypeId=${realEstateType}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }
    getRealEstateMapDetails(
        realEstateId: number
    ): Observable<any> {
        return this._http
            .get(`/api/realestate/details?realEstateId=${realEstateId}`);
    }



    getResidentsForRentMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return new Observable((observer) => {
            let data = {
                map: {
                    residentsForRent: {
                        items: []
                    }
                }
            }
            observer.next(data);
            observer.complete();
        })
    }
    getFacilityMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerFacilityType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapFacility&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}&facilityTypeId=${markerFacilityType}`);
    }
    getSportsUnionMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapSportsUnions&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
        // return new Observable((observer) => {
        //     let data = {
        //         map: {
        //             sportsUnion: {
        //                 items: []
        //             }
        //         }
        //     }
        //     observer.next(data);
        //     observer.complete();
        // })
    }
    getDaycareMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        daycareInstitutionType: number,
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapDaycare&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}&daycareInstitutionTypeId=${daycareInstitutionType}`);
    }
    getSchoolDistrictData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerSchoolDistrictType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapSchoolDistricts&longitude=${longitude}&latitude=${latitude}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}&levelId=${markerSchoolDistrictType}`);
    }
    getTraficCountData(
        longitude: number,
        latitude: number,
        bbox: [[number, number], [number, number]],
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetMapTraficCount&longitude=${longitude}&latitude=${latitude}&swLongitude=${bbox[0][0]}&swLatitude=${bbox[0][1]}&neLongitude=${bbox[1][0]}&neLatitude=${bbox[1][1]}&municipalityId=${municipalityId}&markerSkipCount=${markerSkipCount}&markerTakeCount=${markerTakeCount}`);
    }





    


    getCommonData(): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetCommonData`);
    }

    getOverviewSummaryData(
        municipalityId: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetSummary&municipalityId=${municipalityId}`);
    }



    getSafetyData(
        municipalityId: number,
        violationType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetSafety&municipalityId=${municipalityId}&violationType=${violationType}`);
    }

    getSafetyCrimeReportingData(
        municipalityId: number,
        violationType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetSafetyCrimeReporting&municipalityId=${municipalityId}&violationType=${violationType}`);
    }



    getPopulationData(
        municipalityId: number,
        civilStatusType: string,
        familyCompositionType: string,
        originType: number,
        educationType: string,
        ageType: number,
        growthType: number,
        immigrationType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulation&municipalityId=${municipalityId}&civilStatusType=${civilStatusType}&familyCompositionType=${familyCompositionType}&originType=${originType}&educationType=${educationType}&ageType=${ageType}&growthType=${growthType}&immigrationType=${immigrationType}`);
    }

    getPopulationOriginData(
        municipalityId: number,
        originType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationOrigin&municipalityId=${municipalityId}&originType=${originType}`);
    }

    getPopulationEducationData(
        municipalityId: number,
        educationType: string
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationEducation&municipalityId=${municipalityId}&educationType=${educationType}`);
    }

    getPopulationFamilyCompositionData(
        municipalityId: number,
        familyCompositionType: string
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationFamilyComposition&municipalityId=${municipalityId}&familyCompositionType=${familyCompositionType}`);
    }

    getPopulationCivilStatusData(
        municipalityId: number,
        civilStatusType: string
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationCivilStatus&municipalityId=${municipalityId}&civilStatusType=${civilStatusType}`);
    }

    getPopulationGrowthData(
        municipalityId: number,
        growthType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationGrowth&municipalityId=${municipalityId}&growthType=${growthType}`);
    }

    getPopulationImmigrationData(
        municipalityId: number,
        immigrationType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationImmigration&municipalityId=${municipalityId}&immigrationType=${immigrationType}`);
    }

    getPopulationAgeData(
        municipalityId: number,
        ageType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetPopulationAge&municipalityId=${municipalityId}&ageType=${ageType}`);
    }


    getEconomyData(
        municipalityId: number,
        propertyCategoryId: number,
        incomeType: number,
        taxType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetEconomy&municipalityId=${municipalityId}&propertyCategoryId=${propertyCategoryId}&incomeType=${incomeType}&taxType=${taxType}`);
    }

    getEconomyIncomeData(
        municipalityId: number,
        incomeType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetEconomyIncome&municipalityId=${municipalityId}&incomeType=${incomeType}`);
    }
    getEconomyTaxData(
        municipalityId: number,
        taxType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetEconomyTax&municipalityId=${municipalityId}&taxType=${taxType}`);
    }



    getEducationData(
        municipalityId: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetEducation&municipalityId=${municipalityId}`);
    }



    getFreetimeData(
        municipalityId: number,
        expenseType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetFreetime&municipalityId=${municipalityId}&expenseType=${expenseType}`);
    }
    getFreetimeExpensesData(
        municipalityId: number,
        expenseType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetFreetimeExpenses&municipalityId=${municipalityId}&expenseType=${expenseType}`);
    }



    getResidentialData(
        municipalityId: number,
        housingType: number,
        propertyType: number,
        yearType: number,
        sizeType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidential&municipalityId=${municipalityId}&housingType=${housingType}&propertyType=${propertyType}&yearType=${yearType}&sizeType=${sizeType}`);
    }
    getResidentialHousingData(
        municipalityId: number,
        housingType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidentialHousings&municipalityId=${municipalityId}&housingType=${housingType}`);
    }
    getResidentialPriceData(
        municipalityId: number,
        propertyType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidentialPrice&municipalityId=${municipalityId}&propertyType=${propertyType}`);
    }
    getResidentialSalesPeriodData(
        municipalityId: number,
        propertyType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidentialSalesPeriod&municipalityId=${municipalityId}&propertyType=${propertyType}`);
    }
    getResidentialConstructionYearData(
        municipalityId: number,
        yearType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidentialConstructionYear&municipalityId=${municipalityId}&yearType=${yearType}`);
    }
    getResidentialSizeData(
        municipalityId: number,
        sizeType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetResidentialSize&municipalityId=${municipalityId}&sizeType=${sizeType}`);
    }





    getHealthData(
        municipalityId: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetHealth&municipalityId=${municipalityId}`);
    }




    getDaycareData(
        municipalityId: number,
        availabilityType: number,
        fareType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetDaycare&municipalityId=${municipalityId}&availabilityType=${availabilityType}&fareType=${fareType}`);
    }
    getDaycareAvailabilityData(
        municipalityId: number,
        availabilityType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetDaycareAvailability&municipalityId=${municipalityId}&availabilityType=${availabilityType}`);
    }
    getDaycareFaresData(
        municipalityId: number,
        fareType: number
    ): Observable<any> {
        return this._http
            .get(`/api/areainformation/?requestAction=GetDaycareFares&municipalityId=${municipalityId}&fareType=${fareType}`);
    }






    postFeedbackData(
        routeData: string[],
        rating: number,
        comment: string,
    ): Observable<any> {
        let data = {
            routeData: routeData,
            rating: rating,
            comment: comment,
        };

        return this._http
            .post(`/api/feedback`, data);
    }
}



export interface IDataAccessService {
    getDawaAddressInfoById(id: string, srid?: number): Observable<any>;
    getDawaAddressInfoByAddress(zip: string, roadName: string, houseNumber: string, floor: string, door: string, srid?: number): Observable<any>;
    getMapAreaIds(): Observable<any>;
    getMapAreaGeometries(areaLevel: AreaLevel, regionId?: number, municipalityId?: number): Observable<any>;
    getMapGetMunicipalityList(): Observable<any>;
    getMapFactsheetMunicipality(
        municipalityId: number
    ): Observable<any>;
    getMapFactsheetParish(
        parishId: number
    ): Observable<any>;
    getMapData(): Observable<any>;
    getNurseryMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getKindergaardenMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getPrimarySchoolMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerPrimarySchoolType: number
    ): Observable<any>;
    getHighSchoolMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerHighSchoolType: number
    ): Observable<any>;
    getPrivateDaycareMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getPublicTransportationMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getSupermarketMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getNoiseMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getDoctorMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getRealEstateMapMarkers(
        longitude: number,
        latitude: number,
        bbox: [[number, number], [number, number]],
        realEstateType: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getRealEstateMapDetails(
        realEstateId: number
    ): Observable<any>;
    getResidentsForRentMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getFacilityMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerFacilityType: number
    ): Observable<any>;
    getSportsUnionMarkerData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number
    ): Observable<any>;
    getSchoolDistrictData(
        longitude: number,
        latitude: number,
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerSchoolDistrictType: number
    ): Observable<any>;
    getTraficCountData(
        longitude: number,
        latitude: number,
        bbox: [[number, number], [number, number]],
        municipalityId: number,
        markerSkipCount: number,
        markerTakeCount: number,
        markerSchoolDistrictType: number
    ): Observable<any>;


    getCommonData(): Observable<any>;


    getOverviewSummaryData(
        municipalityId: number
    ): Observable<any>;


    getSafetyData(
        municipalityId: number,
        violationType: number,
    ): Observable<any>;
    getSafetyCrimeReportingData(
        municipalityId: number,
        violationType: number,
    ): Observable<any>;



    getPopulationData(
        municipalityId: number,
        civilStatusType: string,
        familyCompositionType: string,
        originType: number,
        educationType: string,
        ageType: number,
        growthType: number,
        immigrationType: number
    ): Observable<any>;
    getPopulationOriginData(
        municipalityId: number,
        originType: number
    ): Observable<any>;
    getPopulationEducationData(
        municipalityId: number,
        educationType: string
    ): Observable<any>;
    getPopulationFamilyCompositionData(
        municipalityId: number,
        familyCompositionType: string
    ): Observable<any>;
    getPopulationCivilStatusData(
        municipalityId: number,
        civilStatusType: string
    ): Observable<any>;
    getPopulationGrowthData(
        municipalityId: number,
        growthType: number
    ): Observable<any>;
    getPopulationImmigrationData(
        municipalityId: number,
        immigrationType: number
    ): Observable<any>;
    getPopulationAgeData(
        municipalityId: number,
        ageType: number
    ): Observable<any>;



    getEconomyData(
        municipalityId: number,
        propertyCategoryId: number,
        incomeType: number,
        taxType: number
    ): Observable<any>;
    getEconomyIncomeData(
        municipalityId: number,
        incomeType: number
    ): Observable<any>;
    getEconomyTaxData(
        municipalityId: number,
        taxType: number
    ): Observable<any>;



    getEducationData(
        municipalityId: number
    ): Observable<any>;



    getFreetimeData(
        municipalityId: number,
        expenseType: number
    ): Observable<any>;
    getFreetimeExpensesData(
        municipalityId: number,
        expenseType: number
    ): Observable<any>;



    getResidentialData(
        municipalityId: number,
        housingType: number,
        propertyType: number,
        yearType: number,
        sizeType: number
    ): Observable<any>;
    getResidentialHousingData(
        municipalityId: number,
        housingType: number
    ): Observable<any>;
    getResidentialPriceData(
        municipalityId: number,
        propertyType: number
    ): Observable<any>;
    getResidentialSalesPeriodData(
        municipalityId: number,
        propertyType: number
    ): Observable<any>;
    getResidentialConstructionYearData(
        municipalityId: number,
        yearType: number
    ): Observable<any>;
    getResidentialSizeData(
        municipalityId: number,
        sizeType: number
    ): Observable<any>;




    getHealthData(
        municipalityId: number
    ): Observable<any>;


    getDaycareData(
        municipalityId: number,
        availabilityType: number,
        fareType: number
    ): Observable<any>;


    postFeedbackData(
        routeData: string[],
        rating: number,
        comment: string,
    ): Observable<any>;

}