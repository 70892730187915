import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/browser";
import * as i0 from "@angular/core";
var SentryService = /** @class */ (function () {
    function SentryService() {
    }
    SentryService.prototype.captureException = function (exception) {
        if (environment.keys.sentry.isEnabled) {
            Sentry.captureException(exception);
        }
    };
    SentryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SentryService_Factory() { return new SentryService(); }, token: SentryService, providedIn: "root" });
    return SentryService;
}());
export { SentryService };
