<div id="b-zoom-controls-container" class="b-controls" [hidden]="areaLevel < 20 || areaLevel > 40">
    <button class="ui icon basic button" (click)="clickZoomIn()">
        <i class="plus icon"></i>
    </button>
    <br />
    <button class="ui icon basic button" (click)="clickZoomOut()">
        <i class="minus icon"></i>
    </button>
    <div class="b-progress-container">
        <div class="ui tiny progress">
            <div class="bar"></div>
            <!-- <div class="label" *ngIf="zoom">{{zoom.level.current}}</div> -->
        </div>
    </div>
</div>