import { LineGeometry } from './line';
import { PointGeometry } from './point';
import { PolygonGeometry } from './polygon';
import { MultiPolygonGeometry } from './multiPolygon';
import { IBounds } from './bounds';

export interface IFeature {
    type: string;
    id?: number;
    properties?: any;
    geometry: PointGeometry | LineGeometry | PolygonGeometry | MultiPolygonGeometry;
}

export class Feature implements IFeature {
    type: string = "Feature";
    id?: number;
    properties?: any;
    geometry: PointGeometry | LineGeometry | PolygonGeometry | MultiPolygonGeometry;

    constructor(geometry: PointGeometry | LineGeometry | PolygonGeometry | MultiPolygonGeometry, properties: any = {}, id: number = null) {
        this.geometry = geometry;
        this.properties = properties;
        this.id = id;
    }
}