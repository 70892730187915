import { IZoomLevel } from "./zoomLevel";

export interface IZoom {
    level: IZoomLevel;
    factor: number;

    zoomIn(): boolean;
    zoomOut(): boolean;
}

export class Zoom implements IZoom {
    level: IZoomLevel;
    factor: number;

    constructor(level: IZoomLevel, factor: number) {
        this.level = level;
        this.factor = factor;
    }

    zoomIn(): boolean {
        if (this.canZoomIn()) {
            this.level.current += 1;
            return true;
        }
        else {
            return false;
        }
    }

    zoomOut(): boolean {
        if (this.canZoomOut()) {
            this.level.current -= 1;
            return true;
        }
        else {
            return false;
        }
    }

    private canZoomIn(): boolean {
        return this.level.current < this.level.max;
    }

    private canZoomOut(): boolean {
        return this.level.current > this.level.min;
    }


    // level: {
    //     current: 0,
    //     min: -4,
    //     max: 2,
    // },
    // factor: 1.1,

}