/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roadmap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shared/components/menu/menu.component.ngfactory";
import * as i3 from "../shared/components/menu/menu.component";
import * as i4 from "../shared/services/layout.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../shared/components/footer/footer.component.ngfactory";
import * as i8 from "../shared/components/footer/footer.component";
import * as i9 from "./roadmap.component";
var styles_RoadmapComponent = [i0.styles];
var RenderType_RoadmapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RoadmapComponent, data: {} });
export { RenderType_RoadmapComponent as RenderType_RoadmapComponent };
export function View_RoadmapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 63, "div", [["class", "approot-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "appmenu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(3, 4308992, null, 0, i3.MenuComponent, [i4.LayoutService, i5.Router], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 56, "div", [["class", "appcontent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 55, "div", [["class", "ui vertical stripe segment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 54, "div", [["class", "ui middle aligned stackable grid container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "sixteen wide column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Roadmap"])), (_l()(), i1.ɵeld(11, 0, null, null, 49, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 48, "div", [["class", "sixteen wide column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Vi arbejder hele tiden p\u00E5 at forbedre ditomraade.dk, og i de kommende m\u00E5neder planl\u00E6gger vi at tilf\u00F8je f\u00F8lgende data p\u00E5 "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(17, 1), (_l()(), i1.ɵted(-1, null, ["kortsiden"])), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(20, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Boliger til salg "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Trafikst\u00F8j "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Radonrisiko "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Oversv\u00F8mmelserisiko (skybrud, stromflod, etc.) "])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lejeboliger "])), (_l()(), i1.ɵeld(31, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Lokalplaner "])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Trakfikinformation "])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Klimap\u00E5virkning i dit n\u00E6romr\u00E5de "])), (_l()(), i1.ɵeld(37, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Derudover har vi f\u00F8lgende kommende forbedringer p\u00E5 "])), (_l()(), i1.ɵeld(39, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(41, 1), (_l()(), i1.ɵted(-1, null, ["kortsiden"])), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(44, 0, null, null, 6, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Heatmap "])), (_l()(), i1.ɵeld(47, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Forbedret generel performance "])), (_l()(), i1.ɵeld(49, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Forbedret datakvalitet p\u00E5 interessepunkter "])), (_l()(), i1.ɵeld(51, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" og f\u00F8lgende kommende forbedringer p\u00E5 "])), (_l()(), i1.ɵeld(53, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 54).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(54, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(55, 1), (_l()(), i1.ɵted(-1, null, ["kommunesiden"])), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵeld(58, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(59, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sammenligning af kommuner p\u00E5 tv\u00E6rs af alle parametre "])), (_l()(), i1.ɵeld(61, 0, null, null, 2, "div", [["class", "appfooter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(62, 0, null, null, 1, "app-footer", [], null, null, null, i7.View_FooterComponent_0, i7.RenderType_FooterComponent)), i1.ɵdid(63, 114688, null, 0, i8.FooterComponent, [i4.LayoutService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); var currVal_2 = _ck(_v, 17, 0, "/kort"); _ck(_v, 16, 0, currVal_2); var currVal_5 = _ck(_v, 41, 0, "/kort"); _ck(_v, 40, 0, currVal_5); var currVal_8 = _ck(_v, 55, 0, "/kommune"); _ck(_v, 54, 0, currVal_8); _ck(_v, 63, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 16).target; var currVal_1 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 40).target; var currVal_4 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 54).target; var currVal_7 = i1.ɵnov(_v, 54).href; _ck(_v, 53, 0, currVal_6, currVal_7); }); }
export function View_RoadmapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-roadmap", [], null, null, null, View_RoadmapComponent_0, RenderType_RoadmapComponent)), i1.ɵdid(1, 114688, null, 0, i9.RoadmapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RoadmapComponentNgFactory = i1.ɵccf("app-roadmap", i9.RoadmapComponent, View_RoadmapComponent_Host_0, {}, {}, []);
export { RoadmapComponentNgFactory as RoadmapComponentNgFactory };
