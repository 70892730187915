<div *ngIf="item" class="b-container">

    <div class="ui grid">
        <div class="sixteen wide column">
            <h3 class="ui header">{{item.headline}}</h3>
        </div>
    </div>

    <div class="b-image-container">
        <img [src]="imageSrc" style="width: 60px;" />
    </div>

    <div class="b-present-container">
        <h1 class="ui header" [ngClass]="{'green': true == item.positive, 'red': false == item.positive}">
            <span *ngIf="item.present">{{item.present.value | number: '1.0'}} {{item.present.unit}}</span>
            <span *ngIf="!item.present">-</span>
        </h1>
    </div>

    <div class="ui grid">
        <div class="ten wide column">
            <h3>Relativt til land</h3>
            <h3 class="ui header" [ngClass]="{'green': true == item.positive, 'red': false == item.positive}">
                <span *ngIf="item.comparison">{{item.comparison.value | number: '1.0'}} {{item.comparison.unit}}</span>
                <span *ngIf="!item.comparison">-</span>
            </h3>
        </div>
        <div class="six wide column">
            <h3>Rang</h3>
            <h3 class="ui header" [ngClass]="{'green': true == item.positive, 'red': false == item.positive}">
                {{item.placement.rank}}/{{item.placement.rankOf}}
            </h3>
        </div>
    </div>
</div>