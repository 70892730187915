import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CategoriesService } from './categories.service';
import { MarkerItem } from './marker-item';
import { MarkerService } from './marker.service';

@Component({
    selector: '[app-categories-list-mobile]',
    templateUrl: './categories-list-mobile.component.html',
    styleUrls: ['./categories-list-mobile.component.scss']
})
export class CategoriesListMobileComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();

    markerItem: MarkerItem = null;
    isAnyMarkerVisible: boolean = false;

    isLoading: boolean = false;
    isLoaded: boolean = false;

    constructor(private _categoriesService: CategoriesService, private _markerService: MarkerService) {
    }

    ngOnInit(): void {
        this.isAnyMarkerVisible = this._markerService.isAnyMarkerVisible();

        this._categoriesService
            .onLoad
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (false == x.isLoading && x.isLoaded) {
                    this.markerItem = this._markerService.getVisibleMarker();
                }

                this.isLoading = x.isLoading;
                this.isLoaded = x.isLoaded;
            })


        this._markerService
            .onToggleMarkerVisibility
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                // Clearing any marker from another main category
                if (false == x.isAnySubMarkerVisible) {
                    this.markerItem = null;
                }

                this.isAnyMarkerVisible = x.isAnyVisible;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    // clickOpenCompare() {
    //     this._markerService.toggleComparisonVisibility(this.markerItem.markerType, this.markerItem.markerSubType);
    // }
}
