import { Component, Input } from '@angular/core';
import { MarkerService } from './marker.service';
import { MarkerItem } from './marker-item';

@Component({
    templateUrl: './popup-desktop.component.html',
    styleUrls: ['./popup-desktop.component.scss']
})
export class PopupDesktopComponent {
    @Input() markerItem: MarkerItem;
    @Input() item: any;

    constructor(private _markerService: MarkerService) { }

    clickClose() {
        this._markerService.toggleSelected(this.item.id, this.markerItem.markerTypeContainer)
    }

    clickToggleCompare() {
        this._markerService.toggleCompare(this.item.id, this.markerItem.markerTypeContainer)
    }

    clickToggleComparisonVisibility() {
        this._markerService.toggleComparisonVisibility(this.markerItem.markerTypeContainer)
    }
}
