import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import { RouteDataService } from './route-data.service';
import { IRouteData } from './route-data';
import { Subject } from 'rxjs';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { IAreaEventData } from './area-event-data';
import { takeUntil } from 'rxjs/operators';
import { MapService } from './map.service';
import { ICurrentAreaEventData } from './current-area-event-data';
import { MarkerService } from './marker.service';
import { IBaseAreaContainerItem } from './base-area-container-item';
import { MarkerItem } from './marker-item';
import { IAreaContainerGeometries } from './area-container-geometries';

declare var $: any;

@Component({
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();
    private _timer: any;
    private _mobileContainerLastHeight: number = 0;
    private _windowLastHeight: number = 0;

    settings: any = {
        isMapLoaded: false,
        isMapLoading: false,
        isMobileContentActive: false,
        isMobileContentEnabled: false,
        isRouteDataServiceInitialized: false,
        isMarkerSelected: false,
    }

    data: any = {
        current: {
            area: { core: { areaLevel: AreaLevel.None } } as IBaseAreaContainerItem,
            path: {
            },
            factsheet: null,
            selectedMarker: {
                markerItem: null,
                item: null
            }
        }
    }

    constructor(private _mapService: MapService, private _markerService: MarkerService, private _routeDataService: RouteDataService, private _activatedRoute: ActivatedRoute, private _router: Router) {
    }

    // private _i: number = 0;
    // get somePropYes(): any {
    //     console.log('somePropYes', this._i++);

    //     return null;
    // }


    ngOnInit(): void {
        this._routeDataService.onRouteChange
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: IRouteData) => {
                if (x.areaLevel < AreaLevel.Municipality) {
                    this.settings.isMobileContentActive = false;
                    this.settings.isMobileContentEnabled = false;
                }
                else {
                    this.settings.isMobileContentEnabled = true;
                }

                if (x.areaLevel < AreaLevel.Municipality) {
                    this._markerService.clearMarkersVisibility();
                }

                // Always remove selected marker if any
                this.resetSelectedMarker();
            });

        this._activatedRoute
            .url
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((urlSegments: UrlSegment[]) => {
                this._routeDataService.emitRouteChange(urlSegments);
            })

        this._markerService.onToggleSelect
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                if (x == null)
                    return;

                let item = x.markerItem.getSelectedItem();
                if (item != null) {
                    this.settings.isMarkerSelected = true;
                    this.data.current.selectedMarker.markerItem = x.markerItem;
                    this.data.current.selectedMarker.item = item;
                }
                else {
                    this.settings.isMarkerSelected = false;
                    this.data.current.selectedMarker.markerItem = null;
                    this.data.current.selectedMarker.item = null;
                }
            })

        this._mapService.onFitBoundsStart
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: ICurrentAreaEventData) => {
                this.mapboxFitBoundsStart(x);
            })

        this._mapService.onFitBoundsEnd
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: ICurrentAreaEventData) => {
                if (x != null) {
                    this.mapboxFitBoundsEnd(x);
                }
            })

        this.checkForMobileContainerHeightChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();

        clearTimeout(this._timer);
    }


    resetSelectedMarker() {
        if (this.settings.isMarkerSelected) {
            let itemId = this.data.current.selectedMarker.item.id;
            let markerItem = <MarkerItem>this.data.current.selectedMarker.markerItem;
            this._markerService.toggleSelected(itemId, markerItem.markerTypeContainer);
        }
    }

    mapboxMapLoaded(evt: ICurrentAreaEventData): void {
        this.data.current.area = evt.area;
        this.data.current.path = evt.path;
        this.settings.isMapLoaded = true;
    }

    mapboxLayerClick(evt: IAreaEventData): void {
        this.navigateToRoute(evt);
    }

    mapboxFitBoundsStart(evt: ICurrentAreaEventData): void {
        this.settings.isMapLoading = true;
        this.data.current.path = evt.path;
        this.data.current.area = evt.area;
    }

    mapboxFitBoundsEnd(evt: ICurrentAreaEventData): void {
        this.settings.isMapLoading = false;
    }

    breadcrumbDesktopClickArea(evt: IAreaEventData): void {
        this.navigateToRoute(evt);
    }

    breadcrumbDesktopResetArea(): void {
        this.resetBounds();
    }

    breadcrumbMobileClickArea(evt: IAreaEventData): void {
        this.navigateToRoute(evt);
    }

    toggleContentActive(): void {
        this.settings.isMobileContentActive = !this.settings.isMobileContentActive
    }

    // private loadFacilityTypes(areaLevel: AreaLevel): void {
    //     if (areaLevel == AreaLevel.Parish) {
    //         this._mapService
    //             .loadFacilityTypes()
    //             .subscribe(() => {
    //             });
    //     }
    // }

    private checkForMobileContainerHeightChanges() {
        let container = $("#b-mobile-container");
        let containerChanged = container && container.outerHeight() != undefined && container.outerHeight() != this._mobileContainerLastHeight;
        let windowChanged = window && $(window).outerHeight() != undefined && $(window).outerHeight() != this._windowLastHeight;
        if (containerChanged || windowChanged) {
            this._mobileContainerLastHeight = container.outerHeight();
            this._windowLastHeight = $(window).outerHeight();
            this._mapService.onMobileMenuHeightChanged.emit();
        }

        this._timer = setTimeout(() => {
            this.checkForMobileContainerHeightChanges();
        }, 100);
    }

    private resetBounds() {
        this._mapService.onResetBounds.next();
    }

    private navigateToRoute(x: IAreaEventData) {
        if (x.areaLevel == AreaLevel.Country) {
            this._router.navigate(['/kort']);
        }
        else if (x.areaLevel == AreaLevel.Region) {
            let regionParameter = this._mapService.getRegionAreaContainer(x.regionId).core.urlParameter;
            this._router.navigate(['/kort', regionParameter]);
        }
        else if (x.areaLevel == AreaLevel.Municipality) {
            let regionParameter = this._mapService.getRegionAreaContainer(x.regionId).core.urlParameter;
            let municipalityParameter = this._mapService.getMunicipalityAreaContainer(x.municipalityId).core.urlParameter;
            this._router.navigate(['/kort', regionParameter, municipalityParameter]);
        }
        else if (x.areaLevel == AreaLevel.Parish) {
            let regionParameter = this._mapService.getRegionAreaContainer(x.regionId).core.urlParameter;
            let municipalityParameter = this._mapService.getMunicipalityAreaContainer(x.municipalityId).core.urlParameter;
            let parishParameter = this._mapService.getParishAreaContainer(x.parishId).core.urlParameter;
            this._router.navigate(['/kort', regionParameter, municipalityParameter, parishParameter]);
        }
    }
}
