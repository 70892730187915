export enum MarkerType {
    None = 0,
    
    Home = 5,
    Nursery = 10,
    Daycare = 15,
    Kindergarden = 20,
    PrivateDaycare = 25,
    PrimarySchool = 30,
    HighSchool = 32,
    SchoolDistrict = 35,
    // Crime = 40,
    Facility = 50,
    SportsUnion = 55,
    PublicTransportation = 60,
    Supermarket = 65,
    Noise = 70,
    TraficCount = 75,
    Doctor = 80,
    RealEstate = 90,
    ResidentsForRent = 95,

    Municipality = 100,
    Parish = 110,
}