import * as Sentry from "@sentry/browser";
import { Injectable, ErrorHandler } from '@angular/core';
import { environment } from '../../../environments/environment';

if (environment.keys.sentry.isEnabled) {
    Sentry.init({
        dsn: `https://${environment.keys.sentry.key}@sentry.io/${environment.keys.sentry.appId}`
    });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() { }
    handleError(error: any) {
        if (environment.keys.sentry.isEnabled) {
            Sentry.captureException(error.originalError || error);
        }
        throw error;
    }
}
