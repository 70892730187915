export enum AreaLevel {
    None = 0,

    Country = 10,
    Region = 20,
    Municipality = 30,
    Parish = 40,

    // Coordinate = 50,

    // Details = 100
}