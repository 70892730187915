import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-privacy-terms',
    templateUrl: './privacy-terms.component.html',
    styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
