import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { TableCellComponent } from './table-cell.component';
import { SubSectionComponent } from './sub-section.component';
import { MunicipalityComponent } from './municipality.component';
import { SharedModule } from '../shared/shared.module';
import { OverviewNumberSegmentComponent } from './overview-segment-number.component';
import { OverviewChartSegmentComponent } from './overview-segment-chart.component';
import { LeftMenuComponent } from './left-menu.component';
import { OverviewHeadlineComponent } from './overview-headline.component';
import { ScrollService } from './scroll.service';
import { OverviewSegmentMobileComponent } from './overview-segment-mobile.component';
import { TopMenuComponent } from './top-menu.component';
import { AreaLabelComponent } from './area-label.component';
import { OverviewSummaryDesktopComponent } from './overview-summary-desktop.component';
import { OverviewSummaryMobileComponent } from './overview-summary-mobile.component';

@NgModule({
    declarations: [
        MunicipalityComponent,

        SubSectionComponent,        
        TableCellComponent,
        LeftMenuComponent,
        OverviewNumberSegmentComponent,
        OverviewChartSegmentComponent,
        OverviewHeadlineComponent,
        OverviewSegmentMobileComponent,
        TopMenuComponent,
        AreaLabelComponent,
        OverviewSummaryDesktopComponent,
        OverviewSummaryMobileComponent,
    ],
    imports: [
        SharedModule,

        ChartsModule
    ],
    providers : [
        DecimalPipe,
        ScrollService
    ]
})
export class MunicipalityModule { }
