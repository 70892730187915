import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MarkerService } from './marker.service';
import { MarkerItem } from './marker-item';
import { MapService } from './map.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MarkerTypeContainer } from './marker-type-container';

@Component({
    selector: '[app-categories-list-desktop]',
    templateUrl: './categories-list-desktop.component.html',
    styleUrls: ['./categories-list-desktop.component.scss']
})
export class CategoriesListDesktopComponent implements OnInit, OnDestroy {
    private _unsubscribe: Subject<void> = new Subject<void>();

    @Input() markerItem: MarkerItem;

    isLoading: boolean = false;
    isLoaded: boolean = true;

    constructor(private _markerService: MarkerService, private _mapService: MapService) {
    }

    ngOnInit(): void {
        this._mapService
            .onToggleLoadMarkers
            .pipe(takeUntil(this._unsubscribe))
            .subscribe((x: any) => {
                let markerTypeContainer = <MarkerTypeContainer>x.markerTypeContainer;
                if (markerTypeContainer.matches(this.markerItem.markerTypeContainer)) {
                    this.isLoading = x.isLoading;
                    this.isLoaded = x.isLoaded;
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribe.next();
        this._unsubscribe.complete();
    }

    clickOpenCompare() {
        this._markerService.toggleComparisonVisibility(this.markerItem.markerTypeContainer);
    }
}