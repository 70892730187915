import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SitemapComponent } from './sitemap.component';

@NgModule({
    declarations: [
        SitemapComponent
    ],
    imports: [
        SharedModule
    ]
})
export class SitemapModule { }
