<div class="ui inverted vertical footer segment" *ngIf="settings.isFooterEnabled">
    <div class="ui center aligned container">

        <div class="ui stackable inverted divided grid">
            <div class="sixteen wide column">
                <h4 class="ui inverted header">Ditomraade.dk</h4>
            </div>
        </div>

        <div class="ui horizontal inverted small divided link list">
            <a class="item" routerLink="/kontakt">Kontakt</a>
            <!-- <a class="item" routerLink="/sitemap">Sitemap</a> -->
            <a class="item" routerLink="/almindelige-vilkaar">Almindelige vilkår</a>
            <a class="item" routerLink="/cookiepolitik">Cookiepolitik</a>
            <a class="item" routerLink="/privatlivspolitik">Privatlivspolitik</a>
            <a class="item" routerLink="/datakilder">Datakilder</a>
            <a class="item" routerLink="/beta">Hvad er ditomraade.dk beta-version?</a>
        </div>
    </div>
</div>