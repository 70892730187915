/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-segment-mobile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./overview-segment-mobile.component";
import * as i4 from "./scroll.service";
var styles_OverviewSegmentMobileComponent = [i0.styles];
var RenderType_OverviewSegmentMobileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewSegmentMobileComponent, data: {} });
export { RenderType_OverviewSegmentMobileComponent as RenderType_OverviewSegmentMobileComponent };
function View_OverviewSegmentMobileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "b-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.description; _ck(_v, 1, 0, currVal_0); }); }
function View_OverviewSegmentMobileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.item.statistics.municipality.present.value, "1.0")); var currVal_1 = _co.item.statistics.municipality.present.unit; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_OverviewSegmentMobileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_OverviewSegmentMobileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "ui grid b-mobile"], ["style", "margin-top:0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "ten wide column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewSegmentMobileComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "six wide right aligned column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewSegmentMobileComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewSegmentMobileComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.description; _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.item.statistics && _co.item.statistics.municipality); _ck(_v, 8, 0, currVal_2); var currVal_3 = !(_co.item.statistics && _co.item.statistics.municipality); _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.headline; _ck(_v, 3, 0, currVal_0); }); }
export function View_OverviewSegmentMobileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewSegmentMobileComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OverviewSegmentMobileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-overview-segment-mobile", ""]], null, null, null, View_OverviewSegmentMobileComponent_0, RenderType_OverviewSegmentMobileComponent)), i1.ɵdid(1, 49152, null, 0, i3.OverviewSegmentMobileComponent, [i4.ScrollService], null, null)], null, null); }
var OverviewSegmentMobileComponentNgFactory = i1.ɵccf("[app-overview-segment-mobile]", i3.OverviewSegmentMobileComponent, View_OverviewSegmentMobileComponent_Host_0, { fragment: "fragment", item: "item" }, {}, []);
export { OverviewSegmentMobileComponentNgFactory as OverviewSegmentMobileComponentNgFactory };
