import * as tslib_1 from "tslib";
import { BaseArea } from './base-area';
import { AreaLevel } from '../shared/types/areaLevel.enum';
var Country = /** @class */ (function (_super) {
    tslib_1.__extends(Country, _super);
    function Country() {
        var _this = _super.call(this) || this;
        _this.areaLevel = AreaLevel.Country;
        _this.countryId = 208;
        return _this;
    }
    Country.prototype.init = function (obj) {
        this.name = obj.name;
        // this.countryId = obj.countryId;
    };
    Object.defineProperty(Country.prototype, "fullName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    return Country;
}(BaseArea));
export { Country };
