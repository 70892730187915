import { OnInit } from '@angular/core';
import { ScrollService } from './scroll.service';
var OverviewSummaryDesktopComponent = /** @class */ (function () {
    function OverviewSummaryDesktopComponent(_scrollService) {
        this._scrollService = _scrollService;
    }
    Object.defineProperty(OverviewSummaryDesktopComponent.prototype, "imageName", {
        set: function (val) {
            this.imageSrc = '/assets/municipality/' + val;
        },
        enumerable: true,
        configurable: true
    });
    OverviewSummaryDesktopComponent.prototype.ngOnInit = function () {
    };
    OverviewSummaryDesktopComponent.prototype.clickMenuItem = function (fragment) {
        this._scrollService.scrollToId(fragment);
    };
    return OverviewSummaryDesktopComponent;
}());
export { OverviewSummaryDesktopComponent };
