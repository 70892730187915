import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { PageNotFoundComponent } from './pagenotfound/pagenotfound.component';
import { MunicipalityComponent } from './municipality/municipality.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { MenuPosition } from './shared/types/menuPosition.enum';
// import { SitemapComponent } from './sitemap/sitemap.component';
import { CookiesComponent } from './cookies/cookies.component';
import { DataSourcesComponent } from './data-sources/data-sources.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { BetaComponent } from './beta/beta.component';
import { RoadmapComponent } from './roadmap/roadmap.component';

const routes: Routes = [
    { path: '', component: HomeComponent, data: { layoutSettings: { position: MenuPosition.Responsive, isOverlayed: true } } },
    { path: 'kommune', component: MunicipalityComponent, data: { layoutSettings: { position: MenuPosition.Fixed } } },
    { path: 'kommune/:municipality', component: MunicipalityComponent, data: { layoutSettings: { position: MenuPosition.Fixed } } },
    { path: 'kommune/:municipality/:parish', component: MunicipalityComponent, data: { layoutSettings: { position: MenuPosition.Fixed } } },
    { path: 'kommune/:municipality/:parish/:zip/:road', component: MunicipalityComponent, data: { layoutSettings: { position: MenuPosition.Fixed } } },
    { path: 'kommune/:municipality/:parish/:zip/:road/:level', component: MunicipalityComponent, data: { layoutSettings: { position: MenuPosition.Fixed } } },
    { path: 'kort', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'kort/:region', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'kort/:region/:municipality', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'kort/:region/:municipality/:parish', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'kort/:region/:municipality/:parish/:zip/:road', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'kort/:region/:municipality/:parish/:zip/:road/:level', component: MapComponent, data: { layoutSettings: { position: MenuPosition.Fixed, isFooterEnabled: false, isSearchBoxEnabled : true }, reuse: true } },
    { path: 'om-ditomraade', component: AboutComponent },
    { path: 'kontakt', component: ContactComponent },
    { path: 'almindelige-vilkaar', component: TermsAndConditionsComponent },
    // { path: 'sitemap', component: SitemapComponent },
    { path: 'cookiepolitik', component: CookiesComponent },
    { path: 'datakilder', component: DataSourcesComponent },
    { path: 'privatlivspolitik', component: PrivacyTermsComponent },
    { path: 'beta', component: BetaComponent },
    { path: 'roadmap', component: RoadmapComponent },

    // Handle all other routes
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
