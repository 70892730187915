import { IAreaContainerGeometries, AreaContainerGeometries } from './area-container-geometries';
import { IParish, Parish } from './parish';
import { MultiPolygonGeometry } from '../shared/types/geometry/multiPolygon';
import { Feature } from '../shared/types/geometry/feature';
import { PointGeometry } from '../shared/types/geometry/point';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { Bounds } from '../shared/types/geometry/bounds';
import { IBaseAreaContainerItem } from './base-area-container-item';

export class AreaContainerParishItem implements IAreaContainerParishItem, IBaseAreaContainerItem {
    private _isGeometryAdded: boolean = false;
    private _isFactsheetAdded: boolean = false;

    core: IParish;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    constructor() {
        this.geometry = new AreaContainerGeometries();
    }

    addCore(parish: any): void {
        let core = new Parish();
        core.init(parish);
        this.core = core;
    }

    addGeometry(parish: any): void {
        // Area
        let geometry = new MultiPolygonGeometry(parish.geometry.coordinates);
        let properties = { parishId: parish.parishId, municipalityId: parish.municipalityId, regionId: parish.regionId, areaLevel: AreaLevel.Parish /*, bbox: new Bounds(parish.bounds).bbox */ };
        let feature = new Feature(geometry, properties, parish.parishId);
        this.geometry.areaFeature = feature;

        // Visual center
        let g = new PointGeometry([parish.visualCenter.lng, parish.visualCenter.lat]);
        let p = { parishId: parish.parishId, municipalityId: parish.municipalityId, regionId: parish.regionId, name: parish.name };
        let f = new Feature(g, p);
        this.geometry.centerFeature = f;

        // Visual center
        this.geometry.visualCenter = parish.visualCenter;

        // Bounds
        let b = new Bounds(parish.bounds);
        this.geometry.bounds = b;

        // Change state
        this._isGeometryAdded = true;
    }

    addFactsheet(factsheet: any): void {
        this.factsheet = factsheet;

        // Change state
        this._isFactsheetAdded = true;
    }


    hasGeometry(): boolean {
        return this._isGeometryAdded;
    }

    hasFactsheet(): boolean {
        return this._isFactsheetAdded;
    }
}

export interface IAreaContainerParishItem {
    core: IParish;
    geometry: IAreaContainerGeometries;
    factsheet: any;

    addCore(parish: any): void;
    addGeometry(parish: any): void;
    addFactsheet(factsheet: any): void;

    hasGeometry(): boolean;
    hasFactsheet(): boolean;
}