/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./categories-radio-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./categories-radio-button.component";
import * as i4 from "../shared/services/utility.service";
import * as i5 from "./marker.service";
var styles_CategoriesRadioButtonComponent = [i0.styles];
var RenderType_CategoriesRadioButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoriesRadioButtonComponent, data: {} });
export { RenderType_CategoriesRadioButtonComponent as RenderType_CategoriesRadioButtonComponent };
function View_CategoriesRadioButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "input", [["class", "hidden"], ["tabindex", "0"], ["type", "radio"]], [[8, "name", 0], [1, "data-item-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "ui radio checkbox ", _co.elementId, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.elementId, ""); var currVal_2 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_3); }); }
function View_CategoriesRadioButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "b-radio-button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "b-radio-button-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["V\u00E6lg ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "b-radio-button-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "b-radio-button-body-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "ui divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "ui form"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesRadioButtonComponent_2)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.baseMarkerItem.subCategories; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.baseMarkerItem.headlineLowered; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.baseMarkerItem.dropdownHeader; _ck(_v, 8, 0, currVal_1); }); }
export function View_CategoriesRadioButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoriesRadioButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.baseMarkerItem.showMarkers; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CategoriesRadioButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-categories-radio-button", ""]], null, null, null, View_CategoriesRadioButtonComponent_0, RenderType_CategoriesRadioButtonComponent)), i1.ɵdid(1, 245760, null, 0, i3.CategoriesRadioButtonComponent, [i4.UtilityService, i5.MarkerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoriesRadioButtonComponentNgFactory = i1.ɵccf("[app-categories-radio-button]", i3.CategoriesRadioButtonComponent, View_CategoriesRadioButtonComponent_Host_0, { baseMarkerItem: "baseMarkerItem" }, {}, []);
export { CategoriesRadioButtonComponentNgFactory as CategoriesRadioButtonComponentNgFactory };
