import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AreaLevel } from '../shared/types/areaLevel.enum';
import { IAreaEventData } from './area-event-data';
import { MapService } from './map.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-breadcrumb-desktop',
    templateUrl: './breadcrumb-desktop.component.html',
    styleUrls: ['./breadcrumb-desktop.component.scss']
})
export class BreadcrumbDesktopComponent implements OnInit {
    @Input() areaLevel: any;
    @Input() path: any;

    @Output() onClickArea = new EventEmitter<IAreaEventData>();

    private _onResetArea = new Subject<void>();
    @Output() onResetArea = this._onResetArea.asObservable();

    constructor(private _mapService: MapService) { }

    ngOnInit() {
        // let hej = this._mapService.getAreasByParentAreaId(AreaLevel.Parish, 825);
    }

    clickArea(areaLevel: AreaLevel, regionId? : number, municipalityId? : number, parishId? : number) {
        this.onClickArea.emit({
            areaLevel: areaLevel,
            regionId : regionId,
            municipalityId : municipalityId,
            parishId : parishId,
        });
    }

    resetArea() {
        this._onResetArea.next();
    }
}
