import { Component, OnInit } from '@angular/core';
import { IMunicipality } from './types/municipality';
import { ActivatedRoute } from '@angular/router';
import { MunicipalityService } from './municipality.service';
import { RequestDetailsParameters } from './types/request-details-parameters';
import { MunicipalityDataContainer } from './types/municipality-data-container';
import { MunicipalitySettingsContainer } from './types/municipality-settings-container';

@Component({
    templateUrl: './municipality.component.html',
    styleUrls: ['./municipality.component.scss']
})
export class MunicipalityComponent implements OnInit {
    settings: any = null;
    page: any = {
        isLoading: false,
        isLoaded: false
    }

    showMunicipalityList: boolean = true;

    municipalities: IMunicipality[] = [];
    municipality: IMunicipality = null;
    data: MunicipalityDataContainer = null;

    constructor(private _municipalityService: MunicipalityService, private _municipalitySettingsContainer: MunicipalitySettingsContainer, private _activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.settings = this._municipalitySettingsContainer.settings;

        this._municipalityService
            .getMunicipalities()
            .subscribe(municipalities => {
                this.municipalities = municipalities;

                // Subscribing to URL changes
                this._activatedRoute
                    .url
                    .subscribe((y: any) => {
                        if (y.length > 1) {
                            this.municipality = this.getMunicipalityFromUrlParameter(y[1]);
                            this.showMunicipalityList = false;

                            this.bindAllData(this.municipality.municipalityId);
                        }
                        else {
                            this.showMunicipalityList = true;
                        }
                    });
            });
    }

    private getMunicipalityFromUrlParameter(urlParameter: string): IMunicipality {
        for (const item of this.municipalities) {
            if (item.isUrlParameterMatch(urlParameter)) {
                return item;
            }
        }
    }

    private bindAllData(municipalityId: number): void {
        this.page.isLoading = true;
        this.page.isLoaded = false;

        let requestParameters: RequestDetailsParameters =
        {
            municipalityId: municipalityId,

            // ECONOMY - Default values
            economyPropertyCategoryId: 10,
            incomeType: 0,
            taxType: 3,

            // SAFETY - Default values
            violationType: 1320,

            // POPULATION - Default values
            civilStatusType: 'U',
            familyCompositionType: 'PARF',
            originType: 1,
            educationType: "G40",
            ageType: 0,
            growthType: 3,
            immigrationType: 3,

            // FREETIME - Default values
            expenseType: 1,

            // RESIDENTIAL - Default values
            housingType: 1,
            propertyType: 1,
            yearType: 0,
            sizeType: 0,

            // HEALTH - Default values
            // ...

            // DAYCARE - Default values
            availabilityType: 1,
            fareType: 1,
        };

        this._municipalityService
            .getAllData(requestParameters)
            .subscribe((data) => {
                this.data = data;

                this.page.isLoading = false;
                this.page.isLoaded = true;
            })
    }

    public clickOriginType(originType: any) {
        let originTypeId: number = originType.id;
        this.data.population.origin.originType = originType;

        this._municipalityService
            .getPopulationOriginData(this.municipality.municipalityId, originTypeId)
            .subscribe((respData: any) => {
                this.data.population.origin.chart = respData.chart;
            });
    }

    public clickEducationType(type: any) {
        let typeId: string = type.id;
        this.data.population.education.educationType = type;

        this._municipalityService
            .getPopulationEducationData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.education.chart = respData.chart;
            });
    }

    public clickFamilyCompositionType(type: any) {
        let typeId: string = type.id;
        this.data.population.familyComposition.familyCompositionType = type;

        this._municipalityService
            .getPopulationFamilyCompositionData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.familyComposition.chart = respData.chart;
            });
    }

    public clickCivilStatusType(type: any) {
        let typeId: string = type.id;
        this.data.population.civilStatus.civilStatusType = type;

        this._municipalityService
            .getPopulationCivilStatusData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.civilStatus.chart = respData.chart;
            });
    }

    public clickGrowthType(type: any) {
        let typeId: number = type.id;
        this.data.population.growth.growthType = type;

        this._municipalityService
            .getPopulationGrowthData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.growth.chart = respData.chart;
            });
    }

    public clickImmigrationType(type: any) {
        let typeId: number = type.id;
        this.data.population.immigration.immigrationType = type;

        this._municipalityService
            .getPopulationImmigrationData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.immigration.chart = respData.chart;
            });
    }

    public clickAgeType(type: any) {
        let typeId: number = type.id;
        this.data.population.age.ageType = type;

        this._municipalityService
            .getPopulationAgeData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.population.age.chart = respData.chart;

                // To change axis label format, whole chart needs to be re-rendered which this method makes sure of.
                this.settings.population.age.chart.resetOptions(type.id)
            });
    }




    public clickViolationType(type: any) {
        let typeId: number = type.id;
        this.data.safety.crimeReporting.violationType = type;

        this._municipalityService
            .getSafetyCrimeReportingData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.safety.crimeReporting.chart = respData.chart;
            });
    }



    public clickExpenseType(type: any) {
        let typeId: number = type.id;
        this.data.freetime.expenses.expenseType = type;

        this._municipalityService
            .getFreetimeExpensesData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.freetime.expenses.chart = respData.chart;
            });
    }



    public clickIncomeType(type: any) {
        let typeId: number = type.id;
        this.data.economy.income.incomeType = type;

        this._municipalityService
            .getEconomyIncomeData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.economy.income.chart = respData.chart;

                // To change axis label format, whole chart needs to be re-rendered which this method makes sure of.
                this.settings.economy.income.chart.resetOptions(type.id)
            });
    }

    public clickTaxType(type: any) {
        let typeId: number = type.id;
        this.data.economy.tax.taxType = type;

        this._municipalityService
            .getEconomyTaxData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.economy.tax.chart = respData.chart;

                // To change axis label format, whole chart needs to be re-rendered which this method makes sure of.
                this.settings.economy.tax.chart.resetOptions(type.id)
            });
    }



    public clickHousingType(type: any) {
        let typeId: number = type.id;
        this.data.residential.housings.housingType = type;

        this._municipalityService
            .getResidentialHousingData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.residential.housings.chart = respData.chart;
            });
    }

    public clickPricePropertyType(type: any) {
        let typeId: number = type.id;
        this.data.residential.price.propertyType = type;

        this._municipalityService
            .getResidentialPriceData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.residential.price.chart = respData.chart;
            });
    }

    public clickSalesPeriodPropertyType(type: any) {
        let typeId: number = type.id;
        this.data.residential.salesPeriod.propertyType = type;

        this._municipalityService
            .getResidentialSalesPeriodData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.residential.salesPeriod.chart = respData.chart;
            });
    }

    public clickConstructionYearType(type: any) {
        let typeId: number = type.id;
        this.data.residential.constructionYear.yearType = type;

        this._municipalityService
            .getResidentialConstructionYearData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.residential.constructionYear.chart = respData.chart;
            });
    }

    public clickSizeType(type: any) {
        let typeId: number = type.id;
        this.data.residential.size.sizeType = type;

        this._municipalityService
            .getResidentialSizeData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.residential.size.chart = respData.chart;
            });
    }



    public clickAvailabilityType(type: any) {
        let typeId: number = type.id;
        this.data.daycare.availability.availabilityType = type;

        this._municipalityService
            .getDaycareAvailabilityData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.daycare.availability.chart = respData.chart;
            });
    }


    public clickFareType(type: any) {
        let typeId: number = type.id;
        this.data.daycare.fares.fareType = type;

        this._municipalityService
            .getDaycareFaresData(this.municipality.municipalityId, typeId)
            .subscribe((respData: any) => {
                this.data.daycare.fares.chart = respData.chart;
            });
    }





}
