export enum MarkerSchoolDistrictType {
    None = 0,

    PreSchool = 1,
    Class0 = 2,
    Class1 = 3,
    Class2 = 4,
    Class3 = 5,
    Class4 = 6,
    Class5 = 7,
    Class6 = 8,
    Class7 = 9,
    Class8 = 10,
    Class9 = 11,
    Class10 = 12,
}