import { Component, OnInit } from '@angular/core';
import { ScrollService } from './scroll.service';
import { MenuItemsService } from './menu-items.service';
import { UtilityService } from '../shared/services/utility.service';
import { DeviceType } from '../shared/types/device-type.enum';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {
    public items: any;

    isMobileSite: boolean;

    constructor(private _scrollService: ScrollService, private _menuItemsService: MenuItemsService, private _utilityService: UtilityService) {
        this.items = _menuItemsService.items;
        this.isMobileSite = this._utilityService.getClientDeviceType() == DeviceType.Mobile;
    }

    public clickMenuItem(item: any) {
        var offset = 115;
        var fragmentName = item.items[0].fragment

        this._scrollService.scrollToId(fragmentName, offset)
    }
}
