import { Component, Input, EventEmitter, Output } from '@angular/core';

import { ScrollService } from './scroll.service';

@Component({
    selector: '[app-overview-segment-number]',
    styleUrls: ['./overview-segment.scss'],
    template: `
        <div class="b-overview__segment-inner" (click)="clickMenuItem(fragment)">
            <div>
                <h4 class="b-overview__segment-header">
                    {{item.headline}}
                </h4>
            </div>

            <div class="ui grid">
                <div class="sixteen wide column">
                    <div>
                        <h1 class="b-overview__segment-body">
                            <span *ngIf="item.statistics && item.statistics.municipality">{{item.statistics.municipality.present.value | number: '1.0'}} {{item.statistics.municipality.present.unit}}</span>
                            <span *ngIf="!(item.statistics && item.statistics.municipality)">-</span>
                        </h1>
                    </div>
                </div>
            </div>

            <div *ngIf="item.description">
                <p class="b-overview__segment-footer-description">
                    {{item.description}}
                </p>
            </div>
        </div>
    `
})
export class OverviewNumberSegmentComponent {
    @Input() fragment: string;
    @Input() item: any;

    constructor(private _scrollService: ScrollService) {
    }

    public clickMenuItem(fragment: any) {
        this._scrollService.scrollToId(fragment)
    }
}